import Alert from './components/Alert/Alert';
import { OfficeRouter } from './router/OfficeRouter';
import { MaterialCategoryContainer } from '@container/materialCategory';
import { MileageContainer } from '@container/mileage';
import { ProductCategoryContainer } from '@container/productCategory';
import { UserContainer } from '@container/user';
import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { QueryMetaProvider } from 'react-query-swagger';
import { RecoilRoot } from 'recoil';

function App() {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <QueryMetaProvider meta={{ region: 'header' }}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <UserContainer.ContextProvider>
            <MileageContainer.ContextProvider>
              <ProductCategoryContainer.ContextProvider>
                <MaterialCategoryContainer.ContextProvider>
                  <Alert />
                  <OfficeRouter />
                </MaterialCategoryContainer.ContextProvider>
              </ProductCategoryContainer.ContextProvider>
            </MileageContainer.ContextProvider>
          </UserContainer.ContextProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </QueryMetaProvider>
  );
}

export default App;
