import React from 'react';

interface OrderCategoryProps {
  text: string;
  onClick: () => void;
  active: boolean;
}

export const OrderCategory: React.FC<OrderCategoryProps> = ({
  text,
  onClick,
  active,
}) => {
  return (
    <div
      className={
        active
          ? 'px-5 py-3 bg-gray-700 text-white cursor-pointer'
          : 'px-5 py-3 cursor-pointer border-r'
      }
      onClick={onClick}
    >
      {text}
    </div>
  );
};
