import { useAlert } from '@/components/Alert/Alert';
import UserSelectForm from '@/components/form/UserSelectForm';
import { MemberContainer } from '@/container/member';
import { ContentContainer } from '@container/content';
import { UserContainer } from '@container/user';
import { Button, Select, TextArea } from '@entropyparadox/reusable-react';
import React, { useState } from 'react';

const AccumulatedRedeemContent = () => {
  const [memo, setMemo] = useState('');
  const [amount, setAmount] = useState('');
  // true: 지급, false: 차감
  const [sign, setSign] = useState('true');
  const { showSuccessAlert, showErrorAlert } = useAlert();

  const { selectedUsers, selectedGroups, setSelectedGroups, setSelectedUsers } = MemberContainer.useContext();
  const { createUserMileageHistories } = ContentContainer.useContext();
  const { myProfile } = UserContainer.useContext();

  const handleSubmit = () => {
    if (!amount || amount === '0') {
      alert('금액을 입력해주세요');
      return;
    }
    if (!selectedGroups.length && !selectedUsers.length) {
      alert('대상을 선택해주세요');
      return;
    }

    createUserMileageHistories({
      mileage: Number(amount) * (sign === 'true' ? 1 : -1),
      message: '관리자에 의한 적립',
      userId: undefined,
      adminNote: memo,
      adminId: myProfile?.id,
      targetUserGroupIds: selectedGroups.map(row => row.id),
      targetUserIds: selectedUsers.map(row => row.id),
    })
      .then(() => showSuccessAlert())
      .then(() => clear())
      .catch(showErrorAlert);
  };

  const clear = () => {
    setMemo('');
    setAmount('');
    setSelectedGroups([]);
    setSelectedUsers([]);
  };

  return (
    <>
      <h3 className="font-semibold text-lg">기본적립금</h3>
      <div className="grid grid-cols-3 gap-6">
        <UserSelectForm />
        <div className="col-start-1">
          <Select value={sign} onChange={e => setSign(e.target.value)}>
            <option disabled selected value="" hidden>
              지급/차감 선택
            </option>
            {[
              { id: 1, value: 'true', name: '지급' },
              { id: 2, value: 'false', name: '차감' },
            ].map(item => (
              <option key={item.id} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="flex items-end">
          <div
            className="block px-4 w-full h-12 border
                 border-gray-200 rounded-md
                 focus:ring-0 focus-within:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400">
            <input
              type="number"
              className="border-none sm:text-sm focus:ring-0 placeholder-gray-400 h-11 w-10/12"
              placeholder="금액을 입력해주세요"
              value={amount || ''}
              onChange={e => setAmount(e.target.value)}
            />
            <span>원</span>
          </div>
        </div>
        <div className="col-start-1">
          <TextArea label="관리자메모" value={memo || ''} onChange={e => setMemo(e.target.value)} />
        </div>
      </div>

      <div className="flex justify-center mt-16">
        <div className="flex flex-col w-56">
          <Button text="지급/차감하기" onClick={handleSubmit} />
        </div>
      </div>
    </>
  );
};

export const AccumulatedRedeem = () => {
  return (
    <ContentContainer.ContextProvider>
      <MemberContainer.ContextProvider>
        <AccumulatedRedeemContent />
      </MemberContainer.ContextProvider>
    </ContentContainer.ContextProvider>
  );
};
