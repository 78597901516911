export enum FileEnum {
  POSTOFFICE = 'POSTOFFICE',
  TEAMFRESH = 'TEAMFRESH',
  LOGEN = 'LOGEN',
  CJ = 'CJ',
}

export const convertStringToEnum = (value: string) => {
  if (value === 'CJ') {
    return FileEnum.CJ;
  }
  if (value === 'LOGEN') {
    return FileEnum.LOGEN;
  }
  if (value === 'TEAMFRESH') {
    return FileEnum.TEAMFRESH;
  }

  return FileEnum.POSTOFFICE;
};
