import { AxiosClient, AxiosQuery } from '@/api';
import { createContainer } from '@/container/createContainer';
import {
  IRequestCreateMaterialCategoryDto,
  IRequestCreateMaterialDto,
  IRequestCreateMaterialStockDto,
  IRequestUpdateMaterialCategoryDto,
  IRequestUpdateMaterialDto,
  RequestCreateMaterialCategoryDto,
  RequestCreateMaterialDto,
  RequestCreateMaterialStockDto,
  RequestUpdateMaterialCategoryDto,
  RequestUpdateMaterialDto,
} from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import { DefaultGetOneBaseQueryParameters, DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import React, { useState } from 'react';

const MaterialCategoryHook = () => {
  const [selectedMaterialCategoryId, setSelectedMaterialCategoryId] = useState<number>();
  const [selectedMaterialId, setSelectedMaterialId] = useState<number>();
  const [materialStockHistoryPage, setMaterialStockHistoryPage] = useState<number>(1);
  const [materialStockHistoryFilter, setMaterialStockHistoryFilter] = useState<string[]>([]);

  const { data: materialCategoryList, refetch: refetchMaterialCategoryList } =
    AxiosQuery.Query.useGetManyBaseMaterialCategoryControllerMaterialCategoryQuery(
      {
        ...DefaultRequestCrudDto,
        join: ['materials'],
        sort: ['id,ASC'],
      },
      { refetchOnWindowFocus: false },
    );

  const { data: materialList, refetch: refetchMaterialList } =
    AxiosQuery.Query.useGetManyBaseMaterialControllerMaterialQuery(
      {
        ...DefaultRequestCrudDto,
        join: ['products'],
        limit: Const.DefaultPageLimitSize,
        filter: [...DefaultRequestCrudDto.filter, `categoryId||$eq||${selectedMaterialCategoryId}`],
        sort: ['id,DESC'],
      },
      {
        enabled: !!selectedMaterialCategoryId,
        refetchOnWindowFocus: false,
      },
    );

  const { data: selectedMaterial, refetch: refetchSelectedMaterial } =
    AxiosQuery.Query.useGetOneBaseMaterialControllerMaterialQuery(
      {
        ...DefaultGetOneBaseQueryParameters,
        id: selectedMaterialId || 0,
      },
      {
        enabled: !!selectedMaterialId,
        refetchOnWindowFocus: false,
      },
    );

  const { data: materialStockHistoryList, refetch: refetchMaterialStockHistoryList } =
    AxiosQuery.Query.useGetManyBaseMaterialStockControllerMaterialStockQuery(
      {
        ...DefaultRequestCrudDto,
        page: materialStockHistoryPage,
        limit: Const.DefaultPageLimitSize,
        filter: [
          ...DefaultRequestCrudDto.filter,
          `materialId||$eq||${selectedMaterialId}`,
          ...materialStockHistoryFilter,
        ],
        sort: ['id,DESC'],
      },
      {
        enabled: !!selectedMaterialId,
        refetchOnWindowFocus: false,
      },
    );

  const createMaterialCategory = (param: IRequestCreateMaterialCategoryDto): Promise<void> =>
    AxiosClient.createOneBaseMaterialCategoryControllerMaterialCategory(
      new RequestCreateMaterialCategoryDto(param),
    ).then(() => {
      refetchMaterialCategoryList();
    });

  const updateMaterialCategory = (id: number, param: IRequestUpdateMaterialCategoryDto): Promise<void> =>
    AxiosClient.updateOneBaseMaterialCategoryControllerMaterialCategory(
      id,
      new RequestUpdateMaterialCategoryDto(param),
    ).then(() => {
      refetchMaterialCategoryList();
    });

  const deleteMaterialCategory = (id?: number): Promise<void> => {
    if (!id) return Promise.resolve();

    return AxiosClient.deleteOneBaseMaterialCategoryControllerMaterialCategory(id).then(() => {
      refetchMaterialCategoryList();
    });
  };

  const createMaterial = (param: IRequestCreateMaterialDto): Promise<void> =>
    AxiosClient.createOneBaseMaterialControllerMaterial(new RequestCreateMaterialDto(param)).then(() => {
      !!selectedMaterialCategoryId && refetchMaterialList();
    });

  const deleteMaterial = (id: number): Promise<void> =>
    AxiosClient.deleteOneBaseMaterialControllerMaterial(id).then(() => {
      !!selectedMaterialCategoryId && refetchMaterialList();
    });

  const updateMaterial = (id: number, param: IRequestUpdateMaterialDto): Promise<void> =>
    AxiosClient.updateOneBaseMaterialControllerMaterial(id, new RequestUpdateMaterialDto(param)).then(() => {
      !!selectedMaterialCategoryId && refetchMaterialList();
    });

  const createMaterialHistory = (param: IRequestCreateMaterialStockDto) =>
    AxiosClient.createOneBaseMaterialStockControllerMaterialStock(
      new RequestCreateMaterialStockDto(param),
    ).then(() => {
      !!selectedMaterialCategoryId &&
        refetchMaterialList() &&
        refetchSelectedMaterial() &&
        refetchMaterialStockHistoryList();
    });

  return {
    setSelectedMaterialCategoryId,
    setSelectedMaterialId,
    setMaterialStockHistoryPage,
    setMaterialStockHistoryFilter,
    materialCategoryList,
    materialList,
    materialStockHistoryList,
    selectedMaterial,
    createMaterialCategory,
    updateMaterialCategory,
    deleteMaterialCategory,
    createMaterial,
    updateMaterial,
    deleteMaterial,
    createMaterialHistory,
  };
};

export const MaterialCategoryContainer = createContainer(MaterialCategoryHook);
