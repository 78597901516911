import { Field } from './Field';
import {
  IRequestCreateDisplayCategoryDto,
  IRequestCreateDisplayProductDto,
} from '@api/generated/axios-client';
import { useAlert } from '@components/Alert/Alert';
import { MainContainer } from '@container/main';
import { MainPopupContainer } from '@container/mainPopup';
import React from 'react';
import NumberFormat from 'react-number-format';


interface NormalFieldProps {
  label: string;
  property: string;
  subProperty?: string;
}

export const NormalTextField: React.FC<NormalFieldProps> = ({ label, property, subProperty }) => {
  return (
    <Field
      label={label}
      render={row => {
        const renderText = () => {
          if (subProperty) {
            return `${row[property]} ${row[subProperty]}`;
          }
          if (row[property]) {
            return `${row[property]}`;
          }
          return '';
        };
        return <div className="text-sm truncate overflow-hidden w-96">{renderText()}</div>;
      }}
    />
  );
};

export const NormalField: React.FC<NormalFieldProps> = ({ label, property, subProperty }) => {
  return (
    <Field
      label={label}
      render={row => {
        const renderText = () => {
          if (subProperty) {
            return `${row[property]} ${row[subProperty]}`;
          }
          if (row[property]) {
            return `${row[property]}`;
          }
          return '';
        };
        return <div className="text-sm min-w-max">{renderText()}</div>;
      }}
    />
  );
};

export const NormalExposureCategoryField: React.FC<NormalFieldProps> = ({ label, property, subProperty }) => {
  const { showSuccessAlert } = useAlert();
  const { displayCategories, createDisplayCategories, updateDisplayCategories, deleteDisplayCategories } =
    MainContainer.useContext();
  return (
    <Field
      label={label}
      render={row => (
        <div
          className="text-sm min-w-max"
          onClick={() => {
            const value = prompt('우선순위를 입력하세요 ');
            if (value) {
              if (row.id) {
                let params: IRequestCreateDisplayCategoryDto;
                params = {
                  isActive: true,
                  sorted: Number(value),
                  categoryId: row.categoryId,
                  imageUrl: '',
                };
                updateDisplayCategories(row.id, params)
                  .then(() => showSuccessAlert())
                  .catch();
              }
            }
          }}>
          {subProperty ? `${row[property]} ${row[subProperty]}` : `${row[property]}`}
        </div>
      )}
    />
  );
};

export const NormalExposureProductField: React.FC<NormalFieldProps> = ({ label, property, subProperty }) => {
  const { showSuccessAlert } = useAlert();
  const { createDisplayProduct, updateDisplayProduct, deleteDisplayProduct, productList } =
    MainContainer.useContext();
  return (
    <Field
      label={label}
      render={row => (
        <div
          className="text-sm min-w-max"
          onClick={() => {
            const value = prompt('우선순위를 입력하세요 ');
            if (value) {
              if (row.id) {
                let params: IRequestCreateDisplayProductDto;
                params = {
                  isActive: true,
                  sorted: Number(value),
                  productId: row.productId,
                };
                updateDisplayProduct(row.id, params)
                  .then(() => showSuccessAlert())
                  .catch();
              }
            }
          }}>
          {subProperty ? `${row[property]} ${row[subProperty]}` : `${row[property]}`}
        </div>
      )}
    />
  );
};

export const NormalPopupField: React.FC<NormalFieldProps> = ({ label, property, subProperty }) => {
  const { popup, setSelectedPopupId, createPopup, updatePopups, deletePopup, popups } =
    MainPopupContainer.useContext();

  return (
    <Field
      label={label}
      render={row => (
        <div
          className="text-sm min-w-max"
          onClick={() => {
            const value = prompt('우선순위를 입력하세요 ');
            if (value && row.id) {
              updatePopups(row.id, {
                title: row.name,
                imageUrl: row.popupUrl,
                actionText: row.actionText,
                actionUrl: row.actionUrl,
                sorted: Number(value),
              });
            }
          }}>
          {subProperty ? `${row[property]} ${row[subProperty]}` : `${row[property]}`}
        </div>
      )}
    />
  );
};

export const NormalNumberField: React.FC<NormalFieldProps> = ({ label, property }) => {
  return (
    <Field
      label={label}
      render={row => (
        <NumberFormat
          displayType="text"
          thousandSeparator={true}
          value={row[property]}
          className="text-sm min-w-max"
        />
      )}
    />
  );
};

export const JoinCategoryField: React.FC<NormalFieldProps> = ({ label, property }) => {
  return (
    <Field label={label} render={row => <div className="text-sm min-w-max">{row.category[property]}</div>} />
  );
};

export const JoinProductField: React.FC<NormalFieldProps> = ({ label, property }) => {
  return (
    <Field label={label} render={row => <div className="text-sm min-w-max">{row.product[property]}</div>} />
  );
};
