import { useHistory } from 'react-router-dom';
import { Icon } from './Icon';

interface PushPageTopBarProps {
  title: string;
}

export const PushPageTopBar: React.FC<PushPageTopBarProps> = ({ title }) => {
  const history = useHistory();
  return (
    <div className="sticky w-full top-0 z-50">
      <div className="px-5 py-3 border-b flex items-center bg-white space-x-4">
        <button>
          <Icon.ArrowLeft onClick={history.goBack} />
        </button>
        <div className="text-xl font-semibold">{title}</div>
      </div>
    </div>
  );
};
