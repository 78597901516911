import { ReactComponent as FileUploadImg } from '../assets/svg/file-upload.svg';
import { TextField } from '@entropyparadox/reusable-react';
import React, { ChangeEvent } from 'react';

export interface PhotoFieldProps {
  id: string;
  fileUrl: string;
  onFileChange: (id: string, e: ChangeEvent<HTMLInputElement>) => void;
  text: string;
  onTextChange: (id: string, e: ChangeEvent<HTMLInputElement>) => void;
}

export const PhotoField: React.FC<PhotoFieldProps> = ({ id, fileUrl, onFileChange, text, onTextChange }) => {
  return (
    <div>
      <label htmlFor={`${id}_certificateBrokerUpload`}>
        <div className="relative pb-full rounded-md border-2 border-grey-5">
          {fileUrl ? (
            <>
              <img className="absolute w-full h-full rounded object-cover" src={fileUrl} alt="" />
              <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-3 left-3">
                사진 수정하기
              </div>
            </>
          ) : (
            <div className="absolute w-full h-full rounded-md object-cover bg-white">
              <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                <FileUploadImg />
                <div className="text-sm text-gray-500 text-center pt-1">
                  이미지를 업로드해주세요.
                  <br />
                  (최대 1장)
                </div>
              </div>
            </div>
          )}
        </div>
      </label>
      <input
        type="file"
        id={`${id}_certificateBrokerUpload`}
        className="hidden"
        accept="image/*"
        onChange={e => {
          if (!e.target.validity.valid) return;
          const file = e.target.files?.item(0);
          if (file) {
            onFileChange(id, e);
          }
        }}
      />
      <TextField label="연결링크" value={text} onChange={e => onTextChange(id, e)} />
    </div>
  );
};
