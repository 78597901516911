import { AxiosQuery } from '@/api';
import Autocomplete from '@/components/form/Autocomplete';
import {
  IProductOption,
  IProductOptionGroup,
  ProductOption,
  ProductOptionGroup,
} from '@api/generated/axios-client';
import { Toggles } from '@components/Toggles';
import { Const } from '@constants/Const';
import { Button, CloseIcon, TextField } from '@entropyparadox/reusable-react';
import { DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import { useDebounceState } from '@utils/useDebounceState';
import { filter, sortBy } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';


interface ProductOptionCardProps {
  productId?: number;
  item: ProductOption;
  onChange?: (value: ProductOption) => void;
  deletable?: boolean;
  onDelete?: () => void;
}

interface ProductOptionGroupCardProps {
  productId?: number;
  item: ProductOptionGroup;
  onChange?: (value: ProductOptionGroup) => void;
  onDelete?: () => void;
}

interface ProductOptionGroupProps {
  productId?: number;
  onChange?: (value: ProductOptionGroup[]) => void;
  items?: ProductOptionGroup[];
}

const getDefaultProductOption = (productId?: number, groupId?: number): ProductOption => {
  const productOption = new ProductOption();
  productOption.isActive = true;
  productOption.isSoldout = false;
  if (productId) productOption.productId = productId;
  productOption.groupId = groupId;
  return productOption;
};

const getDefaultProductOptionGroup = (productId?: number): ProductOptionGroup => {
  const productOptionGroup = new ProductOptionGroup();
  productOptionGroup.isActive = true;
  if (productId) productOptionGroup.productId = productId;
  productOptionGroup.isSoldOut = false;
  productOptionGroup.isRequired = true;

  productOptionGroup.productOptions = [getDefaultProductOption(productId)];

  return productOptionGroup;
};

export const ProductOptionCard: React.FC<ProductOptionCardProps> = ({
  productId,
  deletable = true,
  item,
  onChange,
  onDelete,
}) => {
  const [productOption, setProductOption] = useState<IProductOption>(
    item ? item : getDefaultProductOption(productId),
  );
  const [keyword, setKeyword, debounceKeyword] = useDebounceState('', 500);

  const { data: searchedMaterialList } = AxiosQuery.Query.useGetManyBaseMaterialControllerMaterialQuery(
    {
      ...DefaultRequestCrudDto,
      join: ['materialCategory'],
      limit: Const.DefaultPageLimitSize,
      filter: [...DefaultRequestCrudDto.filter, `name||$cont||${debounceKeyword}`],
      sort: ['id,DESC'],
    },
    {
      enabled: !!debounceKeyword,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    setProductOption(item);
    setKeyword(item.material?.name || '');
  }, [item]);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!productOption) return;

    const target = e.target as HTMLInputElement;
    const newProductOption = { ...productOption, [target.name]: e.target.value };
    setProductOption(newProductOption);
    onChange?.(new ProductOption(newProductOption));
  };

  return (
    <>
      <div className="col-start-3">
        <TextField
          label="옵션 상세*"
          type="text"
          name="name"
          value={productOption.name}
          onChange={handleChange}
        />
      </div>
      <TextField
        label="옵션 가격*"
        type="text"
        name="extraPrice"
        value={productOption.extraPrice}
        onChange={handleChange}
      />
      <div className="col-span-2">
        {!productOption.material ? (
          <div>
            <label className="block mb-1 text-sm text-gray-800">제품명 (선택)</label>
            <Autocomplete
              items={
                searchedMaterialList?.data.map(row => ({
                  label: `${row.materialCategory.title} / ${row.name} (총 재고: ${row.stock})`,
                  value: row,
                })) || []
              }
              searchKeyword={keyword}
              onChangeKeyword={setKeyword}
              onSelect={item => {
                const newProductOption = {
                  ...productOption,
                  stock: 1,
                  materialId: item.value.id,
                  material: item.value,
                };
                setProductOption(newProductOption);
                onChange?.(new ProductOption(newProductOption));
              }}
            />
          </div>
        ) : (
          <TextField
            label="제품명 (선택)"
            type="text"
            value={`${productOption.material.name} (총 재고: ${productOption.material.stock})`}
            disabled
          />
        )}
      </div>

      <TextField
        label="포함된 개수 (선택)"
        type="number"
        name="stock"
        onWheel={e => e.currentTarget.blur()}
        disabled={!productOption.material}
        value={productOption.stock || ''}
        onChange={e => {
          const newProductOption = { ...productOption, stock: Number(e.target.value) };
          setProductOption(newProductOption);
          onChange?.(new ProductOption(newProductOption));
        }}
      />

      <div className="flex flex-col space-x-2 text-sm">
        <div className="relative mb-3">수동 품절처리</div>
        <Toggles
          value={productOption.isSoldout}
          onChange={v => {
            const newProductionOption = { ...productOption, isSoldout: v };
            setProductOption(newProductionOption);
            onChange?.(new ProductOption(newProductionOption));
          }}
        />
      </div>
      <div className="flex items-center">
        {deletable && <CloseIcon className="mt-5" onClick={() => onDelete?.()} />}
      </div>
      <div className="col-start-3 col-span-6 h-0.5 bg-gray-100"></div>
    </>
  );
};

export const ProductOptionGroupCard: React.FC<ProductOptionGroupCardProps> = ({
  productId,
  item,
  onChange,
  onDelete,
}) => {
  const [productOptionGroup, setProductOptionGroup] = useState<IProductOptionGroup>(
    getDefaultProductOptionGroup(),
  );

  useEffect(() => {
    setProductOptionGroup(item);
  }, [item]);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!productOptionGroup) return;

    const target = e.target as HTMLInputElement;
    const newProductOptionGroup = { ...productOptionGroup, [target.name]: e.target.value };
    setProductOptionGroup(newProductOptionGroup);
    onChange?.(new ProductOptionGroup(newProductOptionGroup));
  };

  const handleDeleteProductOption = (index: number) => {
    if (!productOptionGroup.productOptions) return;
    const filteredProductOptions = filter(productOptionGroup.productOptions, (_, i) => i !== index);
    const newProductOptionGroup = { ...productOptionGroup, productOptions: filteredProductOptions };

    setProductOptionGroup(newProductOptionGroup);
    onChange?.(new ProductOptionGroup(newProductOptionGroup));
  };

  const handleChangeProductOption = (prevItem: ProductOption, newItem: ProductOption) => {
    const newProductOptions =
      productOptionGroup.productOptions?.map(i => (i !== prevItem ? i : newItem)) || [];
    const newProductOptionGroup = {
      ...productOptionGroup,
      productOptions: newProductOptions as ProductOption[],
    };
    setProductOptionGroup(newProductOptionGroup);
    onChange?.(new ProductOptionGroup(newProductOptionGroup));
  };

  return (
    <>
      <div className="border rounded-md p-6 mt-6">
        <div className="flex justify-end items-center mb-6">
          <Button text="이 옵션 삭제" variant="outlined" onClick={() => onDelete?.()} />
        </div>
        <div className="grid grid-cols-9 gap-6">
          <div className="col-span-2">
            <TextField
              label="옵션명*"
              type="text"
              name="name"
              value={productOptionGroup.name}
              onChange={handleChange}
            />
          </div>
          {sortBy(productOptionGroup.productOptions, 'id')?.map((item, i) => {
            return (
              <ProductOptionCard
                key={item.id || i}
                productId={productId}
                item={item}
                onDelete={() => handleDeleteProductOption(i)}
                onChange={productOption => handleChangeProductOption(item, productOption)}
                deletable={(productOptionGroup.productOptions?.length || 0) > 1}
              />
            );
          })}
          <div
            className="col-start-3 col-span-6 text-center cursor-pointer"
            onClick={() => {
              const newProductOptions = productOptionGroup.productOptions?.slice() || [];
              newProductOptions.push(getDefaultProductOption(productId));
              setProductOptionGroup({ ...productOptionGroup, productOptions: newProductOptions });
            }}>
            옵션 상세 추가 +
          </div>
        </div>
      </div>
    </>
  );
};

export const ProductOptionGroupSection: React.FC<ProductOptionGroupProps> = ({
  productId,
  items,
  onChange,
}) => {
  const [productOptionGroups, setProductOptionGroups] = useState<ProductOptionGroup[]>([]);

  useEffect(() => {
    setProductOptionGroups(items || [getDefaultProductOptionGroup(productId)]);
  }, [items]);

  const handleAppendProductOptionGroup = () => {
    productOptionGroups.push(getDefaultProductOptionGroup(productId));
    setProductOptionGroups(productOptionGroups.slice());
    onChange?.(productOptionGroups.slice());
  };

  const handleDeleteProductOptionGroup = (index: number) => {
    if (!productOptionGroups?.length) return;
    const filteredProductOptionGroups = filter(productOptionGroups, (_, i) => i !== index);
    const newProductOptionGroups = [...filteredProductOptionGroups];

    setProductOptionGroups(newProductOptionGroups);
    onChange?.(newProductOptionGroups);
  };

  const handleChangeProductOptionGroup = (prevItem: ProductOptionGroup, newItem: ProductOptionGroup) => {
    const newProductOptionGroups = (productOptionGroups?.map(i => (i !== prevItem ? i : newItem)) ||
      []) as ProductOptionGroup[];
    setProductOptionGroups(newProductOptionGroups);
    onChange?.(newProductOptionGroups);
  };

  return (
    <div className="p-6 2xl:w-full">
      <div className="flex justify-between items-center">
        <h3 className="font-semibold text-xl">상품 옵션 (선택)</h3>
        {productOptionGroups.length === 0 && <Button text="추가" onClick={handleAppendProductOptionGroup} />}
      </div>
      {productOptionGroups
        ?.filter(i => !i.deleted && !i.deletedAt)
        ?.map((item, i) => (
          <ProductOptionGroupCard
            key={item.id || i}
            productId={productId}
            item={item}
            onDelete={() => handleDeleteProductOptionGroup(i)}
            onChange={productOptionGroup => handleChangeProductOptionGroup(item, productOptionGroup)}
          />
        ))}
    </div>
  );
};
