import { ReactComponent as FileUploadImg } from '../assets/svg/file-upload.svg';
import { ReactComponent as CloseIconWhite } from '../assets/svg/icon-closewhite.svg';
import { ReactComponent as CloseIconBlack } from '../assets/svg/icon-close.svg';
import {
  FileParameter,
  MediaType,
  S3ControllerQuery,
  S3ResourceType,
  StoryActionType,
} from '@api/generated/axios-client';
import { Checkbox, TextField } from '@entropyparadox/reusable-react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface StoryItemUploadCardProps {
  item?: any /*StoryDto*/;
  onChange?: (updatedItem: StoryDto /*StoryDto*/) => void;
  onDelete?: () => void;
}
// 스토리 (메인에 표시되는 '온푸 오늘의 소식')
export interface StoryDto {
  id?: number;
  sorted: number;
  title: string;
  description: string;
  mediaType: MediaType;
  mediaUrl: string;
  mediaFileToken?: string;
  actionType?: StoryActionType | undefined;
  isActionType: boolean;
  actionText: string;
  actionUrl: string;
  // 프론트에서 사용
  mediaToken?: string;
}

export const StoryItemUploadCard = ({ item, onChange, onDelete }: StoryItemUploadCardProps) => {
  const [productPhoto, setProductPhoto] = useState<File | null | undefined>();
  const [uploadMediaUrl, setUploadMediaUrl] = useState('');
  const [sorted, setSorted] = useState(item.sorted);
  const [link, setLink] = useState(item.actionUrl);
  const [buttonName, setButtonName] = useState(item.actionText);
  const [mediaType, setMediaType] = useState('');
  const [isActionType, setISActionType] = useState(
    item?.actionType === StoryActionType.Button ? true : false,
  );
  const key = uuidv4();
  const changeMedia = item.mediaType == 'image' && productPhoto?.type.includes('video');
  const checkImage = item.mediaType == 'image' || productPhoto?.type.includes('image');
  const checkVideo = item.mediaType == 'video' || productPhoto?.type.includes('video');

  useEffect(() => {
    if (changeMedia) setMediaType(MediaType.Video);
    else if (checkImage) setMediaType(MediaType.Image);
    else if (checkVideo) setMediaType(MediaType.Video);
  }, [productPhoto]);

  useEffect(() => {
    const updatedItem: StoryDto /*StoryDto*/ = {
      description: '',
      title: '',
      isActionType: isActionType,
      // 위까지 기본값
      ...item,
      mediaType: mediaType,
      sorted: sorted,
      actionUrl: link,
      actionText: buttonName,
      mediaUrl: uploadMediaUrl || item?.mediaUrl || '',
      // mediaToken: uploadMedia?.token,
    };
    onChange?.(updatedItem);
  }, [uploadMediaUrl, link, buttonName, isActionType, sorted, mediaType]);

  const fileUpload = async (file: File) => {
    let fileParameter: FileParameter = {
      data: file,
      fileName: file.name,
    };
    const s3UploadDto = await S3ControllerQuery.Client.s3UploadFile(
      S3ResourceType.RecommendProduct,
      fileParameter,
    );
    setUploadMediaUrl(s3UploadDto.fullUrl);
  };

  const handleChangeChecked = (e: ChangeEvent<HTMLInputElement>) => {
    setISActionType(e.target.checked);
  };

  return (
    <div className="relative">
      <label htmlFor={key}>
        <div className="aspect-w-6 aspect-h-13 w-full rounded-md border-2 border-grey-5">
          {item?.mediaUrl ? (
            <>
              {changeMedia ? (
                <video
                  className="absolute w-full h-full object-cover"
                  controls
                  src={productPhoto ? URL.createObjectURL(productPhoto) : item?.mediaUrl}
                />
              ) : checkImage ? (
                <img
                  className="absolute w-full h-full rounded object-cover"
                  src={productPhoto ? URL.createObjectURL(productPhoto) : item?.mediaUrl}
                  alt=""
                />
              ) : checkVideo ? (
                <video
                  className="absolute w-full h-full object-cover"
                  controls
                  src={productPhoto ? URL.createObjectURL(productPhoto) : item?.mediaUrl}
                />
              ) : (
                ''
              )}
              <div>
                <div className="absolute px-3 py-1.5 bg-brand-1 text-white rounded-lg top-2 left-2 inline-block">
                  사진 수정하기
                </div>
                <div className="absolute px-1 py-1.5 flex top-2 right-1">
                  <CloseIconWhite
                    className="cursor-pointer"
                    onClick={e => {
                      e.preventDefault();
                      onDelete?.();
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="absolute w-full h-full rounded-md object-cover bg-white">
              <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                <FileUploadImg />
                <div className="text-sm text-gray-500 text-center pt-1">
                  이미지(jpg, png) 또는 영상(mp4) 파일을 업로드해주세요.
                  <br />
                  420px * 910px / 1mb이하 / 최대 1장
                </div>
              </div>
            </div>
          )}
        </div>
      </label>
      <input
        type="file"
        id={key}
        className="hidden"
        accept="license/*"
        onChange={e => {
          if (!e.target.validity.valid) return;
          const file = e.target.files?.item(0);
          if (file) {
            setProductPhoto(file);
            fileUpload(file);
          }
        }}
      />
      {item.mediaUrl ? (
        <div className="space-y-2">
          <TextField
            label="연결링크"
            value={link}
            onChange={e => {
              setLink(e.target.value);
            }}
          />
          <TextField
            type="number"
            label="우선순위"
            value={sorted}
            onChange={e => {
              setSorted(e.target.value);
            }}
          />
          {isActionType && (
            <TextField
              label="버튼명"
              value={buttonName}
              onChange={e => {
                e.preventDefault();
                setButtonName(e.target.value);
              }}
            />
          )}
          <div className="flex justify-end">
            <Checkbox
              label="버튼사용"
              name="isActionType"
              checked={isActionType}
              onChange={e => {
                handleChangeChecked(e);
              }}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
