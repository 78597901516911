import { MaterialStockStatus, MaterialStockType } from '@api/generated/axios-client';

export const MaterialStockStatusName = {
  [MaterialStockStatus.Admin_in]: '관리자입고',
  [MaterialStockStatus.Admin_out]: '관리자출고',
  [MaterialStockStatus.Will_out]: '출고예정',
  [MaterialStockStatus.Out]: '출고',
};

export const MaterialStockTypeName = {
  [MaterialStockType.Normal]: '-',
  [MaterialStockType.Gift]: '선물하기',
  [MaterialStockType.Shop]: '매장',
};
