import OrderField from '../components/Field/OrderField';
import Pagination from '../components/Pagination';
import { AxiosQuery } from '@/api';
import { Datagrid } from '@/components/Datagrid';
import { AddressField } from '@/components/Field/AddressField';
import { Field } from '@/components/Field/Field';
import { PaymentField } from '@/components/Field/PaymentField';
import { SearchField } from '@/components/Field/SearchField';
import { TopBar } from '@/components/TopBar';
import { renderer } from '@/utils/renderer';
import { OrderStatus, ShippingType } from '@api/generated/axios-client';
import { OrderItemField } from '@components/Field/OrderItemField';
import { Const } from '@constants/Const';
import { GiftCardImageContainer } from '@container/giftManage';
import { OrderContainer } from '@container/order';
import { isLoginState } from '@recoil/user';
import { PageHelper } from '@utils/PageHelper';
import { DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import { useDebounceState } from '@utils/useDebounceState';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';

const WEB_URL = process.env.REACT_APP_WEB_URL;

export const GiftPageContent = () => {
  const [isLogin] = useRecoilState(isLoginState);
  const [keyword, setKeyword, debounceKeyword] = useDebounceState('', 500);
  const [orderPage, setOrderPage] = useState<number>(1);

  const { data: orders } = AxiosQuery.Query.useGetManyBaseOrderControllerOrderQuery(
    {
      ...DefaultRequestCrudDto,
      join: [
        'orderProducts||id,productName,totalCount,shippingType,orderStatus,shippingCompany,shippingNumber',
        'orderProducts.orderProductOptions||id,optionName',
        'orderPayments',
        'orderGift',
      ],
      s: JSON.stringify({
        $and: [
          { orderStatus: { $ne: OrderStatus.None } },
          { isGift: { $eq: true } },
          ...(debounceKeyword
            ? [
                {
                  $or: [
                    { 'orderProducts.productName': { $contL: debounceKeyword } },
                    { customerName: { $contL: debounceKeyword } },
                    { customerPhone: { $contL: debounceKeyword } },
                    { receiverName: { $contL: debounceKeyword } },
                    { receiverPhone: { $contL: debounceKeyword } },
                    { orderNumber: { $contL: debounceKeyword } },
                  ],
                },
              ]
            : []),
        ],
      }),
      page: orderPage,
      limit: Const.DefaultPageLimitSize,
      sort: ['updatedAt,DESC'],
    },
    {
      enabled: isLogin,
      refetchOnWindowFocus: false,
    },
  );

  const pageInfo = PageHelper.getPageInfo({
    ...orders,
    gotoPage: setOrderPage,
  });

  if (!orders?.data) return null;

  const orderProducts = orders.data.flatMap(order =>
    order.orderProducts.map((orderProduct, i) => ({
      rowIndex: i,
      rowSpan: order.orderProducts.length,
      ...order,
      ...orderProduct,
      id: order.id,
      orderProductId: orderProduct.id,
      userId: order.userId,
    })),
  );

  return (
    <>
      <TopBar title="선물리스트 (선물 내역)" />
      <div className="p-6">
        <div className="flex flex-col items-end mb-4">
          <SearchField className="w-96" value={keyword} onChange={e => setKeyword(e.target.value)} />
        </div>
        <Datagrid data={orderProducts}>
          <OrderField label="주문번호 / 일자" property="createdAt" />
          <OrderItemField label="주문상품" property="id" />
          <Field
            label="상태"
            render={row => <div className="text-sm">{renderer.orderStatus(row.orderStatus)}</div>}
          />
          <Field
            label="배송옵션"
            render={row => (
              <div className="text-sm">
                {renderer.shippingType(row.shippingType || ShippingType.Normal_delivery)}
              </div>
            )}
          />
          <PaymentField label="결제정보" property="id" />
          <AddressField label="받은사람정보" property="id" />
          <Field
            label="보낸메세지"
            render={row =>
              row.orderGift?.message ? (
                <div
                  className="text-sm underline cursor-pointer"
                  onClick={() => window.open(`${WEB_URL}/gift/${row.giftKey}`)}>
                  url
                </div>
              ) : (
                ''
              )
            }
          />
          <Field
            label="답장메세지"
            render={row =>
              row.orderGift?.replyMessage ? (
                <div
                  className="text-sm underline cursor-pointer"
                  onClick={() => window.open(`${WEB_URL}/gift/${row.giftKey}`)}>
                  url
                </div>
              ) : (
                ''
              )
            }
          />
        </Datagrid>
        <Pagination {...pageInfo} />
      </div>
    </>
  );
};

export const GiftPage = () => {
  return (
    <GiftCardImageContainer.ContextProvider>
      <OrderContainer.ContextProvider>
        <GiftPageContent />
      </OrderContainer.ContextProvider>
    </GiftCardImageContainer.ContextProvider>
  );
};
