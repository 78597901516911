import { useAlert } from '@/components/Alert/Alert';
import { ItemModalPopup } from '@/components/Alert/ItemModalPopup';
import { Datagrid } from '@/components/Datagrid';
import { Field } from '@/components/Field/Field';
import Pagination from '@/components/Pagination';
import {
  IRequestCreateMaterialStockDto,
  MaterialStockStatus,
  MaterialStockType,
} from '@api/generated/axios-client';
import { MaterialStockStatusName, MaterialStockTypeName } from '@constants/EnumName';
import { MaterialCategoryContainer } from '@container/materialCategory';
import { Tab, Tabs } from '@entropyparadox/reusable-react';
import { PageHelper } from '@utils/PageHelper';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router';

export const StockDetail = () => {
  const { id } = useParams() as { id: string };
  const materialId = Number(id);
  const [tabIndex, setTabIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { showSuccessAlert, showErrorAlert } = useAlert();

  const {
    setSelectedMaterialId,
    setMaterialStockHistoryPage,
    setMaterialStockHistoryFilter,
    materialStockHistoryList,
    selectedMaterial,
    createMaterialHistory,
  } = MaterialCategoryContainer.useContext();

  useEffect(() => {
    setSelectedMaterialId(materialId ? Number(materialId) : undefined);
  }, [materialId]);

  useEffect(() => {
    setMaterialStockHistoryPage(0);
    setMaterialStockHistoryFilter(
      tabIndex === 1
        ? [`stockType||$eq||${MaterialStockType.Gift}`]
        : tabIndex === 2
        ? [`stockType||$eq||${MaterialStockType.Shop}`]
        : [],
    );
  }, [tabIndex]);

  const handleSave = (item: IRequestCreateMaterialStockDto) => {
    createMaterialHistory(item)
      .then(() => showSuccessAlert())
      .then(() => setIsOpen(false))
      .catch(showErrorAlert);
  };

  if (!materialStockHistoryList) return null;

  const pageInfo = PageHelper.getPageInfo({
    ...materialStockHistoryList,
    gotoPage: setMaterialStockHistoryPage,
  });

  return (
    <>
      <ItemModalPopup open={isOpen} onClose={() => setIsOpen(false)} onSave={handleSave} />
      {selectedMaterial && (
        <div className="w-72 border p-6 space-y-2 rounded-md m-6">
          <div className="text-xl font-semibold">{selectedMaterial?.name}</div>
          <div className="flex justify-between">
            <div className="text-gray-500">현재수량</div>
            <div>
              <NumberFormat displayType="text" thousandSeparator={true} value={selectedMaterial?.stock} />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-gray-500">선물 출고예정 수량</div>
            <div>
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={selectedMaterial?.willOutStockGift}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-gray-500">매장 출고예정 수량</div>
            <div>
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={selectedMaterial?.willOutStockShop}
              />
            </div>
          </div>
        </div>
      )}

      <div className="w-full relative py-2">
        <Tabs>
          <Tab text="전체재고" active={tabIndex === 0} onClick={() => setTabIndex(0)} />
          <Tab text="선물재고" active={tabIndex === 1} onClick={() => setTabIndex(1)} />
          <Tab text="매장재고" active={tabIndex === 2} onClick={() => setTabIndex(2)} />
        </Tabs>

        <button
          className="bg-black text-white px-4 py-2 text-sm rounded-md flex-shrink-0 mr-4 absolute top-0 right-6"
          onClick={() => setIsOpen(true)}>
          입고/출고 하기
        </button>
      </div>
      <div className="p-6">
        <div>
          {materialStockHistoryList?.data && (
            <Datagrid data={materialStockHistoryList.data}>
              <Field
                label="일자"
                render={row => (
                  <div className="text-sm text-gray-500">{new Date(row.createdAt).toLocaleDateString()}</div>
                )}
              />
              <Field
                label="잔여수량"
                render={row => (
                  <div className="text-sm text-gray-500 text-center">
                    <NumberFormat
                      displayType="text"
                      thousandSeparator={true}
                      value={row.remainingStock || 0}
                    />
                    개
                  </div>
                )}
              />
              <Field
                label="상태"
                render={row => (
                  <div className="text-sm text-gray-500 text-center">
                    {MaterialStockStatusName[row.stockStatus as MaterialStockStatus]}
                    {row.willOutDate && (
                      <>
                        <br />
                        {row.willOutDate ? row.willOutDate.toLocaleDateString() : ''}
                      </>
                    )}
                  </div>
                )}
              />
              <Field
                label="입/출고개수"
                render={row => {
                  const isPlus = row.stockStatus === MaterialStockStatus.Admin_in;
                  return (
                    <div
                      className={`text-sm text-gray-500 text-center ${
                        isPlus ? 'text-blue-500' : 'text-red-500'
                      }`}>
                      <NumberFormat
                        displayType="text"
                        thousandSeparator={true}
                        value={row.stock}
                        prefix={isPlus ? '+' : '-'}
                      />
                      개
                    </div>
                  );
                }}
              />
              <Field
                label="재고타입"
                render={row => (
                  <div className="text-sm text-gray-500">
                    {MaterialStockTypeName[row.stockType as MaterialStockType]}
                  </div>
                )}
              />
              <Field
                label="관련상품"
                render={row => <div className="text-sm text-gray-500">{row.productName || '-'}</div>}
              />
              <Field
                label="관리자 메모"
                render={row => <div className="text-sm text-gray-500">{row.note || '-'}</div>}
              />
            </Datagrid>
          )}
        </div>
        <Pagination {...pageInfo} />
      </div>
    </>
  );
};
