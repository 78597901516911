import Cookies from 'universal-cookie';

const cookies = new Cookies();
const TOKEN_NAME = 'admin_access_token';

export function setAccessToken(accessToken: string) {
  cookies.set(TOKEN_NAME, accessToken, {
    path: '/',
    maxAge: 1000 * 60 * 60 * 24 * 30,
  });
}

export function getAccessToken() {
  return cookies.get(TOKEN_NAME);
}

export function existsAccessToken() {
  return !!cookies.get(TOKEN_NAME);
}

export function clearAccessToken() {
  cookies.remove(TOKEN_NAME);
}
