import { UserDetailAccumulated } from './UserDetailAccumulated';
import { UserDetailAddress } from './UserDetailAddress';
import { UserDetailInfo } from './UserDetailInfo';
import { useAlert } from '@/components/Alert/Alert';
import { NomalModalPopup } from '@/components/Alert/NomalModalPopup';
import { EditTopBar } from '@/components/EditTopBar';
import { TopBar } from '@/components/TopBar';
import { MemberContainer } from '@/container/member';
import { IRequestUpdateUserDto, RequestUpdateUserDto, Role } from '@api/generated/axios-client';
import RoutePath from '@constants/RoutePath';
import { Tab, Tabs } from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

const UserDetailPageContent = () => {
  const history = useHistory();
  const { userId } = useParams() as { userId: string };
  const { setUserId, user, updateUser, deleteUsers } = MemberContainer.useContext();
  const [alertOpen, setAlertOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [updateUserInfo, setUpdateUserInfo] = useState<IRequestUpdateUserDto>(new RequestUpdateUserDto());
  const { showSuccessAlert, showErrorAlert } = useAlert();

  useEffect(() => {
    if (!userId) return;

    setUserId(Number(userId));
  }, [userId]);

  useEffect(() => {
    if (!user) return;

    user.termsAgreements &&
      setUpdateUserInfo({ ...user, password: undefined, isMarketingAgree: user.termsAgreements[0].isAgreed });
  }, [user]);

  const handleSave = () => {
    if (!updateUserInfo.name) {
      alert('이름을 입력해주세요');
      return;
    }
    if (!updateUserInfo.phoneNumber) {
      alert('전화번호를 입력해주세요');
      return;
    }
    if (!updateUserInfo.userGroupMembers?.filter(g => !g.deleted).length) {
      alert('1개 이상의 태그를 선택 해 주세요');
      return;
    }

    updateUser(Number(userId), { ...updateUserInfo, role: Role.USER })
      .then(() => showSuccessAlert('수정'))
      .then(() => setUpdateUserInfo(new RequestUpdateUserDto()))
      .catch(showErrorAlert);
  };

  const handleDelete = () => {
    deleteUsers([Number(userId)])
      .then(() => showSuccessAlert('삭제'))
      .then(() => setUpdateUserInfo(new RequestUpdateUserDto()))
      .then(() => history.push(RoutePath.UserNormalList))
      .catch(showErrorAlert);
  };

  if (!user) return null;

  return (
    <>
      <NomalModalPopup
        title="고객을 삭제하시겠습니까?"
        description="삭제 후 복구가 불가합니다. 정말 삭제하시겠습니까?"
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onClick={handleDelete}
      />
      {tabIndex !== 0 && <TopBar title="고객상세" />}
      {tabIndex === 0 && (
        <EditTopBar title="고객상세" saveClick={handleSave} deleteClick={() => setAlertOpen(true)} />
      )}
      <div className="p-6">
        <Tabs>
          <Tab text="고객정보" active={tabIndex === 0} onClick={() => setTabIndex(0)} />
          <Tab text="배송지정보" active={tabIndex === 1} onClick={() => setTabIndex(1)} />
          <Tab text="적립금내역" active={tabIndex === 2} onClick={() => setTabIndex(2)} />
        </Tabs>
        <div className="pt-8">
          {tabIndex === 0 && (
            <UserDetailInfo user={user} updateUser={updateUserInfo} setUpdateUser={setUpdateUserInfo} />
          )}
          {tabIndex === 1 && <UserDetailAddress />}
          {tabIndex === 2 && <UserDetailAccumulated />}
        </div>
      </div>
    </>
  );
};

export const UserDetailPage = () => {
  return (
    <MemberContainer.ContextProvider>
      <UserDetailPageContent />
    </MemberContainer.ContextProvider>
  );
};
