import { MemberContainer } from '@/container/member';
import { PushNotificationReceiver } from '@api/generated/axios-client';
import { PushPageTopBar } from '@components/PushPageTopBar';
import { PushNotifyContainer } from '@container/pushNotify';
import { Checkbox, TextArea, TextField } from '@entropyparadox/reusable-react';
import { format } from 'date-fns';
import { filter, map } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const getUserGroupName = (receivers: PushNotificationReceiver[]) => {
  const results = filter(receivers, receiver => !!receiver?.userGroup?.name);
  if (results.length === 0) return '';
  return map(results, receiver => receiver?.userGroup?.name).join(', ');
};

const PushNotifyDetailContent = () => {
  const { id } = useParams() as { id: string };
  let popupId = Number(id);
  const { setSelectPushId, pushNotification } = PushNotifyContainer.useContext();
  useEffect(() => {
    setSelectPushId(popupId);
  }, [popupId]);

  if (!pushNotification) return <></>;

  const { pushNotificationReceivers, message, url, willSendAt, title } = pushNotification;

  if (!pushNotificationReceivers || pushNotificationReceivers.length < 1) return <></>;

  return (
    <>
      <PushPageTopBar title="푸시알림 상세" />
      <div className="p-6">
        <div className="grid grid-cols-3 gap-6 mt-4">
          <TextField
            disabled
            label="발송 그룹"
            type="text"
            value={getUserGroupName(pushNotificationReceivers)}
          />
          <div className="col-start-1 col-span-2 space-y-4">
            <TextField disabled label="타이틀" type="text" value={title} />
            <TextArea disabled label="내용입력" value={message} />
            <TextField disabled label="연결링크" type="text" value={url} />
          </div>

          <div className="col-start-1 space-y-4 mt-6">
            <Checkbox label="예약발송" checked={!!willSendAt} disabled />
          </div>
        </div>
        {!!willSendAt && (
          <div className="mt-4 w-1/3">
            <TextField
              disabled
              label="발송 예정일"
              type="text"
              value={format(new Date(willSendAt), 'yyyy/MM/dd hh:mm')}
            />
          </div>
        )}
      </div>
    </>
  );
};

export const PushNotifyDetail = () => {
  return (
    <PushNotifyContainer.ContextProvider>
      <MemberContainer.ContextProvider>
        <PushNotifyDetailContent />
      </MemberContainer.ContextProvider>
    </PushNotifyContainer.ContextProvider>
  );
};
