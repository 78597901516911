import { StockCategoryTable } from './StockCategoryTable';
import { StockDetail } from './StockDetail';
import { useAlert } from '@/components/Alert/Alert';
import { CategoryModalPopup } from '@/components/Alert/CategoryModalPopup';
import { MaterialCategory } from '@api/generated/axios-client';
import { MaterialCategoryContainer } from '@container/materialCategory';
import React, { useState } from 'react';
import { Route, Switch, useHistory } from 'react-router';

export const StockPage = () => {
  const { materialCategoryList, createMaterialCategory, updateMaterialCategory, deleteMaterialCategory } =
    MaterialCategoryContainer.useContext();

  const [selectedMaterialCategory, setSelectedMaterialCategory] = useState<MaterialCategory>();
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const history = useHistory();

  const { showAlert, showSuccessAlert, showErrorAlert } = useAlert();
  const id = location.pathname.split('stock/')[1]?.split('/')[0];

  const clear = () => {
    setSelectedMaterialCategory(undefined);
    setOpenCategoryModal(false);
  };

  const handleDeleteMaterialCategory = () => {
    if (!selectedMaterialCategory) return;

    if ((selectedMaterialCategory.materials?.length || 0) > 0) {
      showAlert({
        type: 'warning',
        text: '카테고리에 제품 재고가 1건 이상 있어서 삭제할 수 없습니다.',
      });
      return;
    }

    deleteMaterialCategory(selectedMaterialCategory.id)
      .then(() => {
        showSuccessAlert('삭제');
        clear();
      })
      .catch(showErrorAlert);
  };

  const handleSaveMaterialCategoryItem = (title: string) => {
    if (selectedMaterialCategory) {
      updateMaterialCategory(selectedMaterialCategory.id, {
        title,
      })
        .then(() => showSuccessAlert())
        .then(() => clear())
        .catch(showErrorAlert);
    } else {
      createMaterialCategory({ title })
        .then(() => showSuccessAlert())
        .then(() => clear())
        .catch(showErrorAlert);
    }
  };

  return (
    <>
      <CategoryModalPopup
        item={selectedMaterialCategory ? selectedMaterialCategory.title : ''}
        open={openCategoryModal}
        onSave={handleSaveMaterialCategoryItem}
        onDelete={selectedMaterialCategory ? handleDeleteMaterialCategory : undefined}
        onClose={() => {
          setOpenCategoryModal(false);
          clear();
        }}
      />
      <div className="grid grid-cols-5 h-full">
        <div className="col-span-1">
          <div className="m-4 px-4 py-3 border rounded-md h-full">
            <div className="flex justify-between items-center">
              <h3 className="font-semibold text-xl">카테고리 관리</h3>
              <button
                className="bg-gray-100 px-4 py-2 rounded-md mt-2"
                onClick={() => setOpenCategoryModal(true)}>
                추가
              </button>
            </div>
            <div className="space-y-4 mt-8 font-base">
              {materialCategoryList?.data.map(item => (
                <div key={item.id} className="flex justify-between">
                  <div
                    key={item.id}
                    className={['cursor-pointer', item.id === Number(id) ? 'font-bold' : ''].join(' ')}
                    onClick={() => history.push(`/admin/product/stock/${item.id}`)}>
                    {item.title}
                  </div>
                  <div
                    className="text-gray-400 font-light cursor-pointer"
                    onClick={() => {
                      setSelectedMaterialCategory(item);
                      setOpenCategoryModal(true);
                    }}>
                    Edit
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="pr-6 pl-3 py-6 overflow-y-scroll scroll-box">
          <Switch>
            <Route path="/admin/product/stock/:materialCategoryId" component={StockCategoryTable} />
            <Route
              path="/admin/product/stock/"
              component={() => (
                <div className="bg-gray-50 h-full flex justify-center items-center text-gray-500">
                  카테고리를 선택해주세요
                </div>
              )}
            />
          </Switch>
        </div>
        <div className="col-span-3 border-l">
          <Switch>
            <Route path="/admin/product/stock/:materialCategoryId/material/:id" component={StockDetail} />
            <Route
              path="/admin/product/stock/"
              component={() => (
                <div className="bg-gray-50 h-full flex justify-center items-center text-gray-500">
                  카테고리를 선택해주세요
                </div>
              )}
            />
          </Switch>
        </div>
      </div>
    </>
  );
};
