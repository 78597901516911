import { ReactComponent as FileUploadImg } from '../assets/svg/file-upload.svg';
import { ExposureProductModalPopup } from '@/components/Alert/ExposureProductModalPopup';
import Pagination from '@/components/Pagination';
import {
  DisplayProduct,
  FileParameter,
  S3ControllerQuery,
  S3ResourceType,
} from '@api/generated/axios-client';
import { useAlert } from '@components/Alert/Alert';
import { Datagrid } from '@components/Datagrid';
import { EditTopBar } from '@components/EditTopBar';
import { Field } from '@components/Field/Field';
import { JoinProductField, NormalExposureProductField, NormalField } from '@components/Field/NormalField';
import { MainContainer } from '@container/main';
import { Divider } from '@entropyparadox/reusable-react';
import { PageHelper } from '@utils/PageHelper';
import React, { useState } from 'react';

export const ExposureProductContent = () => {
  const { displayProducts, setDisplayProductPage, shopSetting, updateShopSetting, createShopSetting } =
    MainContainer.useContext();

  const [isOpen, setIsOpen] = useState(false);
  const [treackingFile, setTreackingFile] = useState<File | null | undefined>();
  const [selected, setSelected] = useState<DisplayProduct | null>();
  const { showSuccessAlert, showErrorAlert } = useAlert();

  const pageInfo = PageHelper.getPageInfo({
    ...displayProducts,
    gotoPage: setDisplayProductPage,
  });

  const fileUpload = async () => {
    if (!treackingFile) showSuccessAlert('사진을 업로드 하십시오.');

    let fileParameter: FileParameter = {
      data: treackingFile,
      fileName: treackingFile?.name || 'treackingFile',
    };
    const s3UploadDto = await S3ControllerQuery.Client.s3UploadFile(
      S3ResourceType.DisplayProductBanner,
      fileParameter,
    );

    if (shopSetting) {
      updateShopSetting(shopSetting.id, {
        mainDisplayProductBannerImageUrl: s3UploadDto.fullUrl,
      })
        .then(() => showSuccessAlert())
        .catch(showErrorAlert);
    } else {
      createShopSetting({
        baseMileageEarnRate: 0.05,
        baseReviewEarnMileage: 500,
        mainDisplayProductBannerImageUrl: s3UploadDto.fullUrl,
        photoReviewEarnMileage: 1000,
      })
        .then(() => showSuccessAlert())
        .catch(showErrorAlert);
    }
  };

  return (
    <>
      {selected && (
        <ExposureProductModalPopup
          open={isOpen}
          item={selected}
          onClose={() => {
            setIsOpen(false);
            setSelected(null);
          }}
        />
      )}
      <EditTopBar
        title="노출 상품 관리"
        saveClick={() => {
          fileUpload();
        }}
      />
      <div className="p-6">
        <div className="font-medium text-xl mb-2">
          썸네일 <span className="text-base text-gray-500">(200px * 300px, 1mb이하)</span>
        </div>
        <div className="w-96">
          <label htmlFor="fileupload">
            <div className="relative aspect-w-2 aspect-h-3 rounded-md border-2 border-grey-5">
              {treackingFile || shopSetting?.mainDisplayProductBannerImageUrl ? (
                <div>
                  <img
                    className="absolute w-full h-full rounded object-cover"
                    src={
                      treackingFile
                        ? URL.createObjectURL(treackingFile)
                        : shopSetting?.mainDisplayProductBannerImageUrl
                    }
                    alt=""
                  />
                  <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-3 left-3">
                    사진 수정하기
                  </div>
                </div>
              ) : (
                <div className="absolute w-full h-full rounded object-cover bg-white">
                  <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                    <FileUploadImg />
                    <div className="text-sm text-gray-500 text-center pt-1">
                      이미지를 업로드해주세요.
                      <br />
                      (최대 1장)
                    </div>
                  </div>
                </div>
              )}
            </div>
          </label>
          <input
            // ref={photoUploadRef}
            type="file"
            id="fileupload"
            className="hidden"
            accept="license/*"
            onChange={e => {
              if (!e.target.validity.valid) return;
              const file = e.target.files?.item(0);
              if (file) {
                setTreackingFile(file);
              }
            }}
          />
        </div>
      </div>
      <Divider />
      <div className="p-6">
        <div className="flex items-center my-4">
          <div className="flex space-x-4">
            <button
              className="bg-black text-white px-4 py-2 text-sm rounded-md h-11"
              onClick={() => {
                const dp = new DisplayProduct();
                dp.isActive = true;
                dp.sorted = 0;
                setSelected(dp);
                setIsOpen(true);
              }}>
              추가하기
            </button>
          </div>
        </div>
        <Datagrid data={displayProducts?.data || []}>
          <NormalField label="ID" property="id" />
          <Field
            label="작성일"
            render={row => <div className="text-sm">{new Date(row.createdAt).toLocaleDateString()}</div>}
          />
          <JoinProductField label="상품명" property="name" />
          <NormalExposureProductField label="노출우선순위" property="sorted" />
          <Field
            label="상세보기"
            render={row => (
              <button
                className="bg-gray-100 px-4 py-2 text-sm rounded-md"
                onClick={() => {
                  setIsOpen(true);
                  setSelected(row as DisplayProduct);
                }}>
                상세보기
              </button>
            )}
          />
        </Datagrid>
        <Pagination {...pageInfo} />
      </div>
    </>
  );
};

export const ExposureProductPage = () => (
  <MainContainer.ContextProvider>
    <ExposureProductContent />
  </MainContainer.ContextProvider>
);
