import { atom } from 'recoil';
import AlertType from '@/constants/AlertType';
import { nanoid } from 'nanoid';

export interface IAlert {
  open: boolean;
  text?: string;
  type: 'error' | 'warning' | 'info' | 'success';
  onClose?: Function;
  onClick?: Function;
  timeout?: number;
}

export const alertState = atom<IAlert>({
  key: 'alertState' + nanoid(),
  default: { open: false, text: '', type: AlertType.INFO, timeout: 2500 },
});
