import { ProductTablePage } from './ProductTablePage';
import { CategoryArea } from '@/components/CategoryArea';
import { TopBar } from '@/components/TopBar';
import React from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router';

export const ProductPage = () => {
  const history = useHistory();
  const location = useLocation();
  const id = location.pathname.split('list/')[1]?.split('/')[0];

  return (
    <>
      <TopBar title="상품관리" />

      <div className="grid grid-cols-5 h-full">
        <div className="col-span-1">
          <CategoryArea
            categoryId={Number(id)}
            title="카테고리 관리"
            onClick={() => history.push('/admin/product/category/add')}
          />
        </div>
        <div className="col-span-4 p-6">
          <Switch>
            <Route path="/admin/product/list/:categoryId/:subCategoryId" component={ProductTablePage} />
            <Route path="/admin/product/list/:categoryId" component={ProductTablePage} />
            <Route
              path="/admin/product"
              component={() => (
                <div className="h-full w-full flex justify-center items-center bg-gray-100 rounded-md">
                  카테고리를 선택해주세요.
                </div>
              )}
            />
          </Switch>
        </div>
      </div>
    </>
  );
};
