import { alertState, IAlert } from '@/recoil/alert';
import { Alert as EntropyparadoxAlert, Section } from '@entropyparadox/reusable-react';
import React, { useEffect } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

export function useAlert() {
  const [alert, setAlert] = useRecoilState(alertState);
  const resetAlert = useResetRecoilState(alertState);
  const showAlert = (props: Omit<IAlert, 'open'>) => {
    setAlert({
      ...alert,
      ...props,
      open: true,
    });
  };

  const showSuccessAlert = (message: string = '저장', callback?: () => void) => {
    showAlert({
      type: 'success',
      text: `${message}되었습니다.`,
    });
    callback?.();
  };

  const showWarningAlert = (message: string, callback?: () => void) => {
    showAlert({
      type: 'warning',
      text: message,
    });
    callback?.();
  };

  const showErrorAlert = (e: Error) => {
    showAlert({
      type: 'error',
      text: e.message,
    });
  };

  useEffect(() => {
    if (!alert?.open || !alert?.timeout) return;

    let timer = setTimeout(() => {
      resetAlert();
    }, alert?.timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [alert?.open, alert?.timeout]);
  return { alert, showAlert, showSuccessAlert, showWarningAlert, showErrorAlert, resetAlert };
}

const Alert = () => {
  const { alert, resetAlert } = useAlert();
  const { open, text, type, onClose, onClick } = alert;

  const handleClose = () => {
    resetAlert();
    onClose && onClose();
  };

  if (!open) return <></>;

  return (
    <Section
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 999,
        visibility: open ? 'visible' : 'hidden',
        pointerEvents: open ? 'all' : 'none',
      }}
      onClick={() => onClick && onClick()}>
      <EntropyparadoxAlert text={text} severity={type} onClose={() => handleClose()} />
    </Section>
  );
};

export default Alert;
