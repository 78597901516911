import { useAlert } from '@/components/Alert/Alert';
import RoutePath from '@/constants/RoutePath';
import { UserContainer } from '@/container/user';
import { RequestSignInDto } from '@api/generated/axios-client';
import { Button, TextField } from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

export const LoginPage = () => {
  const { showErrorAlert } = useAlert();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { isLogin, logout, loginByEmail } = UserContainer.useContext();

  const login = () => {
    loginByEmail(
      new RequestSignInDto({
        email,
        password,
        kakaoUserId: undefined,
        appleUserSub: undefined,
        naverUserId: undefined,
      }),
    )
      .then(() => {
        history.push(RoutePath.Home);
      })
      .catch(e => {
        showErrorAlert(e);
        // 로그인 과정 중에 문제 생겼을 경우 처리
        logout();
      });
  };

  useEffect(() => {
    if (isLogin) {
      history.replace('/admin');
    }
  }, [isLogin]);

  return (
    <div
      className="w-full bg-gray-50 relative flex justify-center items-center"
      style={{
        minHeight: 'calc(var(--vh, 1vh) * 100)',
      }}>
      <div className="w-96 my-10 bg-white rounded-xl border p-6 space-y-5">
        <div className="text-xl font-semibold my-4">로그인</div>
        <TextField
          label="이메일"
          type="email"
          placeholder="이메일을 입력해주세요."
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          label="비밀번호"
          type="password"
          placeholder="비밀번호를 입력해주세요."
          value={password}
          onKeyDown={e => {
            if (e.key.toLowerCase() === 'enter') {
              login();
            }
          }}
          onChange={e => setPassword(e.target.value)}
        />
        <div className="flex flex-col w-full pb-6">
          <Button text="로그인" onClick={login} />
        </div>
      </div>
    </div>
  );
};
