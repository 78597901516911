import * as _ from 'lodash';
import { useList } from 'react-use';

export const useCheckField = () => {
  const [checked, action] = useList<number | string | undefined>([]);

  const setAllChecked = (idList: (number | string)[], isChecked: boolean) => {
    if (!isChecked) {
      action.set([]);
      return;
    }
    const checkedList = checked.concat(idList);
    const deleteDuplicate = _.uniq(checkedList);

    action.set(deleteDuplicate);
  };

  const getIndex = (id: number | string): number => {
    return checked.findIndex(row => String(row) === String(id));
  };

  const isChecked = (id: number | string): boolean => {
    return !!checked.find(row => String(row) === String(id));
  };

  const setChecked = (id: number | string, checked: boolean) => {
    const index = getIndex(id);
    const hasChecked = index > -1;

    if (checked && !hasChecked) {
      action.push(id);
      return;
    }

    if (!checked && hasChecked) {
      action.removeAt(index);
    }
  };

  return {
    checked,
    setChecked,
    isChecked,
    setAllChecked,
  };
};
