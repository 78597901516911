import React from 'react';
import { ProductDatagrid } from './ProductDatagrid';
import Pagination from './Pagination';
import { OrderContainer } from '@/container/order';
import { PageHelper } from "@utils/PageHelper";

export const OrderProductTable = () => {
  const {
    orderByProduct,
    setOrderByProductPage,
  } = OrderContainer.useContext();

  const pageInfo = PageHelper.getPageInfo({
    ...orderByProduct,
    gotoPage: setOrderByProductPage,
  });

  if (!orderByProduct?.data) return null;

  return (
    <div>
      <ProductDatagrid items={orderByProduct.data} />
      <Pagination {...pageInfo} />
    </div>
  );
};
