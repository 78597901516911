import { clearAccessToken, getAccessToken } from '@utils/token';
import axios from 'axios';

const API = process.env.REACT_APP_NEXT_PUBLIC_API_URL;

const instance = axios.create({
  baseURL: `${API}/`,
  timeout: 8000,
  headers: {
    Accept: 'application/json ',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(function (config) {
  const accessToken = getAccessToken();
  if (accessToken && config.headers) {
    config.headers.Authorization = `bearer ${accessToken}`;
  }
  return config;
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error.response?.status !== 401) {
      // 서버 오류 메시지 표시될 수 있도록 오류 리턴 변경
      return Promise.reject(error.response ? error.response.data : error);
    }

    clearAccessToken();
    delete error.config.headers.Authorization;

    return axios(error.config);
  },
);
export default instance;
