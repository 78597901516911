import { AxiosClient, AxiosQuery } from '@/api';
import {
  IRequestCreateGiftCardGroupDto,
  IRequestCreateGiftCardImageDto,
  IRequestCreateGiftCardMessageDto,
  IRequestUpdateGiftCardGroupDto,
  IRequestUpdateGiftCardImageDto,
  IRequestUpdateGiftCardMessageDto,
  RequestCreateGiftCardGroupDto,
  RequestCreateGiftCardImageDto,
  RequestCreateGiftCardMessageDto,
  RequestUpdateGiftCardGroupDto,
  RequestUpdateGiftCardImageDto,
  RequestUpdateGiftCardMessageDto,
} from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import { createContainer } from '@container/createContainer';
import { DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import { useState } from 'react';

export const mainHook = () => {
  const [giftCardGroupIdParam, setGiftCardGroupIdParam] = useState<number>();

  const { data: giftCardImages, refetch: refetchGiftCardImages } =
    AxiosQuery.Query.useGetManyBaseGiftCardImageControllerGiftCardImageQuery(
      {
        ...DefaultRequestCrudDto,
        filter: [...DefaultRequestCrudDto.filter],
        sort: ['id,DESC'],
      },
      { refetchOnWindowFocus: false },
    );

  const createGiftCardImage = (param: IRequestCreateGiftCardImageDto) =>
    AxiosClient.createOneBaseGiftCardImageControllerGiftCardImage(
      new RequestCreateGiftCardImageDto(param),
    ).then(() => {
      refetchGiftCardImages();
    });

  const updateGiftCardImage = (id: number, param: IRequestUpdateGiftCardImageDto) =>
    AxiosClient.updateOneBaseGiftCardImageControllerGiftCardImage(
      id,
      new RequestUpdateGiftCardImageDto(param),
    ).then(() => {
      refetchGiftCardImages();
    });

  const deleteGiftCardImage = (id: number) =>
    AxiosClient.deleteOneBaseGiftCardImageControllerGiftCardImage(id).then(() => {
      refetchGiftCardImages();
    });

  const { data: giftCardGroups, refetch: refetchGiftCardGroups } =
    AxiosQuery.Query.useGetManyBaseGiftCardGroupControllerGiftCardGroupQuery(
      {
        ...DefaultRequestCrudDto,
        filter: [...DefaultRequestCrudDto.filter, `deleted||$eq||false`],
        sort: ['id,DESC'],
      },
      { refetchOnWindowFocus: false },
    );

  const createGiftCardGroup = (param: IRequestCreateGiftCardGroupDto) =>
    AxiosClient.createOneBaseGiftCardGroupControllerGiftCardGroup(
      new RequestCreateGiftCardGroupDto(param),
    ).then(() => {
      refetchGiftCardGroups();
    });

  const updateGiftCardGroup = (id: number, param: IRequestUpdateGiftCardGroupDto) =>
    AxiosClient.updateOneBaseGiftCardGroupControllerGiftCardGroup(
      id,
      new RequestUpdateGiftCardGroupDto(param),
    ).then(() => {
      refetchGiftCardGroups();
    });

  const deleteGiftCardGroup = (id: number) =>
    AxiosClient.deleteOneBaseGiftCardGroupControllerGiftCardGroup(id).then(() => {
      refetchGiftCardGroups();
    });

  const [giftCardMessagesPage, setGiftCardMessagesPage] = useState<number>(1);

  const { data: giftCardMessages, refetch: refetchGiftCardMessages } =
    AxiosQuery.Query.useGetManyBaseGiftCardMessageControllerGiftCardMessageQuery(
      {
        ...DefaultRequestCrudDto,
        filter: [
          ...DefaultRequestCrudDto.filter,
          `groupId||$eq||${giftCardGroupIdParam}`,
          `deleted||$eq||false`,
        ],
        page: giftCardMessagesPage,
        limit: Const.DefaultPageLimitSize,
        sort: ['id,DESC'],
      },
      {
        enabled: !!giftCardGroupIdParam,
        refetchOnWindowFocus: false,
      },
    );

  const createGiftMessage = (param: IRequestCreateGiftCardMessageDto) =>
    AxiosClient.createOneBaseGiftCardMessageControllerGiftCardMessage(
      new RequestCreateGiftCardMessageDto(param),
    ).then(() => {
      refetchGiftCardMessages();
    });

  const updateGiftMessage = (id: number, param: IRequestUpdateGiftCardMessageDto) =>
    AxiosClient.updateOneBaseGiftCardMessageControllerGiftCardMessage(
      id,
      new RequestUpdateGiftCardMessageDto(param),
    ).then(() => {
      refetchGiftCardMessages();
    });

  const deleteGiftMessages = (ids: number[]): Promise<void> =>
    Promise.all(
      ids
        .filter(id => id !== -1)
        .map(id => AxiosClient.deleteOneBaseGiftCardMessageControllerGiftCardMessage(id)),
    ).then(() => {
      refetchGiftCardMessages();
    });

  return {
    giftCardImages,
    createGiftCardImage,
    updateGiftCardImage,
    deleteGiftCardImage,
    giftCardGroups,
    createGiftCardGroup,
    updateGiftCardGroup,
    deleteGiftCardGroup,
    giftCardMessages,
    setGiftCardGroupIdParam,
    setGiftCardMessagesPage,
    createGiftMessage,
    updateGiftMessage,
    deleteGiftMessages,
  };
};

export const GiftCardImageContainer = createContainer(mainHook);
