import { Field } from './Field';
import React from 'react';

interface OrderItemFieldProps {
  label: string;
  property: string;
}

export const OrderItemField: React.FC<OrderItemFieldProps> = ({ label, property }) => {
  return (
    <Field
      label={label}
      render={row => (
        <>
          <div className="border-b mb-2">
            <div className="font-semibold mb-4">{row.productName}</div>
            <div className="text-sm space-y-3 mb-2">
              {row.orderProductOptions?.map((option: any) => (
                <div key={option.id} className="font-light text-gray-500">
                  {option.optionName}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    />
  );
};
