import { TopBar } from '@/components/TopBar';
import { BannerInfoPage } from '@/pages/BannerInfo';
import { MainContainer } from '@container/main';
import { Tab, Tabs } from '@entropyparadox/reusable-react';
import React from 'react';

export const BannerMainContent = () => {
  const { mainBannerTabIndex, setMainBannerTabIndex } = MainContainer.useContext();

  return (
    <>
      <TopBar title="메인 배너 / 영상관리" />
      <div className="p-6">
        <Tabs>
          <Tab text="섹션1" active={mainBannerTabIndex === 0} onClick={() => setMainBannerTabIndex(0)} />
          <Tab text="섹션2" active={mainBannerTabIndex === 1} onClick={() => setMainBannerTabIndex(1)} />
          <Tab text="섹션3" active={mainBannerTabIndex === 2} onClick={() => setMainBannerTabIndex(2)} />
        </Tabs>
        {mainBannerTabIndex === 0 && <BannerInfoPage />}
        {mainBannerTabIndex === 1 && <BannerInfoPage />}
        {mainBannerTabIndex === 2 && <BannerInfoPage />}
      </div>
    </>
  );
};

export const BannerMainPage = () => (
  <MainContainer.ContextProvider>
    <BannerMainContent />
  </MainContainer.ContextProvider>
);
