import { AxiosClient, AxiosQuery } from '@/api';
import { createContainer } from '@/container/createContainer';
import {
  IRequestCreatePushNotificationDto,
  IRequestUpdatePushNotificationDto,
  RequestCreatePushNotificationDto,
  RequestUpdatePushNotificationDto,
} from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import { DefaultGetOneBaseQueryParameters, DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import { useDebounceState } from '@utils/useDebounceState';
import { useState } from 'react';

export const mainHook = () => {
  const [pushNotify, setPushNotify] = useState<number>(1);
  const [keyword, setKeyword, debounceKeyword] = useDebounceState('', 500);
  const [selectPushId, setSelectPushId] = useState<number>();

  const { data: pushNotifications, refetch: refetchPushNotifications } =
    AxiosQuery.Query.useGetManyBasePushNotificationControllerPushNotificationQuery(
      {
        ...DefaultRequestCrudDto,
        filter: [...DefaultRequestCrudDto.filter, `message||$cont||%${debounceKeyword}%`],
        join: ['user', 'pushNotificationReceivers.userGroup'],
        page: pushNotify,
        limit: Const.DefaultPageLimitSize,
        sort: ['id,DESC'],
      },
      { refetchOnWindowFocus: false },
    );
  const { data: pushNotification } =
    AxiosQuery.Query.useGetOneBasePushNotificationControllerPushNotificationQuery(
      {
        ...DefaultGetOneBaseQueryParameters,
        join: ['user', 'pushNotificationReceivers.userGroup'],
        id: selectPushId || 0,
      },
      {
        enabled: !!selectPushId,
        refetchOnWindowFocus: false,
      },
    );

  const createPushNotification = (param: IRequestCreatePushNotificationDto) =>
    AxiosClient.createPushNotifications(new RequestCreatePushNotificationDto(param)).then(() => {
      refetchPushNotifications();
    });

  const updatePushNotification = (id: number, param: IRequestUpdatePushNotificationDto) =>
    AxiosClient.updateOneBasePushNotificationControllerPushNotification(
      id,
      new RequestUpdatePushNotificationDto(param),
    ).then(() => {
      refetchPushNotifications();
    });

  const deletePushNotification = (ids: number[]) =>
    Promise.all(
      ids
        .filter(id => id !== -1)
        .map(id => AxiosClient.deleteOneBasePushNotificationControllerPushNotification(id)),
    ).then(() => {
      refetchPushNotifications();
    });

  return {
    keyword,
    setKeyword,
    setPushNotify,
    setSelectPushId,
    pushNotification,
    pushNotifications,
    createPushNotification,
    updatePushNotification,
    deletePushNotification,
  };
};

export const PushNotifyContainer = createContainer(mainHook);
