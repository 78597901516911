import { TextField } from '@/components/TextField';
import { IRequestUpdateUserDto, ResponseUserDto } from '@api/generated/axios-client';
import { SelectableTagsList } from '@components/SelectableTagsList';
import { TextArea } from '@components/TextArea';
import { Checkbox, Label } from '@entropyparadox/reusable-react';
import React, { ChangeEvent, useEffect, useState } from 'react';

interface UserDetailInfoProps {
  user?: ResponseUserDto;
  updateUser: IRequestUpdateUserDto;
  setUpdateUser: (updateUser: IRequestUpdateUserDto) => void;
}

export const UserDetailInfo: React.FC<UserDetailInfoProps> = ({ user, updateUser, setUpdateUser }) => {
  const [isSelected, setIsSelected] = useState<boolean>();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setUpdateUser({ ...updateUser, [target.name]: e.target.value });
  };

  const handleChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    setUpdateUser({ ...updateUser, [target.name]: e.target.value });
  };
  useEffect(() => {
    if (user) {
      const { termsAgreements } = user;
      termsAgreements && setIsSelected(termsAgreements[0]?.isAgreed);
    }
  }, [user]);
  useEffect(() => {
    if (user) {
      setUpdateUser({ ...updateUser, isMarketingAgree: isSelected });
    }
  }, [isSelected]);

  if (!user) return null;

  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-6 2xl:w-4/6">
        <TextField
          label="이름"
          type="text"
          name="name"
          value={updateUser.name || user.name}
          onChange={handleChange}
        />
        <TextField
          label="이메일"
          type="email"
          disabled
          name="email"
          value={updateUser.email || user.email}
          onChange={handleChange}
        />
        <TextField
          label="비밀번호"
          type="password"
          name="password"
          value={updateUser.password || ''}
          onChange={handleChange}
        />
        <TextField
          label="핸드폰 번호"
          placeholder="숫자만 입력해주세요."
          type="number"
          name="phoneNumber"
          value={updateUser.phoneNumber || ''}
          onChange={handleChange}
          maxLength={11}
          pattern="\d{11}"
          onWheel={e => e.currentTarget.blur()}
          onKeyPress={e => {
            if (isNaN(Number(e.key))) {
              e.preventDefault();
            }
          }}
        />
        <TextField label="누적 구매 금액" type="number" disabled value={user.cumulativePurchaseAmount || 0} />
        <TextField label="보유 적립금" type="number" disabled value={user.mileage} />
      </div>

      <h3 className="font-semibold text-xl mb-4">태그</h3>
      <SelectableTagsList
        userId={user.id}
        onChangeUserGroupMembers={userGroupMembers => {
          setUpdateUser({ ...updateUser, userGroupMembers });
        }}
      />
      <h3 className="font-semibold text-xl mb-4">동의 여부</h3>
      <div className="flex space-x-2">
        <Checkbox
          onChange={e => {
            setIsSelected(e.target.checked);
          }}
          checked={isSelected}
        />
        <Label>
          <span
            className="cursor-pointer text-base"
            // onClick={() => window.open(terms.url, '_blank')}
          >
            마케팅 수신 동의 (선택)
          </span>
        </Label>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-6 2xl:w-4/6 mt-4">
        <TextArea
          name={'adminMemo'}
          label={'관리자 메모'}
          value={updateUser.adminMemo || ''}
          onChange={handleChangeTextArea}
        />
      </div>
    </>
  );
};
