import { Icon } from '@/components/Icon';
import Autocomplete from '@/components/form/Autocomplete';
import { ProductSelectContainer } from '@/container/productSelect';
import { Category, Product } from '@api/generated/axios-client';
import { Select } from '@entropyparadox/reusable-react';
import { filter, isEmpty, unionWith } from 'lodash';
import { useEffect, useState } from 'react';


interface ProductSelectSelectFormProps {
  disabled?: boolean;
}

const ProductSelectForm = ({disabled}: ProductSelectSelectFormProps) => {
  const {
    keyword,
    setKeyword,
    products,
    categories,
    selectedCategories,
    setSelectedCategories,
    selectedProducts,
    setSelectedProducts,
  } = ProductSelectContainer.useContext();
  const [target, setTarget] = useState('카테고리명');
  const [category, setCategory] = useState<Category>();
  const [product, setProduct] = useState<Product>();

  useEffect(() => {
    setKeyword('');
  }, [target]);

  const _categories = filter(categories?.data, category => {
    if (!category) return false;
    if (!!category.categoryId) return true;
    return isEmpty(category.subCategories);

  });

  return (
      <>
        <div className="col-start-1">
          <Select
              label="관련상품 선택"
              value={target}
              onChange={e => {
                setTarget(e.target.value);
              }}>
            {[{name: '카테고리명'}].map(item => (
                <option key={item.name} value={item.name}>
                  {item.name}
                </option>
            ))}
          </Select>
        </div>
        {target === '카테고리명' ? (
            <>
              <div>
                <Select
                    label="카테고리명"
                    value={category?.id}
                    onChange={e => setCategory(_categories.find(row => String(row.id) === String(e.target.value)))}>
                  {_categories.map(row => (
                      <option key={row.id} value={row.id}>
                        {row.title}
                      </option>
                  ))}
                </Select>
              </div>
              <div
                  className={[
                    'mt-6 grid place-items-center px-4 rounded-md cursor-pointer bg-gray-900 text-white w-max',
                  ].join(' ')}
                  onClick={() => {
                    if (disabled) return;
                    if (category) {
                      setSelectedCategories(prevState => unionWith(prevState, [category], (a, b) => a.id === b.id));
                    } else if (categories?.data.length) {
                      setSelectedCategories(prevState =>
                          unionWith(prevState, [categories.data[0]], (a, b) => a.id === b.id),
                      );
                    }
                  }}>
                추가하기 +
              </div>
            </>
        ) : (
            <>
              <Autocomplete
                  items={products?.data.map(row => ({label: row.name, value: row})) || []}
                  searchKeyword={keyword}
                  onChangeKeyword={value => {
                    setKeyword(value);
                  }}
                  onSelect={item => {
                    setProduct(item.value);
                  }}
              />
              <div
                  className="mt-6 cursor-pointer grid place-items-center px-4 rounded-md  bg-gray-900 text-white w-max"
                  onClick={() => {
                    if (disabled) return;
                    if (product) {
                      setSelectedProducts(prevState => unionWith(prevState, [product], (a, b) => a.id === b.id));
                    }
                  }}>
                추가하기 +
              </div>
            </>
        )}
        {(!!selectedCategories?.length || !!selectedProducts?.length) && (
            <div className="flex flex-wrap col-span-2">
              {selectedCategories?.map(item => (
                  <div
                      key={item.id}
                      className="flex items-center space-x-3 justify-center border rounded-full px-3 py-1.5 mr-2 mb-2">
                    <div>{item.title}</div>
                    {!disabled && (
                        <Icon.X
                            className="cursor-pointer"
                            onClick={() => {
                              setSelectedCategories(prevState => prevState.filter(row => row.id !== item.id));
                            }}
                        />
                    )}
                  </div>
              ))}
              {selectedProducts?.map(item => (
                  <div
                      key={item.id}
                      className="flex items-center space-x-3 justify-center border rounded-full px-3 py-1.5 mr-2 mb-2">
                    <div>{item.name}</div>
                    {!disabled && (
                        <Icon.X
                            className="cursor-pointer"
                            onClick={() => {
                              setSelectedProducts(prevState => prevState.filter(row => row.id !== item.id));
                            }}
                        />
                    )}
                  </div>
              ))}
            </div>
        )}
      </>
  );
};

export default ProductSelectForm;
