import { OrderByProductDto } from '@api/generated/axios-client';
import React from 'react';

interface ProductDatagridProps {
  items: OrderByProductDto[];
}

export const ProductDatagrid = ({ items }: ProductDatagridProps) => {
  return (
    <table className="w-full h-full">
      <thead className="border bg-gray-100">
        <tr>
          <th className="border border-gray-100 text-left px-3 py-2 text-gray-600 font-medium">주문상품</th>
          <th className="border border-gray-100 text-left px-3 py-2 text-gray-600 font-medium">옵션상품</th>
          <th className="border border-gray-100 text-left px-3 py-2 text-gray-600 font-medium">수량</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          return (
            <React.Fragment key={`${item.productId}_${item.optionId}`}>
              <tr className="hover:bg-gray-50">
                <td className="border-t border-b border-gray-100 px-3 py-1 text-lsm text-gray-700 align-text-top">
                  {item.productName}
                </td>
                    <td className="border-t border-b border-gray-100 px-3 py-1 text-lsm text-gray-700 align-text-top">
                      {item.optionGroupName ? `${item.optionGroupName}) ` : ''}
                      {item.optionName}
                    </td>
                    <td className="border-t border-b border-gray-100 px-3 py-1 text-lsm text-gray-700 align-text-top">
                      {item.totalCount}
                    </td>
              </tr>
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
