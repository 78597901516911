import Pagination from '../components/Pagination';
import { Datagrid } from '@/components/Datagrid';
import { Field } from '@/components/Field/Field';
import { NormalField, NormalPopupField } from '@/components/Field/NormalField';
import { TopBar } from '@/components/TopBar';
import { MainPopupContainer } from '@container/mainPopup';
import { PageHelper } from '@utils/PageHelper';
import React from 'react';
import { useHistory } from 'react-router';

export const MainPopupManageContent = () => {
  const { popups, setPopupsPage } = MainPopupContainer.useContext();
  const history = useHistory();

  if (!popups) return null;
  const pageInfo = PageHelper.getPageInfo({
    ...popups,
    gotoPage: setPopupsPage,
  });

  return (
    <>
      <TopBar title="팝업 관리" />
      <div className="p-6">
        <div className="flex items-center my-4">
          <div className="flex space-x-4">
            <button
              className="bg-black text-white px-4 py-2 text-sm rounded-md h-11"
              onClick={() => history.push('/admin/main/popup/add')}>
              팝업추가
            </button>
          </div>
        </div>
        <Datagrid data={popups?.data}>
          <NormalField label="ID" property="id" />
          <Field
            label="작성일"
            render={row => <div className="text-sm">{new Date(row.createdAt).toLocaleDateString()}</div>}
          />
          <NormalField label="제목" property="title" />
          <NormalPopupField label="노출우선순위" property="sorted" />
          <Field
            label="상세보기"
            render={row => (
              <button
                className="bg-gray-100 px-4 py-2 text-sm rounded-md"
                onClick={() => history.push(`/admin/main/popup/edit/${row.id}`)}>
                상세보기
              </button>
            )}
          />
        </Datagrid>
        <Pagination {...pageInfo} />
      </div>
    </>
  );
};

export const MainPopupManagePage = () => (
  <MainPopupContainer.ContextProvider>
    <MainPopupManageContent />
  </MainPopupContainer.ContextProvider>
);
