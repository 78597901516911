import React from 'react';

interface EditTopBarProps {
  title: string;
  saveClick: () => void;
  deleteClick?: () => void;
  haveId?: boolean;
}

export const EditTopBar: React.FC<EditTopBarProps> = ({ title, saveClick, deleteClick, haveId }) => {
  return (
    <div className="sticky w-full top-0 z-50">
      <div className="px-5 py-3 border-b flex items-center justify-between bg-white">
        <div className="text-xl font-semibold">{title}</div>
        <div className="space-x-5">
          {deleteClick && haveId ? (
            <button className="text-red-500 px-4 py-2 border-red-500 border rounded-lg" onClick={deleteClick}>
              DELETE
            </button>
          ) : null}
          <button className="text-white px-4 py-2 rounded-lg bg-red-500" onClick={saveClick}>
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
};
