import { AxiosQuery } from '@/api';
import { createContainer } from '@/container/createContainer';
import { isLoginState } from '@/recoil/user';
import { clearAccessToken, setAccessToken } from '@/utils/token';
import { RequestSignInDto, Role } from '@api/generated/axios-client';
import RoutePath from '@constants/RoutePath';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

export const userHook = () => {
  const [isLogin, setIsLogin] = useRecoilState(isLoginState);

  const loginByEmail = (params: RequestSignInDto) => {
    return AxiosQuery.AuthControllerQuery.Client.signIn(params)
      .then(result => {
        setAccessToken(result.accessToken);
      })
      .then(() => setIsLogin(true))
      .catch(() => Promise.reject(new Error('권한이 없습니다.')));
  };

  const logout = () => {
    clearAccessToken();
    setIsLogin(false);
  };

  const { data: myProfile } = AxiosQuery.AuthControllerQuery.useMeQuery({
    onSuccess: res => {
      // 권한 체크
      if (res.role !== Role.ADMIN) {
        logout();
      }
    },
    enabled: isLogin,
  });

  useEffect(() => {
    if (!isLogin) {
      if (!window.location.href.endsWith(RoutePath.Login)) {
        window.location.href = RoutePath.Login;
        alert('어드민 유저가 아니거나 세션이 만료되었습니다. 다시 로그인 해주세요');
      }
    }
  }, [isLogin]);

  return { isLogin, myProfile, loginByEmail, logout };
};

export const UserContainer = createContainer(userHook);
