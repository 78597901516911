import { AccumulatedRedeem } from './AccumulatedRedeem';
import { AccumulatedSet } from './AccumulatedSet';
import { AccumulatedTable } from './AccumulatedTable';
import { useAlert } from '@/components/Alert/Alert';
import { EditTopBar } from '@/components/EditTopBar';
import { TopBar } from '@/components/TopBar';
import { MileageContainer } from '@/container/mileage';
import { Tab, Tabs } from '@entropyparadox/reusable-react';
import React, { useState } from 'react';

export const AccumulatedPage = () => {
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const [tabIndex, setTabIndex] = useState(0);
  const { saveConfig } = MileageContainer.useContext();

  const onSaveClick = () => {
    if (tabIndex === 0) {
      saveConfig()
        .then(() => showSuccessAlert())
        .catch(showErrorAlert);
    }
  };

  return (
    <>
      {tabIndex === 0 && <EditTopBar title="적립금 관리" saveClick={onSaveClick} />}
      {tabIndex !== 0 && <TopBar title="적립금 관리" />}
      <div className="p-6">
        <Tabs>
          <Tab text="기본설정" active={tabIndex === 0} onClick={() => setTabIndex(0)} />
          <Tab text="적립금내역" active={tabIndex === 1} onClick={() => setTabIndex(1)} />
          <Tab text="지급/차감하기" active={tabIndex === 2} onClick={() => setTabIndex(2)} />
        </Tabs>
        <div className="pt-8">
          {tabIndex === 0 && <AccumulatedSet />}
          {tabIndex === 1 && <AccumulatedTable />}
          {tabIndex === 2 && <AccumulatedRedeem />}
        </div>
      </div>
    </>
  );
};
