import { AxiosClient, AxiosQuery } from '@/api';
import { createContainer } from '@/container/createContainer';
import { RequestUpdateShopSettingDto } from '@api/generated/axios-client';
import { DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import { useState } from 'react';

export const mileageHook = () => {
  const [baseMileageEarnRate, setBaseMileageEarnRate] = useState<number>(0);
  const [baseReviewEarnMileage, setBaseReviewEarnMileage] = useState<number>(0);
  const [photoReviewEarnMileage, setPhotoReviewEarnMileage] = useState<number>(0);

  const { data } = AxiosQuery.Query.useGetManyBaseShopSettingControllerShopSettingQuery(
    DefaultRequestCrudDto,
    {
      onSuccess: data => {
        if (!data.data[0]) return;

        setBaseMileageEarnRate(data.data[0].baseMileageEarnRate);
        setBaseReviewEarnMileage(data.data[0].baseReviewEarnMileage);
        setPhotoReviewEarnMileage(data.data[0].photoReviewEarnMileage);
      },
      retry: false,
    },
  );

  const saveConfig = () => {
    if (!data?.data[0]) return Promise.resolve();

    return AxiosClient.updateOneBaseShopSettingControllerShopSetting(
      data.data[0].id,
      new RequestUpdateShopSettingDto({
        baseMileageEarnRate,
        baseReviewEarnMileage,
        photoReviewEarnMileage,
      }),
    );
  };

  return {
    baseMileageEarnRate,
    baseReviewEarnMileage,
    photoReviewEarnMileage,
    setBaseMileageEarnRate,
    setBaseReviewEarnMileage,
    setPhotoReviewEarnMileage,
    saveConfig,
  };
};

export const MileageContainer = createContainer(mileageHook);
