import { TextField } from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { ReactComponent as FileUploadImg } from '../assets/svg/file-upload.svg';
import { EditTopBar } from '@/components/EditTopBar';

export const BannerAdd = () => {
  const [productPhoto, setProductPhoto] = useState<File | null | undefined>();
  const [bannerName, setBannerName] = useState('');
  const [createdAt, setCreatedAt] = useState(new Date());
  const [exposureOrder, setExposureOrder] = useState(0);
  const [connectLink, setConnectLink] = useState('');
  return (
    <>
      <EditTopBar title="배너 추가" saveClick={() => {}} deleteClick={() => {}} />
      <div className="flex space-x-6 p-6">
        <div className="w-2/5 2xl:w-1/5">
          <label htmlFor="certificateBrokerUpload">
            <div className="relative pb-5/6 rounded-md border-2 border-grey-5">
              {productPhoto ? (
                <>
                  <img
                    className="absolute w-full h-full rounded object-cover"
                    src={URL.createObjectURL(productPhoto)}
                    alt=""
                  />
                  <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-3 left-3">
                    사진 수정하기
                  </div>
                </>
              ) : (
                <div className="absolute w-full h-full rounded object-cover bg-white">
                  <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                    <FileUploadImg />
                    <div className="text-sm text-gray-500 text-center pt-1">
                      이미지를 업로드해주세요.
                      <br />
                      (최대 1장)
                    </div>
                  </div>
                </div>
              )}
            </div>
          </label>
          <input
            type="file"
            id="certificateBrokerUpload"
            className="hidden"
            accept="image/*"
            onChange={e => {
              e.target.validity.valid && setProductPhoto(e.target.files?.item(0));
            }}
          />
        </div>
        <div className="w-96 space-y-4">
          <TextField label="생성일" value={createdAt.toLocaleDateString()} disabled />
          <TextField
            label="배너명"
            value={bannerName}
            onChange={e => setBannerName(e.target.value)}
          />
          <TextField
            label="연결링크"
            value={connectLink}
            onChange={e => setConnectLink(e.target.value)}
          />
          <TextField
            label="노출우선순위"
            type="number"
            value={exposureOrder}
            onChange={e => setExposureOrder(Number(e.target.value))}
          />
        </div>
      </div>
    </>
  );
};
