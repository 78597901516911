import { ReactComponent as DownIcon } from '../assets/svg/chevron-down.svg';
import { Category } from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import RoutePath from '@constants/RoutePath';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';


interface CategoryGroupItemProps {
  id: number;
  name: string;
  categories?: Category[];
  selected: boolean;
}

export const CategoryGroupItem: React.FC<CategoryGroupItemProps> = ({
  id,
  name,
  categories,
  selected = false,
}) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  if (categories?.length !== 0) {
    // 서브 카테고리 우선순위 낮은 순 정렬
    categories?.sort((c1, c2) => {
      if (c1.sorted > c2.sorted) return 1;
      if (c1.sorted < c2.sorted) return -1;
      return 0;
    });
  }

  return (
    <>
      <div className="flex justify-between">
        <div className="flex space-x-4">
          <div
            className={['cursor-pointer', selected ? 'font-bold' : ''].join(' ')}
            onClick={
              !!categories?.length
                ? () => setIsOpen(!isOpen)
                : !categories?.length
                ? () => history.push(RoutePath.ProductList.replace(':categoryId', String(id)))
                : undefined
            }>
            {name}
          </div>
          {!!categories?.length && (
            <DownIcon
              className={`cursor-pointer ${isOpen ? 'transform rotate-180' : ''}`}
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
        </div>

        {![0, Const.QuickDeliveryCategoryId].includes(id) && (
          <div
            className="text-gray-400 font-light cursor-pointer"
            onClick={() => history.push(RoutePath.ProductCategoryEdit.replace(':categoryId', String(id)))}>
            수정
          </div>
        )}
      </div>

      {categories && isOpen && (
        <div className="space-y-2 pb-2">
          {categories.map(item => {
            const subCategoryProductListPath = RoutePath.SubCategoryProductList.replace(
              ':categoryId',
              String(id),
            ).replace(':subCategoryId', String(item.id));

            return (
              <div
                key={item.id}
                className={`${
                  location.pathname === subCategoryProductListPath && 'font-bold text-gray-900'
                } ml-6 text-gray-500 cursor-pointer`}
                onClick={() => history.push(subCategoryProductListPath)}>
                {item.title}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
