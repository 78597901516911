import React, { Children, createContext, isValidElement } from 'react';

export interface Row {
  [key: string]: any;
}

interface DatagridProps {
  data: Row[];
}

export const LabelContext = createContext<string>('');
export const RowContext = createContext<Row>({});

export const Datagrid: React.FC<DatagridProps> = ({ children, data }) => {
  return (
    <table className="w-full h-full">
      <thead className="border bg-gray-100">
        <tr>
          {Children.map(children, (child, index) => (
            <LabelContext.Provider key={index} value={isValidElement(child) ? child.props.label : ''}>
              <th className="border border-gray-100 text-sm px-3 py-2 text-gray-600 font-medium whitespace-pre text-center">
                {child}
              </th>
            </LabelContext.Provider>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((param, index) => {
          const row = 'original' in param ? param.original : param;
          return (
            <RowContext.Provider key={row.orderProductId} value={row}>
              <tr key={row.id} className={param?.isDifferentStatus && 'bg-gray-50'}>
                {Children.map(children, (child, index) =>
                  isValidElement(child) && child.props.mergedColumn && row.rowIndex ? null : (
                    <td
                      key={index}
                      rowSpan={isValidElement(child) && child.props.mergedColumn ? row.rowSpan : undefined}
                      className="border border-gray-100 px-3 py-1 text-lsm text-gray-700 align-text-top">
                      {child}
                    </td>
                  ),
                )}
              </tr>
            </RowContext.Provider>
          );
        })}
      </tbody>
    </table>
  );
};
