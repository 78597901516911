import { NomalUserTable } from './NomalUserTable';
import { useAlert } from '@/components/Alert/Alert';
import { TagModalPopup } from '@/components/Alert/TagModalPopup';
import { TopBar } from '@/components/TopBar';
import { MemberContainer } from '@/container/member';
import { IRequestCreateUserGroupDto, UserGroup } from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import React, { useState } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import { filter } from 'lodash';

const UserPageContent = () => {
  const history = useHistory();
  const id = location.pathname.split('normal/')[1]?.split('/')[0];

  const [openAddUserGroup, setOpenAddUserGroup] = useState(false);
  const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroup>();
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const { tags, createUserGroup, updateUserGroup, deleteUserGroup } = MemberContainer.useContext();

  const clear = () => {
    setSelectedUserGroup(undefined);
    setOpenAddUserGroup(false);
  };

  const onSaveItem = (params: IRequestCreateUserGroupDto) => {
    if (selectedUserGroup) {
      updateUserGroup(selectedUserGroup.id, params)
        .then(() => showSuccessAlert())
        .then(() => clear())
        .catch(showErrorAlert);
    } else {
      createUserGroup(params)
        .then(() => showSuccessAlert())
        .then(() => clear())
        .catch(showErrorAlert);
    }
  };

  const onDeleteItem = () => {
    if (!selectedUserGroup) return;

    deleteUserGroup(selectedUserGroup.id)
      .then(() => showSuccessAlert())
      .then(() => clear())
      .catch(showErrorAlert);
  };

  const requiredGroupIds = [Const.AllUserGroupId, Const.BlackUserGroupId, Const.NormalUserGroupId];
  const requiredTags = filter(tags, tag => requiredGroupIds.includes(tag.id));
  const restTags = filter(tags, tag => !requiredGroupIds.includes(tag.id));

  return (
    <>
      <TopBar title="회원관리" />
      <TagModalPopup
        open={openAddUserGroup}
        item={selectedUserGroup}
        onSave={onSaveItem}
        onDelete={selectedUserGroup ? onDeleteItem : undefined}
        onClose={() => clear()}
      />
      <div className="grid grid-cols-5 h-full">
        <div className="col-span-1">
          <div className="m-4 px-4 py-3 border rounded-md h-full">
            <div className="flex justify-between items-center">
              <div className="font-semibold text-xl">태그 관리</div>
              <button
                className="bg-gray-100 px-4 py-2 rounded-md mt-2"
                onClick={() => {
                  setSelectedUserGroup(undefined);
                  setOpenAddUserGroup(true);
                }}>
                추가
              </button>
            </div>
            <div className="space-y-4 mt-8 font-base">
              {requiredTags?.map(item => (
                <div key={item.id} className="flex justify-between">
                  <div
                    key={`users-tag-${item.id}`}
                    className={`cursor-pointer ${id === item.id.toString() ? 'font-bold' : ''}`}
                    style={{ color: `${item.color ? item.color : '#000000'}` }}
                    onClick={() => {
                      history.push(`/admin/user/normal/${item.id}`);
                    }}>
                    {item.name}
                  </div>
                </div>
              ))}
              {restTags?.map(item => (
                <div key={item.id} className="flex justify-between">
                  <div
                    key={`users-tag-${item.id}`}
                    className={`cursor-pointer ${id === item.id.toString() ? 'font-bold' : ''}`}
                    style={{ color: `${item.color ? item.color : '#000000'}` }}
                    onClick={() => {
                      history.push(`/admin/user/normal/${item.id}`);
                    }}>
                    {item.name}
                  </div>
                  <div
                    className="text-gray-400 font-light cursor-pointer"
                    onClick={() => {
                      setSelectedUserGroup(item);
                      setOpenAddUserGroup(true);
                    }}>
                    Edit
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-span-4 p-6">
          <Switch>
            <Route path="/admin/user/normal/:tagId" component={NomalUserTable} />
            <Route
              path="/admin/user/normal"
              component={() => (
                <div className="h-full w-full flex justify-center items-center bg-gray-100 rounded-md">
                  그룹을 선택해주세요.
                </div>
              )}
            />
          </Switch>
        </div>
      </div>
    </>
  );
};

export const UserPage = () => {
  return (
    <MemberContainer.ContextProvider>
      <UserPageContent />
    </MemberContainer.ContextProvider>
  );
};
