import { ReactComponent as FileClipIcon } from '../../assets/svg/file-clip.svg';
import { ReactComponent as FileUploadImg } from '../../assets/svg/file-upload.svg';
import { useAlert } from '@/components/Alert/Alert';
import { FileParameter, OrderControllerQuery } from '@api/generated/axios-client';
import React, { useRef, useState } from 'react';

interface FileModalPopupProps {
  open: boolean;
  onClose: () => void;
}

export const FileModalPopup: React.FC<FileModalPopupProps> = ({ onClose, open }) => {
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const [treackingFile, setTreackingFile] = useState<File | null | undefined>();
  const photoUploadRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const deliveryInfoFileUpload = async (file: File) => {
    let fileParameter: FileParameter = {
      data: file,
      fileName: file.name,
    };

    OrderControllerQuery.Client.importDeliveryInfos(fileParameter)
      .then(() => showSuccessAlert('저장'))
      .then(() => onClose())
      .catch(showErrorAlert);
  };
  return (
    <div>
      {open && (
        <div
          className="fixed z-100 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onClose}>
                  <span className="sr-only">Close</span>

                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <h3 className="text-lg leading-6 font-semibold text-gray-900 mb-5" id="modal-title">
                    송장 일괄등록
                  </h3>
                  {/* <div className="mt-2 mb-4">
                    <p className="text-sm text-gray-500">{description}</p>
                  </div> */}
                  <div>
                    <label htmlFor="fileupload">
                      <div className="w-full border-2 py-10 border-dashed border-grey-5 flex flex-col justify-center items-center space-y-1">
                        <FileUploadImg />
                        <div className="text-brand-1">파일을 업로드해주세요!</div>
                      </div>
                      {treackingFile && (
                        <>
                          <div className="w-full flex flex-row justify-between mt-2 py-3 px-4 border-border-gray border rounded-md">
                            <div className="flex flex-row justify-items-center">
                              <FileClipIcon />
                              <span className="text-sm text-black-0 ml-3">{treackingFile.name}</span>
                            </div>
                            <button
                              onClick={() => photoUploadRef.current.click()}
                              className="text-sm text-brand-1">
                              {'파일 재첨부'}
                            </button>
                          </div>
                          {/* <p>{treackingFile.name}</p>
                          <img
                            className="w-full flex flex-col justify-center items-center space-y-1"
                            src={URL.createObjectURL(treackingFile)}
                            alt=""
                          /> */}
                        </>
                      )}
                    </label>
                    <input
                      ref={photoUploadRef}
                      type="file"
                      id="fileupload"
                      className="hidden"
                      accept="license/*"
                      onChange={e => {
                        e.target.validity.valid && setTreackingFile(e.target.files?.item(0));
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4 flex flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white sm:text-sm mx-auto"
                  onClick={() => {
                    if (!treackingFile) {
                      alert('파일 선택하세요.');
                      return;
                    }
                    deliveryInfoFileUpload(treackingFile);
                  }}>
                  등록하기
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
