import { Field } from './Field';
import React from 'react';

interface QuantityFieldProps {
  label: string;
  property: string;
}

export const QuantityField: React.FC<QuantityFieldProps> = ({ label, property }) => {
  return (
    <Field
      label={label}
      render={row => (
        <>
          <div className="border-b mb-2">
            <div className="font-semibold mb-4">{row.totalCount}</div>
          </div>
        </>
      )}
    />
    // ))}
  );
};
