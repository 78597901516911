import { ReactComponent as FileUploadImg } from '../assets/svg/file-upload.svg';
import { useAlert } from '@/components/Alert/Alert';
import { EditTopBar } from '@/components/EditTopBar';
import {
  FileParameter,
  IRequestCreatePopupDto,
  S3ControllerQuery,
  S3ResourceType,
} from '@api/generated/axios-client';
import { MainPopupContainer } from '@container/mainPopup';
import { TextField } from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { NomalModalPopup } from '@components/Alert/NomalModalPopup';
import { Label } from '@components/Label';

export const MainPopupAddContent = () => {
  const { popup, setSelectedPopupId, createPopup, updatePopup, deletePopup, popups } =
    MainPopupContainer.useContext();
  const { id } = useParams() as { id: string };
  let popupId = Number(id);
  const [popupUrl, setPopupUrl] = useState<string>('');
  const [actionUrl, setActionUrl] = useState<string>('');
  const [exposureOrder, setExposureOrder] = useState(0);
  const [buttonText, setButtonText] = useState<string>('');
  const [popupName, setPopupName] = useState('');
  const [popupFile, setPopupFile] = useState<File | null | undefined>();
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  useEffect(() => {
    if (popupId || !isNaN(popupId)) {
      setSelectedPopupId(+popupId);
    }
  }, [popupId]);

  useEffect(() => {
    setPopupUrl(popup?.imageUrl || '');
    setPopupName(popup?.title || '');
    setButtonText(popup?.actionText || '');
    setExposureOrder(popup?.sorted || 0);
    setActionUrl(popup?.actionUrl || '');
  }, [popup]);

  const onSave = () => {
    if (popupId && !isNaN(popupId)) {
      updatePopup(popupId, {
        title: popupName,
        imageUrl: popupUrl,
        actionText: buttonText,
        actionUrl: actionUrl,
        sorted: exposureOrder,
      })
        .then(() => showSuccessAlert())
        .catch(showErrorAlert);
    } else {
      let params: IRequestCreatePopupDto = {
        actionText: buttonText,
        actionUrl: actionUrl,
        sorted: exposureOrder,
        title: popupName,
        imageUrl: popupUrl,
      };
      createPopup(params)
        .then(() => showSuccessAlert())
        .then(() => history.back())
        .catch(showErrorAlert);
    }
  };

  const onDelete = () => {
    if (!popupId && isNaN(popupId)) return;

    deletePopup(popupId)
      .then(() => showSuccessAlert('삭제'))
      .then(() => history.back())
      .catch(showErrorAlert);
  };

  const fileUpload = async (file: File) => {
    let fileParameter: FileParameter = {
      data: file,
      fileName: file.name,
    };
    const s3UploadDto = await S3ControllerQuery.Client.s3UploadFile(S3ResourceType.Popup, fileParameter);
    setPopupUrl(s3UploadDto.fullUrl);
  };

  return (
    <>
      <NomalModalPopup
        title="팝업을 삭제하시겠습니까?"
        description="삭제 후 복구가 불가합니다. 정말 삭제하시겠습니까?"
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onClick={onDelete}
      />
      <EditTopBar
        title="메인 팝업 추가/수정"
        saveClick={onSave}
        deleteClick={() => setAlertOpen(true)}
        haveId={!!popupId}
      />
      <div className="flex space-x-6 p-6">
        <div>
          <label htmlFor="certificateBrokerUpload">
            <Label text="사이즈 가이즈(448px X 448px / 1mb이하)" />
            <div className="relative w-96 h-96 rounded-md border-2 border-grey-5">
              {popupFile || popupUrl ? (
                <>
                  <img
                    className="absolute w-full h-full rounded object-cover"
                    src={popupFile ? URL.createObjectURL(popupFile) : popupUrl}
                    alt=""
                  />
                  <div className="absolute px-3 py-1.5 bg-brand-1 text-white rounded-lg top-3 left-3">
                    사진 수정하기
                  </div>
                </>
              ) : (
                <div className="absolute w-full h-full rounded object-cover bg-white">
                  <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                    <FileUploadImg />
                    <div className="text-sm text-gray-500 text-center pt-1">
                      이미지를 업로드해주세요.
                      <br />
                      448px * 448px / 1mb이하 / 최대 1장
                    </div>
                  </div>
                </div>
              )}
            </div>
          </label>
          <input
            type="file"
            id="certificateBrokerUpload"
            className="hidden"
            accept="image/*"
            onChange={e => {
              if (!e.target.validity.valid) return;
              const file = e.target.files?.item(0);
              if (file) {
                setPopupFile(file);
                fileUpload(file);
              }
            }}
          />
        </div>
        <div className="w-96 space-y-4">
          <TextField
            label="생성일"
            value={popup ? popup.createdAt.toLocaleDateString() : new Date().toLocaleDateString()}
            disabled></TextField>
          <TextField label="팝업명" value={popupName} onChange={e => setPopupName(e.target.value)} />
          <TextField label="텍스트" value={buttonText} onChange={e => setButtonText(e.target.value)} />
          <TextField label="연결링크" value={actionUrl} onChange={e => setActionUrl(e.target.value)} />
          <TextField
            label="노출우선순위"
            type="number"
            value={exposureOrder}
            onChange={e => setExposureOrder(Number(e.target.value))}
          />
        </div>
      </div>
    </>
  );
};

export const MainPopupAddPage = () => (
  <MainPopupContainer.ContextProvider>
    <MainPopupAddContent />
  </MainPopupContainer.ContextProvider>
);
