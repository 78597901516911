import { ReactComponent as FileUploadImg } from '../assets/svg/file-upload.svg';
import { FileParameter, S3ControllerQuery, S3ResourceType } from '@api/generated/axios-client';
import { useAlert } from '@components/Alert/Alert';
import { MainContainer } from '@container/main';
import { Button, TextField } from '@entropyparadox/reusable-react';
import React, { useRef, useState } from 'react';
import { Label } from './Label';

interface RecommendProductModalPopupProps {
  open: boolean;
  item: any; // RecommendProductDto;
  onClose: () => void;
}

export const RecommendProductModalPopup: React.FC<RecommendProductModalPopupProps> = ({
  onClose,
  item,
  open,
}) => {
  const { createRecommendProducts, updateRecommendProducts, deleteRecommendProducts } =
    MainContainer.useContext();

  const { showSuccessAlert, showErrorAlert } = useAlert();
  const [treackingFile, setTreackingFile] = useState<File | null | undefined>();
  const photoUploadRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [link, setLink] = useState(item.actionUrl);
  const [title, setTitle] = useState(item.title);
  const [imageUrl, setImageUrl] = useState(item.imageUrl);

  const onSave = () => {
    if (!item.id) {
      createRecommendProducts({
        adminUserId: undefined,
        description: '',
        isActive: true,
        sorted: 0,
        title,
        actionUrl: link,
        imageUrl: imageUrl,
      })
        .then(() => showSuccessAlert())
        .then(() => onClose())
        .catch(showErrorAlert);
    } else {
      updateRecommendProducts(item.id, {
        adminUserId: undefined,
        description: item.description,
        isActive: true,
        sorted: item.sorted,
        title,
        actionUrl: link,
        imageUrl: imageUrl,
      })
        .then(() => showSuccessAlert())
        .then(() => onClose())
        .catch(showErrorAlert);
    }
  };

  const fileUpload = async (file: File) => {
    let fileParameter: FileParameter = {
      data: file,
      fileName: file.name,
    };
    const s3UploadDto = await S3ControllerQuery.Client.s3UploadFile(
      S3ResourceType.RecommendProduct,
      fileParameter,
    );
    setImageUrl(s3UploadDto.fullUrl);
  };

  const onDelete = () => {
    if (!item?.id) return;

    deleteRecommendProducts(item.id)
      .then(() => showSuccessAlert('삭제'))
      .then(() => onClose())
      .catch(showErrorAlert);
  };

  return (
    <div>
      {open && (
        <div
          className="fixed z-100 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onClose}>
                  <span className="sr-only">Close</span>

                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <h3 className="text-lg leading-6 font-semibold text-gray-900 mb-5" id="modal-title">
                    추천상품 관리
                  </h3>
                  <div className="space-y-4">
                    <div>
                      <Label text="사이즈 가이드 (288px * 208px / 1mb이하)" />
                      <label htmlFor="fileupload">
                        <div className="relative pb-5/6 rounded-md border-2 border-grey-5">
                          {treackingFile || imageUrl ? (
                            <>
                              <img
                                className="absolute w-full h-full rounded object-cover"
                                src={treackingFile ? URL.createObjectURL(treackingFile) : imageUrl}
                                alt=""
                              />
                              <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-3 left-3">
                                사진 수정하기
                              </div>
                            </>
                          ) : (
                            <div className="absolute w-full h-full rounded object-cover bg-white">
                              <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                                <FileUploadImg />
                                <div className="text-sm text-gray-500 text-center pt-1">
                                  이미지를 업로드해주세요.
                                  <br />
                                  288px * 208px / 1mb 이하 / 최대 1장
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </label>
                      <input
                        ref={photoUploadRef}
                        type="file"
                        id="fileupload"
                        className="hidden"
                        accept="license/*"
                        onChange={e => {
                          if (!e.target.validity.valid) return;
                          const file = e.target.files?.item(0);
                          if (file) {
                            setTreackingFile(file);
                            fileUpload(file);
                          }
                        }}
                      />
                    </div>
                    <TextField
                      label="타이틀 (메인에서 태그형태로 보여지는 텍스트)"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                    />
                    <TextField label="연결링크" value={link} onChange={e => setLink(e.target.value)} />
                  </div>
                </div>
              </div>

              <div className="flex space-x-3 mt-4 justify-center">
                <div className="flex flex-col w-56">
                  {!!item.id && <Button text="삭제하기" onClick={onDelete} variant="outlined" />}
                </div>
                <div className="flex flex-col w-56">
                  <Button text="저장하기" onClick={onSave} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
