import { CategoryGroupItem } from './CategoryGroupItem';
import { Const } from '@constants/Const';
import { ProductCategoryContainer } from '@container/productCategory';
import React from 'react';

interface CategoryAreaProps {
  title: string;
  onClick: () => void;
  categoryId: number;
}

export const CategoryArea: React.FC<CategoryAreaProps> = ({ title, onClick, categoryId }) => {
  const { categoryList } = ProductCategoryContainer.useContext();

  // 퀵 딜리버리
  // const filterQuickDelivery = categoryList?.data.filter(category => {
  //   return category.id === Const.QuickDeliveryCategoryId;
  // });

  // 퀵 딜리버리 제외한 나머지 카테고리
  const normalCategoryList = categoryList?.data.filter(category => {
    return category.id !== Const.QuickDeliveryCategoryId;
  });

  return (
    <div className="m-4 px-4 py-3 border rounded-md h-full">
      <div className="flex justify-between items-center">
        <div className="font-semibold text-xl">{title}</div>
        <button className="bg-gray-100 px-4 py-2 rounded-md mt-2" onClick={onClick}>
          추가
        </button>
      </div>
      <div className="space-y-4 mt-8 font-base">
        <CategoryGroupItem id={0} name={'전체'} selected={categoryId === 0} />
        {/* {filterQuickDelivery && (
          <CategoryGroupItem
            id={filterQuickDelivery[0].id}
            name={filterQuickDelivery[0].title}
            selected={categoryId === filterQuickDelivery[0].id}
          />
        )} */}
        {normalCategoryList?.map(item => (
          <CategoryGroupItem
            key={item.id}
            id={item.id}
            name={item.title}
            categories={item.subCategories}
            selected={categoryId === item.id}
          />
        ))}
      </div>
    </div>
  );
};
