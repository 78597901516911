import React from 'react';

export interface PaginationProps {
  canPreviousPage?: boolean;
  canNextPage?: boolean;
  pageCount: number;
  gotoPage: (page: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  pageIndex: number;
  pageSize: number;
}

const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  nextPage,
  pageIndex,
  previousPage,
  gotoPage,
}: PaginationProps) => {
  let adjustPageIndex = Number(pageIndex);

  if (pageCount < 0) {
    pageCount = 0;
  }

  const pageItems = new Array(pageCount).fill(0).map((row, index) => {
    const page = index + 1;
    return (
      <a
        key={page}
        className={`cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 ${
          adjustPageIndex === page ? 'font-bold' : ''
        }`}
        onClick={() => {
          gotoPage(page);
        }}>
        {page}
      </a>
    );
  });

  return (
    <div className="flex flex-col items-center my-10">
      <nav
        className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
        aria-label="Pagination">
        {canPreviousPage && (
          <a
            className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => {
              previousPage();
            }}>
            <span className="sr-only">Previous</span>
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true">
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        )}
        <a
          className={`cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50`}
          onClick={() => {
            gotoPage(1);
          }}>
          처음
        </a>
        {pageItems.slice(pageIndex - 3 < 0 ? 0 : pageIndex - 3, pageIndex + 3)}
        <a
          className={`cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50`}
          onClick={() => {
            gotoPage(pageCount);
          }}>
          끝
        </a>
        {canNextPage && (
          <a
            className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => {
              nextPage();
            }}>
            <span className="sr-only">Next</span>
            {/* <!-- Heroicon name: solid/chevron-right --> */}
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true">
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        )}
      </nav>
    </div>
  );
};

export default Pagination;
