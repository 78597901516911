import { Datagrid } from './Datagrid';
import { AddressField } from './Field/AddressField';
import { Field } from './Field/Field';
import OrderField from './Field/OrderField';
import { OrderItemField } from './Field/OrderItemField';
import { PaymentField } from './Field/PaymentField';
import { PresentField } from './Field/PresentField';
import { QuantityField } from './Field/QuantityField';
import { TrackingField } from './Field/TrackingField';
import { MemoInput } from './MemoInput';
import Pagination from './Pagination';
import { OrderContainer } from '@/container/order';
import { renderer } from '@/utils/renderer';
import { Checkbox } from '@entropyparadox/reusable-react';
import { PageHelper } from '@utils/PageHelper';


export const OrderTable = () => {
  const {
    orderStatus,
    orders,
    setOrderPage,
    useCheck: {
      setAllChecked,
      isChecked,
      setChecked,
      setOrderProductAllChecked,
      isOrderProductChecked,
      setOrderProductChecked,
    },
  } = OrderContainer.useContext();

  const pageInfo = PageHelper.getPageInfo({
    ...orders,
    gotoPage: setOrderPage,
  });

  if (!orders?.data) return null;

  const orderProducts = orders.data.flatMap(order =>
    order.orderProducts.map((orderProduct, i) => ({
      ...order,
      ...orderProduct,
      rowIndex: i,
      rowSpan: order.orderProducts.length,
      shippingType: orderProduct.shippingType,
      orderShippingType: order.shippingType,
      id: order.id,
      orderProductId: orderProduct.id,
      userId: order.userId,
      isDifferentStatus: orderProduct.orderStatus !== orderStatus,
    })),
  );

  return (
    <div>
      <Datagrid data={orderProducts}>
        <Field
          label=""
          mergedColumn
          render={row => (
            <Checkbox
              checked={isChecked(row.id || -1)}
              onChange={e => {
                // 전체
                if (!row.id) {
                  setAllChecked(orders.data.map((item: any) => item.id).concat([-1]), e.target.checked);
                  // 주문 상품들
                  setOrderProductAllChecked(
                    orders.data
                      .flatMap((item: any) => item.orderProducts.map((orderProduct: any) => orderProduct.id))
                      .concat([-1]),
                    e.target.checked,
                  );
                  return;
                }

                setChecked(row.id, e.target.checked);
                // 주문 상품들

                if (row.orderProducts.length > 1) {
                  setOrderProductAllChecked(
                    row.orderProducts.map((item: any) => item.id),
                    e.target.checked,
                  );
                } else {
                  setOrderProductChecked(
                    row.orderProducts.map((item: any) => item.id),
                    e.target.checked,
                  );
                }
              }}
            />
          )}
        />
        <OrderField label="주문번호 / 일자" property="orderNumber" mergedColumn />
        {/* 주문 상품 체크 */}
        <Field
          label=""
          render={row => {
            return !row.id ? null : (
              <Checkbox
                checked={isOrderProductChecked(row.orderProductId || -1)}
                onChange={e => {
                  setOrderProductChecked(row.orderProductId, e.target.checked);
                  if (!e.target.checked) {
                    setChecked(row.id, e.target.checked);
                  } else {
                    const orderProductIds = row.orderProducts.map((item: any) => item.id);
                    if (
                      !orderProductIds.find(
                        (id: any) => !isOrderProductChecked(id) && id !== row.orderProductId,
                      )
                    ) {
                      setChecked(row.id, e.target.checked);
                    }
                  }
                }}
              />
            );
          }}
        />
        <Field label="품목번호" render={row => <div className="text-sm">{row.orderProductId}</div>} />
        <OrderItemField label="주문상품" property="id" />
        <QuantityField label="수량" property="id" />
        <Field
          label="상태"
          render={row => <div className="text-sm">{renderer.orderStatus(row.orderStatus)}</div>}
        />
        <Field
          label="배송옵션"
          render={row => (
            <div className="text-sm">
              {row.isGift
                ? renderer.shippingType(row.orderShippingType)
                : renderer.shippingType(row.shippingType)}
            </div>
          )}
        />
        <PresentField label="선물정보" property="id" mergedColumn />
        <TrackingField label="운송장번호" property="shippingNumber" />
        <AddressField label="배송정보" property="id" mergedColumn />
        <PaymentField label="결제정보" property="id" mergedColumn />
        <Field
          label="관리자메모"
          render={row => (
            <div className="flex flex-col justify-between h-full pb-3">
              <MemoInput row={row} />
            </div>
          )}
          mergedColumn
        />
      </Datagrid>
      <Pagination {...pageInfo} />
    </div>
  );
};
