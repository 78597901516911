import { Field } from './Field';
import moment from 'moment';
import React from 'react';

interface PresentFieldProps {
  label: string;
  property: string;
  mergedColumn?: boolean;
}

const WEB_URL = process.env.REACT_APP_WEB_URL;

export const PresentField: React.FC<PresentFieldProps> = ({ label, property }) => {
  return (
    <Field
      label={label}
      render={row => (
        <div className="text-sm space-y-1">
          <div className="flex space-x-1">
            <div className="min-w-max">수락기한</div>
            <div className="text-gray-500 font-light">
              {row.orderGift?.expiredAt ? moment(row.orderGift.expiredAt).format('YYYY-MM-DD HH:mm') : '-'}
            </div>
          </div>
          <div className="flex space-x-1">
            <div>수락일시</div>
            <div className="text-gray-500 font-light">
              {row.orderGift?.acceptedAt ? moment(row.orderGift.acceptedAt).format('YYYY-MM-DD HH:mm') : '-'}
            </div>
          </div>
          {!!row.giftKey && (
            <button
              className="bg-gray-100 px-3 py-2 text-sm rounded-md"
              onClick={() => {
                window.open(`${WEB_URL}/gift/${row.giftKey}`);
              }}>
              선물메세지
            </button>
          )}
        </div>
      )}
    />
  );
};
