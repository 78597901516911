import Pagination from '../components/Pagination';
import { useAlert } from '@/components/Alert/Alert';
import { NomalModalPopup } from '@/components/Alert/NomalModalPopup';
import { NoticeModalPopup } from '@/components/Alert/NoticeModalPopup';
import { Datagrid } from '@/components/Datagrid';
import { Field } from '@/components/Field/Field';
import { NormalField } from '@/components/Field/NormalField';
import { SearchField } from '@/components/Field/SearchField';
import { TopBar } from '@/components/TopBar';
import { Notice } from '@api/generated/axios-client';
import { NoticeSaveModalPopup } from '@components/Alert/NoticeSaveModalPopup';
import { ContentContainer } from '@container/content';
import { UserContainer } from '@container/user';
import { Checkbox } from '@entropyparadox/reusable-react';
import { PageHelper } from '@utils/PageHelper';
import { useCheckField } from '@utils/hooks/useCheckField';
import React, { useState } from 'react';


const NoticePageContent = () => {
  const [bulkDeleteModalOpen, setBulkDeleteModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isNoticeOpen, setIsNoticeOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const {
    noticeKeyword,
    setNoticeKeyword,
    notices,
    setNoticePage,
    createNotice,
    updateNotice,
    deleteNotices,
  } = ContentContainer.useContext();
  const { myProfile } = UserContainer.useContext();

  const { checked, setAllChecked, isChecked, setChecked } = useCheckField();
  const [selected, setSelected] = useState<Notice | null>(null);

  const { showSuccessAlert, showErrorAlert } = useAlert();

  if (!notices) return null;

  const pageInfo = PageHelper.getPageInfo({
    ...notices,
    gotoPage: setNoticePage,
  });

  const clear = () => {
    setAllChecked([], false);
    clearModal();
  };

  const clearModal = () => {
    setSelected(null);
    setIsNoticeOpen(false);
    setSaveModalOpen(false);
    setBulkDeleteModalOpen(false);
    setDeleteModalOpen(false);
  };

  const onItemClick = (id: number) => {
    const foundNotice = notices.data.find(n => n.id === id);
    setSelected(foundNotice || null);
    setIsNoticeOpen(true);
  };

  const handleSave = (title: string, description: string) => {
    if (selected?.id) {
      updateNotice(selected?.id, { title, content: description })
        .then(() => showSuccessAlert())
        .then(() => clear())
        .catch(showErrorAlert);
    } else {
      createNotice({ title, content: description, isActive: true, adminUserId: myProfile?.id })
        .then(() => showSuccessAlert())
        .then(() => clear())
        .catch(showErrorAlert);
    }
  };

  const handleDelete = (ids: number[] = checked.filter(id => id !== -1).map(Number)) => {
    deleteNotices(ids)
      .then(() => showSuccessAlert('삭제'))
      .catch(showErrorAlert)
      .finally(() => clear());
  };

  return (
    <>
      <TopBar title="공지사항 관리" />
      <NoticeSaveModalPopup open={saveModalOpen} onSave={handleSave} onClose={clearModal} />
      <NoticeModalPopup
        open={isNoticeOpen}
        item={selected}
        onSave={handleSave}
        onDelete={() => setDeleteModalOpen(true)}
        onClose={clearModal}
      />
      <NomalModalPopup
        title="정말 삭제하시겠습니까?"
        description="삭제 후 복구가 불가합니다."
        open={bulkDeleteModalOpen}
        onClose={() => setBulkDeleteModalOpen(false)}
        onClick={() => handleDelete()}
      />
      <NomalModalPopup
        title="정말 삭제하시겠습니까?"
        description="삭제 후 복구가 불가합니다."
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onClick={() => selected?.id && handleDelete([selected.id])}
      />
      <div className="p-6">
        <div className="flex justify-between items-center my-4">
          <div className="space-x-4">
            <button
              className="bg-gray-100 px-4 py-2 text-sm rounded-md"
              onClick={() => {
                setBulkDeleteModalOpen(true);
              }}>
              삭제
            </button>
            <button
              className="bg-black text-white px-4 py-2 text-sm rounded-md"
              onClick={() => setSaveModalOpen(true)}>
              공지사항 추가
            </button>
          </div>
          <div>
            <SearchField
              className="w-96"
              value={noticeKeyword}
              onChange={e => setNoticeKeyword(e.target.value)}
            />
          </div>
        </div>
        <div>
          {notices?.data && (
            <Datagrid data={notices.data}>
              <Field
                label=""
                render={row => (
                  <Checkbox
                    checked={isChecked(row.id || -1)}
                    onChange={e => {
                      // 전체
                      if (!row.id) {
                        setAllChecked(notices.data.map(item => item.id).concat([-1]), e.target.checked);
                        return;
                      }

                      setChecked(row.id, e.target.checked);
                    }}
                  />
                )}
              />
              <NormalField label="ID" property="id" />
              <Field
                label="작성일"
                render={row => <div className="text-sm">{new Date(row.createdAt).toLocaleDateString()}</div>}
              />
              <NormalField label="작성자" property="name" />
              <NormalField label="제목" property="title" />
              <Field
                label="상세보기"
                render={row => (
                  <button
                    className="bg-gray-100 px-4 py-2 text-sm rounded-md"
                    onClick={() => onItemClick(row.id)}>
                    상세보기
                  </button>
                )}
              />
            </Datagrid>
          )}
        </div>
        <Pagination {...pageInfo} />
      </div>
    </>
  );
};

export const NoticePage = () => (
  <ContentContainer.ContextProvider>
    <NoticePageContent />
  </ContentContainer.ContextProvider>
);
