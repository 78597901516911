import React from 'react';
import { ReactComponent as Refresh } from '../assets/svg/refresh-icon.svg';

interface TopBarProps {
  title: string;
  onClick?: () => void;
}

export const TopBar: React.FC<TopBarProps> = ({ title, onClick }) => {
  return (
    <div className="sticky w-full top-0 z-50 bg-white">
      <div className="px-4 h-14 border-b flex items-center space-x-6">
        <div className="text-xl font-semibold">{title}</div>
        {onClick && (
          <div className="flex items-center cursor-pointer" onClick={onClick}>
            <Refresh className="mr-1" />
            새로고침
          </div>
        )}
      </div>
    </div>
  );
};
