import { useAlert } from '@/components/Alert/Alert';
import { EditTopBar } from '@/components/EditTopBar';
import { Toggles } from '@/components/Toggles';
import ProductSelectForm from '@/components/form/ProductSelectForm';
import UserSelectForm from '@/components/form/UserSelectForm';
import { MemberContainer } from '@/container/member';
import { ProductSelectContainer } from '@/container/productSelect';
import DateFormat from '@constants/DateFormat';
import { ContentContainer } from '@container/content';
import { UserContainer } from '@container/user';
import { Checkbox, Select, TextArea, TextField } from '@entropyparadox/reusable-react';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router';


export const couponTypeList = [
  { name: '원', value: 'won' },
  { name: '%', value: 'percent' },
];

const CouponAddContent = () => {
  const history = useHistory();
  const { showWarningAlert, showSuccessAlert, showErrorAlert } = useAlert();
  const { createCoupon } = ContentContainer.useContext();
  const { myProfile } = UserContainer.useContext();
  const { selectedGroups } = MemberContainer.useContext();
  const { selectedProducts, selectedCategories } = ProductSelectContainer.useContext();
  const [name, setName] = useState('');
  const [discountedValue, setDiscountedValue] = useState<number>(0);
  const [discountType, setDiscountType] = useState<string>(couponTypeList[0].value);
  const [validFromAt, setValidFromAt] = useState<string | null>(null);
  const [validUntilAt, setValidUntilAt] = useState<string | null>(null);
  const [canUseMileage, setCanUseMileage] = useState(false);
  const [minimumPayAmount, setMinimumPayAmount] = useState<number | null>(null);
  const [adminNote, setAdminNote] = useState('');
  const [isActive, setIsActive] = useState(false);

  const onSaveClick = () => {
    if (!name) {
      showWarningAlert('이름을 입력해주세요.');
      return;
    }
    if (!validFromAt || !validUntilAt) {
      showWarningAlert('기간을 입력해주세요.');
      return;
    }
    if (!selectedGroups.length) {
      showWarningAlert('대상유저그룹을 입력해주세요.');
      return;
    }

    createCoupon({
      adminId: myProfile?.id,
      adminNote: adminNote,
      canUseMileage: canUseMileage,
      discountedPrice: discountType === '원' ? discountedValue : 0,
      discountedRate: discountType === '%' ? discountedValue : 0,
      isActive,
      minimumPayAmount: minimumPayAmount ? minimumPayAmount : 0,
      name,
      targetCategoryIds: selectedCategories.map(row => row.id),
      targetProductIds: selectedProducts.map(row => row.id),
      targetUserGroupIds: selectedGroups.map(row => row.id),
      validFromAt: moment(validFromAt, DateFormat.YYYYMMDDTHHmm).toDate(),
      validUntilAt: moment(validUntilAt, DateFormat.YYYYMMDDTHHmm).toDate(),
    })
      .then(() => showSuccessAlert())
      .then(() => history.push('/admin/content/coupon'))
      .catch(showErrorAlert);
  };

  return (
    <>
      <EditTopBar title="쿠폰 추가" saveClick={onSaveClick} />
      <div className="p-6">
        <div className="flex items-center space-x-3 justify-end mb-6">
          <span>사용 불가 / 가능</span>
          <Toggles value={isActive} onChange={setIsActive} />
        </div>
        <div className="grid lg:grid-cols-3 grid-cols-2 gap-6">
          <div className="space-y-4 col-start-1">
            <TextField
              label="이름"
              type="text"
              placeholder="쿠폰 이름을 입력해주세요"
              value={name}
              onChange={e => setName(e.target.value)}
            />

            <TextField
              label="기간선택"
              type="datetime-local"
              value={validFromAt || ''}
              onChange={e => {
                setValidFromAt(e.target.value);
              }}
            />
            <TextField
              type="datetime-local"
              value={validUntilAt || ''}
              onChange={e => {
                setValidUntilAt(e.target.value);
              }}
            />
            <div className="col-span-2">
              <div className="col-start-1 grid grid-cols-2 items-end gap-6">
                <TextField
                  type="number"
                  label="할인금액"
                  value={discountedValue || ''}
                  onChange={e => {
                    setDiscountedValue(Number(e.target.value));
                  }}
                />
                <Select
                  label=""
                  value={discountType}
                  onChange={e => {
                    setDiscountType(e.target.value);
                  }}>
                  {couponTypeList.map(item => (
                    <option key={item.name} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="">
              <Checkbox
                label="적립금 중복사용 가능"
                checked={canUseMileage}
                onChange={e => setCanUseMileage(e.target.checked)}
              />
            </div>
          </div>
          <div className="lg:col-start-2 space-y-4">
            <TextArea
              label="관리자메모"
              style={{ height: '200px' }}
              value={adminNote}
              onChange={e => setAdminNote(e.target.value)}
            />

            <div>
              <label className="block mb-1 text-sm text-gray-800">조건설정</label>
              <div
                className="block px-4 w-full h-12 border
                 border-gray-200 rounded-md
                 focus:ring-0 focus-within:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400">
                <input
                  type="number"
                  value={minimumPayAmount || ''}
                  onChange={e => setMinimumPayAmount(Number(e.target.value))}
                  className="border-none sm:text-sm focus:ring-0 placeholder-gray-400 h-11 w-10/12"
                />
                <span>원</span>
              </div>
            </div>

            <div className="mt-6">이상 구매시 사용가능</div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-6 mt-8">
          <UserSelectForm />
          <ProductSelectForm />
        </div>
      </div>
    </>
  );
};

export const CouponAdd = () => (
  <ContentContainer.ContextProvider>
    <MemberContainer.ContextProvider>
      <ProductSelectContainer.ContextProvider>
        <CouponAddContent />
      </ProductSelectContainer.ContextProvider>
    </MemberContainer.ContextProvider>
  </ContentContainer.ContextProvider>
);
