import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/link.min.js';
import React, { useEffect, useRef, useState } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { FroalalControllerQuery } from '@api/generated/axios-client';

interface EditorProps {
  value: string;
  onChange: (value: string) => void;
}

export const Editor: React.FC<EditorProps> = ({ value, onChange }) => {
  const { data: s3Info } = FroalalControllerQuery.useGetSignatureQuery();

  if (!s3Info) {
    return <></>;
  }

  const {
    params: { xAmzAlgorithm, xAmzCredential, xAmzDate, xAmzSignature, ...rest },
  } = s3Info;

  const convertS3Info = {
    ...s3Info,
    params: {
      ...rest,
      'x-amz-algorithm': xAmzAlgorithm,
      'x-amz-credential': xAmzCredential,
      'x-amz-date': xAmzDate,
      'x-amz-signature': xAmzSignature,
    },
  };

  return (
    <FroalaEditor
      config={{
        height: 300,
        key: process.env.REACT_APP_FROALA_KEY,
        attribution: false,
        imageAllowedTypes: ['jpeg', 'jpg', 'png'],
        imageInsertButtons: ['imageUpload'],
        fileUpload: true,
        filesManagerAllowedTypes: ['*'],
        fileAllowedTypes: ['*'],
        imageUpload: true,
        imageUploadToS3: convertS3Info,
        fileUploadToS3: convertS3Info,
        filesManagerUploadToS3: convertS3Info,
        fileMaxSize: 1024 * 1024 * 100,
        htmlExecuteScripts: true,
        imageEditButtons: ['imageDisplay', 'imageAlign', 'imageRemove', 'imageSize'],
      }}
      model={value}
      onModelChange={onChange}
    />
  );
};
