import { useAlert } from '@/components/Alert/Alert';
import { NomalModalPopup } from '@/components/Alert/NomalModalPopup';
import { IGiftCardMessage } from '@api/generated/axios-client';
import { MessageModalPopup } from '@components/Alert/MessageModalPopup';
import { Datagrid } from '@components/Datagrid';
import { Field } from '@components/Field/Field';
import { NormalField, NormalTextField } from '@components/Field/NormalField';
import Pagination from '@components/Pagination';
import { GiftCardImageContainer } from '@container/giftManage';
import { Checkbox } from '@entropyparadox/reusable-react';
import { PageHelper } from '@utils/PageHelper';
import { useCheckField } from '@utils/hooks/useCheckField';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';


export const GiftMessageTable = () => {
  const {
    giftCardMessages,
    setGiftCardGroupIdParam,
    setGiftCardMessagesPage,
    createGiftMessage,
    updateGiftMessage,
    deleteGiftMessages,
  } = GiftCardImageContainer.useContext();
  const params = useParams() as { id: string };
  const groupId = Number(params.id);
  const [alertOpen, setAlertOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const { checked, setAllChecked, isChecked, setChecked } = useCheckField();
  const [selected, setSelected] = useState<IGiftCardMessage | null>(null);
  const { showSuccessAlert, showErrorAlert } = useAlert();

  useEffect(() => {
    if (groupId || !isNaN(groupId)) {
      setGiftCardGroupIdParam(+groupId);
    }
  }, [groupId]);

  if (!giftCardMessages) return null;
  const pageInfo = PageHelper.getPageInfo({
    ...giftCardMessages,
    gotoPage: setGiftCardMessagesPage,
  });

  const clear = () => {
    clearModal();
    setAllChecked([], false);
  };

  const clearModal = () => {
    setSelected(null);
    setMessageOpen(false);
    setAlertOpen(false);
  };

  const onItemClick = (item: IGiftCardMessage) => {
    setSelected(item);
    setMessageOpen(true);
  };

  const onSaveItem = (cardGroupId: number, message: string) => {
    if (selected) {
      updateGiftMessage(selected.id, {
        message: message,
        groupId: cardGroupId,
      })
        .then(clear)
        .then(() => showSuccessAlert())
        .catch(showErrorAlert);
    } else {
      createGiftMessage({ message: message, groupId: cardGroupId })
        .then(clear)
        .then(() => showSuccessAlert())
        .catch(showErrorAlert);
    }
  };

  const onDeleteItem = async () => {
    if (!checked.length) return;

    deleteGiftMessages(checked.map(Number)).then(() => {
      clear();
      showSuccessAlert('삭제');
    });
  };

  return (
    <div>
      <MessageModalPopup
        cardGroupId={groupId}
        open={messageOpen}
        item={selected || undefined}
        onSave={onSaveItem}
        onDelete={selected ? () => setAlertOpen(true) : undefined}
        onClose={clearModal}
      />
      <NomalModalPopup
        title="정말 삭제하시겠습니까?"
        description="삭제 후 복구가 불가합니다."
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onClick={onDeleteItem}
      />
      <div className="flex items-center my-4">
        <div className="space-x-4">
          <button
            className="bg-gray-100 px-4 py-2 text-sm rounded-md"
            onClick={() => {
              setAlertOpen(true);
            }}>
            삭제
          </button>
          <button
            className="bg-black text-white px-4 py-2 text-sm rounded-md"
            onClick={() => setMessageOpen(true)}>
            메세지 추가
          </button>
        </div>
      </div>
      {giftCardMessages?.data && (
        <>
          <Datagrid data={giftCardMessages.data}>
            <Field
              label=""
              render={row => (
                <Checkbox
                  checked={isChecked(row.id || -1)}
                  onChange={e => {
                    // 전체
                    if (!row.id) {
                      setAllChecked(
                        giftCardMessages.data.map((item: any) => item.id).concat([-1]),
                        e.target.checked,
                      );
                      return;
                    }

                    setChecked(row.id, e.target.checked);
                  }}
                />
              )}
            />
            <NormalField label="ID" property="id" />
            <Field
              label="등록일"
              render={row => <div className="text-sm">{new Date(row.createdAt).toLocaleDateString()}</div>}
            />
            <NormalTextField label="텍스트" property="message" />
            <NormalField label="카테고리" property="groupName" />
            <Field
              label="상세보기"
              render={row => (
                <button
                  className="bg-gray-100 px-4 py-2 text-sm rounded-md"
                  onClick={() => onItemClick(row as IGiftCardMessage)}>
                  상세보기
                </button>
              )}
            />
          </Datagrid>
          <Pagination {...pageInfo} />
        </>
      )}
    </div>
  );
};

export const GiftMessageTablePage = () => {
  return (
    <GiftCardImageContainer.ContextProvider>
      <GiftMessageTable />
    </GiftCardImageContainer.ContextProvider>
  );
};
