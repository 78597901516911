import { AxiosClient, AxiosQuery } from '@/api';
import { createContainer } from '@/container/createContainer';
import { IRequestUpdateReviewDto, RequestUpdateReviewDto } from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import { DefaultGetOneBaseQueryParameters, DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import { useDebounceState } from '@utils/useDebounceState';
import React, { useState } from 'react';

const contentReviewHook = () => {
  const [contentReviewPage, setContentReviewPage] = useState<number>(1);
  const [selectedProductId, setSelectedProductId] = useState<number>();
  const [reviewSearchKeyword, setReviewSearchKeyword, debounceKeyword] = useDebounceState('', 500);

  const { data: contentReviews, refetch: refetchContentReviews } =
    AxiosQuery.Query.useGetManyBaseReviewControllerReviewQuery(
      {
        ...DefaultRequestCrudDto,
        page: contentReviewPage,
        limit: Const.DefaultPageLimitSize,
        filter: [
          ...DefaultRequestCrudDto.filter,
          ...(!debounceKeyword ? [] : [`user.name||$contL||%${debounceKeyword}%`]),
        ],
        join: [
          'orderProduct||id,productName',
          'orderProduct.order||orderNumber',
          'user||name',
          'userMileageHistory||mileage',
          'reviewMedias||id',
        ],
        sort: ['id,DESC'],
      },
      {
        refetchOnWindowFocus: false,
      },
    );

  const { data: productIdToReviews, refetch: refetchProductIdToReviews } =
    AxiosQuery.Query.useGetManyBaseReviewControllerReviewQuery(
      {
        ...DefaultRequestCrudDto,
        page: contentReviewPage,
        limit: Const.DefaultPageLimitSize,
        filter: [...DefaultRequestCrudDto.filter, `productId||$eq||${selectedProductId}`],
        join: [
          'orderProduct||id,productName',
          'orderProduct.order||orderNumber',
          'user||name',
          'userMileageHistory||mileage',
          'reviewMedias||id',
        ],
        sort: ['id,DESC'],
      },
      {
        enabled: !!selectedProductId,
        refetchOnWindowFocus: false,
      },
    );

  const [reviewId, setReviewId] = useState<number>();

  const { data: review, refetch: refetchReview } = AxiosQuery.Query.useGetOneBaseReviewControllerReviewQuery(
    {
      ...DefaultGetOneBaseQueryParameters,
      id: reviewId!,
      join: [
        'reviewMedias',
        'orderProduct||productName',
        'orderProduct.productOptions||name',
        'product||id',
        'product.productMedias||thumbnailUrl',
      ],
    },
    {
      enabled: !!reviewId,
      refetchOnWindowFocus: false,
    },
  );

  const updateReview = (id: number, param: IRequestUpdateReviewDto) =>
    AxiosClient.updateOneBaseReviewControllerReview(id, new RequestUpdateReviewDto(param)).then(() => {
      refetchReview();
    });

  const deleteReviews = (ids: number[]): Promise<void> =>
    Promise.all(
      ids.filter(id => id !== -1).map(id => AxiosClient.deleteOneBaseReviewControllerReview(id)),
    ).then(() => {
      refetchProductIdToReviews();
    });

  return {
    reviewSearchKeyword,
    setReviewSearchKeyword,
    contentReviews,
    setContentReviewPage,
    refetchContentReviews,
    review,
    setReviewId,
    updateReview,
    setSelectedProductId,
    productIdToReviews,
    deleteReviews,
  };
};

export const ContentReviewContainer = createContainer(contentReviewHook);
