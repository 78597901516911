import { MileageContainer } from '@/container/mileage';
import React from 'react';

export const AccumulatedSet = () => {
  const {
    baseMileageEarnRate,
    baseReviewEarnMileage,
    photoReviewEarnMileage,

    setBaseMileageEarnRate,
    setBaseReviewEarnMileage,
    setPhotoReviewEarnMileage,
  } = MileageContainer.useContext();

  return (
    <>
      <h3 className="font-semibold text-lg">기본적립금</h3>
      <div className="w-96 mt-3">
        <div>
          <label className="block mb-1 text-sm text-gray-800">기본적립금</label>
          <div
            className="block px-4 w-full h-12 border
                 border-gray-200 rounded-md
                 focus:ring-0 focus-within:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400">
            <input
              type="number"
              value={baseMileageEarnRate}
              onChange={e => setBaseMileageEarnRate(Number(e.target.value))}
              className="border-none sm:text-sm focus:ring-0 placeholder-gray-400 h-11 w-10/12"
            />
            <span>%</span>
          </div>
        </div>
      </div>

      <h3 className="font-semibold text-lg mt-10">후기적립금</h3>
      <div className="w-96 mt-3 space-y-4">
        <div>
          <label className="block mb-1 text-sm text-gray-800">기본후기</label>
          <div
            className="block px-4 w-full h-12 border
                 border-gray-200 rounded-md
                 focus:ring-0 focus-within:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400">
            <input
              type="number"
              value={baseReviewEarnMileage}
              onChange={e => setBaseReviewEarnMileage(Number(e.target.value))}
              className="border-none sm:text-sm focus:ring-0 placeholder-gray-400 h-11 w-10/12"
            />
            <span>원</span>
          </div>
        </div>
        <div>
          <label className="block mb-1 text-sm text-gray-800">포토후기</label>
          <div
            className="block px-4 w-full h-12 border
                 border-gray-200 rounded-md
                 focus:ring-0 focus-within:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400">
            <input
              type="number"
              value={photoReviewEarnMileage}
              onChange={e => setPhotoReviewEarnMileage(Number(e.target.value))}
              className="border-none sm:text-sm focus:ring-0 placeholder-gray-400 h-11 w-10/12"
            />
            <span>원</span>
          </div>
        </div>
      </div>
    </>
  );
};
