import { ReactComponent as DownIcon } from '../assets/svg/chevron-down.svg';
import '../index.css';
import { AccumulatedPage } from './AccumulatedPage';
import { AdminUserAdd } from './AdminUserAdd';
import { AdminUserDetailInfo } from './AdminUserDetailInfo';
import { AdminUserPage } from './AdminUserPage';
import { BannerAdd } from './BannerAdd';
import { CalculatePage } from './CalculatePage';
import { CouponAdd } from './CouponAdd';
import { CouponEdit } from './CouponEdit';
import { CouponPage } from './CouponPage';
import { GiftPage } from './GiftPage';
import { NoticePage } from './NoticePage';
import { OrdersCancelPage } from './OrdersCancelPage';
import { OrdersExchangePage } from './OrdersExchangePage';
import { OrdersPage } from './OrdersPage';
import { OrdersReturnPage } from './OrdersReturnPage';
import { PopupAdd } from './PopupAdd';
import { ProductAdd } from './ProductAdd';
import { ProductCategoryAdd } from './ProductCategoryAdd';
import { ProductCategoryEdit } from './ProductCategoryEdit';
import { ProductEdit } from './ProductEdit';
import { ProductPage } from './ProductPage';
import { ProductReviewList } from './ProductReviewList';
import { PushNotifyAdd } from './PushNotifyAdd';
import { PushNotifyPage } from './PushNotifyPage';
import { ReviewPage } from './ReviewPage';
import { UserDetailPage } from './UserDetailPage';
import { SideTab } from '@/components/SideTab';
import RoutePath from '@/constants/RoutePath';
import { UserContainer } from '@/container/user';
import { BannerMainPage } from '@/pages/BannerMainPage';
import { ExposureCategoryPage } from '@/pages/ExposureCategory';
import { ExposureProductPage } from '@/pages/ExposureProduct';
import { GiftManagePage } from '@/pages/GiftManage';
import { MainPopupAddPage } from '@/pages/MainPopupAdd';
import { MainPopupManagePage } from '@/pages/MainPopupManage';
import { RecommendProductPage } from '@/pages/RecommendProduct';
import { SearchKeywordsPage } from '@/pages/SearchTerm';
import { StockPage } from '@/pages/StockPage';
import { StoriesPage } from '@/pages/StoryPage';
import { TopCategoryPage } from '@/pages/TopCategory';
import { UserPage } from '@/pages/UserPage';
import React, { useState } from 'react';
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { SearchRecommendPage } from './SearchRecommend';
import { PushNotifyDetail } from './PushNotifyDetail';

const MainPage = () => {
  const { logout, myProfile } = UserContainer.useContext();
  const { pathname } = useLocation();
  const history = useHistory();
  const [isOrdersOpen, setIsOrdersOpen] = useState(false);
  const [isProductOpen, setIsProductOpen] = useState(false);
  const [isUserOpen, setIsUserOpen] = useState(false);
  const [isContentOpen, setIsContentOpen] = useState(false);
  const [isMainOpen, setIsMainOpen] = useState(false);
  const [isGiftOpen, setIsGiftOpen] = useState(false);

  if (!myProfile) return null;

  return (
    <div className="flex w-full h-screen">
      <div className="w-60 bg-gray-200 h-screen overflow-y-scroll scroll-box">
        <div className="w-full h-screen">
          <div
            className="text-2xl text-brand-gray-1 font-bold p-4 cursor-pointer"
            onClick={() => history.push('/admin')}>
            ONFU
          </div>
          <div className="mx-3 my-3 px-4 py-3 rounded-lg bg-white block">
            <span className="font-semibold">{myProfile.name}</span>
            <br />
            <p className="text-sm text-gray-500 truncate overflow-ellipsis overflow-hidden">
              {myProfile.email}
            </p>
          </div>
          {/* 주문 관리 */}
          {myProfile.hasAuthorityOrder && (
            <div
              className={
                pathname.startsWith('/admin/orders')
                  ? 'bg-brand-gray-1 text-white flex items-center px-4 py-3 text-sm mx-3 rounded-md justify-between cursor-pointer'
                  : 'text-brand-gray-1 hover:bg-gray-100 flex items-center px-4 py-3 text-sm cursor-pointer mx-3 rounded-md justify-between'
              }
              onClick={() => setIsOrdersOpen(!isOrdersOpen)}>
              주문관리
              <DownIcon className={`${isOrdersOpen ? 'transform rotate-180' : ''}`} />
            </div>
          )}
          {/* 주문관리 메뉴 */}
          {isOrdersOpen && (
            <>
              <SideTab to={RoutePath.OrderList} text="주문관리(배송관리)" />
              <SideTab to={RoutePath.OrderCancelList} text="취소관리" />
              <SideTab to={RoutePath.OrderExchangeList} text="교환관리" />
              <SideTab to={RoutePath.OrderReturnList} text="반품관리" />
            </>
          )}

          {/* 상품 관리 */}
          {myProfile.hasAuthorityProduct && (
            <div
              className={
                pathname.startsWith('/admin/product')
                  ? 'bg-brand-gray-1 text-white flex items-center px-4 py-3 text-sm mx-3 rounded-md mt-4 cursor-pointer justify-between'
                  : 'text-brand-gray-1 hover:bg-gray-100 flex items-center px-4 py-3 text-sm cursor-pointer mx-3 rounded-md mt-4 justify-between'
              }
              onClick={() => setIsProductOpen(!isProductOpen)}>
              상품관리
              <DownIcon className={`${isProductOpen ? 'transform rotate-180' : ''}`} />
            </div>
          )}

          {isProductOpen && (
            <>
              <SideTab to="/admin/product/list" text="상품관리" />
              <SideTab to="/admin/product/stock" text="재고관리" />
            </>
          )}

          {/* 회원 관리 */}
          {myProfile.hasAuthorityUser && (
            <div
              className={
                pathname.startsWith('/admin/user')
                  ? 'bg-brand-gray-1 text-white flex items-center px-4 py-3 text-sm mx-3 rounded-md mt-4 cursor-pointer justify-between'
                  : 'text-brand-gray-1 hover:bg-gray-100 flex items-center px-4 py-3 text-sm cursor-pointer mx-3 rounded-md mt-4 justify-between'
              }
              onClick={() => setIsUserOpen(!isUserOpen)}>
              회원관리
              <DownIcon className={`${isUserOpen ? 'transform rotate-180' : ''}`} />
            </div>
          )}

          {isUserOpen && (
            <>
              <SideTab to={RoutePath.UserNormalList} text="일반회원 관리" />
              <SideTab to={RoutePath.UserAdminList} text="운영진 관리" />
            </>
          )}

          {/*컨텐츠 관리*/}
          {myProfile.hasAuthorityContent && (
            <div
              className={
                pathname.startsWith('/admin/content')
                  ? 'bg-brand-gray-1 text-white flex items-center px-4 py-3 text-sm mx-3 rounded-md mt-4 cursor-pointer justify-between'
                  : 'text-brand-gray-1 hover:bg-gray-100 flex items-center px-4 py-3 text-sm cursor-pointer mx-3 rounded-md mt-4 justify-between'
              }
              onClick={() => setIsContentOpen(!isContentOpen)}>
              컨텐츠 관리
              <DownIcon className={`${isContentOpen ? 'transform rotate-180' : ''}`} />
            </div>
          )}

          {isContentOpen && (
            <>
              <SideTab to="/admin/content/accumulated" text="적립금 관리" />
              <SideTab to="/admin/content/coupon" text="쿠폰 관리" />
              <SideTab to="/admin/content/review" text="후기 관리" />
              <SideTab to="/admin/content/notice" text="공지사항 관리" />
            </>
          )}

          {/*메인 관리*/}
          {myProfile.hasAuthorityMain && (
            <div
              className={
                pathname.startsWith('/admin/main')
                  ? 'bg-brand-gray-1 text-white flex items-center px-4 py-3 text-sm mx-3 rounded-md mt-4 cursor-pointer justify-between'
                  : 'text-brand-gray-1 hover:bg-gray-100 flex items-center px-4 py-3 text-sm cursor-pointer mx-3 rounded-md mt-4 justify-between'
              }
              onClick={() => setIsMainOpen(!isMainOpen)}>
              메인 관리
              <DownIcon className={`${isMainOpen ? 'transform rotate-180' : ''}`} />
            </div>
          )}

          {isMainOpen && (
            <>
              <SideTab to="/admin/main/banner" text="메인 배너/영상 관리" />
              <SideTab to="/admin/main/category" text="최상단 카테고리 관리" />
              <SideTab to="/admin/main/story" text="스토리 관리" />
              <SideTab to="/admin/main/recommend" text="추천상품 관리" />
              <SideTab to="/admin/main/exposure/category" text="노출 카테고리 관리" />
              <SideTab to="/admin/main/exposure/product" text="노출 상품 관리" />
              <SideTab to="/admin/main/popup" text="팝업 관리" />
              <SideTab to="/admin/main/search-term" text="검색어 랭킹 관리" />
              <SideTab to="/admin/main/search-recommend" text="추천검색어 관리" />
            </>
          )}

          {/* 정산 관리 */}
          {myProfile.hasAuthoritySettle && (
            <Link
              to="/admin/calculate"
              className={
                pathname.startsWith('/admin/calculate')
                  ? 'bg-brand-gray-1 text-white flex items-center px-4 py-3 text-sm mx-3 rounded-md mt-4'
                  : 'text-brand-gray-1 hover:bg-gray-100 flex items-center px-4 py-3 text-sm cursor-pointer mx-3 rounded-md mt-4'
              }>
              정산 관리
            </Link>
          )}

          {/* 선물 관리 */}
          {myProfile.hasAuthorityContent && (
            <div
              className={
                pathname.startsWith('/admin/gift')
                  ? 'bg-brand-gray-1 text-white flex items-center px-4 py-3 text-sm mx-3 rounded-md mt-4 cursor-pointer justify-between'
                  : 'text-brand-gray-1 hover:bg-gray-100 flex items-center px-4 py-3 text-sm cursor-pointer mx-3 rounded-md mt-4 justify-between'
              }
              onClick={() => setIsGiftOpen(!isGiftOpen)}>
              선물 관리
              <DownIcon className={`${isGiftOpen ? 'transform rotate-180' : ''}`} />
            </div>
          )}

          {isGiftOpen && (
            <>
              <SideTab to="/admin/gift/manage" text="선물하기 관리" />
              <SideTab to="/admin/gift/list" text="선물리스트" />
            </>
          )}

          {/* 알림 설정 */}
          {myProfile.hasAuthorityNoti && (
            <Link
              to="/admin/push"
              className={
                pathname.startsWith('/admin/push')
                  ? 'bg-brand-gray-1 text-white flex items-center px-4 py-3 text-sm mx-3 rounded-md mt-4'
                  : 'text-brand-gray-1 hover:bg-gray-100 flex items-center px-4 py-3 text-sm cursor-pointer mx-3 rounded-md mt-4'
              }>
              알림 설정
            </Link>
          )}

          <Link
            to=""
            className="text-brand-gray-1 hover:bg-gray-100 flex items-center px-4 py-3 text-sm cursor-pointer mx-3 rounded-md mt-4"
            onClick={() => {
              logout();
              setTimeout(() => {
                window.location.href = RoutePath.Login;
                window.location.reload();
              }, 500);
            }}>
            로그아웃
          </Link>
        </div>
      </div>
      <div className="w-full h-screen overflow-y-scroll scroll-box relative">
        <Switch>
          <Route path="/admin/push/add" component={PushNotifyAdd} />
          <Route path="/admin/push/:id" component={PushNotifyDetail} />
          <Route path="/admin/push" component={PushNotifyPage} />
          <Route path="/admin/calculate" component={CalculatePage} />
          <Route path="/admin/main/category" component={TopCategoryPage} />
          <Route path="/admin/main/search-term" component={SearchKeywordsPage} />
          <Route path="/admin/main/search-recommend" component={SearchRecommendPage} />
          <Route path="/admin/main/popup/add" component={MainPopupAddPage} />
          <Route path="/admin/main/popup/edit/:id" component={MainPopupAddPage} />
          <Route path="/admin/main/popup" component={MainPopupManagePage} />
          <Route path="/admin/main/exposure/product" component={ExposureProductPage} />
          <Route path="/admin/main/exposure/category" component={ExposureCategoryPage} />
          <Route path="/admin/main/recommend" component={RecommendProductPage} />
          <Route path="/admin/main/story" component={StoriesPage} />
          <Route path="/admin/main/banner/popup/add" component={PopupAdd} />
          <Route path="/admin/main/banner/add" component={BannerAdd} />
          <Route path="/admin/main/banner" component={BannerMainPage} />
          <Route path="/admin/content/notice" component={NoticePage} />
          <Route path="/admin/content/review" component={ReviewPage} />
          <Route path="/admin/content/coupon/add" component={CouponAdd} />
          <Route path="/admin/content/coupon/edit/:id" component={CouponEdit} />
          <Route path="/admin/content/coupon" component={CouponPage} />
          <Route path="/admin/content/accumulated" component={AccumulatedPage} />
          <Route path="/admin/gift/manage" component={GiftManagePage} />
          <Route path="/admin/gift/list" component={GiftPage} />
          <Route path="/admin/user/normal/detail/:userId" component={UserDetailPage} />
          <Route path="/admin/user/normal" component={UserPage} />
          <Route path="/admin/user/admin/add" component={AdminUserAdd} />
          <Route path="/admin/user/admin/edit/:userId" component={AdminUserDetailInfo} />
          <Route path="/admin/user/admin" component={AdminUserPage} />
          <Route path={RoutePath.OrderList} component={OrdersPage} />
          <Route path={RoutePath.OrderCancelList} component={OrdersCancelPage} />
          <Route path={RoutePath.OrderReturnList} component={OrdersReturnPage} />
          <Route path={RoutePath.OrderExchangeList} component={OrdersExchangePage} />
          <Route path="/admin/product/list/:id/edit" component={ProductCategoryEdit} />
          <Route path="/admin/product/category/add" component={ProductCategoryAdd} />
          <Route path="/admin/product/review/:id" component={ProductReviewList} />
          <Route path="/admin/product/edit/:productId" component={ProductEdit} />
          <Route path="/admin/product/add" component={ProductAdd} />
          <Route path="/admin/product/stock" component={StockPage} />
          <Route path="/admin/product/list" component={ProductPage} />
        </Switch>
      </div>
    </div>
  );
};

export default MainPage;
