export function range(length: number, start = 0) {
  return Array.from({ length }, (_, i) => i + start);
}

export const getFileExtension = (url: string) => {
  return url.split('.').pop();
};

export const getFileNameInUrl = (url: string) => {
  return url.substring(url.lastIndexOf('/') + 1);
};
