import { OrderStatus, PaymentMethod, ShippingType } from '@api/generated/axios-client';

export const renderer = {
  /**
   * 휴대폰 번호에 하이픈 추가
   */
  phoneNumber: (phoneNumStr?: string) => {
    if (!phoneNumStr) {
      return '';
    }
    return phoneNumStr.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');
  },
  shippingType: (type: ShippingType) => {
    switch (type) {
      case ShippingType.Early_delivery:
        return '새벽배송';
      case ShippingType.Pickup:
        return '방문수령';
      case ShippingType.Normal_delivery:
        return '일반배송';
      case ShippingType.Quick_delivery:
        return '딜리버리';
      default:
        return '';
    }
  },
  paymentMethod: (method: PaymentMethod | string) => {
    switch (method) {
      case PaymentMethod.Card:
        return '카드결제';
      case PaymentMethod.Vbank:
        return '가상계좌';
    }
  },
  orderStatus: (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.Pending:
        return '배송대상';
      case OrderStatus.Ready:
        return '주문 완료';
      case OrderStatus.Wait:
        return '선물 수락 대기';
      case OrderStatus.Done:
        return '배송 완료';
      case OrderStatus.Will_delivery:
        return '배송준비중';
      case OrderStatus.In_delivery:
        return '배송 중';
      case OrderStatus.In_return:
        return '반송 중';
      case OrderStatus.Returned:
        return '반품';
      case OrderStatus.In_exchange:
        return '환불 중';
      case OrderStatus.Exchanged:
        return '교환';
      case OrderStatus.Canceled:
        return '취소됨';
    }
  },
};
