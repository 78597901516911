import { ProductDatagrid } from '../ProductDatagrid';
import { DailyOrderByProductDto } from '@api/generated/axios-client';
import React from 'react';

interface OrderModalPopupProps {
  open: boolean;
  onClose: () => void;
  item: DailyOrderByProductDto;
}

export const OrderModalPopup: React.FC<OrderModalPopupProps> = ({ onClose, open, item }) => {
  return (
    <div>
      {open && (
        <div
          className="fixed z-100 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onClose}>
                  <span className="sr-only">Close</span>

                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <h3 className="text-lg leading-6 font-semibold text-gray-900 mb-5" id="modal-title">
                    날짜별 상세보기
                  </h3>
                  {/* <div className="mt-2 mb-4">
                    <p className="text-sm text-gray-500">{description}</p>
                  </div> */}
                  <ProductDatagrid items={item.data} />
                </div>
              </div>

              <div className="mt-4 flex flex-row-reverse justify-center">
                {/*<Pagination {...pageInfo} />*/}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
