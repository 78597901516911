import { useAlert } from '@components/Alert/Alert';
import DateFormat from '@constants/DateFormat';
import DateValidPattern from '@constants/DateValidPattern';
import { TextField } from '@entropyparadox/reusable-react';
import moment from 'moment/moment';
import React, { useState } from 'react';

interface DateModalPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit?: (time: string) => void;
}

export const DateModalPopup: React.FC<DateModalPopupProps> = ({ onClose, open, onSubmit }) => {
  const [quickStartAt, setQuickStartAt] = useState('');
  const { showWarningAlert } = useAlert();

  const handleSubmit = () => {
    if (!DateValidPattern.HHmm.test(quickStartAt) || !moment(quickStartAt, DateFormat.HHmm).isValid()) {
      showWarningAlert('양식에 맞게 입력해주세요, ex: 08:22');
      return;
    }

    onSubmit?.(quickStartAt);
    onClose();
  };

  return (
    <div>
      {open && (
        <>
          <div
            className="fixed z-100 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"></div>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>

              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={onClose}>
                    <span className="sr-only">Close</span>

                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <div className="mt-3  sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    배송 시작시간 입력하기
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">배송시작시간 입력시 고객에게 알림톡이 전달됩니다.</p>
                  </div>
                  <div className="mt-4 mr-3">
                    <TextField
                      label="시간입력"
                      placeholder="ex)13:22"
                      value={quickStartAt}
                      onChange={e => {
                        setQuickStartAt(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="mt-4 flex flex-row-reverse">
                  <button
                    type="button"
                    className="w-3/5 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-black text-base font-medium text-white sm:text-sm mx-auto"
                    onClick={handleSubmit}>
                    입력하기
                  </button>
                  {/* <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            >
              Cancel
            </button> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
