import Pagination from '../components/Pagination';
import { Datagrid } from '@/components/Datagrid';
import { Field } from '@/components/Field/Field';
import { NormalField } from '@/components/Field/NormalField';
import { ContentContainer } from '@container/content';
import { SearchIcon, TextField } from '@entropyparadox/reusable-react';
import { PageHelper } from '@utils/PageHelper';
import React from 'react';

const AccumulatedTableContent = () => {
  const {
    setUserMileagePage,
    userMileageHistories,
    userMileageStartDt,
    userMileageEndDt,
    setUserMileageStartDt,
    setUserMileageEndDt,
  } = ContentContainer.useContext();

  if (!userMileageHistories) return null;

  const pageInfo = PageHelper.getPageInfo({
    ...userMileageHistories,
    gotoPage: setUserMileagePage,
  });

  return (
    <>
      <div className="flex space-x-4 justify-end mb-4">
        <div className="flex space-x-4 items-center">
          <div className="flex space-x-2 items-center">
            <TextField
              type="date"
              value={userMileageStartDt?.substr(0, 10)}
              onChange={e => {
                setUserMileageStartDt(e.target.value ? `${e.target.value}T00:00:00+09:00` : undefined);
              }}
            />
            <span className="flex-shrink-0">부터</span>{' '}
            <TextField
              type="date"
              value={userMileageEndDt?.substr(0, 10)}
              onChange={e => {
                setUserMileageEndDt(e.target.value ? `${e.target.value}T23:59:59+09:00` : undefined);
              }}
            />
            <div
              className="px-4 h-12 w-56 space-x-2
                 bg-gray-100 rounded-md flex justify-center items-center"
              onClick={() => {}}>
              <div className="flex-shrink-0">검색</div>
              <SearchIcon />
            </div>
          </div>
        </div>
      </div>
      {userMileageHistories?.data && (
        <Datagrid data={userMileageHistories.data}>
          <NormalField label="ID" property="id" />
          <Field
            label="작성일"
            render={row => <div className="text-sm">{new Date(row.createdAt).toLocaleDateString()}</div>}
          />
          <Field label="이름" render={row => <div className="text-sm min-w-max">{row.user.name}</div>} />
          <NormalField label="관련상품" property="message" />
          <NormalField label="관리자메모" property="adminNote" />
          <Field
            label="지급 / 차감"
            render={row =>
              row.mileage >= 0 ? (
                <div className="text-sm">지급</div>
              ) : (
                <div className="text-red-500 text-sm">차감</div>
              )
            }
          />
          <Field
            label="담당자"
            render={row => <div className="text-sm min-w-max">{row.adminUser?.name || ''}</div>}
          />
          <Field
            label="금액"
            render={row => (
              <div className="text-sm">{String(row.mileage).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</div>
            )}
          />
        </Datagrid>
      )}
      <Pagination {...pageInfo} />
    </>
  );
};

export const AccumulatedTable = () => (
  <ContentContainer.ContextProvider>
    <AccumulatedTableContent />
  </ContentContainer.ContextProvider>
);
