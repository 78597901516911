import { DateModalPopup } from '../Alert/DateModalPopup';
import { Field } from './Field';
import { ShippingType } from '@api/generated/axios-client';
import DateFormat from '@constants/DateFormat';
import { OrderContainer } from '@container/order';
import moment from 'moment';
import React, { useState } from 'react';

interface AddressFieldProps {
  label: string;
  property: string;
  mergedColumn?: boolean;
}

export const AddressField: React.FC<AddressFieldProps> = ({ label, property }) => {
  const { updateOrderProductsQuickShippingStartTime } = OrderContainer.useContext();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Field
      label="배송정보"
      render={row => (
        <div className="text-sm">
          <DateModalPopup
            onClose={() => setIsOpen(false)}
            open={isOpen}
            onSubmit={time => {
              updateOrderProductsQuickShippingStartTime(row.id, time);
            }}
          />
          {row.shippingType === ShippingType.Pickup && (
            <div className="font-semibold mb-2">
              날짜선택 {moment(row.willPickupAt).format(DateFormat.YYYYMMDD)}
            </div>
          )}

          {row.isGift && !!row.willDeliveriedAt && (
            <div className="font-semibold mb-2">
              날짜선택 {moment(row.willDeliveriedAt).format(DateFormat.YYYYMMDD)}
            </div>
          )}
          <div>
            수취인 <span className="text-gray-500 font-light">{row.receiverName}</span>
          </div>
          <div>
            전화번호{' '}
            <span className="text-gray-500 font-light">
              {row.receiverPhone?.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}
            </span>
          </div>

          {row.shippingType !== ShippingType.Pickup && (
            <>
              <div>
                주소
                <span className="text-gray-500 font-light">
                  {row.receiverAddress} {row.receiverEmail}
                </span>
              </div>
              <div>
                공동현관 <span className="text-gray-500 font-light">{row.entrancePassword}</span>
              </div>
              <div>
                요청사항 <span className="text-gray-500 font-light">{row.shippingRequest}</span>
              </div>
            </>
          )}
          {row.quickShippingStartTime && (
            <div>
              배송시간{' '}
              <span className="text-gray-500 font-light">
                {moment(row.quickShippingStartTime).format(DateFormat.HHmm)}
              </span>
            </div>
          )}
          {row.shippingType === ShippingType.Quick_delivery && (
            <button className="bg-gray-100 px-4 py-2 rounded-md mt-2" onClick={() => setIsOpen(true)}>
              배송시작시간 입력
            </button>
          )}
        </div>
      )}></Field>
  );
};
