import * as AxiosQuery from './generated/axios-client';
import instance from '@api/client';

const API = process.env.REACT_APP_NEXT_PUBLIC_API_URL || '';

AxiosQuery.setBaseUrl(API);
AxiosQuery.setAxiosFactory(() => instance);

const AxiosClient = new AxiosQuery.Client(API, instance);

export { AxiosQuery, AxiosClient };
