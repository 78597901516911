import { TopBar } from '@/components/TopBar';
import { RecommendProduct } from '@api/generated/axios-client';
import { Datagrid } from '@components/Datagrid';
import { Field } from '@components/Field/Field';
import { NormalField } from '@components/Field/NormalField';
import Pagination from '@components/Pagination';
import { RecommendProductModalPopup } from '@components/RecommendProductModalPopup';
import { MainContainer } from '@container/main';
import { PageHelper } from '@utils/PageHelper';
import React, { useState } from 'react';

export const RecommendProductContent = () => {
  const { recommendProducts, setRecommendProductPage } = MainContainer.useContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<RecommendProduct | null>(null);

  if (!recommendProducts) return null;

  const pageInfo = PageHelper.getPageInfo({
    ...recommendProducts,
    gotoPage: setRecommendProductPage,
  });

  return (
    <>
      {selected && (
        <RecommendProductModalPopup
          open={isOpen}
          item={selected}
          onClose={() => {
            setIsOpen(false);
            setSelected(null);
          }}
        />
      )}

      <TopBar title="추천상품 관리" />
      <div className="p-6">
        <div className="flex items-center my-4">
          <div className="flex space-x-4">
            <button
              className="bg-black text-white px-4 py-2 text-sm rounded-md h-11"
              onClick={() => {
                setSelected(new RecommendProduct());
                setIsOpen(true);
              }}>
              추가하기
            </button>
          </div>
        </div>
        <Datagrid data={recommendProducts.data}>
          <NormalField label="ID" property="id" />
          <Field
            label="작성일"
            render={row => (
              <div className="text-sm">
                {new Date(row.createdAt).toLocaleDateString()}
              </div>
            )}
          />
          <NormalField label="태그명" property="title" />
          <Field
            label="상세보기"
            render={row => (
              <button
                className="bg-gray-100 px-4 py-2 text-sm rounded-md"
                onClick={() => {
                  setSelected(row as RecommendProduct);
                  setIsOpen(true);
                }}>
                상세보기
              </button>
            )}
          />
        </Datagrid>
        <Pagination {...pageInfo} />
      </div>
    </>
  );
};
export const RecommendProductPage = () => (
  <MainContainer.ContextProvider>
    <RecommendProductContent />
  </MainContainer.ContextProvider>
);
