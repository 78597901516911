import { ReactComponent as Download } from '@/assets/svg/download.svg';
import { OrderContainer } from '@/container/order';
import { convertStringToEnum, FileEnum } from '@constants/FileEnum';
import { Select } from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';


interface DualSelectModalPopupProps {
  open: boolean;
  onClose: () => void;
}

const excelPostOfficeHeaders = [
  { header: '주문번호', key: 'orderNumber' },
  { header: '품목주문번호', key: 'orderProductNumber' },
  { header: '택배사', key: 'shippingCompany' },
  { header: '송장번호', key: 'shippingNumber' },
  { header: '주문자명', key: 'customerName' },
  { header: '주문자 연락처', key: 'customerPhone' },
  { header: '주문일시', key: 'orderedAt' },
  { header: '결제일시', key: 'paidAt' },
  { header: '상품명', key: 'productName' },
  { header: '옵션정보', key: 'productOptionName' },
  { header: '재고번호(SKU)', key: 'sku' },
  { header: '단가', key: 'paidAmount' },
  { header: '수량', key: 'amount' },
  { header: '상품별 금액', key: 'productPaid' },
  { header: '배송메세지', key: 'deliveryMessage' },
  { header: '수취인명', key: 'receiverName' },
  { header: '수취인 연락처', key: 'receiverPhone' },
  { header: '주소', key: 'receiverAddress' },
  { header: '상세주소', key: 'receiverAddressDetail' },
  { header: '우편번호', key: 'receiverPostcode' },
  { header: '관리자 메모', key: 'adminMemo' },
  { header: '추가 정보 입력', key: '' },
  { header: '배송비 묶음 여부', key: '' },
];

const excelTeamfreshHeaders = [
  { header: '거래처명', key: 'name' },
  { header: '발송자명', key: 'senderName' },
  { header: '실수령자명', key: 'receiverName' },
  { header: '실수령자연락처', key: 'receiverPhone' },
  { header: '주소(기본)', key: 'receiverAddress' },
  { header: '주소(상세)', key: 'receiverAddressDetail' },
  { header: '신우편번호', key: 'receiverPostcode' },
  { header: '공동현관비밀번호', key: 'entrancePassword' },
  { header: '고객요청사항', key: 'shippingRequest' },
  { header: '상품수량(BOX)', key: 'boxAmount' },
  { header: '개별문자발송(즉시)', key: 'immediateMessage' },
  { header: '고객사주문번호', key: 'orderNumber' },
  { header: '상품정보', key: 'productName' },
  { header: '자유기입1', key: '' },
  { header: '자유기입2', key: '' },
  { header: '자유기입3', key: '' },
  { header: '자유기입4', key: '' },
  { header: '자유기입5', key: '' },
  { header: '프리미엄 배송 여부', key: '' },
  { header: '심야 배송 여부', key: '' },
];

const TargetType = [
  { id: 1, name: '현재탭 전체', type: 'all' },
  { id: 2, name: '선택된 셀', type: 'selected' },
];

const FileType: { id: number; name: string; type: FileEnum }[] = [
  { id: 1, name: '우체국', type: FileEnum.POSTOFFICE },
  { id: 2, name: '팀프레시', type: FileEnum.TEAMFRESH },
  { id: 3, name: '로젠', type: FileEnum.LOGEN },
  { id: 4, name: 'CJ대한통운', type: FileEnum.CJ },
];

export const DualSelectModalPopup: React.FC<DualSelectModalPopupProps> = ({ onClose, open }) => {
  const {
    allOrders,
    viewTypeIndex,
    exportOrderByProduct,
    exportOrderByDaily,
    useCheck: { checked },
  } = OrderContainer.useContext();
  const [target, setTarget] = useState<string>(TargetType[0].type);
  const [fileType, setFileType] = useState<FileEnum>(FileType[0].type);

  if (!allOrders?.data) return null;

  const orderProducts = allOrders.data.flatMap(order =>
    order.orderProducts.map((orderProduct, i) => ({
      rowIndex: i,
      rowSpan: order.orderProducts.length,
      ...order,
      ...orderProduct,
      id: order.id,
      shippingCompany: orderProduct.shippingCompany,
      shippingNumber: orderProduct.shippingNumber,
      orderProductId: orderProduct.id,
      printedName: orderProduct.product.printedName,
      productOptionName: orderProduct.orderProductOptions[0]?.optionName || '선택안함',
      paidAmount: order.orderPayments[0]?.paidAmount || '',
      userId: order.userId,
    })),
  );

  const filterProducts = orderProducts.filter(orderProduct => {
    return checked.includes(orderProduct.id);
  });

  const postOfficeXlsxHeader = excelPostOfficeHeaders.map(header => {
    return header.header;
  });

  const teamFreshXlsxHeader = excelTeamfreshHeaders.map(header => {
    return header.header;
  });

  const downloadXlsx = (data: any[], fileType: FileEnum) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, { skipHeader: true });

    if (fileType !== FileEnum.TEAMFRESH) {
      XLSX.utils.sheet_add_aoa(ws, [postOfficeXlsxHeader]);
    } else {
      XLSX.utils.sheet_add_aoa(ws, [teamFreshXlsxHeader]);
    }

    XLSX.utils.book_append_sheet(wb, ws, '주문 내역');
    XLSX.writeFile(
      wb,
      `${
        fileType === FileEnum.POSTOFFICE
          ? '우체국'
          : fileType === FileEnum.LOGEN
          ? '로젠'
          : fileType === FileEnum.TEAMFRESH
          ? '팀프레시'
          : 'CJ대한통운'
      }_주문내역.xlsx`,
    );
  };

  return (
    <div>
      {open && (
        <div
          className="fixed z-100 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onClose}>
                  <span className="sr-only">Close</span>

                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full space-y-4">
                  <h3 className="text-lg leading-6 font-semibold text-gray-900 mb-5" id="modal-title">
                    엑셀 내보내기
                  </h3>
                  <Select label="내보내기 대상" value={target} onChange={e => setTarget(e.target.value)}>
                    {TargetType.map(item => (
                      <option key={item.id} value={item.type}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                  {viewTypeIndex === 0 && (
                    <Select
                      label="파일종류"
                      value={fileType}
                      onChange={e => {
                        setFileType(convertStringToEnum(e.target.value));
                      }}>
                      {FileType.map(item => (
                        <option key={item.id} value={item.type}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  )}
                </div>
              </div>

              <div className="mt-4 flex flex-row-reverse">
                {viewTypeIndex === 0 && allOrders && fileType !== 'TEAMFRESH' && target === 'all' && (
                  <button
                    onClick={() => {
                      downloadXlsx(
                        orderProducts.map(orderProduct => {
                          return {
                            orderNumber: orderProduct.orderNumber,
                            orderProductNumber: orderProduct.orderProductId,
                            shippingCompany:
                              fileType === 'POSTOFFICE'
                                ? '우체국'
                                : fileType === 'LOGEN'
                                ? '로젠'
                                : 'CJ대한통운',
                            shippingNumber: orderProduct.shippingNumber,
                            customerName: orderProduct.customerName,
                            customerPhone: orderProduct.customerPhone,
                            orderedAt: orderProduct.orderedAt
                              ? new Date(orderProduct.orderedAt).toLocaleDateString()
                              : '',
                            paidAt: orderProduct.createdAt
                              ? new Date(orderProduct.createdAt).toLocaleDateString()
                              : '',
                            productName: orderProduct.printedName,
                            productOptionName: orderProduct.productOptionName,
                            sku: '',
                            paidAmount: orderProduct.paidAmount,
                            amount: orderProduct.totalCount,
                            productPaid: '',
                            deliveryMessage: orderProduct.shippingRequest,
                            receiverName: orderProduct.receiverName,
                            receiverPhone: orderProduct.receiverPhone,
                            receiverAddress: orderProduct.receiverAddress,
                            receiverAddressDetail: '',
                            receiverPostcode: orderProduct.receiverPostcode,
                            adminMemo: orderProduct.adminNote,
                          };
                        }),
                        fileType,
                      );
                    }}
                    className="px-4 py-2 border-2 border-gray-400 rounded-md text-gray-700 flex items-center ">
                    다운로드
                    <Download className="ml-1 transform" />
                  </button>
                )}
                {viewTypeIndex === 0 && allOrders && fileType === 'TEAMFRESH' && target === 'all' && (
                  <button
                    onClick={() => {
                      downloadXlsx(
                        orderProducts.map(orderProduct => {
                          return {
                            name: '온푸',
                            senderName: '온푸',
                            receiverName: orderProduct.receiverName,
                            receiverPhone: orderProduct.receiverPhone,
                            receiverAddress: orderProduct.receiverAddress,
                            receiverAddressDetail: '',
                            receiverPostcode: orderProduct.receiverPostcode,
                            entrancePassword: orderProduct.entrancePassword,
                            shippingRequest: orderProduct.shippingRequest,
                            boxAmount: '',
                            immediateMessage: '',
                            orderNumber: orderProduct.orderNumber,
                            productName: orderProduct.printedName,
                          };
                        }),
                        fileType,
                      );
                    }}
                    className="px-4 py-2 border-2 border-gray-400 rounded-md text-gray-700 flex items-center ">
                    다운로드
                    <Download className="ml-1 transform" />
                  </button>
                )}
                {viewTypeIndex === 0 && allOrders && fileType !== 'TEAMFRESH' && target === 'selected' && (
                  <button
                    onClick={() => {
                      downloadXlsx(
                        filterProducts.map(orderProduct => {
                          return {
                            orderNumber: orderProduct.orderNumber,
                            orderProductNumber: orderProduct.orderProductId,
                            shippingCompany:
                              fileType === 'POSTOFFICE'
                                ? '우체국'
                                : fileType === 'LOGEN'
                                ? '로젠'
                                : 'CJ대한통운',
                            shippingNumber: orderProduct.shippingNumber,
                            customerName: orderProduct.customerName,
                            customerPhone: orderProduct.customerPhone,
                            orderedAt: orderProduct.orderedAt
                              ? new Date(orderProduct.orderedAt).toLocaleDateString()
                              : '',
                            paidAt: orderProduct.createdAt
                              ? new Date(orderProduct.createdAt).toLocaleDateString()
                              : '',
                            productName: orderProduct.printedName,
                            productOptionName: orderProduct.productOptionName,
                            sku: '',
                            paidAmount: orderProduct.paidAmount,
                            amount: orderProduct.totalCount,
                            productPaid: '',
                            deliveryMessage: orderProduct.shippingRequest,
                            receiverName: orderProduct.receiverName,
                            receiverPhone: orderProduct.receiverPhone,
                            receiverAddress: orderProduct.receiverAddress,
                            receiverAddressDetail: '',
                            receiverPostcode: orderProduct.receiverPostcode,
                            adminMemo: orderProduct.adminNote,
                          };
                        }),
                        fileType,
                      );
                    }}
                    className="px-4 py-2 border-2 border-gray-400 rounded-md text-gray-700 flex items-center ">
                    다운로드
                    <Download className="ml-1 transform" />
                  </button>
                )}
                {viewTypeIndex === 0 && allOrders && fileType === 'TEAMFRESH' && target === 'selected' && (
                  <button
                    onClick={() => {
                      downloadXlsx(
                        filterProducts.map(orderProduct => {
                          return {
                            name: '온푸',
                            senderName: '온푸',
                            receiverName: orderProduct.receiverName,
                            receiverPhone: orderProduct.receiverPhone,
                            receiverAddress: orderProduct.receiverAddress,
                            receiverAddressDetail: '',
                            receiverPostcode: orderProduct.receiverPostcode,
                            entrancePassword: orderProduct.entrancePassword,
                            shippingRequest: orderProduct.shippingRequest,
                            boxAmount: '',
                            immediateMessage: '',
                            orderNumber: orderProduct.orderNumber,
                            productName: orderProduct.printedName,
                          };
                        }),
                        fileType,
                      );
                    }}
                    className="px-4 py-2 border-2 border-gray-400 rounded-md text-gray-700 flex items-center ">
                    다운로드
                    <Download className="ml-1 transform" />
                  </button>
                )}
                {viewTypeIndex === 1 && (
                  <button
                    type="button"
                    className="px-4 py-2 border-2 border-gray-400 rounded-md text-gray-700 flex items-center "
                    onClick={exportOrderByProduct}>
                    다운로드 (품목별)
                  </button>
                )}
                {viewTypeIndex === 2 && (
                  <button
                    type="button"
                    className="px-4 py-2 border-2 border-gray-400 rounded-md text-gray-700 flex items-center "
                    onClick={exportOrderByDaily}>
                    다운로드 (날짜별)
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
