import { useAlert } from '@/components/Alert/Alert';
import { NomalModalPopup } from '@/components/Alert/NomalModalPopup';
import { EditTopBar } from '@/components/EditTopBar';
import { MemberContainer } from '@/container/member';
import {
  IRequestUpdateUserDto,
  RequestUpdateUserDto,
  Role,
  UserGroupMember,
} from '@api/generated/axios-client';
import { SelectableTagsList } from '@components/SelectableTagsList';
import RoutePath from '@constants/RoutePath';
import { TextField } from '@entropyparadox/reusable-react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

const AdminUserDetailInfoContent = () => {
  const history = useHistory();
  const { userId } = useParams() as { userId: string };
  const { setUserId, user, updateUser, deleteUsers } = MemberContainer.useContext();
  const [alertOpen, setAlertOpen] = useState(false);
  const [updateUserInfo, setUpdateUserInfo] = useState<IRequestUpdateUserDto>(new RequestUpdateUserDto());
  const [userGroupMembers, setUserGroupMembers] = useState<UserGroupMember[]>([]);
  const { showSuccessAlert, showErrorAlert } = useAlert();

  useEffect(() => {
    if (!userId) return;

    setUserId(Number(userId));
  }, [userId]);

  useEffect(() => {
    if (!user) return;

    user.termsAgreements &&
      setUpdateUserInfo({ ...user, password: undefined, isMarketingAgree: user.termsAgreements[0].isAgreed });
  }, [user]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setUpdateUserInfo({ ...updateUserInfo, [target.name]: e.target.value });
  };

  const handleSave = () => {
    if (!updateUserInfo.name) {
      alert('이름을 입력해주세요');
      return;
    }
    if (!updateUserInfo.phoneNumber) {
      alert('전화번호를 입력해주세요');
      return;
    }
    if (!userGroupMembers.filter(g => !g.deleted).length) {
      alert('1개 이상의 태그를 선택 해 주세요');
      return;
    }

    updateUser(Number(userId), { ...updateUserInfo, userGroupMembers, role: Role.ADMIN })
      .then(() => showSuccessAlert('수정'))
      .then(() => setUpdateUserInfo(new RequestUpdateUserDto()))
      .then(() => history.push(RoutePath.UserAdminList))
      .catch(showErrorAlert);
  };

  const handleDelete = () => {
    deleteUsers([Number(userId)])
      .then(() => showSuccessAlert('삭제'))
      .then(() => setUpdateUserInfo(new RequestUpdateUserDto()))
      .then(() => history.push(RoutePath.UserAdminList))
      .catch(showErrorAlert);
  };

  if (!user) return null;

  return (
    <>
      <NomalModalPopup
        title="삭제하시겠습니까?"
        description="삭제 후 복구가 불가합니다. 정말 삭제하시겠습니까?"
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onClick={handleDelete}
      />
      <EditTopBar title="운영진 상세" saveClick={handleSave} deleteClick={() => setAlertOpen(true)} />

      <div className="p-6">
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-6 2xl:w-4/6">
          <TextField
            label="이름"
            type="text"
            name="name"
            value={updateUserInfo.name || ''}
            onChange={handleChange}
          />
          <TextField label="이메일" type="email" disabled value={updateUserInfo.email || ''} />
          <TextField
            label="비밀번호"
            type="password"
            name="password"
            value={updateUserInfo.password || ''}
            onChange={handleChange}
          />
          <TextField
            label="핸드폰 번호"
            placeholder="숫자만 입력해주세요."
            type="number"
            name="phoneNumber"
            value={updateUserInfo.phoneNumber || ''}
            onChange={handleChange}
            maxLength={11}
            pattern="\d{11}"
            onWheel={e => e.currentTarget.blur()}
            onKeyPress={e => {
              if (isNaN(Number(e.key))) {
                e.preventDefault();
              }
            }}
          />
        </div>

        <h3 className="font-semibold text-xl mb-4 mt-10">태그</h3>
        <SelectableTagsList
          isAdmin
          userId={user.id}
          onChangeUserGroupMembers={ugm => setUserGroupMembers(ugm)}
        />
      </div>
    </>
  );
};

export const AdminUserDetailInfo = () => {
  return (
    <MemberContainer.ContextProvider>
      <AdminUserDetailInfoContent />
    </MemberContainer.ContextProvider>
  );
};
