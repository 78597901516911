import { AxiosClient } from '@/api';
import { useAlert } from '@/components/Alert/Alert';
import AlertType from '@/constants/AlertType';
import { RequestUpdateOrderProductDto } from '@api/generated/axios-client';
import { OrderContainer } from '@container/order';
import { Select, TextField } from '@entropyparadox/reusable-react';
import React, { useState } from 'react';


interface TrackingInputProps {
  row: any;
  disabled?: boolean;
  isEdit?: boolean;
  onSubmit?: () => void;
}

const shippingCompanyList = [
  {
    name: 'CJ대한통운',
  },
  {
    name: '우체국',
  },
  {
    name: '로젠',
  },
  {
    name: '팀프레시',
  },
];

export const TrackingInput: React.FC<TrackingInputProps> = ({ row, disabled, isEdit, onSubmit }) => {
  const { showAlert, showSuccessAlert, showErrorAlert } = useAlert();
  const [shippingNumber, setShippingNumber] = useState(row.shippingNumber);
  const [shippingCompany, setShippingCompany] = useState(row.shippingCompany);

  const { refetchOrders } = OrderContainer.useContext();

  const onSave = () => {
    if (!shippingCompany) {
      showAlert({
        type: AlertType.WARNING,
        text: '택배회사를 선택해주세요.',
      });
      return;
    }

    const body = new RequestUpdateOrderProductDto();
    body.shippingCompany = shippingCompany;
    body.shippingNumber = shippingNumber;
    AxiosClient.updateOneBaseOrderProductControllerOrderProduct(row.orderProductId, body)
      .then(() => {
        showSuccessAlert();
        refetchOrders();
        onSubmit?.();
      })
      .catch(showErrorAlert);
  };

  return (
    <div className="space-y-1 my-2">
      <Select
        placeholder="택배사 선택"
        disabled={disabled}
        defaultValue={shippingCompany}
        onChange={e => {
          setShippingCompany(e.target.value);
        }}>
        {shippingCompanyList.map(item => (
          <option key={item.name}>{item.name}</option>
        ))}
      </Select>
      <TextField
        placeholder="송장번호 입력"
        value={shippingNumber || ''}
        onChange={e => setShippingNumber(e.target.value)}
        disabled={disabled}
      />
      {isEdit ? (
        <div className="flex flex-col space-y-1">
          <button className="bg-gray-100 px-4 py-2 text-sm rounded-md w-24" disabled={disabled}>
            수정완료
          </button>
          <button className="bg-gray-100 px-4 py-2 text-sm rounded-md w-24" disabled={disabled}>
            조회
          </button>
        </div>
      ) : (
        <button className="bg-gray-100 px-4 py-2 text-sm rounded-md" disabled={disabled} onClick={onSave}>
          저장
        </button>
      )}
    </div>
  );
};
