import { AxiosQuery } from '@/api';
import Autocomplete from '@/components/form/Autocomplete';
import { ProductItem } from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import { CloseIcon, TextField } from '@entropyparadox/reusable-react';
import { DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import { useDebounceState } from '@utils/useDebounceState';
import React, { useEffect, useState } from 'react';


interface MaterialStockInputProps {
  item: ProductItem;
  onSelect: (selectedItem: ProductItem) => void;
  onDelete: (materialId?: number) => void;
}

export const MaterialStockSearchInput: React.FC<MaterialStockInputProps> = ({ item, onSelect, onDelete }) => {
  const [keyword, setKeyword, debounceKeyword] = useDebounceState('', 500);
  const [selectedItem, setSelectedItem] = useState<ProductItem>();

  const { data: searchedMaterialList } = AxiosQuery.Query.useGetManyBaseMaterialControllerMaterialQuery(
    {
      ...DefaultRequestCrudDto,
      join: ['materialCategory'],
      limit: Const.DefaultPageLimitSize,
      filter: [...DefaultRequestCrudDto.filter, `name||$cont||${debounceKeyword}`],
      sort: ['id,DESC'],
    },
    {
      enabled: !!debounceKeyword,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    setSelectedItem(item);
    setKeyword(item.material?.name || '');
  }, [item]);

  return (
    <>
      <div className="border rounded-md p-6 mt-6 flex justify-between">
        <div className="grid grid-cols-2 gap-6 w-11/12">
          <div>
            {!selectedItem?.material ? (
              <>
                <label className="block mb-1 text-sm text-gray-800">제품명</label>
                <Autocomplete
                  items={
                    searchedMaterialList?.data.map(row => ({
                      label: `${row.materialCategory.title} / ${row.name} (총 재고: ${row.stock})`,
                      value: row,
                    })) || []
                  }
                  searchKeyword={keyword}
                  onChangeKeyword={setKeyword}
                  onSelect={item => {
                    const v = new ProductItem();
                    v.stock = 1;
                    v.materialId = item.value.id;
                    v.material = item.value;
                    setSelectedItem(v);
                    onSelect(v);
                  }}
                />
              </>
            ) : (
              <TextField
                label="제품명"
                type="text"
                value={`${selectedItem.material.name} (총 재고: ${selectedItem.material.stock})`}
                disabled
              />
            )}
          </div>
          <TextField
            label="포함된 개수"
            type="number"
            value={selectedItem?.stock || ''}
            onWheel={e => e.currentTarget.blur()}
            disabled={!selectedItem}
            onChange={e => {
              if (!selectedItem) return;
              selectedItem.stock = Number(e.target.value);
              onSelect(selectedItem);
            }}
          />
        </div>
        <CloseIcon onClick={() => selectedItem && onDelete(selectedItem?.materialId)} />
      </div>
    </>
  );
};
