import Pagination from '../components/Pagination';
import { useAlert } from '@/components/Alert/Alert';
import { NomalModalPopup } from '@/components/Alert/NomalModalPopup';
import { Datagrid } from '@/components/Datagrid';
import { Field } from '@/components/Field/Field';
import { NormalField } from '@/components/Field/NormalField';
import { SearchField } from '@/components/Field/SearchField';
import { TopBar } from '@/components/TopBar';
import { useCheckField } from '@/utils/hooks/useCheckField';
import { PushNotifyContainer } from '@container/pushNotify';
import { Checkbox } from '@entropyparadox/reusable-react';
import { PageHelper } from '@utils/PageHelper';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { getUserGroupName } from './PushNotifyDetail';

export const PushNotifyContent = () => {
  const { setPushNotify, pushNotifications, deletePushNotification, keyword, setKeyword } =
    PushNotifyContainer.useContext();
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const history = useHistory();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const { checked, setAllChecked, isChecked, setChecked } = useCheckField();

  const pageInfo = PageHelper.getPageInfo({
    ...pushNotifications,
    gotoPage: setPushNotify,
  });

  useEffect(() => {
    setAllChecked([], false);
  }, [pageInfo?.pageIndex]);

  if (!pushNotifications) return null;

  const handleDelete = () => {
    deletePushNotification(checked.map(Number))
      .then(() => showSuccessAlert('삭제'))
      .then(() => setIsAlertOpen(true))
      .catch(showErrorAlert)
      .finally(clear);
  };

  const clear = () => {
    setIsAlertOpen(false);
    setAllChecked([], false);
  };

  return (
    <>
      <TopBar title="푸시알림 관리" />
      <NomalModalPopup
        title={alertTitle}
        open={isAlertOpen}
        onClose={() => {
          setIsAlertOpen(false);
        }}
        onClick={handleDelete}
      />
      <div className="p-6">
        <div className="flex justify-between items-center my-4">
          <div className="space-x-4">
            <button
              className="bg-gray-100 px-4 py-2 text-sm rounded-md"
              onClick={() => {
                if (isEmpty(checked)) {
                  alert('삭제할 푸시알림을 선택해주세요');
                  return;
                }
                setAlertTitle('정말 삭제하시겠습니까?');
                setIsAlertOpen(true);
              }}>
              삭제
            </button>
            <button
              className="bg-black text-white px-4 py-2 text-sm rounded-md"
              onClick={() => history.push('/admin/push/add')}>
              추가하기
            </button>
          </div>
          <div>
            <SearchField
              className="w-96"
              value={keyword}
              onChange={e => {
                setKeyword(e.target.value);
              }}
              autoFocus={!!keyword}
            />
          </div>
        </div>
        <div>
          <Datagrid data={pushNotifications?.data}>
            <Field
              label=""
              render={row => (
                <Checkbox
                  checked={isChecked(row.id || -1)}
                  onChange={e => {
                    // 전체
                    if (!row.id) {
                      setAllChecked(
                        pushNotifications.data.map(item => item.id).concat([-1]),
                        e.target.checked,
                      );
                      return;
                    }

                    setChecked(row.id, e.target.checked);
                  }}
                />
              )}
            />
            <NormalField label="ID" property="id" />
            <Field
              label="작성일"
              render={row => (
                <div className="text-sm">{format(new Date(row.createdAt), 'yyyy/MM/dd HH:mm')}</div>
              )}
            />
            <Field
              label="발송일"
              render={row => (
                <div className="text-sm">
                  {format(new Date(row.willSendAt || row.createdAt), 'yyyy/MM/dd HH:mm')}
                </div>
              )}
            />
            <NormalField label="타이틀" property="title" />
            <Field
              label="대상그룹"
              render={row => <div className="text-sm">{getUserGroupName(row.pushNotificationReceivers)}</div>}
            />
            <Field label="작성자" render={row => <div className="text-sm">{row?.user?.name || ''}</div>} />
            <Field
              label=" "
              render={row => (
                <button
                  className="bg-gray-100 px-4 py-2 text-sm rounded-md"
                  onClick={() => history.push(`/admin/push/${row.id}`)}>
                  상세보기
                </button>
              )}
            />
          </Datagrid>
        </div>
        <Pagination {...pageInfo} />
      </div>
    </>
  );
};

export const PushNotifyPage = () => (
  <PushNotifyContainer.ContextProvider>
    <PushNotifyContent />
  </PushNotifyContainer.ContextProvider>
);
