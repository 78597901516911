import App from './App';
import './index.css';
import { ReusableProvider } from '@entropyparadox/reusable-react';
import React from 'react';
import ReactDOM from 'react-dom';

ReactDOM.render(
  <React.StrictMode>
    <ReusableProvider>
      <App />
    </ReusableProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
