export const DefaultRequestCrudDto = {
  cache: undefined,
  fields: undefined,
  filter: ['deleted||$eq||false'],
  include_deleted: undefined,
  join: undefined,
  limit: 100000000,
  offset: undefined,
  or: undefined,
  page: 1,
  s: undefined,
  sort: undefined,
};

export const DefaultGetOneBaseQueryParameters = {
  id: 0,
  fields: undefined,
  join: undefined,
  cache: undefined,
  include_deleted: undefined,
};
