import { ReviewPopup } from '@/components/Alert/ReviewPopup';
import { SearchField } from '@/components/Field/SearchField';
import { TopBar } from '@/components/TopBar';
import { Datagrid } from '@components/Datagrid';
import { Field } from '@components/Field/Field';
import { NormalField } from '@components/Field/NormalField';
import Pagination from '@components/Pagination';
import DateFormat from '@constants/DateFormat';
import { ContentReviewContainer } from '@container/review';
import { PageHelper } from '@utils/PageHelper';
import moment from 'moment';
import React, { useState } from 'react';

export const ReviewContent = () => {
  const {
    reviewSearchKeyword,
    setReviewSearchKeyword,
    contentReviews,
    setContentReviewPage,
    refetchContentReviews,
  } = ContentReviewContainer.useContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedReviewId, setSelectedReviewId] = useState<number>();

  if (!contentReviews) return null;
  const pageInfo = PageHelper.getPageInfo({
    ...contentReviews,
    gotoPage: setContentReviewPage,
  });

  return (
    <>
      {selectedReviewId && (
        <ReviewPopup reviewId={selectedReviewId} open={isOpen} onClose={() => setIsOpen(false)} />
      )}

      <TopBar title="후기관리" />
      <div className="p-6">
        <div className="flex flex-col items-end mb-4">
          <SearchField
            className="w-96"
            value={reviewSearchKeyword}
            onChange={e => setReviewSearchKeyword(e.target.value)}
            onKeyDown={e => {
              if (e.key.toLowerCase() === 'enter') {
                refetchContentReviews();
              }
            }}
          />
        </div>
        <Datagrid data={contentReviews.data}>
          <NormalField label="ID" property="id" />
          <Field
            label="작성일"
            render={row => <div className="text-sm">{moment(row.createdAt).format(DateFormat.YYYYMMDD)}</div>}
          />
          <Field
            label="주문번호"
            render={row => <div className="text-sm"> {row?.orderProduct?.order?.orderNumber || ''} </div>}
          />
          <Field label="작성자" render={row => <div className="text-sm"> {row?.user?.name || ''} </div>} />
          <Field
            label="주문상품"
            render={row => <div className="text-sm"> {row?.orderProduct?.productName || ''} </div>}
          />
          <NormalField label="제목" property="title" />
          <Field
            label="후기유형"
            render={row => (
              <div className="text-sm">{!!row.reviewMedias?.length ? '포토후기' : '일반후기'}</div>
            )}
          />
          <Field
            label="적립금 지급"
            render={row => (
              <div className="text-sm">
                +{String(row?.userMileageHistory.mileage || 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
              </div>
            )}
          />
          <Field
            label="상세보기"
            render={row => (
              <button
                className="bg-gray-100 px-4 py-2 text-sm rounded-md"
                onClick={() => {
                  setSelectedReviewId(row.id);
                  setIsOpen(true);
                }}>
                상세보기
              </button>
            )}
          />
        </Datagrid>

        <div className="mt-4 flex flex-row-reverse justify-center">
          <Pagination {...pageInfo} />
        </div>
      </div>
    </>
  );
};

export const ReviewPage = () => {
  return (
    <ContentReviewContainer.ContextProvider>
      <ReviewContent />
    </ContentReviewContainer.ContextProvider>
  );
};
