import { useAlert } from '@/components/Alert/Alert';
import { EditTopBar } from '@/components/EditTopBar';
import { MemberContainer } from '@/container/member';
import {
  IRequestCreateUserDto,
  RequestCreateUserDto,
  Role,
  UserGroup,
  UserGroupMember,
} from '@api/generated/axios-client';
import { SelectableTagsList } from '@components/SelectableTagsList';
import RoutePath from '@constants/RoutePath';
import { TextField } from '@entropyparadox/reusable-react';
import React, { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router';

const AdminUserAddContent = () => {
  const history = useHistory();
  const { adminTags: tags, createUser } = MemberContainer.useContext();
  const [updateUserInfo, setUpdateUserInfo] = useState<IRequestCreateUserDto>(new RequestCreateUserDto());
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [userGroupMembers, setUserGroupMembers] = useState<UserGroupMember[]>([]);
  const { showSuccessAlert, showErrorAlert } = useAlert();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setUpdateUserInfo({ ...updateUserInfo, [target.name]: e.target.value });
  };

  const handleChangeChecked = (e: React.ChangeEvent<HTMLInputElement>, item: UserGroup) => {
    const target = e.target as HTMLInputElement;
    if (target.checked) {
      !userGroups.find(g => g.id === item.id) && setUserGroups([...userGroups, item]);
    } else {
      !!userGroups.find(g => g.id === item.id) && setUserGroups(userGroups.filter(g => g.id !== item.id));
    }
  };

  const handleSave = () => {
    if (!updateUserInfo.name) {
      alert('이름을 입력해주세요');
      return;
    }
    if (!updateUserInfo.email) {
      alert('이메일을 입력해주세요');
      return;
    }
    if (!updateUserInfo.password) {
      alert('패스워드를 입력해주세요');
      return;
    }
    if (!updateUserInfo.phoneNumber) {
      alert('전화번호를 입력해주세요');
      return;
    }
    if (!userGroupMembers.filter(g => !g.deleted).length) {
      alert('1개 이상의 태그를 선택 해 주세요');
      return;
    }

    createUser({
      ...updateUserInfo,
      userGroupMembers: userGroupMembers
        .filter(g => !g.deleted)
        .map(g => {
          const m = new UserGroupMember();
          m.groupId = g.groupId;
          return m;
        }),
      role: Role.ADMIN,
    })
      .then(() => showSuccessAlert('추가'))
      .then(() => setUpdateUserInfo(new RequestCreateUserDto()))
      .then(() => history.push(RoutePath.UserAdminList))
      .catch(showErrorAlert);
  };

  return (
    <>
      <EditTopBar title="운영진 상세" saveClick={handleSave} />
      <div className="p-6">
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-6 2xl:w-4/6">
          <TextField
            label="이름"
            type="text"
            name="name"
            value={updateUserInfo.name || ''}
            onChange={handleChange}
          />
          <TextField
            label="이메일"
            type="email"
            name="email"
            value={updateUserInfo.email || ''}
            onChange={handleChange}
          />
          <TextField
            label="비밀번호"
            type="password"
            name="password"
            value={updateUserInfo.password || ''}
            onChange={handleChange}
          />
          <TextField
            label="핸드폰 번호"
            placeholder="숫자만 입력해주세요."
            type="number"
            name="phoneNumber"
            value={updateUserInfo.phoneNumber || ''}
            onChange={handleChange}
            maxLength={11}
            pattern="\d{11}"
            onWheel={e => e.currentTarget.blur()}
            onKeyPress={e => {
              if (isNaN(Number(e.key))) {
                e.preventDefault();
              }
            }}
          />
        </div>

        <h3 className="font-semibold text-xl mb-4 mt-10">태그</h3>
        <SelectableTagsList isAdmin userId={0} onChangeUserGroupMembers={ugm => setUserGroupMembers(ugm)} />
      </div>
    </>
  );
};

export const AdminUserAdd = () => {
  return (
    <MemberContainer.ContextProvider>
      <AdminUserAddContent />
    </MemberContainer.ContextProvider>
  );
};
