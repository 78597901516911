import { AxiosClient, AxiosQuery } from '@/api';
import { createContainer } from '@/container/createContainer';
import {
  IRequestCreatePopupDto,
  IRequestUpdatePopupDto,
  RequestCreatePopupDto,
  RequestUpdatePopupDto,
} from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import { DefaultGetOneBaseQueryParameters, DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import { useState } from 'react';

export const mainHook = () => {
  const [popupsPage, setPopupsPage] = useState<number>(1);
  const [selectedPopupId, setSelectedPopupId] = useState<number>();

  const { data: popup, refetch: refetchPopup } = AxiosQuery.Query.useGetOneBasePopupControllerPopupQuery(
    {
      ...DefaultGetOneBaseQueryParameters,
      id: selectedPopupId || 0,
    },
    {
      enabled: !!selectedPopupId,
      refetchOnWindowFocus: false,
    },
  );

  const { data: popups, refetch: refetchPopups } = AxiosQuery.Query.useGetManyBasePopupControllerPopupQuery(
    {
      ...DefaultRequestCrudDto,
      filter: [...DefaultRequestCrudDto.filter],
      page: popupsPage,
      limit: Const.DefaultPageLimitSize,
      sort: ['sorted,DESC'],
    },
    { refetchOnWindowFocus: false },
  );

  const createPopup = (param: IRequestCreatePopupDto) =>
    AxiosClient.createOneBasePopupControllerPopup(new RequestCreatePopupDto(param)).then(() => {
      refetchPopup();
    });

  const updatePopup = (id: number, param: IRequestUpdatePopupDto) =>
    AxiosClient.updateOneBasePopupControllerPopup(id, new RequestUpdatePopupDto(param)).then(() => {
      refetchPopup();
    });

  const updatePopups = (id: number, param: IRequestUpdatePopupDto) =>
    AxiosClient.updateOneBasePopupControllerPopup(id, new RequestUpdatePopupDto(param)).then(() => {
      refetchPopups();
    });

  const deletePopup = (id: number) =>
    AxiosClient.deleteOneBasePopupControllerPopup(id).then(() => {
      refetchPopup();
    });

  return {
    setPopupsPage,
    popup,
    popups,
    createPopup,
    updatePopup,
    updatePopups,
    deletePopup,
    setSelectedPopupId,
  };
};

export const MainPopupContainer = createContainer(mainHook);
