import { GiftCardImageContainer } from '../container/giftManage';
import { useAlert } from '@/components/Alert/Alert';
import { GiftCardImage } from '@api/generated/axios-client';
import { PhotoUploadModalPopup } from '@components/Alert/PhotoUploadModalPopup';
import React, { useState } from 'react';

export const GiftPhotoManage = () => {
  const { giftCardImages, createGiftCardImage, updateGiftCardImage, deleteGiftCardImage } =
    GiftCardImageContainer.useContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<GiftCardImage | null>(null);
  const { showSuccessAlert, showErrorAlert } = useAlert();

  const clear = () => {
    setSelected(null);
    setIsOpen(false);
  };

  const onDeleteItem = () => {
    if (!selected) return;
    deleteGiftCardImage(selected.id)
      .then(clear)
      .then(() => showSuccessAlert('삭제'))
      .catch(showErrorAlert);
  };

  const onSaveItem = (cardImageUrl: string) => {
    if (selected) {
      updateGiftCardImage(selected.id, {
        name: '',
        imageUrl: cardImageUrl,
      })
        .then(clear)
        .then(() => showSuccessAlert())
        .catch(showErrorAlert);
    } else {
      createGiftCardImage({ name: '', imageUrl: cardImageUrl })
        .then(clear)
        .then(() => showSuccessAlert())
        .catch(showErrorAlert);
    }
  };

  return (
    <div>
      <PhotoUploadModalPopup
        imageUrl={selected?.imageUrl}
        open={isOpen}
        onSave={onSaveItem}
        onDelete={selected ? onDeleteItem : undefined}
        onClose={() => {
          setIsOpen(false);
          clear();
        }}
      />
      <button className="bg-black text-white px-4 py-2 text-sm rounded-md" onClick={() => setIsOpen(true)}>
        이미지 추가
      </button>
      <div className="grid 2xl:grid-cols-5 lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-6 mt-6">
        {giftCardImages?.data.map((giftCardImage: GiftCardImage) => (
          <div>
            <div className="relative pb-full rounded-md border-2 border-grey-5">
              <button
                className="bg-black opacity-60 text-white z-10 absolute right-5 top-5 rounded-md px-4 py-2"
                onClick={() => {
                  setSelected(giftCardImage);
                  setIsOpen(true);
                }}>
                수정
              </button>
              <img
                className="absolute w-full h-full rounded object-cover"
                src={giftCardImage.imageUrl}
                alt=""
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
