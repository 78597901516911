import { useAlert } from '@/components/Alert/Alert';
import {
  FileParameter,
  IRequestCreateDisplayCategoryDto,
  S3ControllerQuery,
  S3ResourceType,
} from '@api/generated/axios-client';
import { MainContainer } from '@container/main';
import { ProductCategoryContainer } from '@container/productCategory';
import { Button, TextField } from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

interface ExposureCategoryModalPopupProps {
  open: boolean;
  item: any; // DisplayCategoryDto;
  onClose: () => void;
}

export const ExposureCategoryModalPopup: React.FC<ExposureCategoryModalPopupProps> = ({
  onClose,
  item,
  open,
}) => {
  const { displayCategories, createDisplayCategories, updateDisplayCategories, deleteDisplayCategories } =
    MainContainer.useContext();
  const { categoryList } = ProductCategoryContainer.useContext();
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const [weight, setWeight] = useState(item.sorted);

  const [selectedCategoryId, setSelectedCategoryId] = useState(item.categoryId);
  const [imageUrl, setImageUrl] = useState(item.imageUrl);
  const [categoryPhoto, setCategoryPhoto] = useState<File | null | undefined>();

  useEffect(() => {
    if (categoryPhoto) {
      let fileParameter: FileParameter = {
        data: categoryPhoto,
        fileName: categoryPhoto.name,
      };
      S3ControllerQuery.Client.s3UploadFile(S3ResourceType.RecommendProduct, fileParameter).then(r =>
        setImageUrl(r.fullUrl),
      );
    }
  }, [categoryPhoto]);

  const onDelete = () => {
    if (!item?.id) return;

    deleteDisplayCategories(item.id)
      .then(() => showSuccessAlert('삭제'))
      .then(() => onClose())
      .catch(showErrorAlert);
  };

  const onSave = () => {
    if (!item.id) {
      let params: IRequestCreateDisplayCategoryDto;
      params = {
        isActive: true,
        sorted: weight,
        categoryId: selectedCategoryId,
        imageUrl: imageUrl,
      };
      createDisplayCategories(params)
        .then(() => showSuccessAlert())
        .then(() => onClose())
        .catch(showErrorAlert);
    } else {
      updateDisplayCategories(item.id, {
        isActive: true,
        sorted: weight,
        categoryId: selectedCategoryId,
        imageUrl: imageUrl,
      })
        .then(() => showSuccessAlert())
        .then(() => onClose())
        .catch(showErrorAlert);
    }
  };

  return (
    <div>
      {open && (
        <div
          className="fixed z-100 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onClose}>
                  <span className="sr-only">Close</span>

                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <h3 className="text-lg leading-6 font-semibold text-gray-900 mb-5" id="modal-title">
                    노출 카테고리 관리
                  </h3>
                  <div className="space-y-4 mb-6">
                    {/*<div className="row-span-3 col-start-1">
                      <label htmlFor="photoUpload">
                        <div className="relative pb-11/12 2xl:pb-80 rounded-md border-2 border-grey-5">
                          {categoryPhoto || imageUrl ? (
                            <>
                              <img
                                className="absolute w-full h-full rounded object-cover"
                                src={categoryPhoto ? URL.createObjectURL(categoryPhoto) : imageUrl}
                                alt=""
                              />
                              <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-3 left-3">
                                사진 수정하기
                              </div>
                            </>
                          ) : (
                            <div className="absolute w-full h-full rounded object-cover bg-white">
                              <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                                <FileUploadImg />
                                <div className="text-sm text-gray-500 text-center pt-1">
                                  이미지를 업로드해주세요.
                                  <br />
                                  (최대 1장)
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </label>
                      <input
                        type="file"
                        id="photoUpload"
                        className="hidden"
                        accept="image/*"
                        onChange={e => {
                          e.target.validity.valid && setCategoryPhoto(e.target.files?.item(0));
                        }}
                      />
                    </div>*/}
                    <div>
                      <label className="block mb-1 text-sm text-gray-800">카테고리</label>
                      <AsyncSelect
                        placeholder="카테고리 검색"
                        getOptionLabel={data => data.title}
                        getOptionValue={data => String(data.id)}
                        loadOptions={(inputValue: string, callback) => {
                          callback(
                            categoryList?.data
                              .filter(c => !displayCategories?.data.find(dc => dc.categoryId === c.id))
                              .filter(c => c.title.indexOf(inputValue) > -1) || [],
                          );
                        }}
                        defaultOptions={categoryList?.data.filter(
                          c => !displayCategories?.data.find(dc => dc.categoryId === c.id),
                        )}
                        value={categoryList?.data.find(row => String(row.id) === String(selectedCategoryId))}
                        isDisabled={item.id ? true : false}
                        onChange={e => {
                          setSelectedCategoryId(e?.id);
                        }}
                      />
                    </div>
                    <TextField
                      label="노출우선순위"
                      value={weight}
                      onChange={e => setWeight(Number(e.target.value))}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-32">{/* 셀렉트 보여주는 영역 */}</div>
              <div className="flex mt-4 justify-center space-x-4">
                <div className="flex space-x-3 mt-4 justify-center">
                  <div className="flex flex-col w-56">
                    {!!item.id && <Button text="삭제하기" onClick={onDelete} variant="outlined" />}
                  </div>
                  <div className="flex flex-col w-56">
                    <Button text="저장하기" onClick={onSave} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
