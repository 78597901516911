import { useAlert } from '@/components/Alert/Alert';
import { EditTopBar } from '@/components/EditTopBar';
import { Editor } from '@/components/Editor';
import { MaterialStockSearchInput } from '@/components/MaterialStockSearchInput';
import { Toggles } from '@/components/Toggles';
import { MileageContainer } from '@/container/mileage';
import {
  Category,
  CategoryProduct,
  IRequestCreateProductDto,
  ProductItem,
  ProductMedia,
  RequestCreateProductDto,
} from '@api/generated/axios-client';
import FileUpload from '@components/Input/FileUpload';
import { ProductOptionGroupSection } from '@components/ProductOptionGroupSection';
import { Select } from '@components/Select';
import { Const } from '@constants/Const';
import { ProductCategoryContainer } from '@container/productCategory';
import { Button, Checkbox, Divider, FlexWrap, TextField } from '@entropyparadox/reusable-react';
import { FilePondFile } from 'filepond';
import { debounce } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { DELIVERY_AMOUNT } from '@constants/deliveryAmount';

export const ProductAdd = () => {
  const history = useHistory();
  const { baseMileageEarnRate } = MileageContainer.useContext();
  const { categoryList, createProduct } = ProductCategoryContainer.useContext();

  const [isDiscountRate, setIsDiscountRate] = useState(false);

  const [updateProductValue, setUpdateProductValue] = useState<IRequestCreateProductDto>(
    new RequestCreateProductDto(),
  );

  const [mileageType, setMileageType] = useState<'원' | '%'>('%');
  const [files, setFiles] = useState<FilePondFile[]>([]);

  const { showSuccessAlert, showErrorAlert } = useAlert();

  useEffect(() => {
    updateProductValue.productItems = [new ProductItem()];
    updateProductValue.availableWhenOutOfStock = false;
    updateProductValue.canUseMileage = false;
    updateProductValue.canUseCoupon = false;
    updateProductValue.isHidden = false;
    updateProductValue.isEarlyDelivery = false;
    updateProductValue.isGift = false;
    updateProductValue.isPickup = false;
    updateProductValue.showCategoryNotice = false;
    updateProductValue.showMessageNotice = false;
    updateProductValue.useDefaultMileage = true;
    updateProductValue.baseDeliveryAmount = DELIVERY_AMOUNT.NORMAL;
    updateProductValue.localDeliveryAmount = DELIVERY_AMOUNT.BACK_COUNTRY;
    updateProductValue.freeDeliveryInAmount = DELIVERY_AMOUNT.FREE_DELIVERY_UP;
    updateProductValue.changeDeliveryAmount = DELIVERY_AMOUNT.CHANGE;
    updateProductValue.refundDeliveryAmount = DELIVERY_AMOUNT.RETURN;
    updateProductValue.baseEarlyDeliveryAmount = DELIVERY_AMOUNT.NORMAL;
    updateProductValue.localEarlyDeliveryAmount = DELIVERY_AMOUNT.BACK_COUNTRY;
    updateProductValue.freeEarlyDeliveryInAmount = DELIVERY_AMOUNT.FREE_DELIVERY_UP;
    updateProductValue.changeEarlyDeliveryAmount = DELIVERY_AMOUNT.CHANGE;
    updateProductValue.refundEarlyDeliveryAmount = DELIVERY_AMOUNT.RETURN;
    updateProductValue.baseGiftDeliveryAmount = DELIVERY_AMOUNT.NORMAL;
    updateProductValue.localGiftDeliveryAmount = DELIVERY_AMOUNT.BACK_COUNTRY;
    updateProductValue.freeGiftDeliveryInAmount = DELIVERY_AMOUNT.FREE_DELIVERY_UP;
    updateProductValue.changeGiftDeliveryAmount = DELIVERY_AMOUNT.CHANGE;
    updateProductValue.refundGiftDeliveryAmount = DELIVERY_AMOUNT.RETURN;
    setUpdateProductValue({ ...updateProductValue });
  }, []);

  const onSubmit = () => {
    if (!updateProductValue) return;

    if (
      updateProductValue.productOptionGroups?.length &&
      updateProductValue.productOptionGroups.find(i => !i.productOptions?.length)
    ) {
      alert('상품 옵션 상세가 1건 이상 필요합니다');
      return;
    }

    if (
      updateProductValue.productOptionGroups?.find(i => i.productOptions?.find(o => !o.name || !o.extraPrice))
    ) {
      alert('상품 옵션에 이름과 가격정보를 입력해주세요');
      return;
    }

    if (!updateProductValue.limitSalesCount) {
      alert('총 판매수량 제한 갯수를 입력해주세요');
      return;
    }

    if (
      !updateProductValue.packageComponents ||
      !updateProductValue.packageWeight ||
      !updateProductValue.packageUnit
    ) {
      alert('상품 구성 정보(중량 / 용량, 구성요소등, 포장)를 입력해주세요.');
      return;
    }

    if (!updateProductValue.productDetail) {
      alert('상품 내용을 입력해주세요.');
      return;
    }
    updateProductValue.productMedias = files.map((file, index) => {
      const media = new ProductMedia();
      media.sorted = index;
      media.thumbnailUrl = file.serverId as string;
      media.originalUrl = file.serverId as string;
      return media;
    });

    if (updateProductValue.productMedias.length === 0) {
      alert('상품 이미지를 등록해주세요.');
      return;
    }

    createProduct({ ...updateProductValue, isActive: true })
      .then(() => showSuccessAlert())
      .then(() => history.push('/admin/product/list'))
      .catch(showErrorAlert);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!updateProductValue) return;

    const target = e.target as HTMLInputElement;
    setUpdateProductValue({ ...updateProductValue, [target.name]: e.target.value });
  };

  useEffect(() => {
    const discountedPrice = !isDiscountRate
      ? updateProductValue.price - (updateProductValue.discountPrice || 0)
      : updateProductValue.price - (updateProductValue.price * (updateProductValue.discountRate || 0)) / 100;
    setUpdateProductValue({
      ...updateProductValue,
      discountedPrice: isNaN(discountedPrice) ? updateProductValue.price : discountedPrice,
    });
  }, [updateProductValue.price, updateProductValue.discountRate, updateProductValue.discountPrice]);

  const handleChangeChecked = (e: ChangeEvent<HTMLInputElement>) => {
    if (!updateProductValue) return;

    const target = e.target as HTMLInputElement;
    setUpdateProductValue({ ...updateProductValue, [target.name]: e.target.checked });
  };

  const handleChangeCategoryChecked = (e: React.ChangeEvent<HTMLInputElement>, item: Category) => {
    if (!updateProductValue) return;
    const checked = e.target.checked;

    const getCategoryProduct = (categoryId: number) => {
      const data = new CategoryProduct();
      data.isActive = true;
      data.categoryId = categoryId;
      data.isQuick = categoryId === Const.QuickDeliveryCategoryId;
      return data;
    };

    const newCategoryProducts =
      [item, ...(item.subCategories || [])].map(i => {
        const categoryProduct =
          updateProductValue?.categoryProducts?.find(cp => cp.categoryId === i.id) ||
          getCategoryProduct(i.id);

        categoryProduct.deleted = !checked;
        categoryProduct.deletedAt = checked ? undefined : categoryProduct.deletedAt || new Date();

        return categoryProduct;
      }) || [];

    const categoryProducts = [
      ...(updateProductValue.categoryProducts?.filter(
        i => !newCategoryProducts.find(n => n.categoryId === i.categoryId),
      ) || []),
      ...newCategoryProducts,
    ];

    // 모든 서브카테고리가 체크되면 부모카테고리도 체크 처리
    if (item.categoryId) {
      let parentCategory = updateProductValue?.categoryProducts?.find(
        cp => cp.categoryId === item.categoryId,
      );

      if (!checked) {
        if (parentCategory && !parentCategory.deleted) {
          parentCategory.deleted = true;
          parentCategory.deletedAt = new Date();
        }
      } else {
        const foundSubCategories = categoryList?.data.find(i => i.id === item.categoryId)?.subCategories;

        if (
          foundSubCategories?.length ===
          categoryProducts.filter(i => !i.deleted && !!foundSubCategories?.find(sc => sc.id === i.categoryId))
            .length
        ) {
          if (!parentCategory) {
            parentCategory = getCategoryProduct(item.categoryId);
            categoryProducts.push(parentCategory);
          }
          parentCategory.deleted = false;
          parentCategory.deletedAt = undefined;
        }
      }
    }

    setUpdateProductValue({
      ...updateProductValue,
      categoryProducts,
    });
  };

  return (
    <>
      <EditTopBar title="상품추가" saveClick={debounce(onSubmit, 800)} />

      <div className="p-6">
        <div className="flex space-x-3 justify-end mb-6">
          <div className="flex space-x-3 ">
            <span>수동 품절처리</span>
            <Toggles
              value={updateProductValue.isSoldout}
              onChange={isSoldout => setUpdateProductValue({ ...updateProductValue, isSoldout })}
            />
          </div>
          <div className="flex space-x-3 ">
            <span>상품 숨김처리</span>
            <Toggles
              value={updateProductValue.isHidden}
              onChange={isHidden => setUpdateProductValue({ ...updateProductValue, isHidden })}
            />
          </div>
        </div>
        {/* 이미지 필드 */}
        <FileUpload
          setFiles={setFiles}
          placeholder={`파일을 드래그 하거나 클릭해서 업로드 해주세요. \n사이즈 가이드 : 448px * 448px / 1mb 이하`}
        />
      </div>
      <Divider />

      {/* 카테고리 설정 */}
      <div className="space-y-4 p-6">
        <h3 className="font-semibold text-xl mb-4">카테고리 설정*</h3>
        {categoryList?.data.map(item => {
          if (item.id === Const.QuickDeliveryCategoryId) return <></>;
          return (
            <div key={item.id}>
              <Checkbox
                label={item.title}
                checked={
                  !!updateProductValue?.categoryProducts
                    ?.filter(i => !i.deleted)
                    .find(i => i.categoryId === item.id)
                }
                onChange={e => handleChangeCategoryChecked(e, item)}
              />
              {!!item.subCategories?.length && (
                <div className="flex space-x-4 ml-12">
                  {item.subCategories?.map(subItem => (
                    <Checkbox
                      key={subItem.id}
                      label={subItem.title}
                      checked={
                        !!updateProductValue?.categoryProducts
                          ?.filter(i => !i.deleted)
                          .find(i => i.categoryId === subItem.id)
                      }
                      onChange={e => handleChangeCategoryChecked(e, subItem)}
                    />
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <Divider />
      {/* 공통안내사항 설정 */}
      <div className="p-6 2xl:w-4/6 ">
        <h3 className="font-semibold text-xl mb-4">공통안내사항 적용</h3>
        <div className="grid sm:grid-cols-3 grid-cols-2 gap-6 ">
          <Select
            label="공통안내사항 적용할 카테고리 선택"
            placeholder="카테고리 선택"
            value={updateProductValue.selectedInformationCategoryId}
            onChange={e => {
              setUpdateProductValue({
                ...updateProductValue,
                selectedInformationCategoryId: Number(e.target.value),
              });
            }}>
            <option>선택안함</option>
            {categoryList?.data.map(item => (
              <option key={item.id} value={item.id}>
                {item.title}
              </option>
            ))}
          </Select>
        </div>
      </div>
      <Divider />

      {/*  상품 상세 */}

      <div className="p-6">
        <h3 className="font-semibold text-xl mb-4">상품정보*</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-6  2xl:w-4/6">
          <TextField
            label="상품명*"
            type="text"
            name="name"
            value={updateProductValue.name}
            onChange={handleChange}
          />
          <TextField
            label="송장출력용 상품명*"
            type="text"
            name="printedName"
            value={updateProductValue.printedName}
            onChange={handleChange}
          />
          <TextField
            label="구성요소*"
            type="text"
            name="packageComponents"
            value={updateProductValue.packageComponents}
            onChange={handleChange}
          />
          <TextField
            label="원산지*"
            type="text"
            name="origin"
            value={updateProductValue.origin}
            onChange={handleChange}
          />
          <TextField
            label="중량 / 용량*"
            type="text"
            name="packageWeight"
            value={updateProductValue.packageWeight}
            onChange={handleChange}
          />
          <TextField
            label="상품 포장*"
            type="text"
            name="packageUnit"
            value={updateProductValue.packageUnit}
            onChange={handleChange}
          />

          <div className="sm:col-span-3 col-span-2">
            <h3 className="font-semibold text-xl mb-4 mt-10">임시텍스트 (선택)</h3>
            <div className="mb-4">
              <Checkbox
                label="노출 (선택)"
                name="showMessageNotice"
                checked={updateProductValue.showMessageNotice}
                onChange={handleChangeChecked}
              />
            </div>
            <TextField
              label="텍스트입력 (선택)"
              type="text"
              name="messageWhenNotice"
              value={updateProductValue.messageWhenNotice}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <Divider />

      {/* 가격 */}
      <div className="p-6">
        <h3 className="font-semibold text-xl mb-4">가격*</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-6  2xl:w-4/6">
          <div>
            <label className="block mb-1 text-sm text-gray-800">정가(원)*</label>
            <div
              className="block px-4 w-full h-12 border
                 border-gray-200 rounded-md
                 focus:ring-0 focus-within:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400">
              <input
                type="text"
                name="price"
                value={updateProductValue.price}
                onChange={handleChange}
                className="border-none sm:text-sm focus:ring-0 placeholder-gray-400 h-11 w-10/12"
              />
              <span>원</span>
            </div>
          </div>
          <div>
            <label className="block mb-1 text-sm text-gray-800">
              {!isDiscountRate ? '할인금액(원)*' : '할인율(%)*'}
            </label>
            <div
              className="block px-4 w-full h-12 border
                 border-gray-200 rounded-md
                 focus:ring-0 focus-within:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400">
              {!isDiscountRate ? (
                <input
                  type="number"
                  name="discountPrice"
                  onWheel={e => e.currentTarget.blur()}
                  value={updateProductValue.discountPrice}
                  onChange={handleChange}
                  className="border-none sm:text-sm focus:ring-0 placeholder-gray-400 h-11 w-10/12"
                />
              ) : (
                <input
                  type="number"
                  name="discountRate"
                  onWheel={e => e.currentTarget.blur()}
                  value={updateProductValue.discountRate}
                  onChange={handleChange}
                  className="border-none sm:text-sm focus:ring-0 placeholder-gray-400 h-11 w-10/12"
                />
              )}
              <span> {!isDiscountRate ? '원' : '%'}</span>
            </div>
            <div className="text-sm text-blue-600">
              <button
                onClick={() => {
                  setIsDiscountRate(!isDiscountRate);
                  setUpdateProductValue({
                    ...updateProductValue,
                    discountPrice: 0,
                    discountRate: 0,
                    discountedPrice: updateProductValue.price,
                  });
                }}>
                전환 → %
              </button>
            </div>
          </div>
          <div>
            <label className="block mb-1 text-sm text-gray-800">할인율 적용시 할인가(실판매가)</label>
            <div
              className="block px-4 w-full h-12 bg-gray-100 border
                 border-gray-200 rounded-md
                 focus:ring-0 focus-within:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400">
              <input
                value={updateProductValue.discountedPrice || updateProductValue.price}
                disabled
                className="border-none sm:text-sm focus:ring-0 placeholder-gray-400 h-11 w-10/12"
              />
              <span>원</span>
            </div>
          </div>

          <div className="col-start-1">
            <FlexWrap>
              <div className="pr-6">
                <Checkbox
                  label="적립금사용가능 (선택)"
                  name="canUseMileage"
                  checked={updateProductValue.canUseMileage}
                  onChange={handleChangeChecked}
                />
              </div>
              <div className="pr-6">
                <Checkbox
                  label="쿠폰사용가능 (선택)"
                  name="canUseCoupon"
                  checked={updateProductValue.canUseCoupon}
                  onChange={handleChangeChecked}
                />
              </div>
            </FlexWrap>
          </div>
          <div className="col-start-1 grid grid-cols-2 items-end gap-6">
            {!updateProductValue.useDefaultMileage && mileageType === '원' && (
              <TextField
                type="number"
                label="적립금*"
                name="mileageAmount"
                value={updateProductValue.mileageAmount || ''}
                disabled={updateProductValue.useDefaultMileage}
                onWheel={e => e.currentTarget.blur()}
                onChange={e => {
                  setUpdateProductValue({
                    ...updateProductValue,
                    mileageAmount: Number(e.target.value),
                    mileageRate: undefined,
                  });
                }}
              />
            )}
            {(updateProductValue.useDefaultMileage || mileageType === '%') && (
              <TextField
                type="number"
                label="적립금*"
                name="mileageRate"
                value={
                  updateProductValue.useDefaultMileage
                    ? baseMileageEarnRate
                    : updateProductValue.mileageRate || ''
                }
                disabled={updateProductValue.useDefaultMileage}
                onWheel={e => e.currentTarget.blur()}
                onChange={e => {
                  setUpdateProductValue({
                    ...updateProductValue,
                    mileageRate: Number(e.target.value),
                    mileageAmount: undefined,
                  });
                }}
              />
            )}
            <Select
              value={updateProductValue.useDefaultMileage ? '%' : mileageType}
              disabled={updateProductValue.useDefaultMileage}
              onChange={e => {
                setMileageType(e.target.value as any);
              }}>
              <option>원</option>
              <option>%</option>
            </Select>
            <div>
              <Checkbox
                label="기본 적립금 사용"
                name="useDefaultMileage"
                checked={updateProductValue.useDefaultMileage}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      {/* 판매수량 설정 */}
      <div className="p-6 2xl:w-4/6">
        <h3 className="font-semibold text-xl mb-4">판매수량 설정*</h3>
        <div className="grid sm:grid-cols-3 grid-cols-2 gap-6 ">
          <TextField
            label="총 판매수량 제한 갯수*"
            type="number"
            name="limitSalesCount"
            onWheel={e => e.currentTarget.blur()}
            value={updateProductValue.limitSalesCount}
            onChange={handleChange}
          />
          <Select
            label="재고가 없을시 처리 방법 선택*"
            placeholder="처리방법 선택"
            value={updateProductValue.availableWhenOutOfStock ? 2 : 1}
            onChange={e =>
              setUpdateProductValue({
                ...updateProductValue,
                availableWhenOutOfStock: Number(e.target.value) === 2,
              })
            }>
            {[
              { id: 1, name: '품절처리' },
              { id: 2, name: '코멘트처리' },
            ].map(item => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
          <TextField
            label="코멘트 처리시"
            type="text"
            placeholder="코멘트를 입력해주세요"
            name="messageWhenOutOfStock"
            value={updateProductValue.messageWhenOutOfStock}
            onChange={handleChange}
          />
        </div>
        <h3 className="font-semibold text-xl mb-4 mt-6">제품 차감 설정 (상품에 포함되는 제품 설정)(선택)</h3>
        {updateProductValue.productItems
          ?.filter(i => !i.deletedAt)
          .map((item, i) => (
            <MaterialStockSearchInput
              key={item.materialId}
              item={item}
              onSelect={(selectedItem: ProductItem) => {
                const newProductItems = updateProductValue.productItems!.slice();
                newProductItems[i] = selectedItem;
                setUpdateProductValue({
                  ...updateProductValue,
                  productItems: newProductItems,
                });
              }}
              onDelete={(materialId?: number) => {
                if (!materialId) {
                  updateProductValue.productItems!.splice(i, 1);
                  setUpdateProductValue({ ...updateProductValue });
                  return;
                }
                setUpdateProductValue({
                  ...updateProductValue,
                  productItems: updateProductValue
                    .productItems!.slice()
                    .filter(v => v.materialId !== materialId),
                });
              }}
            />
          ))}
        <div
          className="flex justify-center cursor-pointer mt-10"
          onClick={() =>
            setUpdateProductValue({
              ...updateProductValue,
              productItems: [...updateProductValue.productItems!, new ProductItem()],
            })
          }>
          + 재고 추가하기
        </div>
      </div>
      <Divider />

      {/* 내용 */}
      <div className="p-6 2xl:w-4/6 ">
        <h3 className="font-semibold text-xl mb-4">
          내용* <span className="text-gray-500 text-base font-normal">(사이즈 가이드 : 가로 448px)</span>
        </h3>
        {/* <div className="mb-4">
          <Checkbox
            label="공통안내사항 노출하기"
            name="showCategoryNotice"
            checked={updateProductValue.showCategoryNotice}
            onChange={handleChangeChecked}
          />
        </div> */}
        <div className="bg-gray-100 w-full h-96">
          <Editor
            value={updateProductValue.productDetail || ''}
            onChange={value => setUpdateProductValue({ ...updateProductValue, productDetail: value })}
          />
        </div>
      </div>
      <Divider />

      <div className="p-6 2xl:w-4/6 ">
        <h3 className="font-semibold text-xl mb-10">배송 및 택배*</h3>
        <div className="grid lg:grid-cols-6 gap-6 my-6 sm:grid-cols-4 grid-cols-2">
          <Checkbox
            label="일반배송*"
            name="isDelivery"
            checked={updateProductValue.isDelivery}
            onChange={handleChangeChecked}
          />
          <div className="col-start-1">
            <TextField
              label="기본 배송비*"
              type="number"
              name="baseDeliveryAmount"
              onWheel={e => e.currentTarget.blur()}
              value={updateProductValue.baseDeliveryAmount}
              disabled
            />
          </div>
          <TextField
            label="제주/산간 지역 배송비*"
            type="number"
            name="localDeliveryAmount"
            onWheel={e => e.currentTarget.blur()}
            value={updateProductValue.localDeliveryAmount}
            disabled
          />
          <TextField
            label="구매금액 합계*"
            type="number"
            name="freeDeliveryInAmount"
            onWheel={e => e.currentTarget.blur()}
            value={updateProductValue.freeDeliveryInAmount}
            disabled
          />
          <div className="flex items-end space-x-2 text-sm">
            <span>이상 구매시 무료 배송</span>
          </div>
          <TextField
            label="교환 배송비*"
            type="number"
            name="changeDeliveryAmount"
            onWheel={e => e.currentTarget.blur()}
            value={updateProductValue.changeDeliveryAmount}
            disabled
          />
          <TextField
            label="반품 배송비*"
            type="number"
            name="refundDeliveryAmount"
            onWheel={e => e.currentTarget.blur()}
            value={updateProductValue.refundDeliveryAmount}
            disabled
          />
        </div>
        <div className="w-full h-0.5 bg-gray-100"></div>
        <div className="grid lg:grid-cols-6 gap-6 my-6 sm:grid-cols-4 grid-cols-2">
          <Checkbox
            label="새벽배송"
            name="isEarlyDelivery"
            checked={updateProductValue.isEarlyDelivery}
            onChange={handleChangeChecked}
          />
          <div className="col-start-1">
            <TextField
              label="기본 배송비*"
              type="number"
              name="baseEarlyDeliveryAmount"
              onWheel={e => e.currentTarget.blur()}
              value={updateProductValue.baseEarlyDeliveryAmount}
              disabled
            />
          </div>
          <TextField
            label="제주/산간 지역 배송비*"
            type="number"
            name="localEarlyDeliveryAmount"
            onWheel={e => e.currentTarget.blur()}
            value={updateProductValue.localEarlyDeliveryAmount}
            disabled
          />
          <TextField
            label="구매금액 합계*"
            type="number"
            name="freeEarlyDeliveryInAmount"
            onWheel={e => e.currentTarget.blur()}
            value={updateProductValue.freeEarlyDeliveryInAmount}
            disabled
          />
          <div className="flex items-end space-x-2 text-sm">
            <span>이상 구매시 무료 배송</span>
          </div>
          <TextField
            label="교환 배송비*"
            type="number"
            name="changeEarlyDeliveryAmount"
            onWheel={e => e.currentTarget.blur()}
            value={updateProductValue.changeEarlyDeliveryAmount}
            disabled
          />
          <TextField
            label="반품 배송비*"
            type="number"
            name="refundEarlyDeliveryAmount"
            onWheel={e => e.currentTarget.blur()}
            value={updateProductValue.refundEarlyDeliveryAmount}
            disabled
          />
        </div>
        <div className="w-full h-0.5 bg-gray-100"></div>
        <div className="grid lg:grid-cols-6 gap-6 my-6 sm:grid-cols-4 grid-cols-2">
          <Checkbox
            label="선물하기"
            name="isGift"
            checked={updateProductValue.isGift}
            onChange={handleChangeChecked}
          />
          <div className="col-start-1">
            <TextField
              label="기본 배송비*"
              type="number"
              name="baseGiftDeliveryAmount"
              onWheel={e => e.currentTarget.blur()}
              value={updateProductValue.baseGiftDeliveryAmount}
              disabled
            />
          </div>
          <TextField
            label="제주/산간 지역 배송비*"
            type="number"
            name="localGiftDeliveryAmount"
            onWheel={e => e.currentTarget.blur()}
            value={updateProductValue.localGiftDeliveryAmount}
            disabled
          />
          <TextField
            label="구매금액 합계*"
            type="number"
            name="freeGiftDeliveryInAmount"
            onWheel={e => e.currentTarget.blur()}
            value={updateProductValue.freeGiftDeliveryInAmount}
            disabled
          />
          <div className="flex items-end space-x-2 text-sm">
            <span>이상 구매시 무료 배송</span>
          </div>
          <TextField
            label="교환 배송비*"
            type="number"
            name="changeGiftDeliveryAmount"
            onWheel={e => e.currentTarget.blur()}
            value={updateProductValue.changeGiftDeliveryAmount}
            disabled
          />
          <TextField
            label="반품 배송비*"
            type="number"
            name="refundGiftDeliveryAmount"
            onWheel={e => e.currentTarget.blur()}
            value={updateProductValue.refundGiftDeliveryAmount}
            disabled
          />
          <Select
            label="배송가능 기간*"
            placeholder="배송가능 기간 선택"
            value={updateProductValue.giftDeliveryAvailableDays || 0}
            onChange={e =>
              setUpdateProductValue({
                ...updateProductValue,
                giftDeliveryAvailableDays: Number(e.target.value),
              })
            }>
            {Array.from({ length: 10 }, (_, i) => 1 + i + '일').map(item => (
              <option key={item} value={item.replace(/[^0-9]/, '')}>
                {item}
              </option>
            ))}
          </Select>
          <div className="flex items-end space-x-2 text-sm">
            <span>후 까지</span>
          </div>
        </div>
        <div className="w-full h-0.5 bg-gray-100"></div>
        {/* <div className="grid lg:grid-cols-6 gap-6 my-6 sm:grid-cols-4 grid-cols-2">
          <Checkbox
            label="매장픽업"
            name="isPickup"
            checked={updateProductValue.isPickup}
            onChange={handleChangeChecked}
          />
          <div className="col-start-1">
            <Select
              label="배송가능 기간*"
              placeholder="기간 선택"
              value={updateProductValue.pickupAvailableDays || 0}
              onChange={e =>
                setUpdateProductValue({
                  ...updateProductValue,
                  pickupAvailableDays: Number(e.target.value),
                })
              }>
              {Array.from({ length: 10 }, (_, i) => 1 + i + '일').map(item => (
                <option key={item} value={item.replace(/[^0-9]/, '')}>
                  {item}
                </option>
              ))}
            </Select>
          </div>
          <div className="flex items-end space-x-2 text-sm">
            <span>후 까지</span>
          </div>
        </div> */}
      </div>
      <Divider />
      <ProductOptionGroupSection
        items={updateProductValue.productOptionGroups}
        onChange={newProductOptionGroups => {
          setUpdateProductValue({ ...updateProductValue, productOptionGroups: newProductOptionGroups });
        }}
      />
      <div className="flex space-x-3 mt-20 mb-10 justify-center 2xl:w-4/6">
        <div className="flex flex-col w-56">
          <Button text="저장하기" onClick={debounce(onSubmit, 800)} />
        </div>
      </div>
    </>
  );
};
