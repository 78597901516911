import Pagination from '../components/Pagination';
import { Datagrid } from '@/components/Datagrid';
import { Field } from '@/components/Field/Field';
import { NormalField } from '@/components/Field/NormalField';
import { TopBar } from '@/components/TopBar';
import DateFormat from '@/constants/DateFormat';
import { Category, Product, UserGroup } from '@api/generated/axios-client';
import { ContentContainer } from '@container/content';
import { MemberContainer } from '@container/member';
import { PageHelper } from '@utils/PageHelper';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router';

export const CouponPageContent = () => {
  const history = useHistory();
  const { coupons, setCouponPage } = ContentContainer.useContext();

  if (!coupons) return null;

  const pageInfo = PageHelper.getPageInfo({
    ...coupons,
    gotoPage: setCouponPage,
  });

  return (
    <>
      <TopBar title="쿠폰관리" />
      <div className="p-6">
        <div className="flex items-center my-4">
          <div className="flex space-x-4">
            <button
              className="bg-black text-white px-4 py-2 text-sm rounded-md h-11"
              onClick={() => history.push('/admin/content/coupon/add')}>
              쿠폰추가
            </button>
          </div>
        </div>
        {coupons.data && (
          <Datagrid data={coupons.data}>
            <NormalField label="ID" property="id" />
            <Field
              label="등록일"
              render={row => (
                <div className="text-sm">{moment(row.createdAt).format(DateFormat.YYYYMMDD)}</div>
              )}
            />
            <NormalField label="쿠폰명" property="name" />
            <Field
              label="대상그룹"
              render={row => (
                <div className="text-sm min-w-max">
                  {row.userGroups?.map((g: UserGroup) => g.name).join() || ''}
                </div>
              )}
            />
            <Field
              label="사용기한"
              render={row => (
                <div className="text-sm">
                  {row.validFromAt ? moment(row.validFromAt).format(DateFormat.YYYYMMDDHHmm) : ''}~
                  {row.validUntilAt ? moment(row.validUntilAt).format(DateFormat.YYYYMMDDHHmm) : ''}
                </div>
              )}
            />
            <Field
              label="사용여부"
              render={row =>
                row.isActive && (!row.validUntilAt || moment(row.validUntilAt).isAfter(new Date())) ? (
                  <div className="text-sm">가능</div>
                ) : (
                  <div className="text-sm text-red-500">불가</div>
                )
              }
            />
            <Field
              label="관련상품"
              render={row => {
                const categoryTitles = row.categories?.map((c: Category) => c.title).join() || '';
                const productNames = row.products?.map((c: Product) => c.name).join() || '';
                return (
                  <div>
                    {!!categoryTitles && (
                      <span>
                        {categoryTitles}
                        {productNames && ' / '}
                      </span>
                    )}
                    {!!productNames && <span>{productNames}</span>}
                  </div>
                );
              }}
            />
            <Field
              label="담당자"
              render={row => <div className="text-sm min-w-max">{row.adminUser?.name || ''}</div>}
            />
            <Field
              label="상세"
              render={row => (
                <button
                  className="bg-gray-100 px-4 py-2 text-sm rounded-md"
                  onClick={() => history.push(`/admin/content/coupon/edit/${row.id}`)}>
                  상세
                </button>
              )}
            />
          </Datagrid>
        )}
        <Pagination {...pageInfo} />
      </div>
    </>
  );
};

export const CouponPage = () => {
  return (
    <ContentContainer.ContextProvider>
      <CouponPageContent />
    </ContentContainer.ContextProvider>
  );
};
