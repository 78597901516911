import { AxiosClient, AxiosQuery } from '@/api';
import { createContainer } from '@/container/createContainer';
import {
  IRequestCreateCouponDto,
  IRequestCreateNoticeDto,
  IRequestCreateUserMileageHistoryDto,
  IRequestUpdateCouponDto,
  IRequestUpdateNoticeDto,
  RequestCreateCouponDto,
  RequestCreateNoticeDto,
  RequestCreateUserMileageHistoryDto,
  RequestUpdateCouponDto,
  RequestUpdateNoticeDto,
} from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import { DefaultGetOneBaseQueryParameters, DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import { useDebounceState } from '@utils/useDebounceState';
import { useEffect, useState } from 'react';

export const contentHook = () => {
  const [noticePage, setNoticePage] = useState<number>(1);
  const [couponPage, setCouponPage] = useState<number>(1);
  const [userMileagePage, setUserMileagePage] = useState<number>(1);
  const [userMileageStartDt, setUserMileageStartDt] = useState<string>();
  const [userMileageEndDt, setUserMileageEndDt] = useState<string>();
  const [noticeKeyword, setNoticeKeyword, debounceNoticeKeyword] = useDebounceState('', 500);
  const [selectCouponId, setSelectCouponId] = useState<number>();

  const { data: userMileageHistories, refetch: refetchUserMileageHistories } =
    AxiosQuery.Query.useGetManyBaseUserMileageHistoryControllerUserMileageHistoryQuery(
      {
        ...DefaultRequestCrudDto,
        page: userMileagePage,
        limit: Const.DefaultPageLimitSize,
        filter: [
          ...DefaultRequestCrudDto.filter,
          ...(userMileageStartDt ? [`createdAt||$gte||${userMileageStartDt}`] : []),
          ...(userMileageEndDt ? [`createdAt||$lte||${userMileageEndDt}`] : []),
        ],
        join: ['adminUser', 'user'],
        sort: ['id,DESC'],
      },
      { refetchOnWindowFocus: false },
    );

  const clearUserMileageHistoriesCondition = () => {
    setUserMileagePage(1);
    setUserMileageStartDt(undefined);
    setUserMileageEndDt(undefined);
  };

  const { data: notices, refetch: refetchNotices } =
    AxiosQuery.Query.useGetManyBaseNoticeControllerNoticeQuery(
      {
        ...DefaultRequestCrudDto,
        page: noticePage,
        limit: Const.DefaultPageLimitSize,
        filter: [...DefaultRequestCrudDto.filter, `title||$cont||%${debounceNoticeKeyword}%`],
        sort: ['id,DESC'],
      },
      { refetchOnWindowFocus: false },
    );

  const { data: coupons } = AxiosQuery.Query.useGetManyBaseCouponControllerCouponQuery(
    {
      ...DefaultRequestCrudDto,
      page: couponPage,
      limit: Const.DefaultPageLimitSize,
      filter: [...DefaultRequestCrudDto.filter, 'isDeleted||$eq||false'],
      join: ['adminUser', 'userGroups', 'categories', 'products'],
      sort: ['id,DESC'],
    },
    { refetchOnWindowFocus: false },
  );

  const { data: coupon } = AxiosQuery.Query.useGetOneBaseCouponControllerCouponQuery(
    {
      ...DefaultGetOneBaseQueryParameters,
      id: selectCouponId || 0,
      join: ['userGroups', 'categories', 'products'],
    },
    {
      enabled: !!selectCouponId,
      refetchOnWindowFocus: false,
    },
  );

  const createNotice = (param: IRequestCreateNoticeDto): Promise<void> =>
    AxiosClient.createOneBaseNoticeControllerNotice(new RequestCreateNoticeDto(param)).then(() => {
      refetchNotices();
    });

  const updateNotice = (id: number, param: IRequestUpdateNoticeDto): Promise<void> =>
    AxiosClient.updateOneBaseNoticeControllerNotice(id, new RequestUpdateNoticeDto(param)).then(() => {
      refetchNotices();
    });

  const deleteNotices = (ids: number[]): Promise<void> =>
    Promise.all(
      ids.filter(id => id !== -1).map(id => AxiosClient.deleteOneBaseNoticeControllerNotice(id)),
    ).then(() => {
      refetchNotices();
    });

  const createUserMileageHistories = (param: IRequestCreateUserMileageHistoryDto): Promise<void> =>
    AxiosClient.createUserMileageHistories(new RequestCreateUserMileageHistoryDto(param)).then(() => {
      clearUserMileageHistoriesCondition();
      refetchUserMileageHistories();
    });

  const createCoupon = (param: IRequestCreateCouponDto) =>
    AxiosClient.createCoupon(new RequestCreateCouponDto(param));

  const updateCoupon = (param: IRequestUpdateCouponDto) =>
    AxiosClient.updateCoupon(new RequestUpdateCouponDto(param));

  useEffect(() => {
    setUserMileagePage(1);
  }, [userMileageStartDt, userMileageEndDt]);

  return {
    noticeKeyword,
    setNoticeKeyword,
    notices,
    setNoticePage,
    createNotice,
    updateNotice,
    deleteNotices,
    coupon,
    coupons,
    setSelectCouponId,
    setCouponPage,
    setUserMileagePage,
    createCoupon,
    updateCoupon,
    userMileageStartDt,
    userMileageEndDt,
    setUserMileageStartDt,
    setUserMileageEndDt,
    userMileageHistories,
    createUserMileageHistories,
  };
};

export const ContentContainer = createContainer(contentHook);
