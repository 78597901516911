import { AxiosQuery } from '@/api';
import { Icon } from '@/components/Icon';
import Autocomplete from '@/components/form/Autocomplete';
import { MemberContainer } from '@/container/member';
import { Role, User, UserGroup } from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import { Select } from '@entropyparadox/reusable-react';
import { DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import { useDebounceState } from '@utils/useDebounceState';
import { unionWith } from 'lodash';
import React, { useState } from 'react';

interface UserSelectFormProps {
  disabled?: boolean;
}

const UserSelectForm = ({ disabled }: UserSelectFormProps) => {
  const { tags, setSelectedGroups, selectedUsers, setSelectedUsers, selectedGroups } =
    MemberContainer.useContext();

  // 대상
  const [target, setTarget] = useState('그룹 선택');
  // 검색 결과
  const [userSearchKeyword, setUserSearchKeyword, debounceKeyword] = useDebounceState('', 500);

  const [selectedUser, setSelectedUser] = useState<User>();
  const [selectedGroup, setSelectedGroup] = useState<UserGroup>();

  const { data: searchedUsers } = AxiosQuery.Query.useGetManyBaseUserControllerUserQuery(
    {
      ...DefaultRequestCrudDto,
      limit: Const.DefaultPageLimitSize,
      filter: [
        ...DefaultRequestCrudDto.filter,
        `role||$eq||${Role.USER}`,
        `name||$contL||%${debounceKeyword}%`,
      ],
      join: ['userGroups'],
      sort: ['id,DESC'],
    },
    {
      enabled: !!debounceKeyword,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <>
      <Select label={'대상선택'} value={target} onChange={e => setTarget(e.target.value)}>
        <option disabled selected value="" hidden>
          대상을 선택해주세요
        </option>
        <option value="그룹 선택">그룹 선택</option>
        {!disabled && <option value="유저명 검색">유저명 검색</option>}
      </Select>
      {target === '그룹 선택' ? (
        <>
          <div>
            <Select
              label="그룹선택"
              value={selectedGroup?.id}
              onChange={e => setSelectedGroup(tags.find(row => String(row.id) === String(e.target.value)))}
              disabled={disabled}>
              {tags.map(row => (
                <option key={row.id} value={row.id}>
                  {row.name}
                </option>
              ))}
            </Select>
          </div>
          <div
            className={[
              'mt-6 grid place-items-center px-4 rounded-md cursor-pointer bg-gray-900 text-white w-max',
            ].join(' ')}
            onClick={() => {
              if (disabled) return;
              if (selectedGroup) {
                setSelectedGroups(prevState =>
                  unionWith(prevState, [selectedGroup], (a, b) => a.id === b.id),
                );
              } else if (tags.length) {
                setSelectedGroups(prevState => unionWith(prevState, [tags[0]], (a, b) => a.id === b.id));
              }
              setSelectedGroup(undefined);
            }}>
            대상선택 추가 +
          </div>
        </>
      ) : (
        <>
          <Autocomplete
            items={
              searchedUsers?.data.map((row: User) => ({
                label: `${row.name} ${row.phoneNumber}`,
                value: row,
              })) || []
            }
            searchKeyword={userSearchKeyword}
            onChangeKeyword={value => {
              setUserSearchKeyword(value);
            }}
            onSelect={item => {
              setSelectedUser(item.value);
            }}
          />
          <div
            className={[
              !selectedUsers.length ? 'mt-6' : 'mt-3',
              'cursor-pointer grid place-items-center px-4 rounded-md  bg-gray-900 text-white w-max',
            ].join(' ')}
            onClick={() => {
              if (disabled) return;
              if (selectedUser) {
                setSelectedUsers(prevState => unionWith(prevState, [selectedUser], (a, b) => a.id === b.id));
              }
              setUserSearchKeyword('');
            }}>
            대상선택 추가 +
          </div>
        </>
      )}
      {(!!selectedUsers?.length || !!selectedGroups?.length) && (
        <div className="flex flex-wrap col-span-2">
          {selectedGroups?.map(item => (
            <div
              key={item.id}
              className="flex items-center space-x-3 justify-center border rounded-full px-3 py-1.5 mr-2 mb-2">
              <div>{item.name}</div>
              {!disabled && (
                <Icon.X
                  className="cursor-pointer"
                  onClick={() => {
                    setSelectedGroups(prevState => prevState.filter(row => row.id !== item.id));
                  }}
                />
              )}
            </div>
          ))}
          {selectedUsers?.map(item => (
            <div
              key={item.id}
              className="flex items-center space-x-3 justify-center border rounded-full px-3 py-1.5 mr-2 mb-2">
              <div>{`${item.name} ${item.phoneNumber}`}</div>
              {!disabled && (
                <Icon.X
                  className="cursor-pointer"
                  onClick={() => {
                    setSelectedUsers(prevState => prevState.filter(row => row.id !== item.id));
                  }}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default UserSelectForm;
