import { OrderModalPopup } from './Alert/OrderModalPopup';
import { Datagrid } from './Datagrid';
import { Field } from './Field/Field';
import { NormalField } from './Field/NormalField';
import Pagination from './Pagination';
import { OrderContainer } from '@/container/order';
import { DailyOrderByProductDto } from '@api/generated/axios-client';
import { PageHelper } from '@utils/PageHelper';
import moment from 'moment';
import React, { useState } from 'react';

export const DateTable = () => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [dailyOrder, setDailyOrder] = useState<DailyOrderByProductDto>();
  const { dailyOrderByProduct, setDailyOrderByProductPage } = OrderContainer.useContext();

  const pageInfo = PageHelper.getPageInfo({
    ...dailyOrderByProduct,
    gotoPage: setDailyOrderByProductPage,
  });

  if (!dailyOrderByProduct?.data) return null;

  return (
    <div>
      {dailyOrder && (
        <OrderModalPopup
          item={dailyOrder}
          open={popupOpen}
          onClose={() => {
            setPopupOpen(false);
            setDailyOrder(undefined);
          }}
        />
      )}
      <Datagrid data={dailyOrderByProduct.data}>
        <Field
          label="날짜"
          render={row => (
            <div className="text-sm min-w-max">{moment(row.orderDate).format('YYYY.MM.DD')}</div>
          )}
        />
        <NormalField label="주문건수" property="totalCount" />
        <Field
          label="상세보기"
          render={row => (
            <>
              <button
                className="bg-gray-100 px-3 py-2 text-sm rounded-md"
                onClick={() => {
                  setDailyOrder(row as DailyOrderByProductDto);
                  setPopupOpen(true);
                }}>
                상세보기
              </button>
            </>
          )}
        />
      </Datagrid>
      <Pagination {...pageInfo} />
    </div>
  );
};
