import { LabelContext, Row, RowContext } from '../Datagrid';
import React, { FC, useContext } from 'react';

interface FieldProps {
  label: string;
  render: (row: Row) => any;
  mergedColumn?: boolean;
}

export const Field: FC<FieldProps> = ({ render }) => {
  const label = useContext(LabelContext);

  const row = useContext(RowContext);

  return <>{label || render(row)}</>;
};
