import Pagination from '../components/Pagination';
import { Datagrid } from '@/components/Datagrid';
import { Field } from '@/components/Field/Field';
import { NormalField } from '@/components/Field/NormalField';
import { MemberContainer } from '@container/member';
import { PageHelper } from '@utils/PageHelper';
import React from 'react';

export const UserDetailAccumulated = () => {
  const { userMileageHistories, setUserMileagePage } = MemberContainer.useContext();

  if (!userMileageHistories) return null;
  const pageInfo = PageHelper.getPageInfo({
    ...userMileageHistories,
    gotoPage: setUserMileagePage,
  });

  return (
    <>
      <Datagrid data={userMileageHistories.data}>
        <NormalField label="ID" property="id" />
        <Field label="작성일" render={row => new Date(row.createdAt).toLocaleString()} />
        <Field label="이름" render={row => row.user.name} />
        <NormalField label="관련상품" property="message" />
        <NormalField label="관리자메모" property="adminNote" />
        <Field
          label="지급 / 차감"
          render={row => (row.mileage >= 0 ? <div>지급</div> : <div className="text-red-500">차감</div>)}
        />
        <Field label="담당자" render={row => row.adminUser?.name || ''} />
        <Field
          label="금액"
          render={row => <div>{String(row.mileage).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</div>}
        />
      </Datagrid>
      <Pagination {...pageInfo} />
    </>
  );
};
