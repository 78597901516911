import { GiftMessageTablePage } from './GiftMessageTable';
import { useAlert } from '@/components/Alert/Alert';
import { IGiftCardGroup } from '@api/generated/axios-client';
import { CategoryModalPopup } from '@components/Alert/CategoryModalPopup';
import { GiftCardImageContainer } from '@container/giftManage';
import React, { useState } from 'react';
import { Route, Switch, useHistory } from 'react-router';

export const GiftMessageManage = () => {
  const { giftCardGroups, createGiftCardGroup, updateGiftCardGroup, deleteGiftCardGroup } =
    GiftCardImageContainer.useContext();
  const history = useHistory();
  const [isGroupAdd, setIsGroupAdd] = useState(false);
  const [selectedGiftCardGroup, setSelectedGiftCardGroup] = useState<IGiftCardGroup | null>(null);
  const { showSuccessAlert, showErrorAlert } = useAlert();

  const clear = () => {
    setIsGroupAdd(false);
  };

  const onDeleteGiftCardGroupItem = () => {
    if (!selectedGiftCardGroup) return;

    deleteGiftCardGroup(selectedGiftCardGroup.id)
      .then(clear)
      .then(() => showSuccessAlert('삭제'))
      .catch(showErrorAlert);
  };

  const onSaveGiftCardGroupItem = (name: string) => {
    if (selectedGiftCardGroup) {
      updateGiftCardGroup(selectedGiftCardGroup.id, {
        name: name,
      })
        .then(clear)
        .then(() => showSuccessAlert())
        .catch(showErrorAlert);
    } else {
      createGiftCardGroup({ name: name })
        .then(clear)
        .then(() => showSuccessAlert())
        .catch(showErrorAlert);
    }
  };

  return (
    <>
      <CategoryModalPopup
        item={selectedGiftCardGroup ? selectedGiftCardGroup.name : ''}
        open={isGroupAdd}
        onSave={onSaveGiftCardGroupItem}
        onDelete={selectedGiftCardGroup ? onDeleteGiftCardGroupItem : undefined}
        onClose={() => {
          setIsGroupAdd(false);
          clear();
        }}
      />
      <div className="grid grid-cols-5 h-full">
        <div className="col-span-1">
          <div className="m-4 px-4 py-3 border rounded-md h-full">
            <div className="flex justify-between items-center">
              <div className="font-semibold text-xl">카테고리 관리</div>
              <button className="bg-gray-100 px-4 py-2 rounded-md mt-2" onClick={() => setIsGroupAdd(true)}>
                추가
              </button>
            </div>
            <div className="space-y-4 mt-8 font-base">
              {giftCardGroups?.data.map(item => (
                <div className="flex justify-between">
                  <div
                    key={item.id}
                    className="cursor-pointer"
                    onClick={() => history.push(`/admin/gift/manage/${item.id}`)}>
                    {item.name}
                  </div>
                  <div
                    className="text-gray-400 font-light cursor-pointer"
                    onClick={() => {
                      setSelectedGiftCardGroup(item);
                      setIsGroupAdd(true);
                    }}>
                    Edit
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-span-4 p-6">
          <Switch>
            <Route path="/admin/gift/manage/:id" component={GiftMessageTablePage} />
            <Route
              path="/admin/gift/manage"
              component={() => (
                <div className="h-full w-full flex justify-center items-center bg-gray-100 rounded-md">
                  그룹을 선택해주세요.
                </div>
              )}
            />
          </Switch>
        </div>
      </div>
    </>
  );
};
