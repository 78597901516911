import { AxiosClient } from '@/api';
import { RequestUpdateOrderDto } from '@api/generated/axios-client';
import { useAlert } from '@components/Alert/Alert';
import AlertType from '@constants/AlertType';
import React, { useState } from 'react';

interface MemoDataProps {
  row: any;
  onSubmit?: () => void;
}

export const MemoInput: React.FC<MemoDataProps> = ({ row, onSubmit }) => {
  const { showAlert, showSuccessAlert, showErrorAlert } = useAlert();
  const [memoData, setMemoData] = useState(row.adminNote);

  const onSave = () => {
    if (!memoData) {
      showAlert({
        type: AlertType.WARNING,
        text: '메모를 입력해주세요.',
      });
      return;
    }

    const body = new RequestUpdateOrderDto();
    body.adminNote = memoData;
    AxiosClient.updateOneBaseOrderControllerOrder(row.id, body)
      .then(() => {
        showSuccessAlert();
        onSubmit?.();
      })
      .catch(showErrorAlert);
  };

  return (
    <div className="space-y-1 my-2">
      <textarea
        placeholder="메모를 입력해주세요"
        value={memoData || ''}
        onChange={e => setMemoData(e.target.value)}
        className="block px-4 w-full h-48 border
      border-gray-200 bg-yellow-100 rounded-md sm:text-sm
      focus:ring-0 focus:border-brand-1 focus:bg-white-1 placeholder-gray-400
      disabled:bg-gray-100 disabled:text-gray-400"
      />
      <button className="bg-gray-100 px-4 py-2 text-sm rounded-md" onClick={onSave}>
        저장
      </button>
    </div>
  );
};
