import { useAlert } from '@/components/Alert/Alert';
import { EditTopBar } from '@/components/EditTopBar';
import UserSelectForm from '@/components/form/UserSelectForm';
import { MemberContainer } from '@/container/member';
import { PushNotificationStatus } from '@api/generated/axios-client';
import { PushNotifyContainer } from '@container/pushNotify';
import { UserContainer } from '@container/user';
import { Checkbox, TextArea, TextField } from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

const PushNotifyAddContent = () => {
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [url, setUrl] = useState('');
  const [visibleSendDt, setVisibleSendDt] = useState<boolean>(false);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const { selectedUsers, selectedGroups, setSelectedGroups, setSelectedUsers } = MemberContainer.useContext();
  const { createPushNotification } = PushNotifyContainer.useContext();
  const { myProfile } = UserContainer.useContext();

  const onSaveClick = () => {
    if (!selectedGroups.length && !selectedUsers.length) {
      alert('대상을 선택해주세요');
      return;
    }
    if (!message || !title) {
      alert('타이틀과 내용을 입력해주세요');
      return;
    }

    const willSendAt = !date || !time ? undefined : new Date(`${date} ${time}`);
    createPushNotification({
      creatorId: myProfile?.id,
      title: title,
      message: message,
      status: PushNotificationStatus.Ready_reserved,
      targetUserGroupIds: selectedGroups.map(row => row.id),
      targetUserIds: selectedUsers.map(row => row.id),
      url: url,
      willSendAt: willSendAt,
    })
      .then(() => showSuccessAlert())
      .then(() => clear())
      .catch(showErrorAlert);
  };

  const clear = () => {
    setSelectedGroups([]);
    setSelectedUsers([]);
    history.push('/admin/push');
  };

  return (
    <>
      <EditTopBar title="푸시알림 추가" saveClick={onSaveClick} />
      <div className="p-6">
        <div className="grid grid-cols-3 gap-6 mt-4">
          <UserSelectForm />
          <div className="col-start-1 col-span-2 space-y-4">
            <TextField
              label="타이틀 입력"
              type="text"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
            <TextArea label="내용 입력" value={message} onChange={e => setMessage(e.target.value)} />
            <TextField label="연결링크" type="text" value={url} onChange={e => setUrl(e.target.value)} />
          </div>

          <div className="col-start-1 space-y-4 mt-6">
            <Checkbox
              label="예약발송"
              checked={visibleSendDt}
              onClick={() => setVisibleSendDt(!visibleSendDt)}
            />
          </div>
          {visibleSendDt && (
            <>
              <div className="col-start-1 space-y-4 mt-6">
                <TextField
                  label="발송 날짜"
                  type="date"
                  value={date}
                  onChange={e => setDate(e.target.value)}
                />
              </div>
              <div className="flex flex-col w-full justify-end">
                <TextField type="time" value={time} onChange={e => setTime(e.target.value)} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export const PushNotifyAdd = () => {
  return (
    <PushNotifyContainer.ContextProvider>
      <MemberContainer.ContextProvider>
        <PushNotifyAddContent />
      </MemberContainer.ContextProvider>
    </PushNotifyContainer.ContextProvider>
  );
};
