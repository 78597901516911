import { AxiosClient, AxiosQuery } from '@/api';
import { createContainer } from '@/container/createContainer';
import {
  IRequestCreateUserDto,
  IRequestCreateUserGroupDto,
  IRequestUpdateUserDto,
  IRequestUpdateUserGroupDto,
  RequestCreateUserDto,
  RequestCreateUserGroupDto,
  RequestUpdateUserDto,
  RequestUpdateUserGroupDto,
  Role,
  UserGroup,
} from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import { DefaultGetOneBaseQueryParameters, DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import { useDebounceState } from '@utils/useDebounceState';
import { useEffect, useState } from 'react';

const memberHook = () => {
  // tag 관련 상태
  const [tags, setTags] = useState<UserGroup[]>([]);
  const [adminTags, setAdminTags] = useState<UserGroup[]>([]);

  // 유저 목록 조회에 사용
  const [selectedTag, setSelectedTag] = useState<UserGroup>();
  const [userType, setUserType] = useState<Role>(Role.USER);
  const [userPage, setUserPage] = useState<number>(1);
  const [userMileagePage, setUserMileagePage] = useState<number>(1);

  const [userSearchKeyword, setUserSearchKeyword, debounceKeyword] = useDebounceState('', 500);

  // 추가한 유저 / 그룹
  const [selectedUsers, setSelectedUsers] = useState<any /*UserDataDto*/[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<UserGroup[]>([]);

  const [userId, setUserId] = useState<number>();

  const { refetch: refetchUserGroupList } = AxiosQuery.Query.useGetManyBaseUserGroupControllerUserGroupQuery(
    {
      ...DefaultRequestCrudDto,
      join: ['userGroupCategories'],
      sort: ['id,ASC'],
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: res => {
        setTags(res.data.filter(i => !i.isAdminGroup));
        setAdminTags(res.data.filter(i => i.isAdminGroup));
      },
    },
  );

  const { data: users, refetch: refetchUsers } = AxiosQuery.Query.useGetManyBaseUserControllerUserQuery(
    {
      ...DefaultRequestCrudDto,
      page: userPage,
      limit: Const.DefaultPageLimitSize,
      filter: [
        ...DefaultRequestCrudDto.filter,
        `role||$eq||${userType === Role.USER ? Role.USER : Role.ADMIN}`,
        // 1 or 13이면 전체 조회
        ...(!selectedTag?.id || selectedTag?.id === 1 || selectedTag?.id === 13
          ? []
          : [`userGroupMembers.groupId||$eq||${selectedTag?.id}`, `userGroupMembers.deleted||$eq||false`]),
        ...(!debounceKeyword ? [] : [`name||$contL||%${debounceKeyword}%`]),
      ],
      join: ['userGroups', 'userGroupMembers'],
      sort: ['id,DESC'],
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: user, refetch: refetchUser } = AxiosQuery.Query.useGetOneBaseUserControllerUserQuery(
    {
      ...DefaultGetOneBaseQueryParameters,
      id: Number(userId),
      join: ['userGroups', 'userGroupMembers'],
    },
    {
      enabled: !!userId,
      refetchOnWindowFocus: false,
    },
  );

  const { data: userShippingAddresses } =
    AxiosQuery.Query.useGetManyBaseShippingAddressControllerShippingAddressQuery(
      {
        ...DefaultRequestCrudDto,
        filter: [...DefaultRequestCrudDto.filter, `userId||$eq||${userId}`],
        join: ['deliveryZones'],
        sort: ['isDefault,DESC', 'id,DESC'],
      },
      { enabled: !!userId, refetchOnWindowFocus: false },
    );

  const { data: userMileageHistories } =
    AxiosQuery.Query.useGetManyBaseUserMileageHistoryControllerUserMileageHistoryQuery(
      {
        ...DefaultRequestCrudDto,
        page: userMileagePage,
        limit: Const.DefaultPageLimitSize,
        filter: [...DefaultRequestCrudDto.filter, `userId||$eq||${userId}`],
        sort: ['id,DESC'],
        join: ['user', 'adminUser'],
      },
      { enabled: !!userId, refetchOnWindowFocus: false },
    );

  useEffect(() => {
    setUserPage(1);
  }, [debounceKeyword]);

  const setSelectTagId = (tagId: number) => {
    setSelectedTag(tags.find(t => t.id === tagId) || adminTags.find(t => t.id === tagId));
  };

  const createUserGroup = (param: IRequestCreateUserGroupDto): Promise<void> =>
    AxiosClient.createOneBaseUserGroupControllerUserGroup(new RequestCreateUserGroupDto(param)).then(() => {
      refetchUserGroupList();
    });

  const updateUserGroup = (id: number, param: IRequestUpdateUserGroupDto): Promise<void> =>
    AxiosClient.updateOneBaseUserGroupControllerUserGroup(id, new RequestUpdateUserGroupDto(param)).then(
      () => {
        refetchUserGroupList();
      },
    );

  const deleteUserGroup = (id?: number): Promise<void> => {
    if (!id) return Promise.resolve();

    return AxiosClient.deleteOneBaseUserGroupControllerUserGroup(id).then(() => {
      refetchUserGroupList();
    });
  };

  const createUser = (param: IRequestCreateUserDto): Promise<void> =>
    AxiosClient.createOneBaseUserControllerUser(new RequestCreateUserDto(param)).then(() => {
      userId && refetchUser();
    });

  const updateUser = (id: number, param: IRequestUpdateUserDto): Promise<void> =>
    AxiosClient.updateOneBaseUserControllerUser(id, new RequestUpdateUserDto(param)).then(() => {
      userId && refetchUser();
    });

  const deleteUsers = (ids: number[]): Promise<void> =>
    Promise.all(ids.filter(id => id !== -1).map(id => AxiosClient.deleteOneBaseUserControllerUser(id))).then(
      () => {
        !!selectedTag?.id && refetchUsers();
      },
    );

  const { data: allUsers } = AxiosQuery.Query.useGetManyBaseUserControllerUserQuery(
    {
      ...DefaultRequestCrudDto,
      filter: [
        ...DefaultRequestCrudDto.filter,
        `role||$eq||${userType === Role.USER ? Role.USER : Role.ADMIN}`,
        `deleted||$eq||false`,
        // 1 or 13이면 전체 조회
        ...(!selectedTag?.id || selectedTag?.id === 1 || selectedTag?.id === 13
          ? []
          : [`userGroupMembers.groupId||$eq||${selectedTag?.id}`, `userGroupMembers.deleted||$eq||false`]),
        ...(!debounceKeyword ? [] : [`name||$contL||%${debounceKeyword}%`]),
      ],
      join: ['userGroups', 'userGroupMembers'],
      sort: ['id,DESC'],
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    userSearchKeyword,
    setUserSearchKeyword,
    setUserId,
    user,
    userShippingAddresses,
    userMileageHistories,
    setUserMileagePage,
    users,
    tags,
    adminTags,
    setSelectTagId,
    setUserType,
    setUserPage,
    selectedUsers,
    setSelectedUsers,
    selectedGroups,
    setSelectedGroups,
    createUserGroup,
    updateUserGroup,
    deleteUserGroup,
    createUser,
    updateUser,
    deleteUsers,
    allUsers,
  };
};

export const MemberContainer = createContainer(memberHook);
