import { AxiosClient, AxiosQuery } from '@/api';
import { createContainer } from '@/container/createContainer';
import { useCheckField } from '@/utils/hooks/useCheckField';
import instance from '@api/client';
import {
  OrderControllerQuery,
  OrderStatus,
  RequestUpdateOrderDto,
  RequestUpdateOrderProductDto,
  ShippingType,
} from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import DateFormat from '@constants/DateFormat';
import { isLoginState } from '@recoil/user';
import { DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import { useDebounceState } from '@utils/useDebounceState';
import { saveAs } from 'file-saver';
import { chain } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';


/**
 * 입금전
 * 선물수락대기
 * 배송대상
 * 배송준비중
 * 배송중
 * 배송완료
 */
export enum CategoryIndex {
  Ready,
  Wait,
  Pending,
  Will_delivery,
  In_delivery,
  Done,
}

export enum TabIndex {
  ALL,
  NORMAL,
  EARLY,
  GIFT,
}

const ShippingTypeTab = {
  [TabIndex.ALL]: '',
  [TabIndex.NORMAL]: ShippingType.Normal_delivery,
  [TabIndex.EARLY]: ShippingType.Early_delivery,
  [TabIndex.GIFT]: '',
};

const orderHook = () => {
  const [isLogin] = useRecoilState(isLoginState);
  const [orderPage, setOrderPage] = useState<number>(1);
  const [orderByProductPage, setOrderByProductPage] = useState<number>(1);
  const [dailyOrderByProductPage, setDailyOrderByProductPage] = useState<number>(1);

  const [orderStatus, setOrderStatus] = useState<OrderStatus | undefined>();
  // YYYY-MM-DD HH:mm:ss
  const [start, setStart] = useState<string>();
  const [end, setEnd] = useState<string>();
  const [, setKeyword, debounceKeyword] = useDebounceState('', 500);

  const [orderCount, setOrderCount] = useState<{ [key in OrderStatus]: number }>();
  const [tabIndex, setTabIndex] = useState(TabIndex.ALL);
  // 상태별 메뉴 카테고리
  const [categoryIndex, setCategoryIndex] = useState(CategoryIndex.Pending);
  const [viewTypeIndex, setViewTypeIndex] = useState(0);
  const { checked, setAllChecked, isChecked, setChecked } = useCheckField();
  const {
    checked: orderProductChecked,
    setAllChecked: setOrderProductAllChecked,
    isChecked: isOrderProductChecked,
    setChecked: setOrderProductChecked,
  } = useCheckField();

  const getShippingTypeCondition = (): any[] => {
    const shippingType = tabIndex in ShippingTypeTab ? ShippingTypeTab[tabIndex] : '';

    if (!shippingType) return [];

    return [{ 'orderProducts.shippingType': { $eq: shippingType } }];
  };

  const { data: orders, refetch: refetchOrders } = AxiosQuery.Query.useGetManyBaseOrderControllerOrderQuery(
    {
      ...DefaultRequestCrudDto,
      join: [
        'orderProducts||id,productName,totalCount,shippingType,orderStatus,shippingCompany,shippingNumber',
        'orderProducts.orderProductOptions||id,optionName',
        'orderPayments',
        'orderGift',
        'user||id',
        'user.userGroups||id',
        'user.userGroupMembers||groupId',
      ],
      s: JSON.stringify({
        $and: [
          { orderStatus: { $ne: OrderStatus.None } },
          ...(orderStatus
            ? [
                {
                  $or: [
                    { 'orderProducts.orderStatus': { $eq: orderStatus } },
                    { orderStatus: { $eq: orderStatus } },
                  ],
                },
              ]
            : []),
          ...(tabIndex === 0 ? [] : [{ isGift: { $eq: tabIndex === 3 } }]),
          ...getShippingTypeCondition(),
          ...(start ? [{ orderedAt: { $gt: start } }] : []),
          ...(end ? [{ orderedAt: { $lt: end } }] : []),
          ...(debounceKeyword
            ? [
                {
                  $or: [
                    { 'orderProducts.productName': { $contL: debounceKeyword } },
                    { customerName: { $contL: debounceKeyword } },
                    { customerPhone: { $contL: debounceKeyword } },
                    { receiverName: { $contL: debounceKeyword } },
                    { receiverPhone: { $contL: debounceKeyword } },
                    { orderNumber: { $contL: debounceKeyword } },
                  ],
                },
              ]
            : []),
        ],
      }),
      page: orderPage,
      limit: Const.DefaultPageLimitSize,
      sort: ['updatedAt,DESC', 'orderPayments.id,DESC'],
    },
    {
      enabled: isLogin,
      refetchOnWindowFocus: false,
    },
  );

  const { refetch: refetchOrderCount } = AxiosQuery.Query.useGetManyBaseOrderControllerOrderQuery(
    {
      ...DefaultRequestCrudDto,
      fields: ['orderStatus', 'orderProducts'],
      join: ['orderProducts||id,orderStatus'],
      s: JSON.stringify({
        $and: [
          { orderStatus: { $ne: OrderStatus.None } },
          ...(tabIndex === 0 ? [] : [{ isGift: { $eq: tabIndex === 3 } }]),
          ...getShippingTypeCondition(),
          ...(start ? [{ orderedAt: { $gt: start } }] : []),
          ...(end ? [{ orderedAt: { $lt: end } }] : []),
          ...(debounceKeyword
            ? [
                {
                  $or: [
                    { 'orderProducts.productName': { $contL: debounceKeyword } },
                    { customerName: { $contL: debounceKeyword } },
                    { customerPhone: { $contL: debounceKeyword } },
                    { receiverName: { $contL: debounceKeyword } },
                    { receiverPhone: { $contL: debounceKeyword } },
                    { orderNumber: { $contL: debounceKeyword } },
                  ],
                },
              ]
            : []),
        ],
      }),
      page: 1,
      limit: 1000000000,
    },
    {
      enabled: isLogin,
      refetchOnWindowFocus: false,
      onSuccess: res => {
        const count = chain(res.data)
          .flatMap('orderProducts')
          .groupBy('orderStatus')
          .reduce((prev: { [key in OrderStatus]: number }, value, key) => {
            prev[key as OrderStatus] = value.length;
            return prev;
          }, {} as { [key in OrderStatus]: number })
          .value();
        setOrderCount(count);
      },
    },
  );

  const { data: dailyOrderByProduct } =
    AxiosQuery.Query.useGetDailyOrderByProductOrderControllerResponseDailyOrderProductDtoQuery({
      shippingType: getShippingTypeCondition()?.[0]?.['orderProducts.shippingType']?.['$eq'],
      isGift: tabIndex === 0 ? undefined : tabIndex === 3,
      orderStatus,
      startDate: start ? moment.utc(start).toDate() : undefined,
      endDate: end ? moment.utc(end).toDate() : undefined,
      limit: Const.DefaultPageLimitSize,
      page: dailyOrderByProductPage,
      orderProductIds: [],
    });

  const { data: orderByProduct } =
    AxiosQuery.Query.useGetOrderByProductOrderControllerResponseOrderProductDtoQuery({
      shippingType: getShippingTypeCondition()?.[0]?.['orderProducts.shippingType']?.['$eq'],
      isGift: tabIndex === 0 ? undefined : tabIndex === 3,
      orderStatus,
      startDate: start ? moment.utc(start).toDate() : undefined,
      endDate: end ? moment.utc(end).toDate() : undefined,
      limit: Const.DefaultPageLimitSize,
      page: orderByProductPage,
      orderProductIds: [],
    });

  const { data: allOrders } = AxiosQuery.Query.useGetManyBaseOrderControllerOrderQuery(
    {
      ...DefaultRequestCrudDto,
      join: [
        'orderProducts||id,productName,totalCount',
        'orderProducts.orderProductOptions||id,optionName',
        'orderProducts.product',
        'orderPayments',
      ],
      s: JSON.stringify({
        $and: [
          ...(orderStatus
            ? [{ orderStatus: { $eq: orderStatus } }]
            : [{ orderStatus: { $ne: OrderStatus.None } }]),
          ...(tabIndex === 0 ? [] : [{ isGift: { $eq: tabIndex === 3 } }]),
          ...getShippingTypeCondition(),
          ...(start ? [{ orderedAt: { $gt: start } }] : []),
          ...(end ? [{ orderedAt: { $lt: end } }] : []),
          ...(debounceKeyword
            ? [
                {
                  $or: [
                    { 'orderProducts.productName': { $contL: debounceKeyword } },
                    { customerName: { $contL: debounceKeyword } },
                    { customerPhone: { $contL: debounceKeyword } },
                    { receiverName: { $contL: debounceKeyword } },
                    { receiverPhone: { $contL: debounceKeyword } },
                    { orderNumber: { $contL: debounceKeyword } },
                  ],
                },
              ]
            : []),
        ],
      }),
      sort: ['id,DESC'],
    },
    {
      enabled: isLogin,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    setOrderPage(1);
    setOrderByProductPage(1);
    setDailyOrderByProductPage(1);
  }, [viewTypeIndex]);

  const updateOrdersStatus = (
    ids: number[],
    orderProductIds: number[],
    orderStatus: OrderStatus,
  ): Promise<any> => {
    const dto = new RequestUpdateOrderDto();
    dto.orderStatus = orderStatus;
    const orderProductDto = new RequestUpdateOrderProductDto();
    orderProductDto.orderStatus = orderStatus;

    if (orderStatus === OrderStatus.Canceled) {
      instance
        .patch('/order/cancel-order', {
          checkedOrderIds: ids.filter(id => id !== -1),
          checkedOrderProductIds: orderProductIds.filter(id => id !== -1),
        })
        .then(() => {
          refetchOrders();
          refetchOrderCount();
        });
    }

    return Promise.all(
      ids.filter(id => id !== -1).map(id => AxiosClient.updateOneBaseOrderControllerOrder(id, dto)),
    )
      .then(() =>
        Promise.all(
          orderProductIds
            .filter(id => id !== -1)
            .map(id => AxiosClient.updateOneBaseOrderProductControllerOrderProduct(id, orderProductDto)),
        ),
      )
      .then(() => {
        setTimeout(() => {
          refetchOrders();
          refetchOrderCount();
        }, 800);
      });
  };

  const updateOrderProductsQuickShippingStartTime = (
    id: number,
    quickShippingStartTime: string,
  ): Promise<any> => {
    const dto = new RequestUpdateOrderDto();
    dto.quickShippingStartTime = moment(quickShippingStartTime, DateFormat.HHmm).toDate();

    return AxiosClient.updateOneBaseOrderControllerOrder(id, dto).then(() => {
      refetchOrders();
    });
  };

  const exportOrder = () => {
    return OrderControllerQuery.Client.exportOrder(
      undefined,
      JSON.stringify({
        $and: [
          { orderStatus: { $ne: OrderStatus.None } },
          ...(orderStatus
            ? [
                {
                  $or: [
                    { 'orderProducts.orderStatus': { $eq: orderStatus } },
                    { orderStatus: { $eq: orderStatus } },
                  ],
                },
              ]
            : []),
          ...(tabIndex === 0 ? [] : [{ isGift: { $eq: tabIndex === 3 } }]),
          ...getShippingTypeCondition(),
          ...(start ? [{ orderedAt: { $gt: start } }] : []),
          ...(end ? [{ orderedAt: { $lt: end } }] : []),
          ...(debounceKeyword
            ? [
                {
                  $or: [
                    { 'orderProducts.productName': { $contL: debounceKeyword } },
                    { customerName: { $contL: debounceKeyword } },
                    { customerPhone: { $contL: debounceKeyword } },
                    { receiverName: { $contL: debounceKeyword } },
                    { receiverPhone: { $contL: debounceKeyword } },
                    { orderNumber: { $contL: debounceKeyword } },
                  ],
                },
              ]
            : []),
        ],
      }),
      undefined,
      undefined,
      ['updatedAt,DESC', 'orderPayments.id,DESC'],
      ['orderProducts', 'orderProducts.orderProductOptions||id,optionName', 'orderPayments'],
      undefined,
      undefined,
    ).then(result => {
      console.log(result);
      saveAs(result.data as any, `주문내역.xlsx`);
    });
  };

  const exportOrderByProduct = () => {
    const shippingType = getShippingTypeCondition()?.[0]?.['orderProducts.shippingType']?.['$eq'];
    const startDate = start ? moment.utc(start).toDate() : undefined;
    const endDate = end ? moment.utc(end).toDate() : undefined;

    return OrderControllerQuery.Client.exportOrdersByProduct(
      shippingType,
      undefined,
      orderStatus,
      startDate,
      endDate,
      undefined,
      undefined,
      undefined,
    ).then(result => {
      console.log(result);
      saveAs(result.data as any, `주문내역(품목별).xlsx`);
    });
  };

  const exportOrderByDaily = () => {
    const shippingType = getShippingTypeCondition()?.[0]?.['orderProducts.shippingType']?.['$eq'];
    const startDate = start ? moment.utc(start).toDate() : undefined;
    const endDate = end ? moment.utc(end).toDate() : undefined;

    return OrderControllerQuery.Client.exportDailyOrderByProduct(
      shippingType,
      undefined,
      orderStatus,
      startDate,
      endDate,
      Const.DefaultPageLimitSize,
      undefined,
      undefined,
    ).then(result => {
      console.log(result);
      saveAs(result.data as any, `주문내역(날짜별).xlsx`);
    });
  };

  return {
    // ui 상단에 공용 자원
    orderStatus,
    setOrderStatus,
    setTabIndex,
    tabIndex,
    setStart,
    setEnd,
    setKeyword,
    viewTypeIndex,
    setViewTypeIndex,
    categoryIndex,
    setCategoryIndex,
    // ui 하단에 개별 자원
    orderCount,
    orders,
    allOrders,
    refetchOrders,
    setOrderPage,
    orderByProduct,
    setOrderByProductPage,
    dailyOrderByProduct,
    setDailyOrderByProductPage,
    updateOrdersStatus,
    updateOrderProductsQuickShippingStartTime,
    exportOrder,
    exportOrderByProduct,
    exportOrderByDaily,
    useCheck: {
      checked,
      setAllChecked,
      isChecked,
      setChecked,
      orderProductChecked,
      setOrderProductAllChecked,
      isOrderProductChecked,
      setOrderProductChecked,
    },
  };
};

export const OrderContainer = createContainer(orderHook);
