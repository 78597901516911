import { GiftCardImageContainer } from '../container/giftManage';
import { GiftMessageManage } from './GiftMessageManage';
import { GiftPhotoManage } from './GiftPhotoManage';
import { TopBar } from '@/components/TopBar';
import { Tab, Tabs } from '@entropyparadox/reusable-react';
import React, { useState } from 'react';

export const GiftManageContent = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <TopBar title="선물하기 관리" />
      <div className="p-6">
        <Tabs>
          <Tab text="이미지관리" active={tabIndex === 0} onClick={() => setTabIndex(0)} />
          <Tab text="메세지관리" active={tabIndex === 1} onClick={() => setTabIndex(1)} />
        </Tabs>
        <div className="pt-8">
          {tabIndex === 0 && <GiftPhotoManage />}
          {tabIndex === 1 && <GiftMessageManage />}
        </div>
      </div>
    </>
  );
};

export const GiftManagePage = () => {
  return (
    <GiftCardImageContainer.ContextProvider>
      <GiftManageContent />
    </GiftCardImageContainer.ContextProvider>
  );
};
