import { TopCategoryPopup } from '@/components/Alert/TopCategoryPopup';
import { Datagrid } from '@/components/Datagrid';
import { Field } from '@/components/Field/Field';
import { NormalField } from '@/components/Field/NormalField';
import Pagination from '@/components/Pagination';
import { TopBar } from '@/components/TopBar';
import { TopCategory } from '@api/generated/axios-client';
import { MainContainer } from '@container/main';
import { PageHelper } from '@utils/PageHelper';
import React, { useState } from 'react';

const TopCategoryContent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { topCategories, setTopCategoryPage } = MainContainer.useContext();

  const [selected, setSelected] = useState<TopCategory | null>(null);

  if (!topCategories) return null;

  const pageInfo = PageHelper.getPageInfo({
    ...topCategories,
    gotoPage: setTopCategoryPage,
  });

  return (
    <>
      {isOpen && (
        <TopCategoryPopup
          open={isOpen}
          item={selected}
          onClose={() => {
            setIsOpen(false);
            setSelected(null);
          }}
        />
      )}

      <TopBar title="최상단 카테고리 관리" />
      <div className="p-6">
        <div className="flex items-center my-4">
          <div className="flex space-x-4">
            <button
              className="bg-black text-white px-4 py-2 text-sm rounded-md h-11"
              onClick={() => setIsOpen(true)}>
              추가하기
            </button>
          </div>
        </div>
        <Datagrid data={topCategories.data}>
          <NormalField label="NO." property="sorted" />
          <Field
            label="작성일"
            render={row => (
              <div className="text-sm">
                {new Date(row.createdAt).toLocaleDateString()}
              </div>
            )}
          />
          <NormalField label="카테고리 텍스트" property="categoryTitle" />
          <NormalField label="URL" property="actionUrl" />
          <Field
            label="상세보기"
            render={row => (
              <button
                className="bg-gray-100 px-4 py-2 text-sm rounded-md"
                onClick={() => {
                  setSelected(row as TopCategory);
                  setIsOpen(true);
                }}>
                상세보기
              </button>
            )}
          />
        </Datagrid>
        <Pagination {...pageInfo} />
      </div>
    </>
  );
};
export const TopCategoryPage = () => (
  <MainContainer.ContextProvider>
    <TopCategoryContent />
  </MainContainer.ContextProvider>
);
