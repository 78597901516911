import { useAlert } from '@/components/Alert/Alert';
import { NomalModalPopup } from '@/components/Alert/NomalModalPopup';
import { ProductItemModalPopup } from '@/components/Alert/ProductItemModalPopup';
import { Datagrid } from '@/components/Datagrid';
import { Field } from '@/components/Field/Field';
import { Material } from '@api/generated/axios-client';
import { MaterialCategoryContainer } from '@container/materialCategory';
import { Button } from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory, useParams } from 'react-router';

export const StockCategoryTable = () => {
  const history = useHistory();
  const { materialCategoryId } = useParams() as { materialCategoryId: string };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState<Material | null>();
  const { showAlert, showSuccessAlert, showErrorAlert } = useAlert();
  const materialId = location.pathname.split('material/')[1];

  const { setSelectedMaterialCategoryId, materialList, createMaterial, updateMaterial, deleteMaterial } =
    MaterialCategoryContainer.useContext();

  useEffect(() => {
    setSelectedMaterialCategoryId(materialCategoryId ? Number(materialCategoryId) : undefined);
    clear();
  }, [materialCategoryId]);

  const clear = () => {
    setSelectedMaterial(null);
    setOpenSaveModal(false);
    setOpenDeleteModal(false);
  };

  const handleSaveMaterialItem = (item: Material) => {
    if (selectedMaterial) {
      updateMaterial(selectedMaterial.id, item)
        .then(() => showSuccessAlert())
        .then(() => clear())
        .catch(showErrorAlert);
    } else {
      item.categoryId = +materialCategoryId;
      createMaterial(item)
        .then(() => showSuccessAlert())
        .then(() => clear())
        .catch(showErrorAlert);
    }
  };

  const handleDeleteMaterial = () => {
    if (!selectedMaterial) return;

    if ((selectedMaterial.productItems?.length || 0) > 0) {
      showAlert({
        type: 'warning',
        text: '상품에 등록된 제품 재고가 1건 이상 있어서 삭제할 수 없습니다.',
      });
      return;
    }

    deleteMaterial(selectedMaterial.id)
      .then(() => showSuccessAlert('삭제'))
      .then(() => clear())
      .catch(showErrorAlert);
  };

  return (
    <>
      <ProductItemModalPopup
        materialCategoryId={+materialCategoryId}
        item={selectedMaterial}
        open={openSaveModal}
        onSave={handleSaveMaterialItem}
        onDelete={selectedMaterial ? () => setOpenDeleteModal(true) : undefined}
        onClose={() => {
          setOpenSaveModal(false);
          clear();
        }}
      />
      <NomalModalPopup
        title="정말 삭제하시겠습니까?"
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onClick={handleDeleteMaterial}
      />
      <h3 className="font-semibold text-xl mb-4">카테고리 재고 관리</h3>
      <Button
        text="새 제품추가"
        onClick={() => {
          setSelectedMaterial(null);
          setOpenSaveModal(true);
        }}
      />
      <div className="mt-4">
        {materialList?.data && (
          <Datagrid data={materialList.data}>
            <Field
              label="제품명"
              render={row => (
                <div
                  className={[
                    'text-sm cursor-pointer',
                    row.id === Number(materialId) ? 'font-bold' : '',
                  ].join(' ')}
                  onClick={() =>
                    history.push(`/admin/product/stock/${materialCategoryId}/material/${row.id}`)
                  }>
                  {row.name}
                </div>
              )}
            />
            <Field
              label="현재수량"
              render={row => (
                <div
                  className={[
                    'text-sm cursor-pointer',
                    row.id === Number(materialId) ? 'font-bold' : '',
                  ].join(' ')}>
                  <NumberFormat displayType="text" thousandSeparator={true} value={row.stock || 0} />
                </div>
              )}
            />
            <Field
              label=" "
              render={row => (
                <div className="flex space-x-4">
                  <div
                    className="text-sm text-blue-500 cursor-pointer"
                    onClick={() => {
                      setSelectedMaterial(row as Material);
                      setOpenSaveModal(true);
                    }}>
                    수정
                  </div>
                </div>
              )}
            />
            <Field
              label=" "
              render={row => (
                <div className="flex space-x-4">
                  <div
                    className="text-sm text-red-500 cursor-pointer"
                    onClick={() => {
                      setSelectedMaterial(row as Material);
                      setOpenDeleteModal(true);
                    }}>
                    삭제
                  </div>
                </div>
              )}
            />
          </Datagrid>
        )}
      </div>
    </>
  );
};
