import { PaginationProps } from '@components/Pagination';
import { Const } from '@constants/Const';

export interface TotalPageInfo {
  gotoPage: (page: number) => void;
  count?: number;
  total?: number;
  page?: number;
  pageCount?: number;
  pageSize?: number;
}

export class PageHelper {
  static getPageInfo({
    gotoPage,
    page = 1,
    pageCount = 0,
    pageSize = Const.DefaultPageLimitSize,
  }: TotalPageInfo): PaginationProps {
    const canNextPage = page < pageCount;
    const canPreviousPage = page > 1;
    const previousPage = () => {
      if (!canPreviousPage) return;
      gotoPage(page - 1);
    };
    const nextPage = () => {
      if (!canNextPage) return;
      gotoPage(page + 1);
    };

    return {
      canNextPage,
      canPreviousPage,
      previousPage,
      gotoPage,
      nextPage,
      pageCount,
      pageIndex: page,
      pageSize,
    };
  }
}
