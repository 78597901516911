import { useAlert } from '@/components/Alert/Alert';
import { NomalModalPopup } from '@/components/Alert/NomalModalPopup';
import { ReviewPopup } from '@/components/Alert/ReviewPopup';
import { Datagrid } from '@/components/Datagrid';
import { Field } from '@/components/Field/Field';
import { NormalField } from '@/components/Field/NormalField';
import { TopBar } from '@/components/TopBar';
import { useCheckField } from '@/utils/hooks/useCheckField';
import { ContentReviewContainer } from '@container/review';
import { Checkbox, Tab, Tabs } from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

export const ProductReviewListContent = () => {
  const { setSelectedProductId, productIdToReviews, deleteReviews } = ContentReviewContainer.useContext();
  const history = useHistory();
  const { pathname } = useLocation();
  const { id } = useParams() as { id: string };
  const productId = Number(id);
  const [isOpen, setIsOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const { checked, setAllChecked, isChecked, setChecked } = useCheckField();
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const [selectedReviewId, setSelectedReviewId] = useState<number>();

  useEffect(() => {
    if (productId || !isNaN(productId)) {
      setSelectedProductId(+productId);
    }
  }, [productId]);

  if (!productIdToReviews) {
    return null;
  }

  const handleDelete = () => {
    deleteReviews(checked.map(Number))
      .then(() => showSuccessAlert('삭제'))
      .then(() => clear())
      .catch(showErrorAlert);
  };

  const clear = () => {
    setIsOpen(false);
    setSelectedReviewId(undefined);
    setAlertOpen(false);
    setAllChecked([], false);
  };

  return (
    <>
      <NomalModalPopup
        title="정말 삭제하시겠습니까?"
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onClick={handleDelete}
      />
      {selectedReviewId && (
        <ReviewPopup reviewId={selectedReviewId} open={isOpen} onClose={() => setIsOpen(false)} />
      )}
      <TopBar title="상품수정" />
      <div className="mt-4">
        <Tabs>
          <Tab
            text="상품정보"
            active={pathname.startsWith('/admin/product/edit')}
            onClick={() => history.push(`/admin/product/edit/${productId}`)}
          />
          <Tab
            text="후기관리"
            active={pathname.startsWith('/admin/product/review')}
            onClick={() => history.push(`/admin/product/review/${productId}`)}
          />
        </Tabs>
      </div>

      <div className="pl-6 pr-6">
        <div className="flex justify-between items-center my-4">
          <div className="space-x-4">
            <button
              className="bg-gray-100 px-4 py-2 text-sm rounded-md"
              onClick={() => {
                setAlertOpen(true);
              }}>
              삭제
            </button>
          </div>
        </div>
        <Datagrid data={productIdToReviews?.data}>
          <Field
            label=""
            render={row => (
              <Checkbox
                checked={isChecked(row.id || -1)}
                onChange={e => {
                  // 전체
                  if (!row.id) {
                    setAllChecked(
                      productIdToReviews?.data.map((item: any) => item.id).concat([-1]),
                      e.target.checked,
                    );
                    return;
                  }

                  setChecked(row.id, e.target.checked);
                }}
              />
            )}
          />
          <NormalField label="NO." property="id" />
          <Field
            label="작성일"
            render={row => <div className="text-sm">{new Date(row.createdAt).toLocaleDateString()}</div>}
          />
          <Field
            label="주문번호"
            render={row => <div className="text-sm"> {row?.orderProduct?.order?.orderNumber || ''} </div>}
          />
          <Field label="작성자" render={row => <div className="text-sm"> {row?.user?.name || ''} </div>} />
          <Field
            label="주문상품"
            render={row => <div className="text-sm"> {row?.orderProduct?.productName || ''} </div>}
          />
          <NormalField label="제목" property="title" />
          <Field label="베스트후기" render={row => (row.isBest ? 'O' : '')} />
          <Field
            label="후기유형"
            render={row => (
              <div className="text-sm">{!!row.reviewMedias?.length ? '포토후기' : '일반후기'}</div>
            )}
          />
          <Field
            label="적립금 지급"
            render={row => (
              <div className="text-sm">
                +{String(row?.userMileageHistory.mileage || 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
              </div>
            )}
          />
          <Field
            label="상세보기"
            render={row => (
              <button
                className="bg-gray-100 px-4 py-2 text-sm rounded-md"
                onClick={() => {
                  setSelectedReviewId(row.id);
                  setIsOpen(true);
                }}>
                상세보기
              </button>
            )}
          />
        </Datagrid>
      </div>
    </>
  );
};

export const ProductReviewList = () => {
  return (
    <ContentReviewContainer.ContextProvider>
      <ProductReviewListContent />
    </ContentReviewContainer.ContextProvider>
  );
};
