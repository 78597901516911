import {
  IRequestCreateMaterialStockDto,
  MaterialStockStatus,
  MaterialStockType,
} from '@api/generated/axios-client';
import { MaterialCategoryContainer } from '@container/materialCategory';
import { Button, TextArea, TextField } from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';

interface ItemModalPopupProps {
  open: boolean;
  onClose: () => void;
  onSave: (item: IRequestCreateMaterialStockDto) => void;
}

export const ItemModalPopup: React.FC<ItemModalPopupProps> = ({ onClose, open, onSave }) => {
  const [name, setName] = useState('');
  const [quantity, setQuantity] = useState<number>(0);
  const [stock, setStock] = useState<number>();
  const [memo, setMemo] = useState('');
  const [stockType, setStockType] = useState<MaterialStockType>(MaterialStockType.Normal);

  const { selectedMaterial } = MaterialCategoryContainer.useContext();

  useEffect(() => {
    setName(selectedMaterial?.name || '');
    setStock(selectedMaterial?.stock);
  }, [selectedMaterial]);

  const clear = () => {
    setQuantity(0);
    setMemo('');
    setStockType(MaterialStockType.Normal);
  };

  return (
    <div>
      {open && (
        <div
          className="fixed z-100 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"></div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    clear();
                    onClose();
                  }}>
                  <span className="sr-only">Close</span>

                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full space-y-4">
                  <h3 className="text-lg leading-6 font-semibold text-gray-900 mb-5" id="modal-title">
                    입/출고하기
                  </h3>
                  {/* <div className="mt-2 mb-4">
                    <p className="text-sm text-gray-500">{description}</p>
                  </div> */}
                  <TextField label="제품명" disabled value={name} />
                  {/*<Select
                    label="재고타입"
                    value={stockType === 'gift' ? '2' : stockType === 'shop' ? '3' : '1'}
                    onChange={e => {
                      setStockType(
                        Number(e.target.value) === 2
                          ? MaterialStockType.Gift
                          : Number(e.target.value) === 3
                          ? MaterialStockType.Shop
                          : MaterialStockType.Normal,
                      );
                    }}>
                    <option disabled selected value="" hidden>
                      재고타입 선택
                    </option>
                    {[
                      { id: 1, name: '미선택' },
                      { id: 2, name: '선물재고' },
                      { id: 3, name: '매장재고' },
                    ].map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>*/}
                  <TextField label="총 재고 개수" value={stock} disabled />
                  <TextField
                    label="입/출고 개수"
                    type="number"
                    value={quantity || ''}
                    onChange={e => setQuantity(Number(e.target.value))}
                    placeholder="출고는 (-)로 입력"
                  />
                  <TextArea label="관리자 메모" value={memo} onChange={e => setMemo(e.target.value)} />
                </div>
              </div>
              <div className="flex flex-col mt-4 justify-center space-x-4">
                <Button
                  text="저장하기"
                  onClick={() => {
                    if (!selectedMaterial) return;
                    onSave({
                      stock: quantity,
                      materialId: selectedMaterial.id,
                      stockType: undefined,
                      remainingStock: undefined,
                      orderProductId: undefined,
                      stockStatus: !!quantity ? MaterialStockStatus.Admin_in : MaterialStockStatus.Admin_out,
                      willOutDate: undefined,
                      note: memo,
                    });

                    clear();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
