import { AxiosQuery } from '@/api';
import { createContainer } from '@/container/createContainer';
import { Category, Product } from '@api/generated/axios-client';
import { DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import { useDebounceState } from '@utils/useDebounceState';
import React, { useState } from 'react';

const ProductSelectHook = () => {
  const [keyword, setKeyword] = useDebounceState('', 500);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

  const { data: products } = AxiosQuery.Query.useGetManyBaseProductControllerProductQuery(
    {
      ...DefaultRequestCrudDto,
      filter: [
        ...DefaultRequestCrudDto.filter,
        `isActive||$eq||true`,
        'deleted||$eq||false',
        'isHidden||$eq||false',
        `name||$cont||%${keyword}%`,
      ],
      sort: ['createdAt,DESC,sorted,ASC'],
    },
    { refetchOnWindowFocus: false },
  );

  const { data: categories } = AxiosQuery.Query.useGetManyBaseCategoryControllerCategoryQuery(
    {
      ...DefaultRequestCrudDto,
      filter: [`isActive||$eq||true`],
      sort: ['id,ASC'],
      join: ['subCategories'],
    },
    { refetchOnWindowFocus: false },
  );

  return {
    keyword,
    setKeyword,
    products,
    categories,
    selectedCategories,
    setSelectedCategories,
    selectedProducts,
    setSelectedProducts,
  };
};

export const ProductSelectContainer = createContainer(ProductSelectHook);
