import Pagination from '../components/Pagination';
import { Datagrid } from '@/components/Datagrid';
import { Field } from '@/components/Field/Field';
import { NormalField } from '@/components/Field/NormalField';
import { TopBar } from '@/components/TopBar';
import { CalculateContainer } from '@container/calculate';
import { TextField } from '@entropyparadox/reusable-react';
import { PageHelper } from '@utils/PageHelper';
import React, { useState } from 'react';

export const CalculateContent = () => {
  const { calculates, setCalculatePage, start, setStart, end, setEnd } = CalculateContainer.useContext();
  const [isOpen, setIsOpen] = useState(false);

  const pageInfo = PageHelper.getPageInfo({
    ...calculates,
    gotoPage: setCalculatePage,
  });

  return (
    <>
      {/*<ReviewPopup open={isOpen} onClose={() => setIsOpen(false)} />*/}
      <TopBar title="정산관리" />
      <div className="p-6">
        <div className="flex justify-end">
          <div className="flex space-x-4 justify-end mb-4">
            <div className="flex items-center space-x-1 mt-4">
              <TextField
                type="date"
                value={start?.substr(0, 10)}
                onChange={e => {
                  setStart(e.target.value ? `${e.target.value}T00:00:00+09:00` : undefined);
                }}
              />
              <span className="flex-shrink-0">부터</span>{' '}
              <TextField
                type="date"
                value={end?.substr(0, 10)}
                onChange={e => {
                  setEnd(e.target.value ? `${e.target.value}T23:59:59+09:00` : undefined);
                }}
              />
            </div>
          </div>
        </div>
        {calculates?.data && (
          <Datagrid data={calculates.data}>
            <NormalField label="일자" property="date" />

            <Field
              label="주문수"
              render={row => (
                <div className="text-sm">{String(row.orderCount).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
              )}
            />
            <Field
              label="총 구매금액"
              render={row => (
                <div className="text-sm">
                  {String(row.orderAmount).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                </div>
              )}
            />
            <Field
              label="가입자수"
              render={row => (
                <div className="text-sm">
                  {String(row.newUserCount).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </div>
              )}
            />
          </Datagrid>
        )}
        <Pagination {...pageInfo} />
      </div>
    </>
  );
};

export const CalculatePage = () => {
  return (
    <CalculateContainer.ContextProvider>
      <CalculateContent />
    </CalculateContainer.ContextProvider>
  );
};
