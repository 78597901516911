import { SearchIcon } from '@entropyparadox/reusable-react';
import React, { FC, InputHTMLAttributes } from 'react';

interface SearchFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  onSearch?: () => void;
}

export const SearchField: FC<SearchFieldProps> = ({ onSearch, ...props }) => {
  return (
    <div className="flex items-center border border-gray-200 focus-within:border-brand-green-1 rounded-full px-4 py-2 ps:py-3">
      <input
        className="block px-4 w-full text-sm ps:text-base focus:ring-0 placeholder-gray-400"
        {...props}
      />
      <SearchIcon onClick={onSearch} />
    </div>
  );
};
