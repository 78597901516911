import {
  IRequestCreateUserGroupDto,
  RequestCreateUserGroupDto,
  UserGroup,
  UserGroupCategory,
} from '@api/generated/axios-client';
import { ProductCategoryContainer } from '@container/productCategory';
import { Checkbox, TextField } from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';

interface TagModalPopupProps {
  item?: UserGroup;
  open: boolean;
  isAdmin?: boolean;
  onDelete?: () => void;
  onSave: (params: IRequestCreateUserGroupDto) => void;
  onClose: () => void;
}

const getDefaultCreateUserGroupDto = (isAdmin: boolean) => {
  const dto = new RequestCreateUserGroupDto();
  dto.color = '#000000';
  dto.isBold = false;
  dto.isAdminGroup = isAdmin;
  return dto;
};

export const TagModalPopup: React.FC<TagModalPopupProps> = ({
  item,
  onClose,
  isAdmin = false,
  open,
  onSave,
  onDelete,
}) => {
  const [params, setParams] = useState<IRequestCreateUserGroupDto>(getDefaultCreateUserGroupDto(isAdmin));

  const { categoryList } = ProductCategoryContainer.useContext();
  const [selectedCategories, setSelectedCategories] = useState<UserGroupCategory[]>([]);

  useEffect(() => {
    if (!item) {
      setParams(getDefaultCreateUserGroupDto(isAdmin));
      setSelectedCategories([]);
      return;
    }

    setParams(item);
    setSelectedCategories(item.userGroupCategories || []);
  }, [item]);

  const handleSave = () => {
    onSave({
      ...params,
      isAdminGroup: isAdmin,
      userGroupCategories: selectedCategories,
    });
  };

  console.log(selectedCategories);
  return (
    <div>
      {open && (
        <>
          <div
            className="fixed z-100 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"></div>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>

              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={onClose}>
                    <span className="sr-only">Close</span>

                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <div className="mt-3  sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-semibold text-gray-900" id="modal-title">
                    태그 관리
                  </h3>
                  <div className="mt-4 mr-3">
                    <TextField
                      label="태그명"
                      placeholder="태그명을 입력해주세요"
                      value={params.name}
                      onChange={e => setParams({ ...params, name: e.target.value })}
                    />
                    <div className="font-semibold mt-6">권한관리</div>
                    <div className="grid grid-cols-3 gap-3 mt-2">
                      {categoryList?.data &&
                        !isAdmin &&
                        categoryList.data.map(category => (
                          <Checkbox
                            key={category.id}
                            label={category.title}
                            checked={
                              !!selectedCategories.find(s => s.categoryId === category.id && !s.deletedAt)
                            }
                            onChange={e => {
                              let newSelectedCategories = selectedCategories.slice();
                              let foundUserGroupCategory = newSelectedCategories.find(
                                s => s.categoryId === category.id,
                              );
                              if (!foundUserGroupCategory) {
                                foundUserGroupCategory = new UserGroupCategory();
                                foundUserGroupCategory.categoryId = category.id;
                                if (item?.id) foundUserGroupCategory.groupId = item.id;
                                newSelectedCategories.push(foundUserGroupCategory);
                              }
                              foundUserGroupCategory.deleted = !e.target.checked;
                              foundUserGroupCategory.deletedAt = e.target.checked ? undefined : new Date();
                              setSelectedCategories(newSelectedCategories);
                            }}
                          />
                        ))}
                      {isAdmin && (
                        <>
                          <Checkbox
                            label="주문관리"
                            checked={params.hasAuthorityOrder}
                            onChange={e => {
                              setParams({
                                ...params,
                                hasAuthorityOrder: e.target.checked,
                              });
                            }}
                          />
                          <Checkbox
                            label="상품관리"
                            checked={params.hasAuthorityProduct}
                            onChange={e => {
                              setParams({
                                ...params,
                                hasAuthorityProduct: e.target.checked,
                              });
                            }}
                          />
                          <Checkbox
                            label="쇼핑환경설정"
                            checked={params.hasAuthorityMain}
                            onChange={e => {
                              setParams({
                                ...params,
                                hasAuthorityMain: e.target.checked,
                              });
                            }}
                          />
                          <Checkbox
                            label="컨텐츠관리"
                            checked={params.hasAuthorityContent}
                            onChange={e => {
                              setParams({
                                ...params,
                                hasAuthorityContent: e.target.checked,
                              });
                            }}
                          />
                          <Checkbox
                            label="정산관리"
                            checked={params.hasAuthoritySettle}
                            onChange={e => {
                              setParams({
                                ...params,
                                hasAuthoritySettle: e.target.checked,
                              });
                            }}
                          />
                          <Checkbox
                            label="회원관리"
                            checked={params.hasAuthorityUser}
                            onChange={e => {
                              setParams({
                                ...params,
                                hasAuthorityUser: e.target.checked,
                              });
                            }}
                          />
                          <Checkbox
                            label="알림설정"
                            checked={params.hasAuthorityNoti}
                            onChange={e => {
                              setParams({
                                ...params,
                                hasAuthorityNoti: e.target.checked,
                              });
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-10 flex space-x-4 mx-2">
                  {onDelete && (
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-white border-black text-base font-medium text-black sm:text-sm mx-auto"
                      onClick={onDelete}>
                      삭제하기
                    </button>
                  )}
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-black text-base font-medium text-white sm:text-sm mx-auto"
                    onClick={handleSave}>
                    저장하기
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
