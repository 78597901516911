import { IUserGroupMember, UserGroup, UserGroupMember } from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import { MemberContainer } from '@container/member';
import { UserContainer } from '@container/user';
import { Checkbox } from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';

interface SelectableTagsListProps {
  isAdmin?: boolean;
  userId: number;
  onChangeUserGroupMembers: (userGroupMembers: UserGroupMember[]) => void;
}

export const SelectableTagsList: React.FC<SelectableTagsListProps> = ({
  isAdmin = false,
  userId,
  onChangeUserGroupMembers,
}) => {
  const { myProfile } = UserContainer.useContext();
  const { adminTags, tags: userTags, setUserId, user } = MemberContainer.useContext();
  const tags = isAdmin ? adminTags : userTags;

  const [userGroupMembers, setUserGroupMembers] = useState<UserGroupMember[]>([]);
  const allGroupId = isAdmin ? Const.AllAdminGroupId : Const.AllUserGroupId;

  useEffect(() => {
    if (!userId) return;

    setUserId(Number(userId));
  }, [userId]);

  useEffect(() => {
    if (!user) return;

    setUserGroupMembers(user.userGroupMembers || []);
  }, [user]);

  useEffect(() => {
    onChangeUserGroupMembers(userGroupMembers);
  }, [userGroupMembers]);

  const handleChangeChecked = (e: React.ChangeEvent<HTMLInputElement>, item: UserGroup) => {
    const target = e.target as HTMLInputElement;

    if (item.id === allGroupId) {
      const newUserGroupMembers = tags.map(
        t =>
          new UserGroupMember({
            deleted: !target.checked,
            deletedAt: target.checked ? undefined : new Date(),
            createdUserId: myProfile?.id,
            updatedUserId: myProfile?.id,
            userId,
            groupId: t.id,
          } as IUserGroupMember),
      );

      setUserGroupMembers([...newUserGroupMembers]);
      return;
    }

    if (target.checked) {
      let newUserGroupMembers;
      if (!userGroupMembers.find(g => g.groupId === item.id)) {
        const m = new UserGroupMember();
        m.groupId = item.id;
        newUserGroupMembers = [...userGroupMembers, m];
      } else {
        newUserGroupMembers = userGroupMembers.map(g => {
          if (g.groupId === item.id) {
            g.deleted = false;
            g.deletedAt = undefined;
          }
          return g;
        });
      }
      // 모두선택되면 전체그룹 켜주기
      if (tags.length - 1 === newUserGroupMembers.filter(g => !g.deleted).length) {
        if (!newUserGroupMembers.find(g => g.groupId === allGroupId)) {
          newUserGroupMembers.push(
            new UserGroupMember({
              deleted: !target.checked,
              deletedAt: target.checked ? undefined : new Date(),
              createdUserId: myProfile?.id,
              updatedUserId: myProfile?.id,
              userId,
              groupId: allGroupId,
            } as IUserGroupMember),
          );
        }
        const allGroup = newUserGroupMembers.find(g => g.groupId === allGroupId);
        allGroup!.deleted = false;
        allGroup!.deletedAt = undefined;
      }
      setUserGroupMembers(newUserGroupMembers);
    } else {
      setUserGroupMembers(
        userGroupMembers.map(g => {
          // 전체그룹도 해제
          if (g.groupId === item.id || g.groupId === allGroupId) {
            g.deleted = true;
            g.deletedAt = new Date();
          }
          return g;
        }),
      );
    }
  };

  return (
    <div className="flex items-center flex-wrap">
      {tags.map(item => (
        <div className="flex space-x-2  mr-4" key={item.id}>
          <Checkbox
            id={`user_detail_tag_${item.id}`}
            name={String(item.id)}
            checked={!!userGroupMembers?.find(g => g.groupId === item.id && !g.deleted)}
            onChange={e => handleChangeChecked(e, item)}
          />
          <label
            htmlFor={`user_detail_tag_${item.id}`}
            className={`cursor-pointer ${item.isBold && 'font-bold'}`}
            style={{ color: `${item.color ? item.color : '#000000'}` }}>
            {item.name}
          </label>
        </div>
      ))}
    </div>
  );
};
