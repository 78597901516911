import { ReactComponent as Download } from '../assets/svg/download.svg';
import { useAlert } from '@/components/Alert/Alert';
import { DualSelectModalPopup } from '@/components/Alert/DualSelectModalPopup';
import { FileModalPopup } from '@/components/Alert/FileModalPopup';
import { SelectModalPopup } from '@/components/Alert/SelectModalPopup';
import { SearchField } from '@/components/Field/SearchField';
import { OrderCategory } from '@/components/OrderCategory';
import { OrderTable } from '@/components/OrderTable';
import { TopBar } from '@/components/TopBar';
import { OrderContainer } from '@/container/order';
import { OrderStatus } from '@api/generated/axios-client';
import { SearchIcon, Tab, Tabs, TextField } from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';


const OrdersExchangePageContent = () => {
  const { showAlert, showSuccessAlert, showErrorAlert } = useAlert();
  const {
    orderCount,
    tabIndex,
    setTabIndex,
    refetchOrders,
    setOrderStatus,
    setKeyword,
    updateOrdersStatus,
    useCheck: { checked, setAllChecked, orderProductChecked, setOrderProductAllChecked },
  } = OrderContainer.useContext();
  const [stateOpen, setStateOpen] = useState(false);
  const [trackingOpen, setTrackingOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);

  useEffect(() => {
    setOrderStatus(OrderStatus.Exchanged);
    refetchOrders();
  }, []);

  const forceRefresh = () => {
    refetchOrders().then(() => {
      showAlert({
        type: 'success',
        text: '데이터를 갱신했습니다.',
        timeout: 800,
      });
    });
  };

  return (
    <>
      <TopBar title="교환관리" onClick={forceRefresh} />
      <div className="p-5 w-full">
        <Tabs>
          <Tab text="전체" active={tabIndex === 0} onClick={() => setTabIndex(0)} />
          <Tab text="일반배송" active={tabIndex === 1} onClick={() => setTabIndex(1)} />
          <Tab text="새벽배송" active={tabIndex === 2} onClick={() => setTabIndex(2)} />
          <Tab text="선물하기" active={tabIndex === 3} onClick={() => setTabIndex(3)} />
          {/* <Tab text="매장픽업" active={tabIndex === 4} onClick={() => setTabIndex(4)} />
          <Tab text="퀵(딜리버리)" active={tabIndex === 5} onClick={() => setTabIndex(5)} /> */}
        </Tabs>

        {/* 기능 버튼 */}
        <div className="flex justify-end mt-4">
          <SearchField
            className="w-96"
            onChange={e => {
              setKeyword(e.target.value);
            }}
          />
        </div>
        <div className="flex justify-between mt-4 mb-4">
          <button className="bg-gray-100 px-4 py-2 text-sm rounded-md" onClick={() => setStateOpen(true)}>
            상태변경
          </button>
          <div className="flex space-x-4 items-center">
            <div className="flex space-x-2 items-center">
              <TextField type="date" />
              <span className="flex-shrink-0">부터</span> <TextField type="date" />
              <div
                className="px-4 h-12 w-56 space-x-2
                 bg-gray-100 rounded-md flex justify-center items-center">
                <div className="flex-shrink-0">검색</div>
                <SearchIcon />
              </div>
            </div>
            <button
              className="px-4 py-2 border-2 border-gray-400 rounded-md text-gray-700 flex items-center"
              onClick={() => setTrackingOpen(true)}>
              송장입력 <Download className="ml-1" />
            </button>

            <button
              className="px-4 py-2 border-2 border-gray-400 rounded-md text-gray-700 flex items-center"
              onClick={() => setExportOpen(true)}>
              내보내기 <Download className="ml-1 transform rotate-180" />
            </button>
          </div>
        </div>

        {/* Alert 리스트 */}
        <SelectModalPopup
          open={stateOpen}
          onClose={() => setStateOpen(false)}
          onSubmit={status => {
            if (!checked.map(Number).length && !orderProductChecked.map(Number).length) {
              showAlert({
                type: 'warning',
                text: '선택한 주문서가 없습니다.',
              });
              setStateOpen(false);
              return;
            }

            updateOrdersStatus(checked.map(Number), orderProductChecked.map(Number), status)
              .then(() => {
                showSuccessAlert('변경');
                setStateOpen(false);
                setAllChecked([], false);
                setOrderProductAllChecked([], false);
              })
              .catch(showErrorAlert);
          }}
        />
        <FileModalPopup open={trackingOpen} onClose={() => setTrackingOpen(false)} />
        <DualSelectModalPopup open={exportOpen} onClose={() => setExportOpen(false)} />

        <div className="w-full flex border-b border-gray-100">
          <OrderCategory
            text={`교환관리(${orderCount?.[OrderStatus.Exchanged] || 0})`}
            active
            onClick={() => {}}
          />
        </div>

        <OrderTable />
      </div>
    </>
  );
};

export const OrdersExchangePage = () => {
  return (
    <OrderContainer.ContextProvider>
      <OrdersExchangePageContent />
    </OrderContainer.ContextProvider>
  );
};
