import React from 'react';
import { Field } from './Field';
import { TrackingInput } from '../TrackingInput';

interface TrackingFieldProps {
  label: string;
  property: string;
  disabled?: boolean;
  isEdit?: boolean;
  onSubmit?: () => void;
}

export const TrackingField: React.FC<TrackingFieldProps> = ({
  label,
  property,
  disabled,
  isEdit,
  onSubmit,
}) => {
  return (
    <Field
      label={label}
      render={row => <TrackingInput row={row} isEdit={isEdit} onSubmit={onSubmit} />}
    />
  );
};
