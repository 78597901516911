import { AxiosClient, AxiosQuery } from '@/api';
import { createContainer } from '@/container/createContainer';
import {
  IRequestCreateBannerDto,
  IRequestCreateDisplayCategoryDto,
  IRequestCreateDisplayProductDto,
  IRequestCreateRecommendProductDto,
  IRequestCreateSearchKeywordDto,
  IRequestCreateShopSettingDto,
  IRequestCreateStoryDto,
  IRequestCreateTopCategoryDto,
  IRequestUpdateBannerDto,
  IRequestUpdateDisplayCategoryDto,
  IRequestUpdateDisplayProductDto,
  IRequestUpdateRecommendProductDto,
  IRequestUpdateSearchKeywordDto,
  IRequestUpdateShopSettingDto,
  IRequestUpdateStoryDto,
  IRequestUpdateTopCategoryDto,
  RequestCreateBannerDto,
  RequestCreateDisplayCategoryDto,
  RequestCreateDisplayProductDto,
  RequestCreateRecommendProductDto,
  RequestCreateSearchKeywordDto,
  RequestCreateShopSettingDto,
  RequestCreateStoryDto,
  RequestCreateTopCategoryDto,
  RequestUpdateBannerDto,
  RequestUpdateDisplayCategoryDto,
  RequestUpdateDisplayProductDto,
  RequestUpdateRecommendProductDto,
  RequestUpdateSearchKeywordDto,
  RequestUpdateShopSettingDto,
  RequestUpdateStoryDto,
  RequestUpdateTopCategoryDto,
  SearchKeywordType,
} from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import { DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import axios from 'axios';
import { useState } from 'react';

export const mainHook = () => {
  const [topCategoryPage, setTopCategoryPage] = useState<number>(1);

  const { data: topCategories, refetch: refetchTopCategories } =
    AxiosQuery.Query.useGetManyBaseTopCategoryControllerTopCategoryQuery(
      {
        ...DefaultRequestCrudDto,
        page: topCategoryPage,
        limit: Const.DefaultPageLimitSize,
        sort: ['sorted,DESC'],
      },
      { refetchOnWindowFocus: false },
    );

  const deleteTopCategory = (id: number) =>
    AxiosClient.deleteOneBaseTopCategoryControllerTopCategory(id).then(() => refetchTopCategories());

  const createTopCategory = (param: IRequestCreateTopCategoryDto) =>
    AxiosClient.createOneBaseTopCategoryControllerTopCategory(new RequestCreateTopCategoryDto(param)).then(
      () => refetchTopCategories(),
    );

  const updateTopCategory = (id: number, param: IRequestUpdateTopCategoryDto) =>
    AxiosClient.updateOneBaseTopCategoryControllerTopCategory(
      id,
      new RequestUpdateTopCategoryDto(param),
    ).then(() => refetchTopCategories());

  const { data: searchKeywords, refetch: refetchSearchKeywords } =
    AxiosQuery.Query.useGetManyBaseSearchKeywordControllerSearchKeywordQuery(
      {
        ...DefaultRequestCrudDto,
        filter: [...DefaultRequestCrudDto.filter, `type||$eq||${SearchKeywordType.Frequent}`],
        sort: ['id,ASC'],
      },
      { refetchOnWindowFocus: false },
    );

  const deleteSearchKeyword = (id: number) =>
    AxiosClient.deleteOneBaseSearchKeywordControllerSearchKeyword(id);

  const deleteSearchKeywords = (ids: number[]) => {
    return axios.delete(`/search_keyword/delete-all`, {
      baseURL: process.env.REACT_APP_NEXT_PUBLIC_API_URL,
      headers: {
        Accept: 'application/json ',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data: { ids },
    });
  };

  const createSearchKeywords = (param: IRequestCreateSearchKeywordDto[]) => {
    return axios.post(
      `/search_keyword/create-all`,
      {
        data: param,
      },
      {
        baseURL: process.env.REACT_APP_NEXT_PUBLIC_API_URL,
        headers: {
          Accept: 'application/json ',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    );
  };

  const createSearchKeyword = (param: IRequestCreateSearchKeywordDto) =>
    AxiosClient.createOneBaseSearchKeywordControllerSearchKeyword(new RequestCreateSearchKeywordDto(param));

  const updateSearchKeywords = (id: number, param: IRequestUpdateSearchKeywordDto) =>
    AxiosClient.updateOneBaseSearchKeywordControllerSearchKeyword(
      id,
      new RequestUpdateSearchKeywordDto(param),
    );

  const { data: searchKeywordsRecommend, refetch: refetchSearchKeywordsRecommend } =
    AxiosQuery.Query.useGetManyBaseSearchKeywordControllerSearchKeywordQuery(
      {
        ...DefaultRequestCrudDto,
        filter: [...DefaultRequestCrudDto.filter, `type||$eq||${SearchKeywordType.Recommend}`],
        sort: ['id,ASC'],
      },
      { refetchOnWindowFocus: false },
    );

  const deleteSearchKeywordsRecommend = (id: number) =>
    AxiosClient.deleteOneBaseSearchKeywordControllerSearchKeyword(id).then(() =>
      refetchSearchKeywordsRecommend(),
    );

  const createSearchKeywordsRecommend = (param: IRequestCreateSearchKeywordDto) =>
    AxiosClient.createOneBaseSearchKeywordControllerSearchKeyword(
      new RequestCreateSearchKeywordDto(param),
    ).then(() => refetchSearchKeywordsRecommend());

  const updateSearchKeywordsRecommend = (id: number, param: IRequestUpdateSearchKeywordDto) =>
    AxiosClient.updateOneBaseSearchKeywordControllerSearchKeyword(
      id,
      new RequestUpdateSearchKeywordDto(param),
    ).then(() => refetchSearchKeywordsRecommend());

  const { data: stories, refetch: refetchStories } = AxiosQuery.Query.useGetManyBaseStoryControllerStoryQuery(
    {
      ...DefaultRequestCrudDto,
      filter: [...DefaultRequestCrudDto.filter, `isActive||$eq||true`],
      sort: ['sorted,DESC'],
    },
    { refetchOnWindowFocus: false },
  );

  const deleteStories = (ids: number[]) =>
    ids
      .filter(id => !!id && id !== -1)
      .map(id => AxiosClient.deleteOneBaseStoryControllerStory(id).then(() => refetchStories()));

  const createStories = (param: IRequestCreateStoryDto) =>
    AxiosClient.createOneBaseStoryControllerStory(new RequestCreateStoryDto(param)).then(() =>
      refetchStories(),
    );

  const updateStories = (id: number, param: IRequestUpdateStoryDto) =>
    AxiosClient.updateOneBaseStoryControllerStory(id, new RequestUpdateStoryDto(param)).then(() =>
      refetchStories(),
    );

  const [recommendProductPage, setRecommendProductPage] = useState<number>(1);

  const { data: recommendProducts, refetch: refetchRecommendProducts } =
    AxiosQuery.Query.useGetManyBaseRecommendProductControllerRecommendProductQuery(
      {
        ...DefaultRequestCrudDto,
        filter: [...DefaultRequestCrudDto.filter, `isActive||$eq||true`],
        page: recommendProductPage,
        limit: Const.DefaultPageLimitSize,
        sort: ['id,DESC'],
      },
      { refetchOnWindowFocus: false },
    );

  const createRecommendProducts = (param: IRequestCreateRecommendProductDto) =>
    AxiosClient.createOneBaseRecommendProductControllerRecommendProduct(
      new RequestCreateRecommendProductDto(param),
    ).then(() => refetchRecommendProducts());

  const updateRecommendProducts = (id: number, param: IRequestUpdateRecommendProductDto) =>
    AxiosClient.updateOneBaseRecommendProductControllerRecommendProduct(
      id,
      new RequestUpdateRecommendProductDto(param),
    ).then(() => refetchRecommendProducts());

  const deleteRecommendProducts = (id: number) =>
    AxiosClient.deleteOneBaseRecommendProductControllerRecommendProduct(id).then(() =>
      refetchRecommendProducts(),
    );

  const [displayCategoryPage, setDisplayCategoryPage] = useState<number>(1);

  const { data: displayCategories, refetch: refetchDisplayCategories } =
    AxiosQuery.Query.useGetManyBaseDisplayCategoryControllerDisplayCategoryQuery(
      {
        ...DefaultRequestCrudDto,
        filter: [...DefaultRequestCrudDto.filter, `isActive||$eq||true`],
        page: displayCategoryPage,
        limit: Const.DefaultPageLimitSize,
        join: ['categories', 'categories.title'],
        sort: ['sorted,DESC,createdAt,DESC'],
      },
      { refetchOnWindowFocus: false },
    );

  const createDisplayCategories = (param: IRequestCreateDisplayCategoryDto) =>
    AxiosClient.createOneBaseDisplayCategoryControllerDisplayCategory(
      new RequestCreateDisplayCategoryDto(param),
    ).then(() => refetchDisplayCategories());

  const updateDisplayCategories = (id: number, param: IRequestUpdateDisplayCategoryDto) =>
    AxiosClient.updateOneBaseDisplayCategoryControllerDisplayCategory(
      id,
      new RequestUpdateDisplayCategoryDto(param),
    ).then(() => refetchDisplayCategories());

  const deleteDisplayCategories = (id: number) =>
    AxiosClient.deleteOneBaseDisplayCategoryControllerDisplayCategory(id).then(() =>
      refetchDisplayCategories(),
    );

  const [displayProductPage, setDisplayProductPage] = useState<number>(1);

  const { data: displayProducts, refetch: refetchDisplayProducts } =
    AxiosQuery.Query.useGetManyBaseDisplayProductControllerDisplayProductQuery(
      {
        ...DefaultRequestCrudDto,
        filter: [
          ...DefaultRequestCrudDto.filter,
          `isActive||$eq||true`,
          'product.deleted||$eq||false',
          'product.isActive||$eq||true',
          'product.isHidden||$eq||false',
        ],
        page: displayProductPage,
        limit: Const.DefaultPageLimitSize,
        join: ['product', 'product.name'],
        sort: ['sorted,DESC,createdAt,DESC'],
      },
      { refetchOnWindowFocus: false },
    );

  const { data: productList } = AxiosQuery.Query.useGetManyBaseProductControllerProductQuery(
    {
      ...DefaultRequestCrudDto,
      filter: [
        ...DefaultRequestCrudDto.filter,
        `isActive||$eq||true`,
        'deleted||$eq||false',
        'isActive||$eq||true',
        'isHidden||$eq||false',
      ],
      sort: ['createdAt,DESC,sorted,ASC'],
    },
    { refetchOnWindowFocus: false },
  );

  const createDisplayProduct = (param: IRequestCreateDisplayProductDto) =>
    AxiosClient.createOneBaseDisplayProductControllerDisplayProduct(
      new RequestCreateDisplayProductDto(param),
    ).then(() => refetchDisplayProducts());

  const updateDisplayProduct = (id: number, param: IRequestUpdateDisplayProductDto) =>
    AxiosClient.updateOneBaseDisplayProductControllerDisplayProduct(
      id,
      new RequestUpdateDisplayProductDto(param),
    ).then(() => refetchDisplayProducts());

  const deleteDisplayProduct = (id: number) =>
    AxiosClient.deleteOneBaseDisplayProductControllerDisplayProduct(id).then(() => refetchDisplayProducts());

  const { data: shopSettings } = AxiosQuery.Query.useGetManyBaseShopSettingControllerShopSettingQuery(
    {
      ...DefaultRequestCrudDto,
      limit: 1,
      sort: ['id,DESC'],
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const updateShopSetting = (id: number, param: IRequestUpdateShopSettingDto) =>
    AxiosClient.updateOneBaseShopSettingControllerShopSetting(id, new RequestUpdateShopSettingDto(param));

  const createShopSetting = (param: IRequestCreateShopSettingDto) =>
    AxiosClient.createOneBaseShopSettingControllerShopSetting(new RequestCreateShopSettingDto(param));

  const [mainBannerTabIndex, setMainBannerTabIndex] = useState(0);

  const { data: mainBanner, refetch: refetchMainBanner } =
    AxiosQuery.Query.useGetManyBaseBannerControllerBannerQuery(
      {
        ...DefaultRequestCrudDto,
        filter: [...DefaultRequestCrudDto.filter, `sorted||$eq||${mainBannerTabIndex}`],
        sort: ['id,DESC'],
      },
      { refetchOnWindowFocus: false },
    );

  const createMainBanners = (param: IRequestCreateBannerDto) => {
    return AxiosClient.createOneBaseBannerControllerBanner(new RequestCreateBannerDto(param)).then(() =>
      refetchMainBanner(),
    );
  };

  const updateMainBanners = (id: number, param: IRequestUpdateBannerDto) =>
    AxiosClient.updateOneBaseBannerControllerBanner(id, new RequestUpdateBannerDto(param)).then(() =>
      refetchMainBanner(),
    );

  return {
    topCategories,
    setTopCategoryPage,
    createTopCategory,
    updateTopCategory,
    deleteTopCategory,
    searchKeywords,
    refetchSearchKeywords,
    deleteSearchKeyword,
    deleteSearchKeywords,
    createSearchKeyword,
    createSearchKeywords,
    updateSearchKeywords,
    searchKeywordsRecommend,
    createSearchKeywordsRecommend,
    deleteSearchKeywordsRecommend,
    updateSearchKeywordsRecommend,
    stories,
    deleteStories,
    createStories,
    updateStories,
    recommendProducts,
    setRecommendProductPage,
    createRecommendProducts,
    updateRecommendProducts,
    deleteRecommendProducts,
    displayCategories,
    setDisplayCategoryPage,
    createDisplayCategories,
    updateDisplayCategories,
    deleteDisplayCategories,
    displayProducts,
    productList,
    setDisplayProductPage,
    createDisplayProduct,
    updateDisplayProduct,
    deleteDisplayProduct,
    shopSetting: shopSettings?.data?.[0],
    updateShopSetting,
    createShopSetting,
    mainBanner,
    createMainBanners,
    updateMainBanners,
    mainBannerTabIndex,
    setMainBannerTabIndex,
  };
};

export const MainContainer = createContainer(mainHook);
