import { Datagrid } from '@/components/Datagrid';
import { Field } from '@/components/Field/Field';
import { NormalField } from '@/components/Field/NormalField';
import { DeliveryZone, DeliveryZoneType } from '@api/generated/axios-client';
import { MemberContainer } from '@container/member';
import React from 'react';

export const UserDetailAddress = () => {
  const { userShippingAddresses } = MemberContainer.useContext();

  if (!userShippingAddresses?.data) return null;

  return (
    <>
      <Datagrid data={userShippingAddresses.data}>
        <NormalField label="ID" property="id" />
        <Field
          label="배송유형"
          render={row => (
            <div className="text-sm">
              {!!row.deliveryZones?.find((d: DeliveryZone) => d.zoneType === DeliveryZoneType.Early) && (
                <div>새벽배송</div>
              )}
              {!!row.deliveryZones?.find((d: DeliveryZone) => d.zoneType === DeliveryZoneType.Quick) && (
                <div>딜리버리</div>
              )}
              <div>일반배송</div>
            </div>
          )}
        />
        <NormalField label="주소" property={'address'} subProperty={'detailAddress'} />
        <NormalField label="공동현관번호" property="entrancePassword" />
      </Datagrid>
    </>
  );
};
