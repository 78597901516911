import { DisplayProduct, IRequestCreateDisplayProductDto } from '@api/generated/axios-client';
import { useAlert } from '@components/Alert/Alert';
import { MainContainer } from '@container/main';
import { Button, TextField } from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

interface ExposureProductModalPopupProps {
  open: boolean;
  onClose: () => void;
  item?: DisplayProduct;
}

export const ExposureProductModalPopup: React.FC<ExposureProductModalPopupProps> = ({
  onClose,
  item,
  open,
}) => {
  const { createDisplayProduct, updateDisplayProduct, deleteDisplayProduct, productList } =
    MainContainer.useContext();
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const [weight, setWeight] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<number>(0);
  // const [searchResult, setSearchResult] = useState<ProductDetailDto[]>([]);

  useEffect(() => {
    setSelectedId(item?.id || 0);
    setWeight(item?.sorted || 0);
  }, [item]);

  const onDelete = () => {
    if (!item?.id) return;

    deleteDisplayProduct(item.id)
      .then(() => showSuccessAlert('삭제'))
      .then(() => onClose())
      .catch(showErrorAlert);
  };

  const onSave = () => {
    if (!item?.id) {
      let params: IRequestCreateDisplayProductDto = {
        isActive: true,
        sorted: weight,
        productId: selectedId,
      };
      createDisplayProduct(params)
        .then(() => showSuccessAlert())
        .then(() => onClose())
        .catch(showErrorAlert);
    } else {
      updateDisplayProduct(item?.id, {
        isActive: true,
        sorted: weight,
        productId: selectedId,
      })
        .then(() => showSuccessAlert())
        .then(() => onClose())
        .catch(showErrorAlert);
    }
  };

  return (
    <div>
      {open && (
        <div
          className="fixed z-100 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onClose}>
                  <span className="sr-only">Close</span>

                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <h3 className="text-lg leading-6 font-semibold text-gray-900 mb-5" id="modal-title">
                    노출 상품 관리
                  </h3>
                  <div className="space-y-4 mb-6">
                    <div>
                      <label className="block mb-1 text-sm text-gray-800">상품검색</label>
                      {/* 디자인도 기획도 없어서 상상으로 함 */}
                      <AsyncSelect
                        placeholder="상품검색"
                        getOptionLabel={data => data.name}
                        getOptionValue={data => String(data.id)}
                        // loadOptions={(inputValue: string, callback) => {
                        //   getSearchProducts(inputValue).then(result =>
                        //     callback(result.data),
                        //   );
                        // }}
                        defaultOptions={productList?.data}
                        value={productList?.data.find(row => String(row.id) === String(item?.productId))}
                        onChange={newValue => {
                          setSelectedId(newValue?.id || 0);
                        }}
                      />
                    </div>
                    <TextField
                      label="노출우선순위"
                      value={weight}
                      onChange={e => setWeight(Number(e.target.value))}
                    />
                  </div>
                </div>
              </div>
              <div className="flex mt-4 justify-center space-x-4">
                <div className="flex flex-col w-56">
                  {!!item?.id && <Button text="삭제하기" onClick={onDelete} variant="outlined" />}
                </div>
                <div className="flex flex-col w-56">
                  <Button text="저장하기" onClick={onSave} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
