import { Button } from '../Button';
import { TextField } from '../TextField';
import { useAlert } from '@/components/Alert/Alert';
import { TopCategory } from '@api/generated/axios-client';
import { MainContainer } from '@container/main';
import React, { useEffect, useState } from 'react';

interface TopCategoryPopupProps {
  open: boolean;
  item?: TopCategory | null;
  onClose: () => void;
}

export const TopCategoryPopup: React.FC<TopCategoryPopupProps> = ({ onClose, item, open }) => {
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const { topCategories, createTopCategory, updateTopCategory, deleteTopCategory } =
    MainContainer.useContext();

  const [sorted, setSorted] = useState('0');
  const [categoryTitle, setCategoryTitle] = useState('');
  const [actionUrl, setActionUrl] = useState('');

  const onSave = () => {
    if (!item) {
      if (topCategories?.data.find(t => t.sorted === Number(sorted))) {
        alert('우선순위가 중복됩니다. 다시 입력해주세요');
        return;
      }
      createTopCategory({
        categoryTitle,
        actionUrl,
        sorted: Number(sorted),
        categoryId: undefined,
      })
        .then(() => showSuccessAlert())
        .then(() => onClose())
        .catch(showErrorAlert);
    } else {
      updateTopCategory(item.id, {
        categoryTitle,
        actionUrl,
        sorted: Number(sorted),
      })
        .then(() => showSuccessAlert())
        .then(() => onClose())
        .catch(showErrorAlert);
    }
  };

  const onDelete = () => {
    if (!item?.id) return;

    deleteTopCategory(item.id)
      .then(() => showSuccessAlert('삭제'))
      .then(() => onClose())
      .catch(showErrorAlert);
  };

  useEffect(() => {
    if (item) {
      setCategoryTitle(item.categoryTitle);
      setActionUrl(item.actionUrl);
      setSorted(String(item.sorted));
    }
  }, [item]);

  return (
    <div>
      {open && (
        <div
          className="fixed z-100 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onClose}>
                  <span className="sr-only">Close</span>

                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <h3 className="text-lg leading-6 font-semibold text-gray-900 mb-5" id="modal-title">
                    최상단 카테고리 관리
                  </h3>
                  <div className="space-y-4 mb-6">
                    <TextField
                      label="카테고리 텍스트"
                      value={categoryTitle}
                      onChange={e => setCategoryTitle(e.target.value)}
                    />
                    <TextField
                      label="클릭시 이동 URL"
                      value={actionUrl}
                      onChange={e => setActionUrl(e.target.value)}
                    />
                    <TextField
                      label="노출우선순위"
                      value={sorted}
                      onChange={e => setSorted(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex mt-4 justify-center space-x-4">
                <div className="w-full flex flex-col">
                  <Button
                    text="삭제하기"
                    className="border border-gray-800 text-gray-800"
                    onClick={onDelete}
                  />
                </div>
                <div className="w-full flex flex-col">
                  <Button text="저장하기" className="bg-gray-800 text-white" onClick={onSave} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
