enum RoutePath {
  Root = '/',
  Login = '/admin/login',
  Home = '/admin',
  ProductList = '/admin/product/list/:categoryId',
  SubCategoryProductList = '/admin/product/list/:categoryId/:subCategoryId',
  ProductCategoryEdit = '/admin/product/list/:categoryId/edit',
  UserNormalList = '/admin/user/normal/1',
  UserAdminList = '/admin/user/admin/13',
  OrderList = '/admin/orders/main',
  OrderCancelList = '/admin/orders/cancel',
  OrderReturnList = '/admin/orders/return',
  OrderExchangeList = '/admin/orders/exchange',
}

export default RoutePath;
