import { FilePondFile, FilePondOptions } from 'filepond';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
//@ts-ignore
import FilePondPluginVideoPreview from 'filepond-plugin-media-preview';
import { get, map } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { getFileExtension, getFileNameInUrl } from '@utils/utils';
import { FILE_EXTENSION_MAP } from '@constants/file';
import { S3ControllerQuery, S3ResourceType } from '@api/generated/axios-client';

//@ts-ignore
registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginGetFile,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginVideoPreview,
);
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css';

interface FileUploadProps {
  initFiles?: string[];
  setFiles: (files: FilePondFile[]) => void;
  options?: FilePondOptions;
  placeholder?: string;
}

function FileUpload(props: FileUploadProps) {
  const { initFiles, setFiles: setPropsFiles, options, placeholder } = props;
  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    setFiles(
      map(initFiles, url => ({
        source: url,
        options: {
          type: 'local',
        },
      })),
    );
  }, [JSON.stringify(initFiles)]);

  useEffect(() => {
    setPropsFiles(files);
  }, [files]);

  return (
    <FilePond
      files={files}
      onupdatefiles={setFiles}
      allowMultiple={true}
      allowReorder={true}
      onerror={(err, file) => {
        setFiles(files.filter(prevFile => prevFile.id !== file?.id));
      }}
      name="files"
      server={{
        load: (source, load, error) => {
          const fileName = getFileNameInUrl(source);
          const extention = getFileExtension(fileName);
          const contentType = extention && get(FILE_EXTENSION_MAP, extention);

          fetch(source)
            .then(function (response) {
              response.blob().then(function (myBlob) {
                load(myBlob.slice(0, myBlob.size, contentType || undefined));
              });
            })
            .catch(e => error(e));
        },
        process: async (fieldName, file, metadata, load, error) => {
          try {
            const result = await S3ControllerQuery.Client.s3UploadFile(S3ResourceType.Product, {
              data: file,
              fileName: file.name,
            });
            load(result.fullUrl);
          } catch (e) {
            console.log('e : ', e);
            error('file upload error');
          }
        },
      }}
      onprocessfile={(error, file) => {
        setFiles(prev => {
          const findIndex = prev.findIndex(prevFile => prevFile.id === file.id);
          if (findIndex !== -1) {
            prev[findIndex] = file;
            return [...prev];
          } else {
            return [...prev, file];
          }
        });
      }}
      acceptedFileTypes={['image/*', 'video/*']}
      labelIdle={placeholder ? placeholder : '파일을 드래그 하거나 클릭해서 업로드 해주세요'}
      onreorderfiles={reorderFiles => props.setFiles(reorderFiles)}
      styleItemPanelAspectRatio={'1'}
      {...options}
    />
  );
}
export default memo(FileUpload);
