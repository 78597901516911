import { LoginPage } from '@/pages/LoginPage';
import MainPage from '@/pages/MainPage';
import RoutePath from '@constants/RoutePath';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

export const OfficeRouter = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path={[RoutePath.Login, RoutePath.Root]} component={LoginPage} exact />
          <Route path={RoutePath.Home} component={MainPage} />
        </Switch>
      </BrowserRouter>
    </>
  );
};
