import { Select } from '@entropyparadox/reusable-react';
import React, { ChangeEvent, useState } from 'react';

interface ProductSelectModalPopupProps {
  open: boolean;
  onClose: () => void;
  // stateType 사용
  onSubmit: (type: number) => void;
}

export const StateType = [
  { id: 1, name: '판매중' },
  { id: 2, name: '품절' },
  { id: 3, name: '숨김' },
];

export const ProductSelectModalPopup: React.FC<ProductSelectModalPopupProps> = ({
  onClose,
  open,
  onSubmit,
}) => {
  const [type, setType] = useState(1);

  return (
    <div>
      {open && (
        <div
          className="fixed z-100 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            />

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onClose}>
                  <span className="sr-only">Close</span>

                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <h3
                    className="text-lg leading-6 font-semibold text-gray-900 mb-5"
                    id="modal-title">
                    상품 상태 변경
                  </h3>
                  {/* <div className="mt-2 mb-4">
                    <p className="text-sm text-gray-500">{description}</p>
                  </div> */}
                  <Select
                    label="상품 상태 선택"
                    placeholder="변경할 상태를 선택해주세요."
                    value={type}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                      setType(Number(e.target.value))
                    }>
                    {StateType.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="mt-4 flex flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white sm:text-sm mx-auto"
                  onClick={() => {
                    onSubmit(type);
                    setType(1);
                  }}>
                  상태변경하기
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
