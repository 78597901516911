import { AxiosClient, AxiosQuery } from '@/api';
import { createContainer } from '@/container/createContainer';
import {
  Category,
  IRequestCreateCategoryDto,
  IRequestCreateProductDto,
  IRequestUpdateCategoryDto,
  IRequestUpdateProductDto,
  RequestCreateCategoryDto,
  RequestCreateProductDto,
  RequestUpdateCategoryDto,
  RequestUpdateProductDto,
} from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import { DefaultGetOneBaseQueryParameters, DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import { useDebounceState } from '@utils/useDebounceState';
import React, { useEffect, useState } from 'react';

const ProductCategoryHook = () => {
  const [selectedProductId, setSelectedProductId] = useState<number>(0);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>();
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<number>();
  const [categoryProductPage, setCategoryProductPage] = useState<number>(1);
  const [categoryProductFilter, setCategoryProductFilter] = useState<string[]>([]);
  const [keyword, setKeyword, debounceKeyword] = useDebounceState('', 500);

  const { data: categoryList, refetch: refetchCategoryList } =
    AxiosQuery.Query.useGetManyBaseCategoryControllerCategoryQuery(
      {
        ...DefaultRequestCrudDto,
        join: ['subCategories', 'categoryBanners', 'userGroupCategories'],
        filter: [...DefaultRequestCrudDto.filter, 'categoryId||$isnull'],
        sort: ['sorted,DESC', 'createdAt,ASC'],
      },
      { refetchOnWindowFocus: false },
    );

  const { data: categoryProductList, refetch: refetchCategoryProductList } =
    AxiosQuery.Query.useGetManyBaseProductControllerProductQuery(
      {
        ...DefaultRequestCrudDto,
        page: categoryProductPage,
        limit: Const.DefaultPageLimitSize,
        filter: [
          ...DefaultRequestCrudDto.filter,
          `categoryProducts.categoryId||$eq||${selectedSubCategoryId || selectedCategoryId}`,
          ...categoryProductFilter,
          ...(debounceKeyword ? [`name||$cont||%${debounceKeyword}%`] : []),
        ],
        join: ['productMedias', 'categoryProducts'],
        sort: ['id,DESC'],
      },
      {
        enabled: !!selectedCategoryId || !!selectedSubCategoryId,
        refetchOnWindowFocus: false,
      },
    );

  const { data: product, refetch: refetchProduct } =
    AxiosQuery.Query.useGetOneBaseProductControllerProductQuery(
      {
        ...DefaultGetOneBaseQueryParameters,
        id: selectedProductId,
        join: [
          'productMedias',
          'productItems',
          'productItems.material',
          'categoryProducts',
          'productOptionGroups',
          'productOptionGroups.productOptions',
          'productOptionGroups.productOptions.material',
        ],
      },
      {
        enabled: !!selectedProductId,
        refetchOnWindowFocus: false,
      },
    );

  const createProduct = (param: IRequestCreateProductDto): Promise<void> =>
    AxiosClient.createOneBaseProductControllerProduct(new RequestCreateProductDto(param)).then(() => {
      (!!selectedCategoryId || !!selectedSubCategoryId) && refetchCategoryProductList();
      !!selectedProductId && refetchProduct();
    });

  const updateProducts = (ids: number[], param: IRequestUpdateProductDto): Promise<void> =>
    Promise.all(
      ids
        .filter(id => id !== -1)
        .map(id => AxiosClient.updateOneBaseProductControllerProduct(id, new RequestUpdateProductDto(param))),
    ).then(() => {
      (!!selectedCategoryId || !!selectedSubCategoryId) && refetchCategoryProductList();
      !!selectedProductId && refetchProduct();
    });

  const deleteProducts = (ids: number[]): Promise<void> =>
    Promise.all(
      ids.filter(id => id !== -1).map(id => AxiosClient.deleteOneBaseProductControllerProduct(id)),
    ).then(() => {
      (!!selectedCategoryId || !!selectedSubCategoryId) && refetchCategoryProductList();
      refetchProduct();
      refetchAllAdminCategoryProducts();
    });

  const copyProducts = (ids: number[]): Promise<void> =>
    AxiosClient.copyProductProductControllerResponseBoolean(ids.filter(id => id !== -1).map(String)).then(
      () => {
        (!!selectedCategoryId || !!selectedSubCategoryId) && refetchCategoryProductList();
        refetchProduct();
        refetchAllAdminCategoryProducts();
      },
    );

  const createCategory = (param: IRequestCreateCategoryDto): Promise<void> =>
    AxiosClient.createOneBaseCategoryControllerCategory(new RequestCreateCategoryDto(param)).then(() => {
      refetchCategoryList();
    });

  const updateCategory = (id: number, param: IRequestUpdateCategoryDto): Promise<void> =>
    AxiosClient.updateOneBaseCategoryControllerCategory(id, new RequestUpdateCategoryDto(param)).then(() => {
      refetchCategoryList();
    });

  const deleteCategory = (id: number): Promise<void> =>
    AxiosClient.deleteOneBaseCategoryControllerCategory(id).then(() => {
      refetchCategoryList();
    });

  useEffect(() => {
    if (!selectedCategoryId && selectedCategory && selectedCategoryId === selectedCategory.id) return;

    setSelectedCategory(categoryList?.data.find(item => item.id === selectedCategoryId));
  }, [selectedCategoryId, categoryList]);

  const searchCategories = (keyword: string) =>
    AxiosQuery.Query.useGetManyBaseCategoryControllerCategoryQuery(
      {
        ...DefaultRequestCrudDto,
        filter: [
          ...DefaultRequestCrudDto.filter,
          'categoryId||$isnull',
          `categories.name||$contL||${keyword}`,
        ],
        sort: ['sorted,ASC'],
      },
      { refetchOnWindowFocus: false },
    );

  const { data: allCategoryProducts } = AxiosQuery.Query.useGetManyBaseProductControllerProductQuery(
    {
      ...DefaultRequestCrudDto,
      filter: [
        ...DefaultRequestCrudDto.filter,
        `categoryProducts.categoryId||$eq||${selectedSubCategoryId || selectedCategoryId}`,
        ...categoryProductFilter,
        ...(debounceKeyword ? [`name||$cont||%${debounceKeyword}%`] : []),
      ],
      join: ['productMedias', 'categoryProducts'],
      sort: ['id,DESC'],
    },
    {
      enabled: !!selectedCategoryId || !!selectedSubCategoryId,
      refetchOnWindowFocus: false,
    },
  );

  const { data: allAdminCategoryProducts, refetch: refetchAllAdminCategoryProducts } =
    AxiosQuery.Query.useGetManyBaseProductControllerProductQuery(
      {
        ...DefaultRequestCrudDto,
        filter: [
          ...DefaultRequestCrudDto.filter,
          ...categoryProductFilter,
          ...(debounceKeyword ? [`name||$cont||%${debounceKeyword}%`] : []),
        ],
        join: ['productMedias', 'categoryProducts'],
        sort: ['id,DESC'],
      },
      {
        refetchOnWindowFocus: false,
      },
    );

  return {
    setSelectedCategoryId,
    selectedCategory,
    setSelectedCategory,
    selectedSubCategoryId,
    setSelectedSubCategoryId,
    categoryList,
    categoryProductList,
    setCategoryProductPage,
    setCategoryProductFilter,
    keyword,
    setKeyword,
    product,
    setSelectedProductId,
    createProduct,
    updateProducts,
    deleteProducts,
    copyProducts,
    createCategory,
    updateCategory,
    deleteCategory,
    searchCategories,
    allCategoryProducts,
    allAdminCategoryProducts,
  };
};

export const ProductCategoryContainer = createContainer(ProductCategoryHook);
