import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface SideTabProps {
  to: string;
  text: string;
}

export const SideTab: React.FC<SideTabProps> = ({ to, text }) => {
  const { pathname } = useLocation();
  return (
    <div>
      <Link
        to={to}
        className={
          pathname.startsWith(to)
            ? 'text-brand-gray-1 flex items-center px-4 py-2 text-sm mx-3 font-semibold'
            : 'text-gray-400 hover:bg-gray-100 flex items-center px-4 py-2 text-sm cursor-pointer mx-3'
        }>
        {text}
      </Link>
    </div>
  );
};
