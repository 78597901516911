import { ReactComponent as Download } from '../assets/svg/download.svg';
import { useAlert } from '@/components/Alert/Alert';
import { NomalModalPopup } from '@/components/Alert/NomalModalPopup';
import { ProductSelectModalPopup } from '@/components/Alert/ProductSelectModalPopup';
import { Datagrid } from '@/components/Datagrid';
import { Field } from '@/components/Field/Field';
import { NormalField, NormalNumberField } from '@/components/Field/NormalField';
import { SearchField } from '@/components/Field/SearchField';
import Pagination from '@/components/Pagination';
import { useCheckField } from '@/utils/hooks/useCheckField';
import { ProductCategoryContainer } from '@container/productCategory';
import { Checkbox, Tab, Tabs } from '@entropyparadox/reusable-react';
import { PageHelper } from '@utils/PageHelper';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory, useParams } from 'react-router';


export const ProductTablePage = () => {
  const history = useHistory();
  const { subCategoryId, categoryId } = useParams() as {
    subCategoryId: string;
    categoryId: string;
  };
  const [tabIndex, setTabIndex] = useState(0);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const { checked, setAllChecked, isChecked, setChecked } = useCheckField();
  const { showSuccessAlert, showErrorAlert, showWarningAlert } = useAlert();

  const {
    setSelectedCategoryId,
    setSelectedSubCategoryId,
    categoryProductList,
    setCategoryProductPage,
    setCategoryProductFilter,
    keyword,
    setKeyword,
    deleteProducts,
    updateProducts,
    copyProducts,
    allCategoryProducts,
    allAdminCategoryProducts,
  } = ProductCategoryContainer.useContext();

  const clear = () => {
    setCopyModalOpen(false);
    setDeleteModalOpen(false);
    setAllChecked([], false);
  };

  const excelHeaders = [
    { label: 'ID', key: 'id' },
    { label: '등록', key: 'createdAt' },
    { label: '상품명', key: 'name' },
    { label: '상태', key: 'isHidden' },
    { label: '재고', key: 'limitSalesCount' },
  ];

  useEffect(() => {
    setSelectedSubCategoryId(subCategoryId ? Number(subCategoryId) : undefined);
    setSelectedCategoryId(categoryId ? Number(categoryId) : undefined);
    setTabIndex(0);
    setCategoryProductFilter([]);
    clear();
  }, [subCategoryId, categoryId]);

  if (!categoryProductList && !allAdminCategoryProducts) return null;

  const pageInfo = PageHelper.getPageInfo({
    ...categoryProductList,
    gotoPage: setCategoryProductPage,
  });

  const adminPageInfo = PageHelper.getPageInfo({
    ...allAdminCategoryProducts,
    gotoPage: setCategoryProductPage,
  });

  const handleChangeTableIndex = (index: number) => {
    setTabIndex(index);
    const filter = [];
    if (index === 1) {
      // 판매중
      filter.push('isSoldout||$eq||false');
      filter.push('isHidden||$eq||false');
    } else if (index === 2) {
      // 품절
      filter.push('isSoldout||$eq||true');
    } else if (index === 3) {
      // 숨김
      filter.push('isHidden||$eq||true');
    }

    if (keyword) {
      filter.push('isHidden||$eq||true');
    }

    setCategoryProductFilter(filter);
    clear();
  };

  const handleDelete = () => {
    deleteProducts(checked.map(Number))
      .then(() => showSuccessAlert('삭제'))
      .then(() => setDeleteModalOpen(false))
      .catch(showErrorAlert)
      .finally(clear);
  };

  const handleCopy = () => {
    copyProducts(checked.map(Number))
      .then(() => showSuccessAlert('삭제'))
      .then(() => setCopyModalOpen(false))
      .catch(showErrorAlert)
      .finally(clear);
  };

  /**
   * [기존 주석 유지]
   * 사실 숨김상태와 품절상태는 상호 배타적이지 않아서
   * - 판매중: isSoldout=false, isHidden=false,
   * - 품절: isSoldout=true, isHidden=true
   * - 숨김: isSoldout=false, isHidden=true 로 보내면 됨 (나중에 추가개발을 고려해서 이대로 작업)
   *
   * * @param type - { id: 1, name: '판매중' }, { id: 2, name: '품절' }, { id: 3, name: '숨김' }
   */
  const handleUpdateStatus = (type: number) => {
    const param =
      type === 3
        ? { isHidden: true }
        : type === 2
        ? { isSoldout: true }
        : { isHidden: false, isSoldout: false };

    updateProducts(checked.map(Number), param)
      .then(() => showSuccessAlert('변경'))
      .then(() => setUpdateModalOpen(false))
      .catch(showErrorAlert)
      .finally(clear);
  };
  return (
    <div>
      <NomalModalPopup
        title="정말 삭제하시겠습니까?"
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onClick={handleDelete}
      />
      <NomalModalPopup
        title="해당 상품을 복제하시겠습니까?"
        open={copyModalOpen}
        onClose={() => setCopyModalOpen(false)}
        onClick={handleCopy}
      />
      <ProductSelectModalPopup
        open={updateModalOpen}
        onClose={() => setUpdateModalOpen(false)}
        onSubmit={handleUpdateStatus}
      />
      <Tabs>
        <Tab text="전체" active={tabIndex === 0} onClick={() => handleChangeTableIndex(0)} />
        <Tab text="판매중" active={tabIndex === 1} onClick={() => handleChangeTableIndex(1)} />
        <Tab text="품절" active={tabIndex === 2} onClick={() => handleChangeTableIndex(2)} />
        <Tab text="숨김" active={tabIndex === 3} onClick={() => handleChangeTableIndex(3)} />
      </Tabs>
      <div className="flex justify-between items-center my-4">
        <div className="space-x-4">
          <button
            className="bg-gray-100 px-4 py-2 text-sm rounded-md"
            onClick={() => {
              checked.length === 0 ? showWarningAlert('선택된 상품이 없습니다') : setUpdateModalOpen(true);
            }}>
            상태변경
          </button>
          <button
            className="bg-gray-100 px-4 py-2 text-sm rounded-md"
            onClick={() => {
              checked.length === 0 ? showWarningAlert('선택된 상품이 없습니다') : setDeleteModalOpen(true);
            }}>
            삭제
          </button>
          <button
            className="bg-gray-100 px-4 py-2 text-sm rounded-md"
            onClick={() => {
              checked.length === 0 ? showWarningAlert('선택된 상품이 없습니다') : setCopyModalOpen(true);
            }}>
            복제
          </button>
        </div>
        <div>
          <SearchField className="w-96" value={keyword} onChange={e => setKeyword(e.target.value)} />
        </div>
        <div className="flex space-x-4">
          <button
            className="bg-black text-white px-4 py-2 text-sm rounded-md"
            onClick={() => history.push('/admin/product/add')}>
            상품추가
          </button>
          {allCategoryProducts && (
            <CSVLink
              headers={excelHeaders}
              data={allCategoryProducts?.data.map(categoryProduct => {
                return {
                  id: categoryProduct.id,
                  createdAt: new Date(categoryProduct.createdAt).toLocaleDateString(),
                  name: categoryProduct.name,
                  isHidden: categoryProduct.isHidden,
                  limitSalesCount: categoryProduct.limitSalesCount,
                };
              })}
              filename={'product.csv'}
              className="px-4 py-2 border-2 border-gray-400 rounded-md text-gray-700 flex items-center "
              target="_blank">
              내보내기
              <Download className="ml-1 transform rotate-180" />
            </CSVLink>
          )}
        </div>
      </div>
      {categoryId === '0' && !!allAdminCategoryProducts?.data && (
        <Datagrid data={allAdminCategoryProducts.data}>
          <Field
            label=""
            render={row => (
              <Checkbox
                checked={isChecked(row.id || -1)}
                onChange={e => {
                  // 전체
                  if (!row.id) {
                    setAllChecked(
                      allAdminCategoryProducts.data.map(item => item.id).concat([-1]),
                      e.target.checked,
                    );
                    return;
                  }

                  setChecked(row.id, e.target.checked);
                }}
              />
            )}
          />
          <NormalField label="ID" property="id" />
          <Field
            label="등록일"
            render={row => <div className="text-sm">{new Date(row.createdAt).toLocaleDateString()}</div>}
          />
          <NormalField label="상품명" property="name" />
          <Field
            label="이미지"
            render={row => (
              <div className="w-10 h-10 relative">
                <img
                  src={row.productMedias[0].thumbnailUrl}
                  className="object-cover absolute w-full h-full"
                />
              </div>
            )}
          />
          <NormalNumberField label="판매가" property="discountedPrice" />
          <Field
            label="상태"
            render={row => (
              <div className="text-sm">
                {row.isSoldout && row.isHidden ? '품절' : row.isHidden ? '숨김' : '판매중'}
              </div>
            )}
          />
          <Field label="재고" render={row => <div className="text-sm">{row.limitSalesCount}</div>} />
          <Field
            label="상세보기"
            render={row => (
              <button
                className="bg-gray-100 px-4 py-2 text-sm rounded-md"
                onClick={() => history.push(`/admin/product/edit/${row.id}`)}>
                상세보기
              </button>
            )}
          />
        </Datagrid>
      )}

      {categoryId !== '0' && !!categoryProductList?.data && (
        <Datagrid data={categoryProductList.data}>
          <Field
            label=""
            render={row => (
              <Checkbox
                checked={isChecked(row.id || -1)}
                onChange={e => {
                  // 전체
                  if (!row.id) {
                    setAllChecked(
                      categoryProductList.data.map(item => item.id).concat([-1]),
                      e.target.checked,
                    );
                    return;
                  }

                  setChecked(row.id, e.target.checked);
                }}
              />
            )}
          />
          <NormalField label="ID" property="id" />
          <Field
            label="등록일"
            render={row => <div className="text-sm">{new Date(row.createdAt).toLocaleDateString()}</div>}
          />
          <NormalField label="상품명" property="name" />
          <Field
            label="이미지"
            render={row => (
              <div className="w-10 h-10 relative">
                <img
                  src={row.productMedias[0].thumbnailUrl}
                  className="object-cover absolute w-full h-full"
                />
              </div>
            )}
          />
          <NormalNumberField label="판매가" property="discountedPrice" />
          <Field
            label="상태"
            render={row => (
              <div className="text-sm">
                {row.isSoldout && row.isHidden ? '품절' : row.isHidden ? '숨김' : '판매중'}
              </div>
            )}
          />
          <Field
            label="재고"
            render={row => (
              <div className="text-sm">
                {/* TODO: 상품 재고, limitSalesCount와 재료(material count) 중 작은거 해야할까? */}
                {row.limitSalesCount}
              </div>
            )}
          />
          <Field
            label="상세보기"
            render={row => (
              <button
                className="bg-gray-100 px-4 py-2 text-sm rounded-md"
                onClick={() => history.push(`/admin/product/edit/${row.id}`)}>
                상세보기
              </button>
            )}
          />
        </Datagrid>
      )}
      {categoryId === '0' ? <Pagination {...adminPageInfo} /> : <Pagination {...pageInfo} />}
    </div>
  );
};
