import Pagination from '../components/Pagination';
import { ReactComponent as Download } from '@/assets/svg/download.svg';
import { useAlert } from '@/components/Alert/Alert';
import { NomalModalPopup } from '@/components/Alert/NomalModalPopup';
import { Datagrid } from '@/components/Datagrid';
import { Field } from '@/components/Field/Field';
import { NormalField, NormalNumberField } from '@/components/Field/NormalField';
import { SearchField } from '@/components/Field/SearchField';
import { MemberContainer } from '@/container/member';
import { useCheckField } from '@/utils/hooks/useCheckField';
import { Role } from '@api/generated/axios-client';
import { Checkbox } from '@entropyparadox/reusable-react';
import { PageHelper } from '@utils/PageHelper';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory, useParams } from 'react-router';

const NomalUserTableContent = () => {
  const history = useHistory();
  const { tagId } = useParams() as { tagId: string };
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { checked, setAllChecked, isChecked, setChecked } = useCheckField();
  const { showSuccessAlert, showErrorAlert } = useAlert();

  const {
    tags,
    setSelectTagId,
    setUserType,
    setUserPage,
    users,
    userSearchKeyword,
    setUserSearchKeyword,
    deleteUsers,
    allUsers,
  } = MemberContainer.useContext();

  useEffect(() => {
    setSelectTagId(+tagId);
    setUserType(Role.USER);
    clear();
  }, [tagId, tags]);

  const clear = () => {
    setDeleteModalOpen(false);
    setAllChecked([], false);
  };

  const pageInfo = PageHelper.getPageInfo({
    ...users,
    gotoPage: setUserPage,
  });

  const handleDelete = () => {
    deleteUsers(checked.map(Number))
      .then(() => showSuccessAlert('삭제'))
      .then(() => clear())
      .catch(showErrorAlert);
  };

  const excelHeaders = [
    { label: 'ID', key: 'id' },
    { label: '이메일', key: 'email' },
    { label: '전화번호', key: 'phone' },
    { label: '적립', key: 'mileage' },
    { label: '누적구매금액', key: 'cumulativePurchaseAmount' },
    { label: '가입일일', key: 'createdAt' },
  ];

  return (
    <div>
      <NomalModalPopup
        title="체크한 고객을 삭제하시겠습니까?"
        description="삭제 후 복구가 불가합니다. 정말 삭제하시겠습니까?"
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onClick={handleDelete}
      />
      <div className="flex justify-end">
        <SearchField
          className="w-96"
          value={userSearchKeyword}
          onChange={e => setUserSearchKeyword(e.target.value)}
        />
      </div>
      <div className="flex justify-between items-center my-4">
        <div className="space-x-4">
          <button
            className="bg-gray-100 px-4 py-2 text-sm rounded-md"
            onClick={() => {
              setDeleteModalOpen(true);
            }}>
            삭제
          </button>
        </div>

        <div className="flex space-x-4">
          {allUsers && (
            <CSVLink
              headers={excelHeaders}
              data={allUsers.data.map(user => {
                return {
                  id: user.id,
                  email: user.email,
                  phone: user.phoneNumber,
                  mileage: user.mileage,
                  cumulativePurchaseAmount: user.cumulativePurchaseAmount,
                  createdAt: new Date(user.createdAt).toLocaleDateString(),
                };
              })}
              filename={'회원목록.csv'}
              className="px-4 py-2 border-2 border-gray-400 rounded-md text-gray-700 flex items-center"
              target="_blank">
              내보내기
              <Download className="ml-1 transform rotate-180" />
            </CSVLink>
          )}
        </div>
      </div>
      {users?.data && (
        <>
          <Datagrid data={users.data}>
            <Field
              label=""
              render={row => (
                <Checkbox
                  checked={isChecked(row.id || -1)}
                  onChange={e => {
                    // 전체
                    if (!row.id) {
                      setAllChecked(users.data.map((item: any) => item.id).concat([-1]), e.target.checked);
                      return;
                    }

                    setChecked(row.id, e.target.checked);
                  }}
                />
              )}
            />
            <NormalField label="ID" property="id" />
            <NormalField label="이름" property="name" />
            <Field
              label="가입일"
              render={row => <div className="text-sm">{row.createdAt?.toLocaleDateString() || ''}</div>}
            />
            <NormalNumberField label="적립금" property="mileage" />
            <NormalNumberField label="누적구매금액" property="cumulativePurchaseAmount" />
            <Field
              label="상세보기"
              render={row => (
                <button
                  className="bg-gray-100 px-4 py-2 text-sm rounded-md"
                  onClick={() => history.push(`/admin/user/normal/detail/${row.id}`)}>
                  상세보기
                </button>
              )}
            />
          </Datagrid>
          <Pagination {...pageInfo} />
        </>
      )}
    </div>
  );
};

export const NomalUserTable = () => {
  return (
    <MemberContainer.ContextProvider>
      <NomalUserTableContent />
    </MemberContainer.ContextProvider>
  );
};
