import { AxiosQuery } from '@/api';
import { createContainer } from '@/container/createContainer';
import { Const } from '@constants/Const';
import { DefaultRequestCrudDto } from '@utils/types/request.crud.dto';
import { useDebounceState } from '@utils/useDebounceState';
import { useState } from 'react';

export const mainHook = () => {
  const [calculatePage, setCalculatePage] = useState<number>(1);
  const [start, setStart] = useDebounceState('', 500);
  const [end, setEnd] = useDebounceState('', 500);

  const { data: calculates } = AxiosQuery.Query.useGetManyBaseCalculateDailyControllerCalculateDailyQuery(
    {
      ...DefaultRequestCrudDto,
      filter: [
        ...DefaultRequestCrudDto.filter,
        ...(start ? [`createdAt||$gte||${start}`] : []),
        ...(end ? [`createdAt||$lte||${end}`] : []),
      ],
      page: calculatePage,
      limit: Const.DefaultPageLimitSize,
      sort: ['id,DESC'],
    },
    { refetchOnWindowFocus: false },
  );

  return {
    start,
    setStart,
    end,
    setEnd,
    calculates,
    setCalculatePage,
  };
};

export const CalculateContainer = createContainer(mainHook);
