import { useAlert } from '@/components/Alert/Alert';
import { EditTopBar } from '@/components/EditTopBar';
import { Toggles } from '@/components/Toggles';
import ProductSelectForm from '@/components/form/ProductSelectForm';
import UserSelectForm from '@/components/form/UserSelectForm';
import { MemberContainer } from '@/container/member';
import { ProductSelectContainer } from '@/container/productSelect';
import DateFormat from '@constants/DateFormat';
import { ContentContainer } from '@container/content';
import { UserContainer } from '@container/user';
import { Checkbox, TextArea, TextField } from '@entropyparadox/reusable-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';


const CouponEditContent = () => {
  const history = useHistory();
  const { id } = useParams() as { id: string };
  const couponId = Number(id);
  const { setSelectCouponId, coupon, updateCoupon } = ContentContainer.useContext();
  const { selectedGroups, setSelectedGroups } = MemberContainer.useContext();
  const { selectedProducts, selectedCategories, setSelectedProducts, setSelectedCategories } =
    ProductSelectContainer.useContext();
  const { myProfile } = UserContainer.useContext();
  // const [imageFileToken, setImageFileToken] = useState<string | null>(null);
  // const [imageFileUrl, setImageFileUrl] = useState<string | null>(null);
  const [name, setName] = useState('');
  const [discountedValue, setDiscountedValue] = useState<number>(0);
  const [discountType, setDiscountType] = useState<string>('');
  const [validFromAt, setValidFromAt] = useState<string | null>(null);
  const [validUntilAt, setValidUntilAt] = useState<string | null>(null);
  const [canUseMileage, setCanUseMileage] = useState(false);
  const [minimumPayAmount, setMinimumPayAmount] = useState<number | null>(null);
  const [adminNote, setAdminNote] = useState('');
  const [isActive, setIsActive] = useState(false);
  const { showWarningAlert, showSuccessAlert, showErrorAlert } = useAlert();

  useEffect(() => {
    if (!couponId) {
      return;
    }
    setSelectCouponId(couponId);
  }, [couponId]);

  useEffect(() => {
    if (!coupon) {
      return;
    }

    if (coupon.categories) setSelectedCategories(coupon.categories);
    if (coupon.products) setSelectedProducts(coupon.products);
    if (coupon.userGroups) setSelectedGroups(coupon.userGroups);
    if (coupon.name) setName(coupon.name);
    if (coupon.discountedPrice) {
      setDiscountedValue(coupon.discountedPrice);
      setDiscountType('원');
    }
    if (coupon.discountedRate) {
      setDiscountedValue(coupon.discountedRate);
      setDiscountType('%');
    }
    if (coupon.validFromAt) setValidFromAt(moment(coupon.validFromAt).format(DateFormat.YYYYMMDDTHHmm));
    if (coupon.validUntilAt) setValidUntilAt(moment(coupon.validUntilAt).format(DateFormat.YYYYMMDDTHHmm));
    if (coupon.canUseMileage) setCanUseMileage(coupon.canUseMileage);
    if (coupon.minimumPayAmount) setMinimumPayAmount(coupon.minimumPayAmount);
    if (coupon.adminNote) setAdminNote(coupon.adminNote);
    if (coupon.isActive && (!coupon.validUntilAt || moment(coupon?.validUntilAt).isAfter(new Date()))) {
      setIsActive(coupon.isActive);
    }
  }, [coupon]);

  const onSaveClick = () => {
    if (!couponId) return;

    if (!name) {
      showWarningAlert('이름을 입력해주세요.');
      return;
    }
    if (!validFromAt || !validUntilAt) {
      showWarningAlert('기간을 입력해주세요.');
      return;
    }
    if (!selectedGroups.length) {
      showWarningAlert('대상유저그룹을 입력해주세요.');
      return;
    }

    updateCoupon({
      adminId: myProfile?.id,
      adminNote: adminNote,
      canUseMileage: canUseMileage,
      discountedPrice: discountedValue,
      discountedRate: 0,
      id: couponId,
      isActive: isActive,
      minimumPayAmount: minimumPayAmount ? minimumPayAmount : 0,
      name: name,
      targetCategoryIds: selectedCategories.map(row => row.id),
      targetProductIds: selectedProducts.map(row => row.id),
      targetUserGroupIds: selectedGroups.map(row => row.id),
      validFromAt: moment(validFromAt, DateFormat.YYYYMMDDTHHmm).toDate(),
      validUntilAt: moment(validUntilAt, DateFormat.YYYYMMDDTHHmm).toDate(),
    })
      .then(() => showSuccessAlert())
      .then(() => history.push('/admin/content/coupon'))
      .catch(showErrorAlert);
  };

  const disabled = true;

  return (
    <>
      <EditTopBar title="쿠폰 상세" saveClick={onSaveClick} />
      <div className="p-6">
        <div className="flex items-center space-x-3 justify-end mb-6">
          <span>사용 불가 / 가능</span>
          <Toggles value={isActive} onChange={setIsActive} />
        </div>
        <div className="grid lg:grid-cols-3 grid-cols-2 gap-6">
          <div className="space-y-4 col-start-1">
            <TextField
              label="이름"
              type="text"
              placeholder="쿠폰 이름을 입력해주세요"
              value={name}
              onChange={e => setName(e.target.value)}
              disabled={disabled}
            />

            <TextField
              label="기간선택"
              type="datetime-local"
              value={validFromAt || ''}
              onChange={e => {
                setValidFromAt(e.target.value);
              }}
              disabled={disabled}
            />
            <TextField
              type="datetime-local"
              value={validUntilAt || ''}
              onChange={e => {
                setValidUntilAt(e.target.value);
              }}
              disabled={disabled}
            />
            <div className="col-span-2">
              <div className="col-start-1 grid grid-cols-2 items-end gap-6">
                <TextField
                  type="number"
                  label="할인금액"
                  value={discountedValue || ''}
                  onChange={e => {
                    setDiscountedValue(Number(e.target.value));
                  }}
                  disabled={disabled}
                />
                <TextField value={discountType} disabled />
              </div>
            </div>
            <div className="">
              <Checkbox
                label="적립금 중복사용 가능"
                checked={canUseMileage}
                onChange={e => setCanUseMileage(e.target.checked)}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="lg:col-start-2 space-y-4">
            <TextArea
              label="관리자메모"
              style={{ height: '200px' }}
              value={adminNote}
              onChange={e => setAdminNote(e.target.value)}
            />

            <div>
              <label className="block mb-1 text-sm text-gray-800">조건설정</label>
              <div
                className="block px-4 w-full h-12 border
                 border-gray-200 rounded-md
                 focus:ring-0 focus-within:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400">
                <input
                  type="number"
                  value={minimumPayAmount || ''}
                  onChange={e => setMinimumPayAmount(Number(e.target.value))}
                  className="border-none sm:text-sm focus:ring-0 placeholder-gray-400 h-11 w-10/12"
                  disabled={disabled}
                />
                <span>원</span>
              </div>
            </div>

            <div className="mt-6">이상 구매시 사용가능</div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-6 mt-8">
          <UserSelectForm disabled />
          <ProductSelectForm disabled />
        </div>
      </div>
    </>
  );
};

export const CouponEdit = () => (
  <ContentContainer.ContextProvider>
    <MemberContainer.ContextProvider>
      <ProductSelectContainer.ContextProvider>
        <CouponEditContent />
      </ProductSelectContainer.ContextProvider>
    </MemberContainer.ContextProvider>
  </ContentContainer.ContextProvider>
);
