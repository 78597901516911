import React, { useEffect, useState } from 'react';
import { SearchIcon } from '@entropyparadox/reusable-react';
import { debounce } from 'lodash';

export interface AutocompleteItem {
  label: string;
  value: any;
}

export interface AutocompleteProps {
  items: AutocompleteItem[];
  searchKeyword?: string;
  onSelect?: (item: AutocompleteItem) => void;
  onChangeKeyword?: (value: string) => void;
}

const Autocomplete = ({
  items,
  onSelect,
  onChangeKeyword,
  searchKeyword = '',
}: AutocompleteProps) => {
  const [keyword, setKeyword] = useState(searchKeyword);
  const [selected, setSelected] = useState<AutocompleteItem>();

  useEffect(() => {
    debounce(() => setKeyword(keyword), 400);
  }, [searchKeyword]);

  return (
    <div className="w-full flex items-end relative">
      <div
        className="flex items-center px-4 w-full h-12
                 rounded-md border border-gray-200 focus-within:border-brand-1">
        <input
          style={{ border: 'none' }}
          type="text"
          className="flex-1 text-sm w-full outline-none focus:outline-none focus:ring-0 placeholder-gray-400"
          value={keyword}
          onChange={e => {
            setSelected(undefined);
            setKeyword(e.target.value);
            onChangeKeyword?.(e.target.value);
          }}
        />
        <SearchIcon />
      </div>
      {!selected && keyword && !!items.length && (
        <div className="absolute left-0 top-18 w-full max-h-72 overflow-y-scroll absolute left-0 right-0">
          {items.map((item, index) => (
            <div
              key={index}
              className="w-full bg-gray-100 px-4 py-3 border-b cursor-pointer"
              onClick={() => {
                setSelected(item);
                onSelect?.(item);
                setKeyword(item.label);
              }}>
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
