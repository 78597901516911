import { useAlert } from '@/components/Alert/Alert';
import { EditTopBar } from '@/components/EditTopBar';
import { ISearchKeyword, SearchKeywordType } from '@api/generated/axios-client';
import { MainContainer } from '@container/main';
import { TextField } from '@entropyparadox/reusable-react';
import { useEffect } from 'react';
import { useList } from 'react-use';


export const SearchRecommend = () => {
  const { searchKeywordsRecommend, refetchSearchKeywords, createSearchKeywords, deleteSearchKeywords } =
    MainContainer.useContext();
  const [modifySearchKeywords, action] = useList<ISearchKeyword>([]);
  const { showSuccessAlert, showErrorAlert } = useAlert();

  useEffect(() => {
    if (!searchKeywordsRecommend?.data || modifySearchKeywords.length !== 0) return;
    action.push(
      ...searchKeywordsRecommend.data,
      ...new Array(30 - searchKeywordsRecommend.data.length).fill({}),
    );
  }, [searchKeywordsRecommend]);
  const save = async () => {
    const filteredItems = modifySearchKeywords.filter(item => item.id || item.keyword);
    const deletedIds = filteredItems.map(item => item.id);

    const createItems = filteredItems.filter(item => item.keyword);
    const createList = createItems.map(item => {
      item.type = SearchKeywordType.Recommend;

      return item;
    });

    try {
      await deleteSearchKeywords(deletedIds).then(async () => {
        await createSearchKeywords(createList).then(() => {
          refetchSearchKeywords();
          showSuccessAlert('저장', () => window.location.reload());
        });
      });
    } catch (e: any) {
      showErrorAlert(e);
    }
  };

  return (
    <>
      <EditTopBar title="추천검색어 관리" saveClick={save} />
      <div className="p-6 grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 ">
        {modifySearchKeywords.map((item, index) => (
          <TextField
            key={index}
            label={String(index + 1)}
            value={item.keyword}
            onChange={event => {
              action.updateAt(index, {
                ...item,
                sorted: item.sorted ? item.sorted : index + 1,
                keyword: event.target.value,
              });
            }}
          />
        ))}
      </div>
    </>
  );
};

export const SearchRecommendPage = () => (
  <MainContainer.ContextProvider>
    <SearchRecommend />
  </MainContainer.ContextProvider>
);
