import { ReactComponent as FileUploadImg } from '../assets/svg/file-upload.svg';
import { useAlert } from '@/components/Alert/Alert';
import { EditTopBar } from '@/components/EditTopBar';
import { PhotoField } from '@/components/PhotoField';
import { Toggles } from '@/components/Toggles';
import { MemberContainer } from '@/container/member';
import {
  Category,
  CategoryBanner,
  FileParameter,
  IRequestUpdateCategoryDto,
  IUserGroupCategory,
  RequestUpdateCategoryDto,
  S3ControllerQuery,
  S3ResourceType,
  UserGroupCategory,
} from '@api/generated/axios-client';
import { Const } from '@constants/Const';
import { ProductCategoryContainer } from '@container/productCategory';
import { Checkbox, Divider, FlexWrap, TextArea, TextField } from '@entropyparadox/reusable-react';
import { find, some, sortBy } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';


const ProductCategoryEditContent = () => {
  const history = useHistory();
  const { id } = useParams() as { id: string };
  const categoryId = Number(id);
  const quickDelivery: boolean = categoryId === Const.QuickDeliveryCategoryId;

  const [category, setCategory] = useState<Category>();
  const [updateCategoryValue, setUpdateCategoryValue] = useState<IRequestUpdateCategoryDto>();

  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [userGroupCategories, setUserGroupCategories] = useState<UserGroupCategory[]>([]);
  const [categoryBanners, setCategoryBanners] = useState<CategoryBanner[]>([]);

  const { showSuccessAlert, showErrorAlert, showWarningAlert } = useAlert();
  const { tags } = MemberContainer.useContext();
  const { categoryList, updateCategory, deleteCategory } = ProductCategoryContainer.useContext();

  useEffect(() => {
    if (!categoryList?.data) return;

    const foundCategory = categoryList.data.find(c => c.id === categoryId);
    setCategory(foundCategory);
    const converted = foundCategory
      ? new RequestUpdateCategoryDto({
          ...foundCategory,
        })
      : undefined;
    setUpdateCategoryValue(converted);
    setSubCategories(
      foundCategory?.subCategories?.sort((a, b) => ((a.sorted || 0) > (b.sorted || 0) ? 1 : -1)) || [],
    );

    const defaultBanner = new CategoryBanner();
    defaultBanner.id = 0;
    defaultBanner.title = '';
    defaultBanner.description = '';
    defaultBanner.imageUrl = '';
    defaultBanner.url = '';
    defaultBanner.isActive = true;
    defaultBanner.sorted = foundCategory?.categoryBanners?.length || 0;
    setCategoryBanners([
      ...(foundCategory?.categoryBanners?.sort((a, b) => ((a.sorted || 0) > (b.sorted || 0) ? 1 : -1)) || []),
      defaultBanner,
    ]);

    setUserGroupCategories(foundCategory?.userGroupCategories || []);
  }, [categoryList]);

  const onSaveClick = () => {
    if (!updateCategoryValue) return;
    if (!updateCategoryValue.iconUrl) {
      return showWarningAlert('카테고리 이미지를 등록해주세요.');
    }
    if (
      some([
        !updateCategoryValue.title,
        !find(userGroupCategories, category => !category.deleted),
        !updateCategoryValue.noticeMessage,
      ])
    ) {
      return showWarningAlert('필수값을 입력해주세요.');
    }

    if (!updateCategoryValue.sorted && updateCategoryValue.sorted !== 0) {
      return showWarningAlert('필수값을 입력해주세요.');
    }

    updateCategory(categoryId, {
      ...updateCategoryValue,
      subCategories: subCategories.filter(sc => !!sc.title),
      categoryBanners: categoryBanners.filter(cb => !!cb.imageUrl),
      userGroupCategories,
    })
      .then(() => showSuccessAlert())
      .then(() => history.push('/admin/product/list'))
      .catch(showErrorAlert);
  };

  const onDeleteClick = () => {
    deleteCategory(categoryId)
      .then(() => showSuccessAlert('삭제'))
      .then(() => history.push('/admin/product/list'))
      .catch(showErrorAlert);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!updateCategoryValue) return;

    const target = e.target as HTMLInputElement;
    setUpdateCategoryValue({ ...updateCategoryValue, [target.name]: e.target.value });
  };

  const handleChangeChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const groupId = Number(target.name);

    if (groupId === Const.AllUserGroupId) {
      const newUserGroupCategories = tags.map(t => {
        const foundItem = userGroupCategories.find(i => i.groupId === t.id);
        if (foundItem) {
          foundItem.deleted = !target.checked;
          foundItem.deletedAt = target.checked ? undefined : new Date();
          return foundItem;
        }

        return new UserGroupCategory({
          deleted: !target.checked,
          deletedAt: target.checked ? undefined : new Date(),
          categoryId,
          groupId: t.id,
        } as IUserGroupCategory);
      });

      setUserGroupCategories([...newUserGroupCategories]);
      return;
    }

    let newUserGroupCategories = userGroupCategories.slice();
    const foundItem = newUserGroupCategories.find(i => i.groupId === groupId);
    if (foundItem) {
      foundItem.deleted = !target.checked;
      foundItem.deletedAt = target.checked ? undefined : new Date();
    } else {
      const item = new UserGroupCategory();
      item.groupId = groupId;
      item.categoryId = categoryId;
      item.deleted = !target.checked;
      item.deletedAt = target.checked ? undefined : new Date();
      newUserGroupCategories.push(item);
    }

    const foundAllItem = newUserGroupCategories.find(i => i.groupId === Const.AllUserGroupId);
    if (!foundAllItem) {
      const item = new UserGroupCategory();
      item.groupId = Const.AllUserGroupId;
      item.categoryId = categoryId;
      newUserGroupCategories.push(item);
    }
    newUserGroupCategories = newUserGroupCategories.map(g => {
      // 전체그룹도 해제
      if (g.groupId === Const.AllUserGroupId) {
        const allUserGroupChecked =
          tags.length - 1 ===
          newUserGroupCategories.filter(g => !g.deleted && g.groupId !== Const.AllUserGroupId).length;
        g.deleted = !allUserGroupChecked;
        g.deletedAt = allUserGroupChecked ? undefined : new Date();
      }
      return g;
    });

    setUserGroupCategories(newUserGroupCategories);
  };

  const checkLastBannerAndAddBannersAndGet = (id: string) => {
    const lastBanner = categoryBanners[categoryBanners.length - 1];
    const newBanners = categoryBanners.slice();

    if (lastBanner.sorted === Number(id)) {
      const cb = new CategoryBanner();
      cb.categoryId = categoryId;
      cb.title = '';
      cb.description = '';
      cb.imageUrl = '';
      cb.url = '';
      cb.isActive = true;
      cb.sorted = lastBanner.sorted + 1;
      newBanners.push(cb);
    }
    return newBanners;
  };

  const onBannerTextChange = (id: string, e: ChangeEvent<HTMLInputElement>) => {
    let newBanners = checkLastBannerAndAddBannersAndGet(id);
    const findBanner = newBanners.find(b => b.sorted === Number(id));
    if (!findBanner) return;
    findBanner.url = e.target.value;
    setCategoryBanners(newBanners);
  };

  const onBannerFileChange = async (id: string, e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.validity.valid) return;
    let newBanners = checkLastBannerAndAddBannersAndGet(id);
    const findBanner = newBanners.find(b => b.sorted === Number(id));
    if (!findBanner) return;
    const file = e.target.files?.item(0);
    if (file) {
      let fileParameter: FileParameter = {
        data: file,
        fileName: file.name,
      };
      const s3UploadDto = await S3ControllerQuery.Client.s3UploadFile(S3ResourceType.Category, fileParameter);
      findBanner.imageUrl = s3UploadDto.fullUrl;
      setCategoryBanners(newBanners);
    }
  };

  const fileUpload = async (file: File) => {
    let fileParameter: FileParameter = {
      data: file,
      fileName: file.name,
    };
    const s3UploadDto = await S3ControllerQuery.Client.s3UploadFile(S3ResourceType.Category, fileParameter);
    setUpdateCategoryValue({ ...updateCategoryValue, iconUrl: s3UploadDto.fullUrl });
  };

  if (!category || !updateCategoryValue) return null;

  return (
    <>
      {/* 카테고리정보 */}
      <EditTopBar
        title="카테고리 관리"
        saveClick={onSaveClick}
        deleteClick={quickDelivery ? undefined : onDeleteClick}
      />

      <div className="p-6">
        <div className="flex justify-between">
          <h3 className="font-semibold text-xl">카테고리 정보</h3>
          <div className="flex items-center space-x-3 justify-end xl:w-5/6 mb-6">
            <span>비공개 / 공개</span>
            <Toggles
              value={updateCategoryValue.isDisplayMenu}
              onChange={isDisplayMenu => setUpdateCategoryValue({ ...updateCategoryValue, isDisplayMenu })}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-6 lg:w-4/6 2xl:w-1/2">
          <div className="col-span-3 w-3/5">
            <label htmlFor="photoUpload">
              <span className="label text-gray-500">
                카테고리 리스트 아이콘 * (사이즈 가이드 : 32px * 32px / 500kb이하)
              </span>
              <div className="relative aspect-w-1 aspect-h-1 rounded-md border-2 border-grey-5 overflow-hidden">
                {updateCategoryValue.iconUrl ? (
                  <>
                    <img
                      className="absolute w-full h-full object-cover"
                      src={updateCategoryValue.iconUrl}
                      alt=""
                    />
                    <div>
                      <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-3 left-3">
                        사진 수정하기
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="absolute w-full h-full rounded object-cover bg-white">
                    <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                      <FileUploadImg />
                      <div className="text-sm text-gray-500 text-center pt-1">
                        이미지를 업로드해주세요.
                        <br />
                        사이즈 가이드 : 32px * 32px / 용량 500kb 이하 / 최대 1장
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </label>
            <input
              type="file"
              id="photoUpload"
              className="hidden"
              accept="image/*"
              onChange={e => {
                if (!e.target.validity.valid) return;
                const file = e.target.files?.item(0);
                file && fileUpload(file);
              }}
            />
          </div>
          <TextField
            label="카테고리명 *"
            name="title"
            value={updateCategoryValue.title}
            onChange={handleChange}
          />
          <TextField
            label="노출우선순위 *"
            type="number"
            name="sorted"
            value={updateCategoryValue.sorted}
            onChange={handleChange}
          />
        </div>

        {/*  권한관리 */}
        <div className="lg:w-4/6 2xl:w-1/2 mt-6">
          <h3 className="font-semibold text-xl mb-4">권한관리 *</h3>
          <FlexWrap>
            {tags.map(item => (
              <div className="pr-6" key={item.id}>
                <Checkbox
                  label={item.name}
                  name={String(item.id)}
                  checked={!!userGroupCategories.find(i => !i.deleted && i.groupId === item.id)}
                  onChange={handleChangeChecked}
                />
              </div>
            ))}
          </FlexWrap>
        </div>

        {/* 배송비설정 */}
        <div className="space-y-4">
          <h3 className="font-semibold text-xl mt-12">배송비 설정</h3>
          <div className="grid grid-cols-3 gap-6 lg:w-4/6 2xl:w-1/2">
            <TextField
              label="기본 배송비"
              type="number"
              name="deliveryAmount"
              value={updateCategoryValue.deliveryAmount}
              disabled
            />
            {!quickDelivery && (
              <>
                <TextField
                  label="제주 배송비"
                  type="number"
                  name="deliveryJejuAmount"
                  value={updateCategoryValue.deliveryJejuAmount}
                  disabled
                />
                <TextField
                  label="산간지역 배송비"
                  type="number"
                  name="deliveryBackCountryAmount"
                  value={updateCategoryValue.deliveryBackCountryAmount}
                  disabled
                />
              </>
            )}
            <div className={quickDelivery ? '' : 'col-start-1'}>
              <TextField
                label="구매금액 합계"
                type="number"
                name="freeDeliveryUpAmount"
                value={updateCategoryValue.freeDeliveryUpAmount}
                disabled
              />
            </div>
            <span className="flex items-end">이상 구매시 무료 배송</span>
            {!quickDelivery && (
              <>
                <div className="col-start-1">
                  <TextField
                    label="교환 배송비"
                    type="number"
                    name="changeAmount"
                    value={updateCategoryValue.changeAmount}
                    disabled
                  />
                </div>
                <TextField
                  label="반품 배송비"
                  type="number"
                  name="returnAmount"
                  value={updateCategoryValue.returnAmount}
                  disabled
                />
              </>
            )}
          </div>
        </div>

        {/* 공통안내사항 */}
        <div className="mt-12">
          <h3 className="font-semibold text-xl mb-4">공통안내사항 *</h3>
          <TextArea name="noticeMessage" value={updateCategoryValue.noticeMessage} onChange={handleChange} />
        </div>

        {/* 세부 카테고리 관리 */}
        {!quickDelivery && (
          <div className="mt-12">
            <h3 className="font-semibold text-xl mb-4">세부카테고리 관리</h3>
            {subCategories
              .filter(item => !item.deleted)
              .map(item => (
                <div className="grid grid-cols-4 gap-6 mb-6 lg:w-4/6 2xl:w-1/2">
                  <TextField
                    label="세부 카테고리명"
                    type="text"
                    value={item.title}
                    onChange={e => {
                      let newSubCategories = subCategories.slice();
                      let findOne = newSubCategories.find(i => i === item);
                      if (findOne) {
                        findOne.title = e.target.value;
                      }
                      setSubCategories(newSubCategories);
                    }}
                  />
                  <TextField
                    label="노출우선순위"
                    type="number"
                    value={item.sorted}
                    onChange={e => {
                      let newSubCategories = subCategories.slice();
                      let findOne = newSubCategories.find(i => i === item);
                      if (findOne) {
                        findOne.sorted = Number(e.target.value);
                      }
                      setSubCategories(newSubCategories);
                    }}
                  />
                  <div className="flex items-center pt-5 space-x-3">
                    <span>비공개 / 공개</span>
                    <Toggles
                      value={item.isDisplayMenu}
                      onChange={e => {
                        let newSubCategories = subCategories.slice();
                        let findOne = newSubCategories.find(i => i === item);
                        if (findOne) {
                          findOne.isDisplayMenu = e;
                        }
                        setSubCategories(newSubCategories);
                      }}
                    />
                  </div>
                  <div
                    className="flex items-center pt-5 space-x-3 text-gray-400 font-light cursor-pointer"
                    onClick={() => {
                      let newSubCategories = subCategories.slice();
                      newSubCategories.splice(
                        newSubCategories.findIndex(i => i === item),
                        1,
                      );
                      setSubCategories(newSubCategories);
                    }}>
                    삭제
                  </div>
                </div>
              ))}
            <div className="grid grid-cols-3 lg:w-4/6 2xl:w-1/2">
              <div
                className="col-span-2 flex justify-center cursor-pointer"
                onClick={() => {
                  const c = new Category();
                  c.title = '';
                  c.sorted = 0;
                  c.isDisplayMenu = false;
                  c.isActive = true;
                  c.categoryId = categoryId;
                  setSubCategories([...subCategories, c]);
                }}>
                +세부카테고리 추가
              </div>
            </div>
          </div>
        )}
      </div>
      <Divider />
      <div className="p-6 mb-12">
        <h3 className="font-semibold text-xl mb-4">카테고리 배너관리</h3>
        <div className="grid grid-cols-3 gap-6 lg:w-4/6 2xl:w-1/2">
          {sortBy(categoryBanners, 'sorted').map(item => (
            <PhotoField
              key={item.sorted}
              id={String(item.sorted)}
              text={item.url}
              onTextChange={onBannerTextChange}
              fileUrl={item.imageUrl || ''}
              onFileChange={onBannerFileChange}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export const ProductCategoryEdit = () => {
  return (
    <MemberContainer.ContextProvider>
      <ProductCategoryEditContent />
    </MemberContainer.ContextProvider>
  );
};
