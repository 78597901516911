import { OrderContainer } from '@/container/order';
import { OrderStatus } from '@api/generated/axios-client';
import { useAlert } from '@components/Alert/Alert';
import RoutePath from '@constants/RoutePath';
import { Select } from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

interface SelectModalPopupProps {
  open: boolean;
  onSubmit: (status: OrderStatus) => void;
  onClose: () => void;
}

const StateType = [
  { id: 1, name: '입금전', type: OrderStatus.Ready },
  { id: 2, name: '결제완료(선물수락대기)', type: OrderStatus.Wait },
  { id: 3, name: '결제완료(배송대상)', type: OrderStatus.Pending },
  { id: 4, name: '배송준비중', type: OrderStatus.Will_delivery },
  { id: 5, name: '배송중', type: OrderStatus.In_delivery },
  { id: 6, name: '배송완료', type: OrderStatus.Done },
  { id: 7, name: '교환처리', type: OrderStatus.Exchanged },
  { id: 8, name: '반품처리', type: OrderStatus.Returned },
  { id: 9, name: '취소처리', type: OrderStatus.Canceled },
];

export const SelectModalPopup: React.FC<SelectModalPopupProps> = ({ onSubmit, onClose, open }) => {
  const history = useHistory();
  const { setOrderStatus, setViewTypeIndex, setCategoryIndex } = OrderContainer.useContext();
  const [value, setValue] = useState<OrderStatus>();
  const { showAlert } = useAlert();

  return (
    <div>
      {open && (
        <div
          className="fixed z-100 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onClose}>
                  <span className="sr-only">Close</span>

                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <h3 className="text-lg leading-6 font-semibold text-gray-900 mb-5" id="modal-title">
                    상태 변경하기
                  </h3>
                  {/* <div className="mt-2 mb-4">
                    <p className="text-sm text-gray-500">{description}</p>
                  </div> */}
                  <Select
                    label="상태 선택"
                    placeholder="변경할 상태를 선택해주세요."
                    onChange={e => {
                      setValue(e.target.value as OrderStatus);
                    }}>
                    {StateType.map(item => (
                      <option key={item.id} value={item.type}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="mt-4 flex flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white sm:text-sm mx-auto"
                  onClick={() => {
                    if (!value) {
                      showAlert({
                        type: 'error',
                        text: '변경할 상태를 선택해주세요.',
                      });
                      return;
                    }

                    if (
                      ![OrderStatus.Exchanged, OrderStatus.Returned, OrderStatus.Canceled].includes(value)
                    ) {
                      setOrderStatus(value);
                      setViewTypeIndex(0);
                      setCategoryIndex(
                        value === OrderStatus.Wait
                          ? 1
                          : value === OrderStatus.Pending
                          ? 2
                          : value === OrderStatus.Will_delivery
                          ? 3
                          : value === OrderStatus.In_delivery
                          ? 4
                          : value === OrderStatus.Done
                          ? 5
                          : 0,
                      );
                    }
                    onSubmit(value);

                    if ([OrderStatus.Exchanged, OrderStatus.Returned, OrderStatus.Canceled].includes(value)) {
                      history.push(
                        OrderStatus.Canceled === value
                          ? RoutePath.OrderCancelList
                          : OrderStatus.Returned === value
                          ? RoutePath.OrderReturnList
                          : OrderStatus.Exchanged === value
                          ? RoutePath.OrderExchangeList
                          : RoutePath.OrderList,
                      );
                    }
                  }}>
                  상태변경하기
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
