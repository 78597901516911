import { Field } from './Field';
import { renderer } from '@/utils/renderer';
import { Const } from '@constants/Const';
import React from 'react';
import { useHistory } from 'react-router';


interface PaymentFieldProps {
  label: string;
  property: string;
  mergedColumn?: boolean;
}

export const PaymentField: React.FC<PaymentFieldProps> = ({ label, property }) => {
  const history = useHistory();

  return (
    <Field
      label="결제정보"
      render={row => (
        <div className="text-sm">
          {row.user?.userGroupMembers?.map((g: any) => g.groupId)?.includes(Const.BlackUserGroupId) ? (
            <>
              <span className="text-xs px-3 py-1 bg-red-100 text-red-500 rounded-full">블랙</span>
              <div className="mt-2"></div>
            </>
          ) : (
            ''
          )}
          {row.orderPayments?.[0]?.impUid ? (
            <>
              <div className="font-semibold mb-2">결제번호 {row.orderPayments[0].pgOrderNumber}</div>
              <div>
                결제일시{' '}
                <span className="font-light">
                  {row.orderPayments[0].paidAt
                    ? new Date(row.orderPayments[0].paidAt).toLocaleDateString()
                    : '-'}
                </span>
              </div>
              <div>
                결제방법{' '}
                <span className="font-light">
                  {renderer.paymentMethod(
                    row.orderPayments[0].payMethod || row.orderPayments[0].paymentMethod,
                  )}
                </span>
              </div>
              <div>
                주문자명{' '}
                <span
                  className="font-medium underline text-blue-500 cursor-pointer"
                  onClick={() => history.push(`/admin/user/normal/detail/${row.userId}`)}>
                  {row.customerName}
                </span>
              </div>
              <div>
                총배송비{' '}
                <span className="text-gray-500 font-light">
                  {String(row.deliveryAmount).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                </span>
              </div>
              <div>
                할인금액{' '}
                <span className="text-gray-500 font-light">
                  -{String(row.orderPayments[0].discountAmount || 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                </span>
              </div>
              <div>
                쿠폰사용금액{' '}
                <span className="text-gray-500 font-light">
                  -{String(row.orderPayments[0].couponAmount || 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                </span>
              </div>
              <div>
                적립금사용{' '}
                <span className="text-gray-500 font-light">
                  -{String(row.orderPayments[0].usedMileage).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                </span>
              </div>
              <div>
                총결제금액{' '}
                <span className="text-gray-500 font-light">
                  {String(row.orderPayments[0].paidAmount).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                </span>
              </div>
              <div>
                적립{' '}
                <span className="text-gray-500 font-light">
                  +
                  {String(row.orderPayments[0].earnedMileage || row.orderPayments[0].mileage).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ',',
                  )}
                  원
                </span>
              </div>
              <div>
                현금영수증
                <span className="text-gray-500 font-light"> {!!row.cashReceiptNumber ? 'O' : 'X'}</span>
              </div>
            </>
          ) : (
            <div>결제정보가 없습니다.</div>
          )}
        </div>
      )}
    />
  );
};
