import { useAlert } from '@/components/Alert/Alert';
import { EditTopBar } from '@/components/EditTopBar';
import { ReactComponent as FileUploadImg } from '../assets/svg/file-upload.svg';

import {
  FileParameter,
  IStory,
  MediaType,
  RequestCreateStoryDtoActionType,
  RequestUpdateStoryDtoActionType,
  S3ControllerQuery,
  S3ResourceType,
  StoryActionType,
} from '@api/generated/axios-client';
import { StoryItemUploadCard } from '@components/StoryItemUploadCard';
import { MainContainer } from '@container/main';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useList } from 'react-use';
export const StoryPage = () => {
  const [uploadMediaUrl, setUploadMediaUrl] = useState('');
  const [productPhoto, setProductPhoto] = useState<File | null | undefined>();
  const { go } = useHistory();
  const { stories, deleteStories, updateStories, createStories } = MainContainer.useContext();
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const [modifyStories, storiesAction] = useList<IStory>([]);
  const [thumbNail, thumbNailAction] = useList<IStory>([]);
  const [deleteStoryIds, setDeleteStoryIds] = useState<number[]>([]);

  const fileUpload = async (file: File) => {
    let fileParameter: FileParameter = {
      data: file,
      fileName: file.name,
    };
    const s3UploadDto = await S3ControllerQuery.Client.s3UploadFile(
      S3ResourceType.RecommendProduct,
      fileParameter,
    );
    setUploadMediaUrl(s3UploadDto.fullUrl);
    thumbNail.map((row, index) => {
      if (index === 0) {
        thumbNailAction.updateAt(index, {
          ...row,
          mediaType: MediaType.Image,
          sorted: 0,
          actionUrl: '',
          mediaUrl: s3UploadDto.fullUrl || row.mediaUrl,
          title: '',
          description: 'THUMBNAIL',
          actionText: '',
          actionType: StoryActionType.None,
        });
      }
    });
  };

  useEffect(() => {
    if (!stories?.data || modifyStories.length !== 0) return;
    const filterThumbnail = stories.data.filter(story => story.description === 'THUMBNAIL');
    const filteredStories = stories.data.filter(story => story.description !== 'THUMBNAIL');
    storiesAction.push(...filteredStories, ...new Array(1).fill({}));
    thumbNailAction.push(...filterThumbnail, ...new Array(1).fill({}));
  }, [stories]);

  useEffect(() => {
    if (!modifyStories.length) {
      return;
    }

    const lastStory = modifyStories[modifyStories.length - 1];
    if (lastStory.actionUrl || lastStory.mediaUrl || lastStory.actionText) {
      storiesAction.push(...new Array(1).fill({}));
    }
  }, [modifyStories]);

  return (
    <>
      <EditTopBar
        title="스토리 관리"
        saveClick={() => {
          Promise.all([
            deleteStoryIds.length > 0 ? deleteStories(deleteStoryIds) : null,
            modifyStories.map(item => {
              if (!(item.mediaUrl && item.actionUrl && item.sorted)) {
                return;
              }

              if (item.id) {
                return updateStories(item.id, {
                  title: item.title,
                  description: item.description,
                  mediaUrl: item.mediaUrl,
                  mediaType: item.mediaType,
                  actionText: item.actionText || '',
                  actionType:
                    item.actionType === StoryActionType.Button
                      ? RequestUpdateStoryDtoActionType.Button
                      : RequestUpdateStoryDtoActionType.None,
                  actionUrl: item.actionUrl,
                  sorted: item.sorted,
                  isActive: true,
                  adminUserId: item.adminUserId,
                });
              } else {
                return createStories({
                  title: item.title,
                  description: item.description,
                  mediaUrl: item.mediaUrl,
                  mediaType: item.mediaType,
                  actionText: item.actionText || '',
                  actionUrl: item.actionUrl,
                  sorted: item.sorted,
                  actionType:
                    item.actionType === StoryActionType.Button
                      ? RequestCreateStoryDtoActionType.Button
                      : RequestCreateStoryDtoActionType.None,
                  isActive: true,
                  adminUserId: item.adminUserId,
                });
              }
            }),
            thumbNail.map(item => {
              if (!item.description) {
                return;
              }
              if (item.id) {
                return updateStories(item.id, {
                  title: item.title,
                  description: item.description,
                  mediaUrl: item.mediaUrl,
                  mediaType: item.mediaType,
                  actionText: item.actionText || '',
                  actionType:
                    item.actionType === StoryActionType.Button
                      ? RequestUpdateStoryDtoActionType.Button
                      : RequestUpdateStoryDtoActionType.None,
                  actionUrl: item.actionUrl,
                  sorted: item.sorted,
                  isActive: true,
                  adminUserId: item.adminUserId,
                });
              } else {
                return createStories({
                  title: item.title,
                  description: item.description,
                  mediaUrl: item.mediaUrl,
                  mediaType: item.mediaType,
                  actionText: item.actionText || '',
                  actionUrl: item.actionUrl,
                  sorted: item.sorted,
                  actionType:
                    item.actionType === StoryActionType.Button
                      ? RequestCreateStoryDtoActionType.Button
                      : RequestCreateStoryDtoActionType.None,
                  isActive: true,
                  adminUserId: item.adminUserId,
                });
              }
            }),
          ])
            .then(() => {
              go(0);
              showSuccessAlert();
            })
            .catch(showErrorAlert);
        }}
      />

      <div className="p-6 mb-12">
        <div className="font-bold text-xl mb-5">
          썸네일 추가{' '}
          <span className="text-gray-500 text-base font-normal">*이미지 가이드 300px x 320px / 1mb 이하</span>
        </div>

        <div className="grid gap-6 space-y-2 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5  mb-5">
          <label htmlFor="certificateBrokerUpload">
            <div className="relative pb-5/6 rounded-md border-2 border-grey-5">
              {productPhoto || thumbNail[0]?.mediaUrl ? (
                <>
                  <img
                    className="absolute w-full h-full rounded object-cover"
                    src={productPhoto ? URL.createObjectURL(productPhoto) : thumbNail[0]?.mediaUrl}
                    alt=""
                  />
                  <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-3 left-3">
                    사진 수정하기
                  </div>
                </>
              ) : (
                <div className="absolute w-full h-full rounded object-cover bg-white">
                  <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                    <FileUploadImg />
                    <div className="text-sm text-gray-500 text-center pt-1">
                      이미지를 업로드해주세요.
                      <br />
                      300px x 320px / 1mb 이하 / 최대 1장
                    </div>
                  </div>
                </div>
              )}
            </div>
          </label>
          <input
            type="file"
            id="certificateBrokerUpload"
            className="hidden"
            accept="license/*"
            onChange={e => {
              if (!e.target.validity.valid) return;
              const file = e.target.files?.item(0);
              if (file) {
                setProductPhoto(file);
                fileUpload(file);
              }
            }}
          />
        </div>
        <div className="font-bold text-xl mb-5">
          이미지 추가{' '}
          <span className="text-gray-500 text-base font-normal">
            *버튼 미사용시에도 썸네일 클릭시 url로 이동됩니다. / 이미지 가이드(420px X 910px, 1mb이하)
          </span>
        </div>
        <div className="grid gap-6 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {modifyStories.map((row, index) => (
            <StoryItemUploadCard
              key={row.id}
              item={row}
              onChange={data => {
                storiesAction.updateAt(index, {
                  ...row,
                  mediaType: data.mediaType,
                  sorted: data.sorted,
                  actionUrl: data.actionUrl,
                  mediaUrl: data.mediaUrl,
                  title: data.title,
                  description: data.description,
                  actionText: data.actionText,
                  actionType: !data.isActionType ? StoryActionType.None : StoryActionType.Button,
                });
              }}
              onDelete={() => {
                row.id && setDeleteStoryIds([...deleteStoryIds, row.id]);
                storiesAction.removeAt(index);
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export const StoriesPage = () => (
  <MainContainer.ContextProvider>
    <StoryPage />
  </MainContainer.ContextProvider>
);
