import { ExposureCategoryModalPopup } from '@/components/Alert/ExposureCategoryModalPopup';
import { Datagrid } from '@/components/Datagrid';
import { Field } from '@/components/Field/Field';
import { JoinCategoryField, NormalExposureCategoryField, NormalField } from '@/components/Field/NormalField';
import Pagination from '@/components/Pagination';
import { TopBar } from '@/components/TopBar';
import { DisplayCategory } from '@api/generated/axios-client';
import { MainContainer } from '@container/main';
import { ProductCategoryContainer } from '@container/productCategory';
import { PageHelper } from '@utils/PageHelper';
import React, { useState } from 'react';

export const ExposureCategoryContent = () => {
  const { displayCategories, setDisplayCategoryPage } = MainContainer.useContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<any /*DisplayCategoryDto*/ | null>(null);
  if (!displayCategories) return null;
  const pageInfo = PageHelper.getPageInfo({
    ...displayCategories,
    gotoPage: setDisplayCategoryPage,
  });

  return (
    <>
      {selected && (
        <ExposureCategoryModalPopup
          open={isOpen}
          item={selected}
          onClose={() => {
            setIsOpen(false);
            setSelected(null);
          }}
        />
      )}
      <TopBar title="노출 카테고리 관리" />
      <div className="p-6">
        <div className="flex items-center my-4">
          <div className="flex space-x-4">
            <button
              className="bg-black text-white px-4 py-2 text-sm rounded-md h-11"
              onClick={() => {
                setIsOpen(true);
                setSelected({
                  createdAt: '',
                  id: 0,
                  categoryId: 0,
                  categoryTitle: '',
                  sorted: 0,
                });
              }}>
              추가하기
            </button>
          </div>
        </div>
        <Datagrid data={displayCategories.data}>
          <NormalField label="ID" property="id" />
          <Field
            label="작성일"
            render={row => <div className="text-sm">{new Date(row.createdAt).toLocaleDateString()}</div>}
          />
          <JoinCategoryField label="카테고리명" property="title" />
          <NormalExposureCategoryField label="노출우선순위" property="sorted" />
          <Field
            label="상세보기"
            render={row => (
              <button
                className="bg-gray-100 px-4 py-2 text-sm rounded-md"
                onClick={() => {
                  setIsOpen(true);
                  setSelected(row as DisplayCategory);
                }}>
                상세보기
              </button>
            )}
          />
        </Datagrid>
        <Pagination {...pageInfo} />
      </div>
    </>
  );
};

export const ExposureCategoryPage = () => (
  <MainContainer.ContextProvider>
    <ProductCategoryContainer.ContextProvider>
      <ExposureCategoryContent />
    </ProductCategoryContainer.ContextProvider>
  </MainContainer.ContextProvider>
);
