import { Review } from '@api/generated/axios-client';
import { useAlert } from '@components/Alert/Alert';
import { ContentReviewContainer } from '@container/review';
import { Button, Checkbox } from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';

interface FileModalPopupProps {
  reviewId: number;
  open: boolean;
  onClose: () => void;
}

export const ReviewPopup: React.FC<FileModalPopupProps> = ({ reviewId, onClose, open }) => {
  const { review, setReviewId, updateReview } = ContentReviewContainer.useContext();
  const [reviewDto, setReviewDto] = useState<Review /*ReviewItemDataDto*/>();
  const [isBestReview, setIsBestReview] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>();
  const { showSuccessAlert, showErrorAlert } = useAlert();

  useEffect(() => {
    if (reviewId || !isNaN(reviewId)) {
      setReviewId(+reviewId);
    }
  }, [reviewId]);

  useEffect(() => {
    if (!review) {
      return;
    }
    setReviewDto(review);
    setIsBestReview(review.isBest);
    setIsActive(review.isActive);
    setThumbnailUrl(
      review.product.productMedias?.sort((a, b) => ((a.sorted || 0) > (b.sorted || 0) ? 1 : -1))[0]
        ?.thumbnailUrl,
    );
  }, [review]);

  console.log('thumbnailUrl=====', review);

  if (!reviewDto) return null;

  const onSave = (isBestReview: boolean, isActive: boolean) => {
    if (reviewId && !isNaN(reviewId)) {
      updateReview(reviewId, {
        isBest: isBestReview,
        isActive: isActive,
      })
        .then(() => showSuccessAlert())
        .catch(showErrorAlert);
    }
  };

  return (
    <div>
      {open && (
        <div
          className="fixed inset-0 overflow-y-auto z-100"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"></div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onClose}>
                  <span className="sr-only">Close</span>

                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <h3 className="text-lg leading-6 font-semibold text-gray-900 mb-5" id="modal-title">
                    리뷰 상세보기
                  </h3>
                  <div>
                    <h3 className="font-semibold mb-2">구매한 상품</h3>
                    <div className="flex space-x-3 w-full items-center">
                      <div className="relative rounded-md w-16 h-16">
                        <img
                          className="absolute w-full h-full rounded-md object-cover"
                          src={thumbnailUrl}
                          alt=""
                        />
                      </div>
                      <div>
                        <div className="text-lg">{reviewDto.orderProduct.productName}</div>
                        <div className="font-light">
                          {reviewDto.orderProduct.productOptions.map(option => option.name)}
                        </div>
                      </div>
                    </div>

                    <div className="w-full h-0.5 bg-gray-100 my-6"></div>

                    <div className="grid grid-cols-3 gap-6">
                      {reviewDto.reviewMedias.map(media => (
                        <div className="relative rounded-md w-full pb-full">
                          {media.filetype === 'video' ? (
                            <video
                              className="absolute w-full h-full rounded-md object-cover"
                              key={media.id}
                              controls
                              src={`${media.originalUrl}#t=0.1`}
                            />
                          ) : (
                            <img
                              className="absolute w-full h-full rounded-md object-cover"
                              src={media.thumbnailUrl}
                              alt=""
                            />
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="w-full h-0.5 bg-gray-100 my-6"></div>

                    <div className="text-gray-500 text-sm mb-2">
                      {reviewDto.user.name}・{new Date(reviewDto.createdAt).toLocaleDateString()}
                    </div>
                    <div className="mb-8">{reviewDto.content}</div>
                    <Checkbox
                      label="베스트후기 선정"
                      checked={isBestReview}
                      onChange={e => setIsBestReview(e.target.checked)}
                    />
                    <Checkbox
                      label="숨김 처리"
                      checked={!isActive}
                      onChange={e => setIsActive(!e.target.checked)}
                    />
                    <div className="flex flex-col mt-10">
                      <Button text="저장하기" onClick={() => onSave(isBestReview, isActive)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
