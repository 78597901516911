import { Field } from './Field';
import moment from 'moment';
import React from 'react';

interface OrderFieldProps {
  label: string;
  property: string;
  mergedColumn?: boolean;
}

const OrderField: React.FC<OrderFieldProps> = () => {
  return (
    <Field
      label="주문번호 / 일자"
      render={row => (
        <div className="text-sm space-y-1">
          <div>{row.orderNumber ? row.orderNumber : row.pgOrderNumber}</div>
          <div className="text-gray-400">{moment(row.orderedAt).format('YYYY.MM.DD HH:mm:ss')}</div>
        </div>
      )}
    />
  );
};

export default OrderField;
