import { ReactComponent as FileUploadImg } from '../assets/svg/file-upload.svg';
import { Checkbox } from '../components/Checkbox';
import { TextArea } from '../components/TextArea';
import { Toggles } from '@/components/Toggles';
import {
  FileParameter,
  IRequestCreateBannerDto,
  MediaType,
  S3ControllerQuery,
  S3ResourceType,
} from '@api/generated/axios-client';
import { useAlert } from '@components/Alert/Alert';
import AlertType from '@constants/AlertType';
import { MainContainer } from '@container/main';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Label } from '@components/Label';

export const BannerInfoPage = () => {
  const { mainBannerTabIndex, mainBanner, createMainBanners, updateMainBanners } = MainContainer.useContext();
  const { showAlert, showSuccessAlert, showErrorAlert } = useAlert();
  const [photo, setPhoto] = useState<File | null | undefined>();
  const [thumbNail, setThumbNail] = useState<File | null | undefined>();
  const [actionUrl, setActionUrl] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [mainBannerFileUrl, setMainBannerFileUrl] = useState('');
  const [mainBannerThumbnailFileUrl, setMainBannerThumbnailFileUrl] = useState('');
  const [mediaTypeImage, setMediaTypeImage] = useState(false);
  const [mediaTypeVideo, setMediaTypeVideo] = useState(false);

  useEffect(() => {
    if (!mainBanner?.data.length) return;

    setIsActive(mainBanner.data[0]?.isActive);
    setActionUrl(mainBanner.data[0]?.actionUrl);
    setMainBannerFileUrl(mainBanner.data[0]?.mediaUrl);
    setMainBannerThumbnailFileUrl(mainBanner.data[0]?.thumbnailUrl || '');
    if (mainBanner.data[0]?.mediaType === 'video') {
      setMediaTypeVideo(true);
      setMediaTypeImage(false);
    } else {
      setMediaTypeVideo(false);
      setMediaTypeImage(true);
    }
  }, [mainBanner]);

  const clear = () => {
    setIsActive(true);
    setActionUrl('');
  };

  const onSave = () => {
    if (!mediaTypeImage && !mediaTypeVideo) {
      showAlert({
        type: AlertType.WARNING,
        text: '배너 혹은 영상을 선택하여 주십시오.',
      });
      return;
    } else if (mediaTypeImage && mediaTypeVideo) {
      showAlert({
        type: AlertType.WARNING,
        text: '배너 혹은 영상 선택은 하나만 선택하실 수 있습니다.',
      });
      return;
    }

    let mediaType;
    if (mediaTypeImage) {
      mediaType = MediaType.Image;
    } else {
      mediaType = MediaType.Video;
    }

    if (!mainBanner?.data[0]?.id) {
      const params: IRequestCreateBannerDto = {
        actionUrl: actionUrl,
        isActive: isActive,
        mediaType: mediaType,
        mediaUrl: mainBannerFileUrl,
        thumbnailUrl: mainBannerThumbnailFileUrl,
        sorted: mainBannerTabIndex,
        sectionType: mainBannerTabIndex,
      };
      createMainBanners(params)
        .then(() => showSuccessAlert())
        .catch(showErrorAlert);
    } else {
      updateMainBanners(mainBanner.data[0].id, {
        isActive: isActive,
        actionUrl: actionUrl,
        mediaType: mediaType,
        thumbnailUrl: mainBannerThumbnailFileUrl,
        mediaUrl: mainBannerFileUrl,
      })
        .then(() => showSuccessAlert())
        .catch(showErrorAlert);
    }
  };

  const fileUpload = async (file: File) => {
    console.log('BannerInfoPage - fileUpload', file);
    let fileParameter: FileParameter = {
      data: file,
      fileName: file.name,
    };

    const s3UploadDto = await S3ControllerQuery.Client.s3UploadFile(S3ResourceType.Banner, fileParameter);
    console.log('BannerInfoPage - fileUpload', s3UploadDto);
    setMainBannerFileUrl(s3UploadDto.fullUrl);
  };

  const thumbnailFileUpload = async (file: File) => {
    let fileParameter: FileParameter = {
      data: file,
      fileName: file.name,
    };

    const s3UploadDto = await S3ControllerQuery.Client.s3UploadFile(S3ResourceType.Banner, fileParameter);

    setMainBannerThumbnailFileUrl(s3UploadDto.fullUrl);
  };

  const handleChangeChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.name === 'mediaTypeImage') {
      setMediaTypeImage(e.target.checked);
      setMediaTypeVideo(false);
    } else {
      setMediaTypeImage(false);
      setMediaTypeVideo(e.target.checked);
    }
  };

  return (
    <div className="space-y-5 mt-5">
      <div className="flex justify-between">
        <div className="flex space-x-3">
          <Checkbox
            label="배너버전"
            name="mediaTypeImage"
            checked={mediaTypeImage}
            onChange={e => handleChangeChecked(e)}
          />
          <Checkbox
            label="영상버전"
            name="mediaTypeVideo"
            checked={mediaTypeVideo}
            onChange={e => handleChangeChecked(e)}
          />
        </div>
        <div className="flex items-center space-x-3 justify-end xl:w-5/6 mb-6">
          <span>비공개 / 공개</span>
          <Toggles value={isActive} onChange={setIsActive} />
        </div>
      </div>
      <label htmlFor="photoUpload">
        <Label text="사진/영상 (사진사이즈 가이드 : 440px * 120px)" />
        <div
          className={`relative rounded-md border-2 border-grey-5 overflow-hidden ${
            mediaTypeImage ? 'aspect-h-3 aspect-w-11 w-full' : 'w-96 h-96'
          }`}>
          {photo || mainBannerFileUrl ? (
            <>
              {mediaTypeImage ? (
                <img
                  className="absolute w-full h-full object-cover"
                  src={photo ? URL.createObjectURL(photo) : mainBannerFileUrl}
                  alt=""
                />
              ) : (
                <video
                  className="absolute w-full h-full object-cover"
                  controls
                  poster={thumbNail ? URL.createObjectURL(thumbNail) : mainBannerThumbnailFileUrl}
                  src={photo ? URL.createObjectURL(photo) : mainBannerFileUrl}
                />
              )}
              <div>
                {!mediaTypeVideo ? (
                  <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-3 left-3">
                    사진 수정하기
                  </div>
                ) : (
                  <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-3 left-3">
                    동영상 수정하기
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="absolute w-full h-full rounded object-cover bg-white">
              <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                <FileUploadImg />
                <div className="text-sm text-gray-500 text-center pt-1">
                  1mb 이하이 이미지(jpg, png / 440px * 120px)
                  <br />
                  또는 영상(mp4) 파일을 업로드해주세요(1장)
                </div>
              </div>
            </div>
          )}
        </div>
      </label>
      {mediaTypeVideo && (
        <div>
          <label htmlFor="thumbnailUpload">
            <div className="flex items-center justify-start text-black-500">
              <p className="font-bold text-xl">썸네일 추가</p>
              <Label text="(448px*150px)" />
            </div>
            <div className="relative w-96 h-96 rounded-md border-2 border-grey-5 overflow-hidden">
              {thumbNail || mainBannerThumbnailFileUrl ? (
                <>
                  {mainBanner?.data[0].mediaType === 'video' && (
                    <img
                      className="absolute w-full h-full object-cover"
                      src={thumbNail ? URL.createObjectURL(thumbNail) : mainBannerThumbnailFileUrl}
                      alt=""
                    />
                  )}
                  <div>
                    <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-3 left-3">
                      썸네일 수정하기
                    </div>
                  </div>
                </>
              ) : (
                <div className="absolute w-full h-full rounded object-cover bg-white">
                  <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                    <FileUploadImg />
                    <div className="text-sm text-gray-500 text-center pt-1">
                      이미지(jpg, png) 또는 영상(mp4) 파일을
                      <br />
                      업로드해주세요(1장)
                    </div>
                  </div>
                </div>
              )}
            </div>
          </label>
        </div>
      )}

      <input
        type="file"
        id="photoUpload"
        className="hidden"
        accept="license/*"
        onChange={e => {
          if (!e.target.validity.valid) return;
          const file = e.target.files?.item(0);
          if (file) {
            setPhoto(file);
            fileUpload(file);
          }
        }}
      />
      <input
        type="file"
        id="thumbnailUpload"
        className="hidden"
        accept="license/*"
        onChange={e => {
          if (!e.target.validity.valid) return;
          const file = e.target.files?.item(0);

          if (file) {
            setThumbNail(file);
            thumbnailFileUpload(file);
          }
        }}
      />
      {!mediaTypeVideo && (
        <TextArea
          label="연결링크"
          className="h-18"
          value={actionUrl}
          disabled={mediaTypeVideo}
          onChange={e => setActionUrl(e.target.value)}
        />
      )}
      <button className="bg-black text-white px-4 py-2 text-sm rounded-md" onClick={onSave}>
        저장하기
      </button>
    </div>
  );
};
